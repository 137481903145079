
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";
import { AppState, PERMISSION } from "@/store/states/AppStateMachine";
import { Viewport } from "@/store/modules/Viewport";

import { TutoArrowEnum } from "@/components/Tuto/Tuto.vue";
import Tuto from "@/components/Tuto/Tuto.vue";

export default defineComponent({
  components: {
    Tuto,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      tutoSlides: [
        {
          text: `Open your mind and be fully\naware of the unexpected and\nwonderful.`,
          buttonLabel: "Skip",
          arrow: TutoArrowEnum.STRAIGHT,
          rotate: 1,
          isIntro: true,
        },
        {
          text: "Be with us, live in the moment,\nand tell us how you feel today to be\npart of our participatory art piece.",
          buttonLabel: "Skip",
          arrow: TutoArrowEnum.SWIRL,
          rotate: -2,
          isIntro: true,
        },
        {
          doodle: {
            path: Viewport.isLandscape
              ? "tutorials/I_Feel_Interaction_desktop"
              : "tutorials/I_Feel_Interaction_mobile",
            frames: 8,
            autoplay: true,
            loop: true,
          },
          // image: "/assets/feel-1.png",
          text: "Express your emotion\nto your device camera\nto set your mood",
          buttonLabel: "Okay!",
          border: true,
          rotate: -2,
          requires: PERMISSION.VIDEO,
          fallback: {
            doodle: {
              path: "tutorials/I_Feel_Fallback",
              frames: 8,
              autoplay: true,
              loop: true,
            },
            // image: "/assets/feel-1-fallback.png",
            text: "Use the arrows\nto choose your mood",
            buttonLabel: "Okay!",
            border: true,
          },
        },
      ],
    };
  },

  computed: {
    skipIntro(): boolean {
      return this.state?.context?.skipTutoIntro || false;
    },

    skipOutro(): boolean {
      return true;
    },

    isStateTuto(): boolean {
      return this.state.value["step_feel"] === "tuto";
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.fromTo(
        el,
        {
          x: !this.skipIntro ? Viewport.windowWidth : 0,
          opacity: !this.skipIntro ? 1 : 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: !this.skipIntro ? 2 : 0.5,
          ease: !this.skipIntro ? "quart.inOut" : "sine.out",
        },
        "start"
      );
    },

    afterEnter() {},

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.fromTo(
        el,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
          ease: "quart.inOut",
        },
        "start"
      );
    },

    tutoComplete() {
      this.send("ENTER");
    },
  },
});
