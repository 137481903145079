
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { UserCard } from "@/store/modules/UserCard";
import { Face, IFace } from "@/store/modules/Faces";
import { Viewport } from "@/store/modules/Viewport";
import UIBtnPrimary from "@/components/UIBtn/UIBtnPrimary.vue";
import Tracking from "@/core/Tracking";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  computed: {
    backgroundImage(): string | null {
      return UserCard.card?.toDataURL() || null;
    },

    isGenerated() {
      return UserCard.generated;
    },

    isRoll() {
      return Face.isRoll;
    },

    faceTip(): IFace {
      return {
        x: Face.x,
        y: Face.y,
        width: Face.width,
        age: Face.age,
        guid: Face.guid,
        mood: Face.mood,
        mantra: Face.mantra,
        country: Face.country,
      };
    },

    faceTipStyle() {
      if (Viewport.windowWidth <= 232 * 2.5) {
        // 232px is the width of the tooltip
        return {
          transform: `translate(${
            (Viewport.windowWidth - 232) * 0.5
          }px, 132px)`,
        };
      }

      return {
        transform: `translate(${
          (this as any).faceTip?.x + (this as any).faceTip?.width
        }px, ${(this as any).faceTip?.y}px)`,
      };
    },

    participateBtnLabel(): string {
      return Viewport.isDesktop
        ? "participate to the art piece"
        : "participate";
    },

    shareLabel(): string {
      return Viewport.isDesktop ? "Share your Card" : "Share Yours";
    },

    isStateDefault(): boolean {
      return this.state?.value["artwork"] === "default";
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      if (this.state?.event?.type === "INTRO_COMPLETE") {
        tl.addLabel("start", "+=0");
      } else {
        tl.addLabel("start", "+=2");
      }

      tl.fromTo(
        el,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: "sine.out" },
        "start"
      );
      tl.add((this.$refs.shareBtn as typeof UIBtnPrimary)?.enter(), "-=0.25");
      if (this.$refs.shareBtn) {
        tl.fromTo(
          ".ui-card-share__background",
          {
            rotate: "-30deg",
            opacity: 0,
            y: 200,
          },
          {
            rotate: "0deg",
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "cta.y.out",
          },
          "-=0.75"
        );
      }
      tl.add(
        (this.$refs.participateBtn as typeof UIBtnPrimary)?.enter(),
        "-=0.25"
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");
      tl.to(el, { opacity: 0, duration: 0.5, ease: "sine.out" }, "start");
    },

    onBackClick() {
      Tracking.eventPush("back-to-home", "xp-cta-i-understand-2");
      this.send("EXIT");
    },

    shareMouseEnter() {
      gsap.to(".ui-card-share__background", {
        rotate: "12deg",
        y: -38,
        duration: 0.5,
        ease: "cta.y.out",
      });
    },

    shareMouseLeave() {
      gsap.to(".ui-card-share__background", {
        rotate: 0,
        y: 0,
        duration: 0.5,
        ease: "cta.y.out",
      });
    },

    onShare() {
      (this.$refs.shareBtn as typeof UIBtnPrimary)
        ?.leave()
        .eventCallback("onReverseComplete", () => {
          this.send("ENTER");
        });

      gsap.to(".ui-card-share__background", {
        opacity: 0,
        y: -200,
        duration: 1,
        ease: "cta.y.out",
      });
    },

    onParticipate() {
      (this.$refs.participateBtn as typeof UIBtnPrimary)
        ?.leave()
        .eventCallback("onReverseComplete", () => {
          Tracking.eventPush(
            "participate-to-the-art-piece",
            "xp-cta-i-understand-3"
          );
          this.send("EXIT");
        });
    },
  },
});
