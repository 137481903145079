import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "view-default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tuto = _resolveComponent("Tuto")!

  return (_openBlock(), _createBlock(_Transition, {
    onEnter: _ctx.enter,
    onAfterEnter: _ctx.afterEnter,
    onLeave: _ctx.leave,
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isStateTuto)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Tuto, {
              content: _ctx.tutoSlides,
              skipIntro: _ctx.skipIntro,
              skipOutro: false,
              onTutoComplete: _ctx.tutoComplete
            }, null, 8, ["content", "skipIntro", "onTutoComplete"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onEnter", "onAfterEnter", "onLeave"]))
}