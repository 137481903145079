
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  props: {
    slide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showAgenda(): boolean {
      return !(this as any).state.matches("agenda.default");
    },

    showCollaboration(): boolean {
      return !(this as any).state.matches("collab.default");
    },
  },

  methods: {
    onCollabClick() {
      this.send("COLLAB");
    },
    onAgendaClick() {
      this.send("AGENDA");
    },
  },
});
