import { gsap } from "gsap";
import CustomEase from "@/utils/CustomEase";

gsap.registerPlugin(CustomEase);

CustomEase.create("customOut", "0.33, 0, 0.67, 1");
CustomEase.create("customOut2", "0.33, 0, 0, 1");
CustomEase.create("customOut3", "0.22, 1.44, 0.36, 1");
CustomEase.create("archeOut", "0.84, 0, 0.16, 1");
CustomEase.create("cta.x.out", "0.25, 1.6, 0.3, 1");
CustomEase.create("cta.y.out", "0.5, 1.6, 0.3, 1");
