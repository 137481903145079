
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { AppStore } from "@/store/modules/AppStore";
import { Viewport } from "@/store/modules/Viewport";
import { UserCard } from "@/store/modules/UserCard";
import PageFooter from "@/components/PageFooter/PageFooter.vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setCurrentVoice } from "@/store/modules/Sound";
import Tracking from "@/core/Tracking";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  components: {
    "page-footer": PageFooter,
  },

  data() {
    return {
      email: "",
      feedback: false,
      feebackTitle: "Your card <br />has been sent!",
      feedbackDetails: `If you want to be informed about Ruinart experiences, subscribe to our newsletter.`,
    };
  },

  computed: {
    backgroundImage(): string | null {
      return UserCard?.card?.toDataURL() || null;
    },

    isStateShare(): boolean {
      return this.state?.value["artwork"] === "share";
    },
  },

  methods: {
    async submit() {
      const token = await grecaptcha.execute(
        process.env.VUE_APP_CAPTCHA_SITE_KEY,
        {
          action: "submit",
        }
      );
      const guid = UserCard.guid;

      const data = {
        email: this.email,
        guid,
        token,
      };

      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_PATH}/send`,
        settings
      );

      if (!response.ok) {
        // const message = `An error has occured: ${response.status}`;
        console.error((response as any)?.error);
        this.feebackTitle = "Error";
        this.feedbackDetails = (response as any)?.error;
        // throw new Error(message);
      } else {
        this.feebackTitle = "Your card <br />has been sent!";
        this.feedbackDetails = `If you want to be informed about Ruinart experiences, subscribe to our newsletter.`;

        Tracking.eventPush("get-your-card", "xp-cta-thank-you");
      }

      this.feedback = true;
    },

    close() {
      AudioManager.stop(AUDIO_ID.VOICE_THANKYOU);
      setCurrentVoice(null);
      this.send("EXIT");
    },

    enter(el: Element, onComplete: GSAPCallback) {
      AudioManager.play(AUDIO_ID.VOICE_THANKYOU);
      setCurrentVoice(AUDIO_ID.VOICE_THANKYOU);

      const viewportFactor = Viewport.isDesktop ? 1 : 1.1;

      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.addLabel("start", "+=0.1");

      tl.fromTo(
        this.$refs.shareCard as Element,
        { x: Viewport.windowWidth * 1.5 },
        { x: 0, duration: 2, ease: "quart.inOut" },
        "start"
      );

      tl.fromTo(
        el.querySelector(".Footer"),
        { x: Viewport.windowWidth * viewportFactor },
        { x: 0, duration: 2, ease: "quart.inOut" },
        "start"
      );

      tl.fromTo(
        this.$refs.shareForm as Element,
        { opacity: 0 },
        { opacity: 1, ease: "quart.inOut" }
      );

      tl.fromTo(
        Array.from(el.querySelectorAll(".Footer .MenuItem")),
        { opacity: 0, yPercent: 100 * viewportFactor },
        {
          opacity: 1,
          yPercent: 0,
          duration: 1,
          ease: "cubic.out",
          stagger: 0.1,
        }
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      const viewportFactor = Viewport.isDesktop ? 1 : 1.1;

      tl.to(
        el.querySelector(".ShareCard"),
        {
          x: Viewport.windowWidth * 1.5,
          duration: 2.2,
          ease: "quart.inOut",
        },
        "start"
      );

      tl.to(
        el.querySelector(".BtnClose"),
        {
          opacity: 0,
          duration: 0.5,
          ease: "linear",
        },
        "start"
      );

      tl.to(
        el.querySelector(".Footer"),
        {
          x: Viewport.windowWidth * viewportFactor,
          duration: 2,
          ease: "quart.inOut",
        },
        "start"
      );

      tl.to(
        el.querySelector(".ShareForm"),
        {
          x: Viewport.windowWidth * viewportFactor,
          duration: 2,
          ease: "quart.inOut",
        },
        "start"
      );
    },
  },
});
