import TextureData from "./TextureData";


export enum TextureWrap {
  CLAMP,
  REPEAT,
  MIRROR,
}

export enum TextureFiltering {
  NEAREST                        = 0x2600,
  LINEAR                         = 0x2601,
  NEAREST_MIPMAP_NEAREST         = 0x2700,
  LINEAR_MIPMAP_NEAREST          = 0x2701,
  NEAREST_MIPMAP_LINEAR          = 0x2702,
  LINEAR_MIPMAP_LINEAR           = 0x2703,
}


export interface ITextureRequestOptions {
  bbc       : boolean
  flipY     : boolean
  genMips   : boolean
  wrap      : TextureWrap
  filtering : TextureFiltering
  // format?    : GLenum
  // internalFormat? : GLenum
}



export interface ITextureRequestLod {
  files : string[]
  buffers : ArrayBuffer[]
}

export interface ITextureRequestSource {
  codec : string
  lods : ITextureRequestLod[]
  datas : TextureData | null
}


export interface ITextureRequest {
  options : ITextureRequestOptions
  sources : ITextureRequestSource[]
}


export class TextureSrcSet implements ITextureRequest {

  options: ITextureRequestOptions;
  sources: ITextureRequestSource[];


  constructor( sources : string | [string, string][] ){

    if( typeof sources === 'string' ){
      sources = [ ['std', sources] ]
    } 

    this.sources = []
    for (const codec of sources) {
      const url = codec[1];
      this.sources.push( {
        codec: codec[0],
        lods : [{files:[url], buffers:null}],
        datas : null
      });
    }
  }

  clearData() {
    for (let x = 0; x < this.sources.length; x++) {
      this.sources[x].datas = null;
      for (let y = 0; y < this.sources[x].lods.length; y++) {
        this.sources[x].lods[y].buffers = null;
      }
    }
  }

}


export class CubeSrcSet implements ITextureRequest {

  options: ITextureRequestOptions;
  sources: ITextureRequestSource[];

  constructor( sources : Record<string, Array<string>> ){

    this.sources = []
    for (const codec in sources) {

      let lods = [];
      for(let i = 0; i < sources[codec].length; i++){
        lods.push(
          {
            files: [sources[codec][i]],
            buffers: null
          }
        );
      }

      this.sources.push( {
        codec,
        lods : lods,
        datas : null
      });

    }

  }


}