import PlaneGeometry from "@/webgl/lib/PlaneGeometry";

import vShader from "@/webgl/glsl/mantra/mantra.vert";
import fShader from "@/webgl/glsl/mantra/mantra.frag";
import Program from "nanogl/program";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import Texture2D from "nanogl/texture-2d";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { quat, vec2, vec3 } from "gl-matrix";
import GLConfig from "nanogl-state/config";
import gsap, { Quart } from "gsap";
import { Viewport } from "@/store/modules/Viewport";

const MANTRA_SCALE = 0.6
const MANTRA_SCALE_MOBILE = 0.4
export default class Mantra {

  isRender:boolean

  private glconfig:GLConfig

  prg:Program

  node:Node

  mantraTex:Texture2D

  reflectTex:Texture2D

  mantraAspect:number

  mX:number
  mY:number

  mouse:vec2

  constructor(id:number, private scene:Scene, private geom: PlaneGeometry, private camera:Camera, private resources:ResourceGroup, private root:Node) {
    const gl = this.scene.gl
    this.glconfig = new GLConfig();

    this.glconfig
      .enableDepthTest(false)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.prg = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())
    this.node = new Node()
    const img = this.resources.get(`mantra-0${id}`)
    this.mantraTex = new Texture2D(gl, gl.RGBA)
    this.mantraTex.fromImage(img)
    this.mantraTex.clamp()
    this.mantraTex.setFilter(true, false, false)

    this.reflectTex = new Texture2D(gl, gl.RGBA)
    this.reflectTex.fromImage(this.resources.get(`mantra-reflection`))
    this.reflectTex.clamp()
    this.reflectTex.setFilter(true, false, false)
    this.root.add(this.node)
    this.mX = this.mY = 0
    this.mouse = vec2.create()

    this.node.y = Viewport.isDesktop ? 0 : 0.1

    this.isRender = false
  }

  async leave() {
    await gsap.to(this.node, { x: -1, duration: 1.5, ease: Quart.easeInOut })
    this.isRender = false
  }
  

  async enter() {
    this.isRender = true
    this.node.x = 1
    await gsap.to(this.node, { x: 0, duration: 1.5, ease: Quart.easeInOut })
  }

  preRender(mX:number, mY:number) {
    if(!this.isRender) return
    quat.identity(this.node.rotation)
    this.mX += (mX - this.mX) * 0.05
    this.mY += (mY - this.mY) * 0.05
    vec2.set(this.mouse, this.mX, this.mY)
    this.node.rotateY(this.mX  * 0.15)
    this.node.rotateX(this.mY * 0.15)
    this.node.invalidate()
    this.node.updateWorldMatrix()
  }

  render() {
    if(!this.isRender) return
    this.scene.glstate.now(this.glconfig)
    this.prg.use()
    const M4 = this.camera.getMVP(this.node._wmatrix);
    this.prg.uMVP(M4);
    this.prg.uMouse(this.mouse);
    this.prg.uTex(this.mantraTex);
    this.prg.uReflexion(this.reflectTex);
    this.geom.bind(this.prg);
    this.geom.draw();
  }

  resize(width, height) {
    console.log("resize")
    const s = Viewport.isDesktop ? MANTRA_SCALE : MANTRA_SCALE_MOBILE
    vec3.set(this.node.scale,(height / width) * s,  1 * s, 1 * s)
    this.node.invalidate()
    this.node.updateWorldMatrix()
  }

  destroy() {
    this.mantraTex.dispose()
    this.root.remove(this.node)
    this.prg.dispose()
    this.prg = null
  }
}