import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Scene from "@/webgl/Scene";
import Node from "nanogl-node";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import CinemachinePath from "@/webgl/lib/cinemachine/CinemachinePath";
import gsap, { Quart } from "gsap";
import { vec3 } from "gl-matrix";

// export const enum MUSEUM_ANIMATION_ID {
//   IntroToMe = "IntroToMe",
//   MeToHome = "MeToHome",
//   HomeToFeel = "HomeToFeel",
//   HomeToArtwork = "HomeToArtwork"
// }

export const enum MUSEUM_ANIMATION_ID_NB {
  Intro = 0,
  AgeGate = 1,
  Home = 2,
  Feel = 3,
  Feel2 = 4,
  Do = 5,
  Artwork = 6,
  Collab = 7,
}

const V = vec3.create()

export default class CameraAnimations {

  gltf: Gltf;

  public camPos: vec3

  public lookatv: vec3

  public mouseY: number


  readonly root = new Node()

  private lookats: GltfNode

  private _target: Node;

  private _currentPosition = 0

  path: CinemachinePath;
  canvaspath: CinemachinePath;
  collabpath: CinemachinePath;
  skipagpath: CinemachinePath;

  get Target(): Node {
    return this._target;
  }


  constructor(
    public readonly scene: Scene
  ) {
    this.mouseY = 0
  }

  ready(lookats) {
    this.lookats = lookats
    this._currentPosition = 0
    this.path = CinemachinePath.CreateFromArrayBuffer(this.scene.museumScene.resources.get("pathcam"), 50);
    this.canvaspath = CinemachinePath.CreateFromArrayBuffer(this.scene.museumScene.resources.get("pathcanvascam"), 50);
    this.collabpath = CinemachinePath.CreateFromArrayBuffer(this.scene.museumScene.resources.get("pathcollabcam"), 50);
    this.skipagpath = CinemachinePath.CreateFromArrayBuffer(this.scene.museumScene.resources.get("pathskipag"), 50);
    this.camPos = vec3.create()
    this.lookatv = vec3.create()

  }

  syncTo(from = 0) {
    vec3.copy(this.lookatv, this.lookats._children[from].position)
    vec3.copy(this.camPos, this.path.EvaluatePosition(from))
  }

  goto = async (from = 0, to = 1, isSkipAgeGate = false, duration = 4) => {
    vec3.copy(this.lookatv, this.lookats._children[from].position)

    let path = this.path
    let toPath = to
    let fromPath = from
    if (fromPath === MUSEUM_ANIMATION_ID_NB.Artwork) {
      fromPath = 1
      toPath = 0
      path = this.canvaspath
    }
    if (toPath === MUSEUM_ANIMATION_ID_NB.Artwork) {
      toPath = 1
      fromPath = 0
      path = this.canvaspath
    }
    if (fromPath === MUSEUM_ANIMATION_ID_NB.Collab) {
      fromPath = 1
      toPath = 0
      path = this.collabpath
    }
    if (toPath === MUSEUM_ANIMATION_ID_NB.Collab) {
      toPath = 1
      fromPath = 0
      path = this.collabpath
    }
    if (isSkipAgeGate) {
      toPath = 1
      fromPath = 0
      path = this.skipagpath
      from = 0
      to = 2
    }
    this._currentPosition = fromPath
    vec3.copy(this.camPos, path.EvaluatePosition(this._currentPosition))
    const s = (toPath - fromPath)
    console.log("from", (this.lookats._children[from] as GltfNode).name, "to", (this.lookats._children[to] as GltfNode).name)
    gsap.to(this, { duration, mouseY: 0, ease: Quart.easeInOut })
    return gsap.to(this, {
      duration, _currentPosition: toPath, onUpdate: () => {
        vec3.copy(this.camPos, path.EvaluatePosition(this._currentPosition))
        vec3.lerp(this.lookatv, this.lookats._children[from].position, this.lookats._children[to].position, (this._currentPosition - fromPath) / s)
      }, ease: Quart.easeInOut
    })


  }

}