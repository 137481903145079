
import gsap from "gsap";
import { defineComponent } from "vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

export default defineComponent({
  setup() {},

  data() {
    return {
      tl: null,
    };
  },

  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.tl = this.createTimeline();
    });
  },

  methods: {
    createTimeline(): gsap.core.Timeline {
      const tl: gsap.core.Timeline = gsap.timeline({ paused: true });
      tl.addLabel("start");

      tl.fromTo(
        this.$refs.underline as Element,
        {
          scaleX: this.reverse ? 1 : 0,
        },
        {
          scaleX: this.reverse ? 0 : 1,
          duration: 1,
          ease: "cubic.inOut",
        },
        "start"
      );
      return tl;
    },

    mouseenter() {
      AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      gsap.set(this.$refs.underline as Element, { transformOrigin: this.reverse ? "100% 50%" : "0% 50%" });
      this.tl.restart();
    },

    mouseleave() {
      if (this.$refs.underline) {
        gsap.set(this.$refs.underline as Element, {
          transformOrigin: this.reverse ? "0% 50%" : "100% 50%"
        });
      }
      this.tl.reverse();
    },
  },
});
