import { IActivity } from "@/webgl/activities/Activity";
import MuseumActivity from "@/webgl/activities/MuseumActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class MuseumActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new MuseumActivity(scene);
  }
}

const MuseumActivityService = new MuseumActivityServiceImpl('museum')

export default MuseumActivityService

/////////////
/////////////////
/////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////
 
//////////