
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";
import { Viewport } from "@/store/modules/Viewport";

import { TutoArrowEnum } from "@/components/Tuto/Tuto.vue";
import Tuto from "@/components/Tuto/Tuto.vue";

export default defineComponent({
  components: {
    Tuto,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      tutoSlides: [
        {
          text: "When I love myself, loving others\ncomes easily. Relax and follow the\nrhythm of your heartbeat.",
          buttonLabel: "Skip",
          arrow: TutoArrowEnum.SWIRL,
          isIntro: true,
          rotate: 1,
        },
        {
          doodle: {
            path: !Viewport.isTouch
              ? "tutorials/I_Love_Desktop"
              : "tutorials/I_Love_Mobile",
            frames: 8,
            autoplay: true,
            loop: true,
          },
          // image: '/assets/love-1.png',
          text: !Viewport.isTouch
            ? "Press and hold \nthe space bar to choose \nthe color of your mood"
            : "Press and hold \nthe screen to choose \nthe color of your mood",
          buttonLabel: "Okay!",
          border: true,
          rotate: -2,
        },
      ],
    };
  },

  computed: {
    skipIntro(): boolean {
      return this.state?.context?.skipTutoIntro || false;
    },

    isStateTuto(): boolean {
      return this.state?.value["step_love"] === "tuto";
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=1");

      tl.fromTo(
        el,
        {
          x: !this.skipIntro ? Viewport.windowWidth : 0,
          opacity: !this.skipIntro ? 1 : 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: !this.skipIntro ? 2 : 0.5,
          ease: !this.skipIntro ? "quart.inOut" : "sine.out",
        },
        "start"
      );
    },

    afterEnter() {},

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.fromTo(
        el,
        {
          opacity: 1,
        },
        {
          x: 0,
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    tutoComplete() {
      this.send("ENTER");
    },
  },
});
