
import gsap from "gsap";
import { defineComponent } from "vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setFPS } from "@/utils/FrameToTime";
const frames = setFPS(60);

export default defineComponent({
  setup() {},

  props: {
    show: {
      type: [Number, Boolean],
      default: 1,
    },
  },

  data() {
    return {
      tl: null,
    };
  },

  watch: {
    show: {
      handler(show) {
        if (this.show) {
          this.mouseEnter();
        } else {
          this.mouseLeave();
        }
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.createTimeline();
    });
  },

  methods: {
    createTimeline() {
      const tl: gsap.core.Timeline = gsap.timeline({ paused: true });
      tl.addLabel("start", `+=${frames(0)}`);

      tl.fromTo(
        [this.$refs.spot as Element],
        {
          y: 80,
          rotate: "-25deg",
        },
        {
          y: 0,
          rotate: "0deg",
          duration: frames(60),
          ease: "cta.y.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.background as Element,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: frames(60),
          ease: "cta.x.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.background as Element,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          duration: frames(60),
          ease: "cta.y.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.label as Element,
        {
          scaleX: 0,
          scaleY: 0,
        },
        {
          scaleX: 1,
          scaleY: 1,
          duration: frames(27),
          ease: "cubic.inOut",
        },
        `start+=${frames(3)}`
      );

      tl.timeScale(1.5);

      this.tl = tl;
    },

    mouseEnter() {
      AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      this.tl.restart();
    },

    mouseLeave() {
      // AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      this.tl.reverse();
    },

    click() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);
    },
  },
});
