
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    icon() {
      this.name; // Trigger the name as a dependency for change detection
      return defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "icon-[request]" */
            "@/assets/icons/" + this.name + ".svg"
          )
      );
    },
  },
});
