
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import Delay from "@/core/Delay";

import { Menu, setMenuOpen } from "@/store/modules/Menu";
import AppService from "@/store/states/AppService";

import UIMenuToggleBtn from "@/components/UIMenuToggleBtn/UIMenuToggleBtn.vue";
import UIBrandLogo from "@/components/UIBrandLogo/UIBrandLogo.vue";

export default defineComponent({
  components: {
    UIMenuToggleBtn,
    UIBrandLogo,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      disabledMenu: false,
    };
  },

  computed: {
    openMenu(): boolean {
      return Menu.open;
    },

    showMenu(): boolean {
      return (
        !this.state.matches("step_me") &&
        !this.state.matches("museum.tuto") &&
        !this.state.matches("step_do.outro") &&
        !this.state.matches("step_love.outro") &&
        !this.state.matches("step_speak.outro") &&
        !this.state.matches("step_see.outro") &&
        !this.state.matches("artwork.share") &&
        !this.state.matches("collab.default") &&
        !this.state.matches("agenda.default")
      );
    },

    showBrand(): boolean {
      return (
        !this.state.matches("artwork.default") &&
        !this.state.matches("collab.default") &&
        !this.state.matches("agenda.default")
      );
    },
  },

  methods: {
    async toggleMenu() {
      this.disabledMenu = true;
      setMenuOpen(!Menu.open);
      await Delay(2500);
      this.disabledMenu = false;
    },
  },
});
