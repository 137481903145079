/////////////
////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////

//////////////////////////////////////////////
///////////////////////////////////////
///////////////////
/////////////////////
/////////////////////
///////////
/////////////
///////////////
/////////////////
 

///////////////////////////
////////////////
 
//
////////////////////////////////////////////
////////////////////////

//////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////

/////////////////////////////////////////////
/////////////////////
/////////////////////////////////////
///
//


/////////////////////////////////
///////////////////////////////////
 

////////////////////

/////////////////////////////////////////////////////
/////////////////////////
/////////////////
///////////////////////
//////////////////////
/////
//////////////////////
//////////////
 

///////////////////////////////////////
/////////////////////
/////////////////////

/////////////////////////////////////////////////
/////////////////////////
//////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////

//////////////////////////////////////////////////
//////////////////////////
//////////////////////////////
//////////////////////////////
///////
/////

 

////////////////////////////////
/////////////////////////////////////////////
//////////////////////////
///
//////////////////////
 

/////////
export function CreatePane(title, expanded = false) {

}

export function Presetpane(tgt, name) {

}

export function DisposePanes() {

}
//////////