import fbodebug from '@/webgl/dev/FboDebug';
import DebugDraw from '@/webgl/dev/DebugDraw';

import GLState from 'nanogl-state';
import Node from 'nanogl-node';
import Camera from 'nanogl-camera';
import Rect from 'nanogl-primitives-2d/rect';
import { GLContext } from 'nanogl/types';


import Programs from '@/webgl/gl/Programs';
import Inputs from '@/webgl/lib/inputs';
import GLView from '@/webgl/GLView'

import Deferred from '@/core/Deferred';
import ResourceGroup from './assets/ResourceGroup';
// import CamerasManager from './camera/CamerasManager';
import { BaseTextureResource } from '@/webgl/assets/TextureResource';
import GlobalResources from '@/webgl/GlobalResources';
import LightsManager from "@/webgl/entities/lights/LightsManager";
import Fbo from 'nanogl/fbo';

import ActivitiesManager from './activities/ActivitiesManager';
import { CreatePane } from '@/dev/Tweak';
import MuseumScene from '@/webgl/scenes/MuseumScene';

export default class Scene {

  dt: number
  time: number
  aspect: number
  ilayer: HTMLElement
  loaddefer: Deferred
  glview: GLView
  gl: GLContext
  sroot: Node
  root: Node
  glstate: GLState
  quad: Rect
  inputs: Inputs
  programs: Programs
  // cameras: CamerasManager

  enableDebugDraw: boolean = false;
  forceFps: any

  resources: ResourceGroup;

  lights: LightsManager;
  envRotation: number = 0;

  activities: ActivitiesManager
  museumScene: MuseumScene;


  camera: Camera

  get Resources(): ResourceGroup {
    return this.resources;
  }

  // get camera() {
  //   return this.cameras.camera
  // }


  constructor() {

    this.dt = 0
    this.time = 0
    this.aspect = 1.0
    this.ilayer = null

  }


  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview: GLView) {

    this.loaddefer = new Deferred()
    

    this.glview = glview
    this.gl = this.glview.gl

    BaseTextureResource.getTextureLoader(this.gl);

    this.resources = new ResourceGroup();
    GlobalResources.setupPrograms(this.programs);

    // Setup global resources
   
    

    this.sroot = new Node();
    this.root = new Node();
    this.sroot.add(this.root);

    this.glstate = new GLState(this.gl);
    this.programs = new Programs(this);
    this.quad = new Rect(this.gl);
    this.inputs = new Inputs(this.ilayer);
    // this.cameras = new CamerasManager(this);
    this.lights = new LightsManager(this);

    this.activities = new ActivitiesManager(this);

    this.inputs.start();

    this.glview.onRender.on(this.onCanvasFrame)

    this.museumScene = new MuseumScene(this);

/////////////////
/////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////

  }

  handleResize() {

  }

  onCanvasFrame = (dt: number) => {
    this.render(dt)
  }


  render(dt: any) {

    this.dt = dt;
    this.time += dt;
    this.activities.preFrame();
    // this.drawScene(this.camera);
    this.drawScene();

  }

  // // PRE RENDER
  // // =============
  preRender() {

    this.activities.preRender();

    // this.cameras.preRender();

    // upadate graph
    // =================
    this.root.rotation.set([0, 0, 0, 1]);
    this.root.rotateY(this.envRotation * Math.PI);
    this.root.updateWorldMatrix();

  }



  // // RENDER
  // // =============
  drawScene(fbo: Fbo = null, ui: boolean = true) {

    const w = fbo ? fbo.width : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.aspect = w / h;
    // camera.lens.aspect = this.aspect;
    //

    this.preRender();
    // camera.updateViewProjectionMatrix(w, h);

    // RTT
    // ==========
    this.lights.setup.prepare(this.gl);
    this.activities.rttPass();
    this.glstate.apply();

    // RENDER
    // ========
    
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null)
    this.gl.viewport(0, 0, this.glview.width, this.glview.height)
    this.gl.clearColor(1, 1, 1, 1)
    this.gl.clear(this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT)
    // this.gl.clear(this.gl.COLOR_BUFFER_BIT)
    this.activities.render();
    this.glstate.apply();

/////////////////
////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////
////////////////////////////
/////
//////////////

  }

  async load() {
    await this.resources.load();
    await this.compileShaders()

    this.onLoaded();

  }


  loadPaintingScene() {
    // this.museumScene.unload()
    // return this.paintingScene.load()
  }

  loadMuseumScene() {
    // this.paintingScene.unload()
    return this.museumScene.load()
  }


  compileShaders = () => {
    this.programs.compile()
    return Promise.resolve()
  }


  onLoaded = () => {

  }

}