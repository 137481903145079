import { Howl, Howler, HowlOptions } from "howler";
import GLApp from "@/webgl/main";

import Paths from "@/core/Paths";
import UISoundCollection from "./uis.json";
import XPSoundCollection from "./xps.json";
import ChapterSoundCollection from "./chapters.json";
import VoiceSoundCollection from "./voices.json";
import AmbianceSoundCollection from "./ambs.json";

const BASE_VOLUME = 0.7;

export const enum AUDIO_ID {
  // UIs
  UI_CLICK_MAIN = "click-main",
  UI_CLICK_SECONDARY = "click-secondary",
  UI_ROLLOVER = "rollover",
  UI_TRANSITION = "transition",
  // CHAPTERS
  MAIN = "main",
  CHAPTER_FEEL = "step_feel",
  CHAPTER_DO = "step_do",
  CHAPTER_LOVE = "step_love",
  CHAPTER_SEE = "step_see",
  CHAPTER_SPEAK = "step_speak",
  CHAPTER_ARTWORK = "artwork",
  // VOICES
  VOICE_MANIFESTO = "manifesto",
  VOICE_WDYS = "wdys",
  VOICE_THANKYOU = "thank_you",
  // XPS
  XP_DO_DRAW = "do-draw",
  XP_FEEL_EXPRESSION = "feel-expression",
  XP_LOVE_LOOP = "love-loop",
  XP_LOVE_VALIDATE = "love-validate",
  XP_OPEN_EYES = "see-open-eyes",
  XP_SPEAK_SLIDE = "speak-slide",
  XP_UNDERSTAND_DRAG = "understand-drag",
  // AMBS
  AMB_OUTRO_DO = "step_do_earth",
  AMB_OUTRO_LOVE = "step_love_water",
  AMB_OUTRO_SPEAK = "step_speak_air",
  AMB_OUTRO_SEE = "step_see_sun",
}

// const VZERO = vec3.fromValues(0.0, 0.0, 0.0);
// const VUP = vec3.fromValues(0.0, 1.0, 0.0);
// const VZ = vec3.fromValues(0.0, 0.0, -1.0);
// const V3A = vec3.create();
// const V3B = vec3.create();
// const V3C = vec3.create();
// const QUAT = quat.create();
// const MAT3 = mat3.create();

export class AudioLib {
  _audio: Map<AUDIO_ID, Howl> = new Map<AUDIO_ID, Howl>();
  _loop: Map<AUDIO_ID, { loopStart: number; loopEnd: number }> = new Map<
    AUDIO_ID,
    { loopStart: number; loopEnd: number }
  >();

  _currentHowl: Map<AUDIO_ID, Howl> = new Map<AUDIO_ID, Howl>();

  constructor() {
    // Howler.autoSuspend = false;
    // Howler.html5PoolSize = 0;
    // Howler.usingWebAudio = true;

    Object.keys(UISoundCollection).forEach((key) => {
      //const src = UISoundCollection[key]?.src;
      const filename = UISoundCollection[key]?.src;
      const src = require(`@/assets/audio/${filename}`);
      const volume = key === AUDIO_ID.UI_ROLLOVER ? 0.3 : 0.5
      this.addAudio(key as AUDIO_ID, src, { volume });
    });

    Object.keys(XPSoundCollection).forEach((key) => {
      const filename = XPSoundCollection[key]?.src;
      const src = require(`@/assets/audio/${filename}`);
      const loop: boolean =
        XPSoundCollection[key].loopStart && XPSoundCollection[key].loopEnd;
      if (loop) {
        const { loopStart, loopEnd } = XPSoundCollection[key];
        this._loop.set(key as AUDIO_ID, { loopStart, loopEnd });
      }
      this.addAudio(key as AUDIO_ID, src, { loop, volume: BASE_VOLUME });
    });

    Object.keys(ChapterSoundCollection).forEach((key) => {
      const filename = ChapterSoundCollection[key]?.src;
      const src = require(`@/assets/audio/${filename}`);
      const loop: boolean =
        ChapterSoundCollection[key].loopStart &&
        ChapterSoundCollection[key].loopEnd;
      if (loop) {
        const { loopStart, loopEnd } = ChapterSoundCollection[key];
        this._loop.set(key as AUDIO_ID, { loopStart, loopEnd });
      }
      this.addAudio(key as AUDIO_ID, src, { loop, volume: 0 });
    });

    Object.keys(VoiceSoundCollection).forEach((key) => {
      const filename = VoiceSoundCollection[key]?.src;
      const src = require(`@/assets/audio/${filename}`);
      this.addAudio(key as AUDIO_ID, src, { volume: BASE_VOLUME });
    });

    Object.keys(AmbianceSoundCollection).forEach((key) => {
      const filename = AmbianceSoundCollection[key]?.src;
      const src = require(`@/assets/audio/${filename}`);
      const loop: boolean =
        AmbianceSoundCollection[key].loopStart &&
        AmbianceSoundCollection[key].loopEnd;
      if (loop) {
        const { loopStart, loopEnd } = AmbianceSoundCollection[key];
        this._loop.set(key as AUDIO_ID, { loopStart, loopEnd });
      }
      this.addAudio(key as AUDIO_ID, src, { loop, volume: BASE_VOLUME });
    });

    GLApp.getInstance().glview.onRender.on(this.updateLoop.bind(this));
  }

  setMute(flag: boolean) {
    Howler.volume(flag ? 0 : 1);
  }

  getAudio(id: AUDIO_ID): Howl {
    return this._audio.get(id);
  }

  play(id: AUDIO_ID): Howl {
    const howl = this._audio.get(id);
    if (howl.playing()) return;
    if (this._currentHowl.get(id)) return;
    this._currentHowl.set(id, howl);
    howl.seek(0);
    howl.play();

    return howl;
  }

  updateLoop() {
    if (this._currentHowl) {
      this._currentHowl.forEach((howl: Howl, id: AUDIO_ID) => {
        if (howl._loop) {
          const loop: { loopStart: number; loopEnd: number } = this._loop.get(id);
          if (howl.seek() >= loop.loopEnd / 1000) {
            howl.seek(loop.loopStart / 1000);
          }
        }
      });
    }
  }

  stop(id: AUDIO_ID) {
    this._audio.get(id).stop();
    this._currentHowl.delete(id);
  }

  playUI(id: AUDIO_ID) {
    if (Howler.state != "running") return;
    const howl = this._audio.get(id);
    howl.play();
  }

  fadeOut(id: AUDIO_ID, duration: number = 1000): Promise<any> {
    const howl = this._audio.get(id);
    howl.off("fade");
    howl.fade(howl.volume(), 0, duration);
    return new Promise((resolve) => {
      howl.once("fade", resolve);
    });
  }

  fadeIn(
    id: AUDIO_ID,
    volume: number = 1.0,
    duration: number = 1000
  ): Promise<any> {
    const howl = this._audio.get(id);

    if (!howl.playing()) this.play(id);

    howl.off("fade");
    howl.fade(howl.volume(), volume, duration);

    return new Promise((resolve) => {
      howl.once("fade", resolve);
    });
  }

  fadeOutStop(id: AUDIO_ID, duration: number = 1000) {
    this.fadeOut(id, duration).then(() => this.stop(id));
  }

  addAudio(id: AUDIO_ID, src: string, opts: HowlOptions = {}): Howl {
    const _opts = {
      preload: true,
      autoplay: false,
    };
    Object.assign(_opts, opts);

    const howl = new Howl({
      // src: [Paths.resolve(src)],
      src: [src],
      ..._opts,
    });

    this._audio.set(id, howl);

    return howl;
  }
}

export default new AudioLib();
