
const Paths = {

  get public(){
    return __webpack_public_path__
  },
  
  resolve(path:string):string{
    return this.public+path;
  },

}



export default Paths