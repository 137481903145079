import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61f5717e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "ui-share-link__icon" }
const _hoisted_3 = { class: "ui-share-link__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: "ui-share-link",
    href: _ctx.url,
    target: "_blank",
    rel: "noopener nofollow",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.share && _ctx.share(...args)), ["prevent"]))
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_ui_icon, { name: "share" })
    ]),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 8, _hoisted_1))
}