
import gsap from "gsap";
import MuseumActivity from "@/webgl/activities/MuseumActivity";
import GLApp from "@/webgl/main";
import AppService from "@/store/states/AppService";
import {
  AgeGate as AgeGateStore,
  preferedCountryCodes,
  save as SaveAgeGateData,
} from "@/store/modules/AgeGate";
import { AgeGateRules } from "@/store/modules/AgeGate";
import { useActor } from "@xstate/vue";
import { defineComponent } from "vue";
import { UserCard } from "@/store/modules/UserCard";
import { Viewport } from "@/store/modules/Viewport";

interface IInputAtts {
  min?: number;
  max?: number;
  minlength?: number;
  maxlength?: number;
  pattern?: string;
  placeholder?: string;
}

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      isSubmitted: false,
      x: 0,
      y: 0,
    };
  },

  computed: {
    elStyle() {
      if (!Viewport.isDesktop) {
        return;
      }
      return {
        transform: `translate(${(this as any).x}px, ${(this as any).y}px)`,
      };
    },

    ageGateRules(): AgeGateRules[] {
      const filteredRules: AgeGateRules[] = AgeGateStore.rules.filter((rule) =>
        preferedCountryCodes.includes(rule.country_code)
      );
      const otherRules: AgeGateRules[] = AgeGateStore.rules.filter(
        (rule) => !preferedCountryCodes.includes(rule.country_code)
      );
      return [...filteredRules, ...otherRules];
    },

    selectedAgeGateRule(): AgeGateRules {
      return (
        AgeGateStore.rules.filter(
          (rule) => rule.country_code === AgeGateStore.countryCode
        )[0] || null
      );
    },

    selectedCountryInputType(): string[] {
      return this.selectedAgeGateRule.input_type.split("");
    },

    hasLegalAge(): boolean {
      const birthdate: Date = new Date(
        AgeGateStore.birthdate.y,
        AgeGateStore.birthdate.m - 1,
        AgeGateStore.birthdate.d
      );
      const now: Date = new Date();
      const diff: number = now.getTime() - birthdate.getTime();

      return this.selectedAgeGateRule.age * (1000 * 60 * 60 * 24 * 365) <= diff;
    },

    isAllowedCountry(): boolean {
      return this.selectedAgeGateRule.allowed_country !== "no";
    },

    hasFranceCountryCode(): boolean {
      return AgeGateStore.countryCode === "FR";
    },

    hasCurrentStepRequiredData(): boolean {
      return (
        this.hasLegalAge &&
        (UserCard.age as unknown as boolean) &&
        (UserCard.country as unknown as boolean)
      );
    },

    formattedAge(): string {
      return (
        (AgeGateStore.birthdate.y as unknown as string) ||
        (UserCard.age as unknown as string)
      );
    },

    formattedCountry(): string {
      return UserCard.country as unknown as string;
    },
  },

  created() {
    if (AgeGateStore.legalAge) {
      this.isSubmitted = true;
    }
  },

  mounted() {
    GLApp.getInstance().glview.onRender.on(this.updateAgeGatePos);
  },

  unmounted() {
    GLApp.getInstance().glview.onRender.off(this.updateAgeGatePos);
  },

  methods: {
    afterEnter() {
      gsap.delayedCall(3, () => {
        if (this.hasCurrentStepRequiredData) {
          this.send("ENTER");
        }
      });
    },

    updateAgeGatePos() {
      this.x = MuseumActivity.ageGatePos[0] * 1.01;
      this.y = MuseumActivity.ageGatePos[1] * 1.01;
    },

    selectCountry(val: AgeGateRules) {
      AgeGateStore.countryCode = (val && val.country_code) || "";
    },

    selectBirthDateYear(year: number) {
      AgeGateStore.birthdate.y = year;
    },

    selectBirthDateMonth(month: number) {
      AgeGateStore.birthdate.m = month;
    },

    selectBirthDateDay(day: number) {
      AgeGateStore.birthdate.d = day;
    },

    selectBirthdate(input: string, value: string) {
      const atts = this.getBirthdateAttributes(input);
      const $inputs = Array.from(
        (<HTMLElement>this.$refs.birthdate).querySelectorAll("input")
      );
      const index = this.selectedCountryInputType.indexOf(input);

      // ensure maxlength is respected
      if (value.length > atts.maxlength) {
        value = value.slice(0, atts.maxlength);
      }
      // ensure value is in range
      if (parseInt(value) > atts.max) {
        value = atts.max.toString();
      }

      // restore input value
      $inputs[index].value = value;

      if (this.validateBirthdate(input, value)) {
        const actions = {
          d: "selectBirthDateDay",
          m: "selectBirthDateMonth",
          y: "selectBirthDateYear",
        };
        const action = actions[input];
        action && this[action](parseInt(value));
      }

      // autofocus next input when length is reached
      if (value.length >= atts.maxlength) {
        $inputs[index + 1] && $inputs[index + 1].focus();
      }
    },

    getBirthdateAttributes(input: string): IInputAtts {
      const currentYear: number = new Date().getFullYear();
      const options = {
        d: {
          min: 1,
          max: 31,
          minlength: 1,
          maxlength: 2,
          pattern: "[0-9]{1,2}",
          placeholder: "DD",
        },
        m: {
          min: 1,
          max: 12,
          minlength: 1,
          maxlength: 2,
          pattern: "[0-9]{1,2}",
          placeholder: "MM",
        },
        y: {
          min: currentYear - 100,
          max: currentYear,
          minlength: 4,
          maxlength: 4,
          pattern: "[0-9]{4}",
          placeholder: "YYYY",
        },
      };

      return options[input];
    },

    validateInput($event) {
      if (!/[0-9]/.test($event.key)) {
        if ($event.key !== "Enter") {
          $event.preventDefault();
        }

        return false;
      }

      return true;
    },

    validateBirthdate(input: string, value: string) {
      const intValue = parseInt(value);
      const atts = this.getBirthdateAttributes(input);

      const inRange = intValue >= atts.min && intValue <= atts.max;
      const hasLength =
        value.length >= atts.minlength && value.length <= atts.maxlength;

      return inRange && hasLength;
    },

    validateAge() {
      AgeGateStore.legalAge = this.hasLegalAge && !this.hasFranceCountryCode;
      SaveAgeGateData();

      this.isSubmitted = true;

      if (!this.hasLegalAge || this.hasFranceCountryCode) {
        setTimeout(() => {
          window.location.replace("https://www.wineinmoderation.eu/");
        }, 1000);
        return;
      }

      this.send("ENTER");
    },
  },
});
