
import gsap from "gsap/all";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import { Viewport } from "@/store/modules/Viewport";
import MuseumActivity from "@/webgl/activities/MuseumActivity";
import GLApp from "@/webgl/main";

import AnimateSprite from "@/components/AnimateSprite/AnimateSprite.vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  components: {
    AnimateSprite,
  },

  data() {
    return {
      div0: 0,
      div1: 0,
      div2: 0,
      touchIdle: true,
    };
  },

  computed: {
    buttonLength: () => {
      return MuseumActivity.buttons.length;
    },

    isTouch(): boolean {
      return Viewport.isTouch
    },
  },

  watch: {
    state: {
      immediate: true,
      handler(newState: AppState, oldState: AppState) {
        if (newState.matches("museum.default")) {
          this.$nextTick(() => {
            gsap.set(this, { div0: 0, div1: 0, div2: 0 });

            const tl: gsap.core.Timeline = gsap.timeline();

            tl.to(this, {
              div0: 1,
              snap: { div0: 1 },
              duration: 0.4,
            });

            tl.to(
              this,
              {
                div1: 1,
                snap: { div1: 1 },
                duration: 0.4,
              },
              "+=0.1"
            );
            tl.to(
              this,
              {
                div2: 1,
                snap: { div2: 1 },
                duration: 0.4,
              },
              "+=0.1"
            );

            tl.addLabel("back", "+=0.5");

            tl.to(
              this,
              {
                div0: 0,
                snap: { div0: 1 },
                duration: 0.4,
              },
              "back"
            );

            tl.to(
              this,
              {
                div1: 0,
                snap: { div1: 1 },
                duration: 0.4,
              },
              "+=0.1"
            );

            tl.to(
              this,
              {
                div2: 0,
                snap: { div2: 1 },
                duration: 0.4,
              },
              "+=0.1"
            );
          });
        }
      },
    },
  },

  mounted() {
    const tl = gsap.timeline();
    GLApp.getInstance().glview.onRender.on(this.updateButtonsPosition);
    GLApp.getInstance().glview.canvas.addEventListener(
      "touchstart",
      this.onTouch,
      { passive: false }
    );
  },

  unmounted() {
    GLApp.getInstance().glview.onRender.off(this.updateButtonsPosition);
    GLApp.getInstance().glview.canvas.removeEventListener(
      "touchstart",
      this.onTouch
    );
  },

  methods: {
    onTouch() {
      this.touchIdle = false;
      GLApp.getInstance().glview.canvas.removeEventListener(
        "touchstart",
        this.onTouch
      );
    },

    onXpClick() {
      this.send("ENTER");
    },

    onArtClick() {
      this.send("ARTWORK");
    },

    onCollabClick() {
      this.send("COLLAB");
    },

    updateButtonsPosition() {
      const btns = this.$el.querySelectorAll("button");
      for (let i = 0; i < MuseumActivity.buttons.length; i++) {
        const [x, y, show] = MuseumActivity.buttons[i];
        gsap.set(btns[i], { x: x - 50, y: y - 50 });
        if (!Viewport.isDesktop) this["div" + i] = show;
      }
    },
  },
});
