// WEBGL ASSETS CONFIG
// ===================
import Program from 'nanogl/program';

import { TextureCodecs } from './assets/TextureCodec'
import TextureCodecStd, { TextureCodecWebp } from './assets/TextureCodecStd'

import { TextureCodecDxt, TextureCodecEtc, TextureCodecPvr } from './assets/TextureCodecBBC'

import Gltf                                from '@/webgl/lib/nanogl-gltf/lib'
import KHR_texture_transform               from '@/webgl/lib/nanogl-gltf/lib/extensions/KHR_texture_transform'
import KHR_materials_pbrSpecularGlossiness from '@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_pbrSpecularGlossiness'
import KHR_lights_punctual                 from '@/webgl/lib/nanogl-gltf/lib/extensions/KHR_lights_punctual'
import KHR_materials_unlit                 from '@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit'
import EXT_texture_webp                    from '@/webgl/lib/nanogl-gltf/lib/extensions/EXT_texture_webp'
import MMP_compressed_texture              from '@/webgl/lib/nanogl-gltf/lib/extensions/MMP_compressed_texture';
import EXT_lights_image_based              from '@/webgl/lib/nanogl-gltf/lib/extensions/EXT_lights_image_based';
import MMP_lights_image_based              from '@/webgl/lib/nanogl-gltf/lib/extensions/MMP_lights_image_based';


/////////////
/////////////////////
/////////
Program.debug = false;
//////////


TextureCodecs.registerCodec( new TextureCodecStd() );
TextureCodecs.registerCodec( new TextureCodecWebp() );
TextureCodecs.registerCodec( new TextureCodecDxt() );
TextureCodecs.registerCodec( new TextureCodecEtc() );
TextureCodecs.registerCodec( new TextureCodecPvr() );


Gltf.addExtension( new KHR_texture_transform              () );
Gltf.addExtension( new KHR_materials_pbrSpecularGlossiness() );
Gltf.addExtension( new KHR_lights_punctual                () );
Gltf.addExtension( new KHR_materials_unlit                () );
Gltf.addExtension( new EXT_texture_webp                   () );
Gltf.addExtension( new MMP_compressed_texture             () );
Gltf.addExtension( new EXT_lights_image_based             () );
Gltf.addExtension( new MMP_lights_image_based             () );