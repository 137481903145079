
import { Viewport } from "@/store/modules/Viewport";
import gsap from "gsap";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},

  props: {
    path: {
      type: String,
      required: true,
    },
    frames: {
      type: Number,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    frameCollection(): string[] {
      const frames: string[] = [];
      const ext = Viewport.webpSupport ? "webp" : "png";
      for (let i = 0; i < this.frames; i++) {
        const f = require(`@/assets/images/sprites/${this.path}/frame-${i
          .toString()
          .padStart(5, "0000")}.${ext}`);
        frames.push(f);
      }
      return frames;
    },
  },

  mounted() {
    const vars: gsap.TweenVars = {};
    if (this.loop) {
      vars["repeat"] = -1;
    }
    if (this.autoplay) {
      this.animate(vars);
    }
  },

  methods: {
    animate(vars: gsap.TweenVars = {}) {
      const $frames = Array.from(this.$el.querySelectorAll(".frame"));

      const state = {
        current: 0,
        set currentFrame(index: number) {
          this.current = index;
          gsap.set($frames, { opacity: (i) => +(i === index) });
        },
        get currentFrame() {
          return this.current;
        },
      };

      // immediate render first sprite
      const firstFrame = vars.runBackwards ? <number>this.frames - 1 : 0;
      gsap.set($frames, { opacity: (i) => +(i === firstFrame) });

      return gsap.to(state, {
        currentFrame: <number>this.frames - 1,
        snap: { currentFrame: 1 },
        duration: 1,
        ease: "linear",
        ...vars,
        inherit: false,
      });
    },

    enter(vars: gsap.TweenVars = {}): gsap.core.Tween {
      return this.animate({
        ...vars,
        runBackwards: false,
      });
    },

    leave(vars: gsap.TweenVars = {}): gsap.core.Tween {
      return this.animate({
        ...vars,
        runBackwards: true,
      });
    },
  },
});
