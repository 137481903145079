import AppService from "@/store/states/AppService"
import { AppState } from "@/store/states/AppStateMachine"


type StateHandlerParam = {
  readonly match: string,
  enter?:(s:AppState)=>void
  update?:(s:AppState)=>void
  exit?:()=>void
}

class StateHandler {

  private _active = false
  public get active() {
    return this._active
  }

  constructor( private params : StateHandlerParam ){

  }

  handleState( state:AppState ){

    const active = state.matches( this.params.match )

    if( active !== this._active ){
      this._active = active
      active ? this.params.enter?.( state ) : this.params.exit?.()
    }

    if( this._active ){
      this.params.update?.( state )
    }

  }

  stop(){
    if( this._active ) this.params.exit?.()
    this._active = false
  }

}

export default class StateHelper {
  
  private handlers: StateHandler[]

  constructor( handlers : StateHandlerParam[]){
    this.handlers = handlers.map(p=>new StateHandler(p))
  }

  start(){
    AppService.onTransition( this._onTransition )
    this._onTransition( AppService.getSnapshot() )
  }
  
  stop(){
    AppService.off( this._onTransition )
    this.handlers.forEach( h => h.stop() )
  }
  
  
  private _onTransition = ( state: AppState )=>{
    this.handlers.filter(h=>h.active) .forEach( h => h.handleState( state ) )
    this.handlers.filter(h=>!h.active).forEach( h => h.handleState( state ) )
  }

}

