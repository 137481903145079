// import { countryCodes } from "@/dev/CountryCodes"
// import { AgeGate } from "@/store/modules/AgeGate"
import { AppStore, setRecaptchaToken } from "@/store/modules/AppStore"
import { setGenerated, setGuid, UserCard } from "@/store/modules/UserCard"

import { ImageResource } from "@/webgl/assets/Net"
import ResourceGroup from "@/webgl/assets/ResourceGroup"

const CARD_WIDTH = Math.round(1280 / 2)
const CARD_HEIGHT = Math.round(720 / 2)

const CARD_FOLDER = "assets/usercard/"
const CARD_OVERLAY = "card-overlay.png"
const CARD_LOGO = "logo.png"

const URL = "/api/"
const POST = "post"

export default class CardDataSender {

  static _self: CardDataSender

  static get occurence(): CardDataSender {
    if (!CardDataSender._self) CardDataSender._self = new CardDataSender()
    return CardDataSender._self
  }

  canvas: HTMLCanvasElement
  context: CanvasRenderingContext2D

  background: HTMLCanvasElement
  bgContext: CanvasRenderingContext2D

  resources: ResourceGroup

  constructor() {
    this.canvas = document.createElement("canvas")
    this.canvas.width = CARD_WIDTH
    this.canvas.height = CARD_HEIGHT
    this.context = this.canvas.getContext("2d")

    this.background = document.createElement("canvas")
    this.background.width = CARD_WIDTH
    this.background.height = CARD_HEIGHT
    this.bgContext = this.background.getContext("2d")

    this.resources = new ResourceGroup()
  }

  async prepareCard() {

    if (UserCard.mantraId === -1 || UserCard.background === null || UserCard.emotion === null || UserCard.generated) {
      console.warn("missing elements to generate cards")
      return
    }

    const mantraImg = CARD_FOLDER + "mantras/mantra_0" + UserCard.mantraId + ".png"
    const faceImg = CARD_FOLDER + "faces/" + UserCard.emotion + "_01.png"
    const bgImg = UserCard.background

    console.log("PrepareCard => do load", faceImg, mantraImg)
    this.resources.add(new ImageResource(mantraImg), "mantra")
    this.resources.add(new ImageResource(faceImg), "face")
    this.resources.add(new ImageResource(CARD_FOLDER + CARD_OVERLAY), "overlay")
    this.resources.add(new ImageResource(CARD_FOLDER + CARD_LOGO), "logo")

    await this.resources.load()

    this.coverImg(bgImg, "cover", this.context)
    // this.context.fillStyle = "rgba(255, 255, 255, 0.2)"
    // this.context.fillRect(0, 0, CARD_WIDTH, CARD_HEIGHT)
    this.context.drawImage(this.resources.get("overlay"), 0, 0, this.canvas.width, this.canvas.height)
    this.context.drawImage(this.resources.get("face"), 0, 0, this.canvas.width, this.canvas.height)
    this.context.drawImage(this.resources.get("mantra"), 0, 0, this.canvas.width, this.canvas.height)
    this.context.drawImage(this.resources.get("logo"), 0, 0, this.canvas.width, this.canvas.height)

    this.coverImg(bgImg, "cover", this.bgContext)
    this.bgContext.drawImage(this.resources.get("overlay"), 0, 0, this.canvas.width, this.canvas.height)

    setGenerated(this.canvas)
    console.log("PrepareCard => card generated and ready")
    // document.body.appendChild(bgImg)
    // bgImg.style.position = "fixed"
    // bgImg.style.left = "0"
    // bgImg.style.width = "30%"
    // bgImg.style.top = "0"
    // bgImg.style.pointerEvents = "none"


    // document.body.appendChild(this.canvas)
    // this.canvas.style.position = "fixed"
    // this.canvas.style.left = "30%"
    // this.canvas.style.width = "30%"
    // this.canvas.style.top = "0"
    // this.canvas.style.pointerEvents = "none"

    // const age = 2022 - AgeGate.birthdate.y
    // const cc = AgeGate.countryCode

    const token = await grecaptcha.execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, {action: 'submit'})
    setRecaptchaToken(token)

    const postData = {
      token: token,
      country: UserCard.country,
      age: UserCard.age,
      mood: UserCard.emotion,
      mantra: UserCard.mantraId,
      draw: this.background.toDataURL(),
      postcard: this.canvas.toDataURL(),
    }

    var content_without_mime = this.canvas.toDataURL().split(",")[1];
    var size_in_bytes = window.atob(content_without_mime).length;

    var content_without_mime2 = this.background.toDataURL().split(",")[1];
    var size_in_bytes2 = window.atob(content_without_mime2).length;

    console.log("PrepareCard => send post data => ", postData, (size_in_bytes + size_in_bytes2), "bytes")

    const rawResponse = await fetch(`${URL}${POST}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`RQbnec0M:u59qoQVrjsua`),
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(postData)
    });
    const content = await rawResponse.json();
    setGuid(content.guid)

    console.log("PrepareCard => server response => ", content)
  }

  coverImg = (img, type, context) => {
    const imgRatio = img.height / img.width
    const winRatio = CARD_HEIGHT / CARD_WIDTH
    if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
      const h = CARD_WIDTH * imgRatio
      context.drawImage(img, 0, (CARD_HEIGHT - h) / 2, CARD_WIDTH, h)
    }
    if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
      const w = CARD_WIDTH * winRatio / imgRatio
      context.drawImage(img, (CARD_WIDTH - w) / 2, 0, w, CARD_HEIGHT)
    }
  }

}