import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";
import ArtworkActivity from "@/webgl/activities/ArtworkActivity";
import { IActivity } from "@/webgl/activities/Activity";

class ArtworkActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new ArtworkActivity(scene)
  }
}

let ArtworkActivityService = new ArtworkActivityServiceImpl('artwork')

export default ArtworkActivityService

/////////////
/////////////////
/////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////
 
//////////
