
import lottie, { AnimationItem } from "lottie-web";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import FrontResources from "@/core/FrontResources";
import { AppState, AppStateEnum } from "@/store/states/AppStateMachine";
import AppService from "@/store/states/AppService";
import { isArray } from "xstate/lib/utils";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      lotties: null,
      steps: {
        [AppStateEnum.STEP_ME]: {
          color: "me",
          icon: "square",
          visited: false,
          current: false,
        },
        [AppStateEnum.STEP_FEEL]: {
          color: "feel",
          icon: "circle",
          visited: false,
          current: false,
        },
        [AppStateEnum.STEP_DO]: {
          color: "do",
          icon: "triangle",
          visited: false,
          current: false,
        },
        [AppStateEnum.STEP_LOVE]: {
          color: "love",
          icon: "rectangle",
          visited: false,
          current: false,
        },
        [AppStateEnum.STEP_SPEAK]: {
          color: "speak",
          icon: "oval",
          visited: false,
          current: false,
        },
        [AppStateEnum.STEP_SEE]: {
          color: "see",
          icon: "diamond",
          visited: false,
          current: false,
        },
        [AppStateEnum.ARTWORK]: {
          color: "artwork",
          icon: "hexagon",
          visited: false,
          current: false,
        },
      },
    };
  },

  created() {
    this.lotties = new Map();
  },

  mounted() {
    const keys: string[] = Object.keys(this.steps);
    keys.forEach((key) => {
      if (!this.lotties.get(key)) {
        const container = this.$el.querySelector(`.step--${key} .step__arrow`);
        const animationItem: AnimationItem = lottie.loadAnimation({
          container,
          renderer: "svg",
          autoplay: true,
          loop: false,
          animationData: FrontResources.Group.get("timeline-arrow"),
        });
        this.lotties.set(key, animationItem);
      }
    });
  },

  beforeDestroy() {
    this.lotties.forEach((lottie) => {
      lottie.destroy();
    });
  },

  watch: {
    state: {
      immediate: true,
      handler(state: AppState, oldState: AppState) {
        const keys: string[] = Object.keys(this.steps);
        if (isArray(state.context.timeline)) {
          state.context.timeline.forEach((state: AppStateEnum) => {
            this.steps[state].visited = true;
          });
          this.steps[
            state.context.timeline[state.context.timeline.length - 1]
          ].current = true;
          return;
        }
        keys.forEach((key) => {
          this.steps[key].visited = false;
          this.steps[key].current = false;
        });
      },
    },
  },
});
