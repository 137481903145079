var fn = function( obj ){
var __t,__p='';
__p+='';
 if( obj.pv === true ){ 
__p+='\n\n#define SKIN_NUM_JOINTS '+
(obj.numJoints)+
'\nuniform mat4 uJoints[SKIN_NUM_JOINTS];\n'+
(obj.attribDecl)+
'\nmat4 ComputeSkinMatrix() {\n  mat4 SM = '+
(obj.matrixSum)+
';\n  return SM;\n}\n';
 } 
__p+='\n\n';
 if( obj.vertex_warp === true ){ 
__p+='\n  mat4 _skinMatrix = ComputeSkinMatrix();\n  vertex.worldMatrix = vertex.worldMatrix * _skinMatrix;\n';
 } 
__p+='\n';
return __p;
};
fn.toString=fn;
module.exports = fn;