import { reactive } from "@vue/runtime-core";
import rules from "@/store/datas/ageGateRules.json";

export const UserCard = reactive({
  age: 0,
  guid: '',
  country: '',
  emotion: null,
  mantraId: -1,
  background: null,
  card: null,
  generated: false
})

export function setAge(age: number) {
  UserCard.age = age
}

export function setGuid(guid: string) {
  UserCard.guid = guid
}

export function setCountry(country: string) {
  UserCard.country = (rules.find((rule) => rule.country_code.toLowerCase() === country.toLowerCase())
  ?.country_name || country) as string;
}

export function setEmotionCard(emotion: "anger" | "disgust" | "fear" | "happiness" | "sadness" | "surprise" | "neutral") {
  UserCard.emotion = emotion
}

export function setBackground(img: HTMLCanvasElement) {
  UserCard.background = img
}

export function setMantraId(id: number) {
  UserCard.mantraId = id
}

export function setGenerated(card: HTMLCanvasElement) {
  UserCard.card = card
  UserCard.generated = true
}