
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { setMenuOpen } from "@/store/modules/Menu";
import PageFooter from "@/components/PageFooter/PageFooter.vue";
import { Viewport } from "@/store/modules/Viewport";
import AudioManager from "@/core/audio/AudioManager";
import ViewBrandSection from "@/components/Collab/ViewBrandSection.vue";
import ViewBioSection from "@/components/Collab/ViewBioSection.vue";
import ViewSection3 from "@/components/Collab/ViewSection3.vue";
import ViewSection2 from "@/components/Collab/ViewSection2.vue";
import ViewSection1 from "@/components/Collab/ViewSection1.vue";
import ViewIntro from "@/components/Collab/ViewIntro.vue";
import ViewCover from "@/components/Collab/ViewCover.vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  components: {
    ViewCover,
    ViewIntro,
    ViewSection1,
    ViewSection2,
    ViewSection3,
    ViewBrandSection,
    ViewBioSection,
    "page-footer": PageFooter,
  },

  computed: {
    isStateDefault(): boolean {
      return this.state.matches("collab.default");
    },
  },

  mounted() {
    setMenuOpen(false);
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      gsap.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "cubic.out",
        }
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      gsap.to(el, {
        opacity: 0,
        duration: 1,
      });
    },

    onBackClick() {
      this.send("EXIT");
    },
  },
});
