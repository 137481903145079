import ResourceGroup from "./ResourceGroup";
import Deferred from "@/core/Deferred";
import { CreateAbortController, AbortControllerShim } from "./AbortController";


export enum ResourceState {
  PENDING,
  LOADING,
  LOADED,
  ERRORED,
}




export abstract class Resource<T = any>{

  private _value: T | null = null;

  private _deferred: Deferred<T>;

  protected _abortCtrl: AbortControllerShim;

  private _state: ResourceState = ResourceState.PENDING;

  private _isControlled = false;

  /**
   * true if this resources is added to a group
   * manual call to load() / unload() have no effect when resource is controlled
   */
  get isControlled(): boolean {
    return this._isControlled;
  }

  get isGroup(): boolean {
    return false;
  }

  constructor() {
    this._reset();
  }

  private _reset(): void {
    this._state = ResourceState.PENDING;
    this._value = null;
    this._deferred = new Deferred<T>();
    this._abortCtrl = CreateAbortController();
  }


  get value(): T | null {
    return this._value;
  }

  get state() { return this._state }
  get isLoaded() { return this._state === ResourceState.LOADED }
  get isErrored() { return this._state === ResourceState.ERRORED }
  get isPending() { return this._state === ResourceState.PENDING }
  get isLoading() { return this._state === ResourceState.LOADING }

  get isComplete() {
    return this.isErrored || this.isLoaded;
  }


  response(): Promise<T> {
    return this._deferred.promise;
  }



  public load(): Promise<T> {
    if (this.isControlled) {
      throw new Error(`Can't call load() on a controlled Resource`)
    }
    return this._load();
  }


  public unload(): void {
    if (this.isControlled) {
      throw new Error(`Can't call unload() on a controlled Resource`)
    }
    this._unload();
  }


  private _load(): Promise<T> {
    if (this.isPending) {
      this._state = ResourceState.LOADING;

      const deferred = this._deferred;

      this.doLoad().then(

        // resolve
        // ! prevent resource state to be changed by old loadings
        v => {
          if (deferred === this._deferred) {
            this._value = v;
            this._state = ResourceState.LOADED;
            deferred.resolve(v)
          }
        },

        // reject
        e => {
          if (deferred === this._deferred) {
            this._state = ResourceState.ERRORED;
            deferred.reject(e);
          }
        }

      );

    }
    return this.response();
  }



  private _unload(): void {
    if (!this.isPending) {
      if (this._state != ResourceState.LOADED) {
        this._abortCtrl.abort();
      }
      this.doUnload();
      if (this._state != ResourceState.LOADED) {
        this._deferred.reject('cancelled');
      }
      this._reset();
    }
  }


  /**
   * 
   */
  abstract doLoad(): Promise<T>;

  /**
   * Called when resource needs to be unloaded
   * state can ba anything but PENDING when this method is called
   */
  abstract doUnload(): void;



  protected setProgress(percent: number): void {
    // if( this.isLoading ){

    // }
  }


  static all<T>(resources: Resource<T>[]): Promise<T[]> {
    return Promise.all(resources.map(r => r.response()))
  }

}



export class LambdaResource<T = any> extends Resource<T>{

  constructor(private _loadFunc: () => Promise<T>) {
    super();
  }

  doLoad(): Promise<T> {
    return this._loadFunc();
  }

  doUnload(): void {

  }
}


/**
 * @internal
 */
export class InternalResourceHelper {

  static loadResource<T>(r: Resource<T>): Promise<T> {
    return (r as any)._load()
  }

  static unloadResource(r: Resource) {
    (r as any)._unload()
  }

  static markControlled(r: Resource, value: boolean) {
    (r as any)._isControlled = value
  }

  static resetResource(r: Resource) {
    (r as any)._reset()
  }

}





