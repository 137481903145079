import ResourceGroup from "@/webgl/assets/ResourceGroup";
import PlaneGeometry from "@/webgl/lib/PlaneGeometry";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import GLConfig from "nanogl-state/config";
import Program from "nanogl/program";
import vShader from "@/webgl/glsl/dodraw/dodraw.vert";
import fShader from "@/webgl/glsl/dodraw/dodraw.frag";
import Node from "nanogl-node";
import Texture2D from "nanogl/texture-2d";
import GLApp from "@/webgl/main";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import GltfNode from "../../lib/nanogl-gltf/lib/elements/Node";
import { vec2, vec3 } from "gl-matrix";
import Delay from "@/core/Delay";
import gsap, { Quart, Sine } from "gsap";
import { smoothstep } from "@/webgl/math";
import MuseumActivity from "../MuseumActivity";
import { Viewport } from "@/store/modules/Viewport";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

const V3A = vec3.create()
const V3A1 = vec3.create()

const CANVAS_WIDTH = 512

const CHECK_TRIANGLE = [[245, 255], [220, 230], [200, 215], [140, 160], [90, 110], [55, 80]]

export default class DoDrawing {
  private glconfig:GLConfig
  private planeGeom: PlaneGeometry
  private doProgram:Program
  private aspect:vec2

  private _canvas:HTMLCanvasElement
  private _canvasIntro:HTMLCanvasElement
  private _canvasCheck:HTMLCanvasElement
  private _canvasTex:Texture2D
  private _texEnd:Texture2D
  private _context:CanvasRenderingContext2D
  private _checkContext:CanvasRenderingContext2D

  private _isDown = false
  private _isMask = false

  private _points:number[][] = []
  private _xy:number[]
  private _xylerp:number[]
  private _checktriangle:boolean[]

  private _lk:GltfNode

  private _canvasScale = 1
  private _transition = 0

  private _canvasRatio = 1

  private _intro = 0
  private introDone = false


  constructor(public scene:Scene, public camera:Camera, public root:Node, private museumCamera:Camera, private resources:ResourceGroup, private callBack:Function) {
    const gl = this.scene.gl
    this.glconfig = new GLConfig();
    this.aspect = vec2.create()

    this.glconfig
      .enableDepthTest(false)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

      this.planeGeom = new PlaneGeometry(this.scene.gl, 1, 1, 1, 1)
      this.doProgram = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())

    this._canvasTex = new Texture2D(gl, gl.RGBA)
    this._canvasTex.setFilter(true, false, false)
    this._canvasTex.clamp()
    this._canvas = document.createElement("canvas")
    this._canvasIntro = document.createElement("canvas")
    this._canvasCheck = document.createElement("canvas")
    this._context = this._canvas.getContext("2d")
    this._checkContext = this._canvasCheck.getContext("2d")

    // document.body.appendChild(this._canvas)
    // this._canvas.style.position = "fixed"
    // this._canvas.style.left = "0"
    // this._canvas.style.top = "0"
    // this._canvas.style.pointerEvents = "none"
    this._points = []

    this._canvasScale = 1
    this._transition = 0

    this._texEnd = this.scene.resources.get("do-end")
    this._texEnd.bind()
    this._texEnd.setFilter(true, false, false)
    this._texEnd.clamp()

    this._lk = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "lookAt5")

    this.showMask()
  }

  async showMask() {
    this.introDone = false
    this._intro = 0
    await gsap.to(this, { _intro: 1, duration: 5, ease: Sine.easeInOut })
    this.introDone = true
  }

  drawCanvas() {
    const glView = GLApp.getInstance().glview
    this._context.clearRect(0, 0, glView.canvasWidth, glView.canvasHeight)
    const cw = this._canvasRatio
    if(!this.introDone) {
      this.drawIntro()
      this._context.drawImage(this._canvasIntro, 0, 0)
      this._context.globalCompositeOperation = 'source-in';
      this._context.drawImage(this.resources.get("follow"), (this._canvas.width - cw) / 2, (this._canvas.height - cw) / 2, cw, cw)
      this._context.globalCompositeOperation= "source-over";
    } else {
      this._context.drawImage(this.resources.get("follow"), (this._canvas.width - cw) / 2, (this._canvas.height - cw) / 2, cw, cw)
      // this._context.drawImage(this._canvasCheck, 0, 0)
      if(this._points.length > 1) {
        this._context.strokeStyle = "#2963A9"
        this._context.lineWidth = 10
        this._context.lineJoin = "round"
        this._context.lineCap = "round"
        this._context.beginPath()
        this._context.moveTo(this._points[0][0], this._points[0][1])
        for (let i = 1; i < this._points.length - 1; i++) {
          // this._context.lineTo(this._points[i][0], this._points[i][1])
          var xc = (this._points[i][0] + this._points[i + 1][0]) / 2;
          var yc = (this._points[i][1] + this._points[i + 1][1]) / 2;
          this._context.quadraticCurveTo(this._points[i][0], this._points[i][1], xc, yc);
        }
        const last = this._points.length - 1
        const p = this._checkContext.getImageData(this._points[last][0], this._points[last][1], 1, 1)
        for (let i = 0; i < this._checktriangle.length; i++) {
          if(p.data[0] > CHECK_TRIANGLE[i][0] && p.data[0] < CHECK_TRIANGLE[i][1]) this._checktriangle[i] = true
          
        }
        if(!this._isDown)
          this._context.lineTo(this._points[0][0], this._points[0][1])
        this._context.stroke()
      }
    }
    
    
  }

  drawIntro() {
    const introCtx = this._canvasIntro.getContext("2d")
    introCtx.clearRect(0, 0, this._canvasIntro.width, this._canvasIntro.height)
    const cw = this._canvasRatio / 510
    const triangleSize = 185 * cw
    const triangleSizeX = 230 * cw
    const step1 = smoothstep(0, 0.3333, this._intro)
    const step2 = smoothstep(0.3333, 0.6666, this._intro)
    const step3 = smoothstep(0.6666, 1, this._intro)
    introCtx.beginPath()
    introCtx.translate((this._canvas.width) / 2, (this._canvas.height) / 2)
    introCtx.strokeStyle = "white"
    introCtx.lineWidth = 35
    introCtx.lineJoin = "round"
    introCtx.lineCap = "round"
    introCtx.moveTo(0, -triangleSize)
    if(step1 > 0) {
      const xy = this.drawStep(step1, 0, -triangleSize, triangleSizeX, triangleSize)
      introCtx.lineTo(xy[0], xy[1])
    }
    if(step2 > 0) {
      const xy = this.drawStep(step2, triangleSizeX, triangleSize, -triangleSizeX, triangleSize)
      introCtx.lineTo(xy[0], xy[1])
    }
    if(step3 > 0) {
      const xy = this.drawStep(step3, -triangleSizeX, triangleSize, 0, -triangleSize)
      introCtx.lineTo(xy[0], xy[1])
    }
    introCtx.stroke()
    introCtx.resetTransform()
  }

  drawStep(step, startX, startY, stopX, stopY) {
    const distX = stopX - startX
    const distY = stopY - startY

    const dist = Math.sqrt(distX * distX + distY * distY)
    const angle = Math.atan2(distY, distX)

    return [startX + Math.cos(angle) * dist * step,startY +  Math.sin(angle) * dist * step]
  }

  fillCanvasMask() {
    const glView = GLApp.getInstance().glview
    this._context.clearRect(0, 0, glView.canvasWidth, glView.canvasHeight)
    this._context.fillStyle = "white"
    this._context.beginPath()
    this._context.moveTo(this._points[0][0], this._points[0][1])
    for (let i = 1; i < this._points.length - 1; i++) {
      var xc = (this._points[i][0] + this._points[i + 1][0]) / 2;
      var yc = (this._points[i][1] + this._points[i + 1][1]) / 2;
      this._context.quadraticCurveTo(this._points[i][0], this._points[i][1], xc, yc);
    }
    this._context.lineTo(this._points[0][0], this._points[0][1])
    this._context.fill()
  }

  mousemove(x:number, y:number) {
    if(this._isMask) return
    if(this._isDown) {
      this._xy = [x, y]
    }
  }

  mousedown(x:number, y:number) {
    if(this._isMask) return
    if (this._intro >= 1) {
      AudioManager.play(AUDIO_ID.XP_DO_DRAW)?.volume(1)
    }
    this._checktriangle = CHECK_TRIANGLE.map(() => false)
    this._xy = [x,y]
    this._xylerp = [x, y]
    this._points = []
    this._isDown = true
  }

  async mouseup() {
    if(this._isMask) return
    this._isDown = false
    AudioManager.fadeOutStop(AUDIO_ID.XP_DO_DRAW, 500)
    await Delay(500)
    const total = Viewport.isDesktop ? CHECK_TRIANGLE.length : CHECK_TRIANGLE.length - 2
    if(this._checktriangle.filter(b => b).length >= total) {
      this._isMask = true
      this._transition = 1
      this.fillCanvasMask()
      const museumAct: MuseumActivity = this.scene.activities?.getActivity("museum") as MuseumActivity
      museumAct.setCameraCtrlMouseMove(false)
      await this.animateMask()
      this.callBack()
    } else {
      this._points = []
    }
  }

  async animateMask() {
    // gsap.to(this, { duration: 0.1, _transition: 1, ease: Quart.easeIn })

    const museumAct:MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct.doCamZoom()
    await gsap.to(this, { duration: 2, _canvasScale: 0.025, ease: Quart.easeIn })
  }

  preRender() {
    if(!this._isMask)this.drawCanvas()
    if(this._isDown && this._xy) {
      this._xylerp[0] += (this._xy[0] - this._xylerp[0]) * 0.1
      this._xylerp[1] += (this._xy[1] - this._xylerp[1]) * 0.1
      if(this._points.length > 1) {
        const l = this._points.length - 1
        const dx = Math.abs(this._xylerp[0] - this._points[l][0])
        const dy = Math.abs(this._xylerp[1] - this._points[l][1])
        const distance = Math.sqrt(dx * dx + dy * dy)
        if(distance > 2.5) this._points.push([this._xylerp[0], this._xylerp[1]])
      } else this._points.push([this._xylerp[0], this._xylerp[1]])
      
    }
    this._canvasTex.fromImage(this._canvas)
    vec3.set(V3A, this._lk._wmatrix[12], this._lk._wmatrix[13],this._lk._wmatrix[14])
    vec3.transformMat4(V3A, V3A, this.museumCamera._viewProj)
    // quat.copy(this.root.rotation, this.lk.rotation)
    vec3.lerp(this.root.position, V3A, [0, 0, 0], this._transition)
    this.root.invalidate()
    this.root.updateWorldMatrix()
  }

  render() {
    this.scene.glstate.now(this.glconfig)
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.doProgram.use()
    this.doProgram.uMVP(M4);
    this.doProgram.uCanvas(this._canvasTex)
    this.doProgram.uTex(this._texEnd)
    this.doProgram.uTransition(this._transition)
    this.doProgram.uCanvasScale(this._canvasScale)
    this.doProgram.uAspect(this.aspect);
    // this.doProgram.uIsMobile(Viewport.isDesktop ? 0 : 1);
    this.planeGeom.bind(this.doProgram);
    this.planeGeom.draw();
  }

  _resize(aspect) {
    const p1 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "DoPoint1")
    const p2 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "DoPoint2")
    this.museumCamera.updateWorldMatrix()
    p1.updateWorldMatrix()
    p2.updateWorldMatrix()
    vec3.set(V3A, p1._wmatrix[12], p1._wmatrix[13],p1._wmatrix[14])
    vec3.set(V3A1, p2._wmatrix[12], p2._wmatrix[13],p2._wmatrix[14])

    vec3.transformMat4(V3A, V3A, this.museumCamera._viewProj)
    vec3.transformMat4(V3A1, V3A1, this.museumCamera._viewProj)

    const w = GLApp.getInstance().glview.canvasWidth
    const h = GLApp.getInstance().glview.canvasHeight
    
    const x1 = (V3A[0] + 1) / 2 * w
    const y1 = (-1 * V3A[1] + 1) / 2 * h
    const x2 = (V3A1[0] + 1) / 2 * w
    const y2 = (-1 * V3A1[1] + 1) / 2 * h
    this._canvasRatio  = (x2 - x1) * 0.8


    this._canvas.width = GLApp.getInstance().glview.canvasWidth
    this._canvasIntro.width = GLApp.getInstance().glview.canvasWidth
    this._canvas.height = GLApp.getInstance().glview.canvasHeight
    this._canvasIntro.height = GLApp.getInstance().glview.canvasHeight
    this._canvasCheck.width = GLApp.getInstance().glview.canvasWidth
    this._canvasCheck.height = GLApp.getInstance().glview.canvasHeight

    const cw = this._canvasRatio
    this._checkContext.drawImage(this.resources.get("limit"), (this._canvas.width - cw) / 2, (this._canvas.height - cw) / 2, cw, cw)
    
    if(w > h) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)
  }

  destroy() {
    this.doProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    if(this._isMask) this.fillCanvasMask()
    this.planeGeom = null
  }
}