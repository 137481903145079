import vShader from "@/webgl/glsl/eyeblink/eyeblink.vert";
import fShader from "@/webgl/glsl/eyeblink/eyeblink.frag";

import PlaneGeometry from "@/webgl/lib/PlaneGeometry"
import Program from "nanogl/program"
import Camera from "nanogl-camera";
import Scene from "@/webgl/Scene";
import Node from "nanogl-node";
import Texture2D from "nanogl/texture-2d";
import { watch } from "vue";
import { setEyeClosure, Visage } from "@/store/modules/Visage";
import AppService from "@/store/states/AppService";
import { AppStore } from "@/store/modules/AppStore";
import { vec2 } from "gl-matrix";
import { Viewport } from "@/store/modules/Viewport";
import { clamp } from "@/webgl/math";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import Delay from "@/core/Delay";
import Tracking from "@/core/Tracking";

const BEIGE = [236 / 255, 233 / 255, 228 / 255]
const BLUE = [41 / 255, 99 / 255, 169 / 255]
const FPS6 = 1000 / 6
export default class EyeBlink {

  planeGeom: PlaneGeometry
  blinkProgram: Program

  dimensions: {
    width: number,
    height: number,
    aspect: number
  }

  videoTex: Texture2D
  isTransition: number
  wallTex: Texture2D


  videoVine: HTMLVideoElement
  videoVineCanvas: HTMLCanvasElement
  videoVineContext: CanvasRenderingContext2D
  videoVineTime: number
  vineTex: Texture2D
  videoVineAspect: number[]

  currentFrame = 0
  currentTime = 0
  actualFrame = 0

  blinkWatcher
  aspect: vec2

  started = false
  isBlinking = false
  isBlinkingPlaying = false
  videoDone = false
  blinkTime
  isBlink = 0
  secondHalf = false
  holdTo = false

  addCurrentTime = 0

  constructor(private scene: Scene, private camera: Camera, private root: Node, private endTex: Texture2D, private resources: ResourceGroup) {
    const gl = this.scene.gl
    this.planeGeom = new PlaneGeometry(gl, 1, 1, 1, 1)
    this.blinkProgram = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())
    this.aspect = vec2.create()

    this.wallTex = this.scene.resources.get("wall-tex")
    this.wallTex.bind()
    this.wallTex.repeat()

    this.addCurrentTime = 0
    this.actualFrame = 0
    this.currentFrame = 0
    this.currentTime = 0

    this.secondHalf = this.holdTo = false

    this.createVideo()
    this.createVideoVine()

    this.isBlink = 0
    this.isTransition = 0

    this.started = false
  }

  startWatchEyeBlink() {
    setEyeClosure(-1);
    this.blinkWatcher = watch(() => Visage.eyeClosure, async (b) => {
      this.blinkV(b)
    })
    this.started = true
  }

  createVideoVine() {
    this.videoVine = document.createElement("video")
    this.videoVine.src = URL.createObjectURL(this.scene.resources.get("vine"))
    this.videoVine.playsInline = true
    this.videoVine.muted = true
    this.videoVine.loop = true
    this.videoVineAspect = [1, 1]
    this.videoVine.onloadedmetadata = () => this.resize(this.dimensions.aspect, this.dimensions.width, this.dimensions.height)

    this.videoVineCanvas = document.createElement("canvas")
    this.videoVineContext = this.videoVineCanvas.getContext("2d")
    this.videoVineContext.imageSmoothingEnabled = false
    this.vineTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.vineTex.setFilter(true, false, false)
    this.vineTex.clamp()
    this.videoVine.play()
  }

  async createVideo() {

    this.videoTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.videoTex.setFilter(true, false, false)
    this.videoTex.clamp()
    this.videoTex.fromImage(this.resources.get("frame-0"))
  }

  blinkV(v) {
    if (this.isBlink === 0) {
      this.addCurrentTime = v ? -1 : 1
    } else if (!this.holdTo) {
      this.addCurrentTime = 0
    }
    if (v && this.isBlink === 1 && !this.holdTo && AppService.state.value['step_see'] === "default") {
      AppService.send("ENTER");
      Tracking.eventPush("xp-cta-i-see", AppStore.mediaEnabled ? "eyes-closed" : "hold")
      this.holdTo = true
    }
  }

  blinkSoundDone() {
    this.addCurrentTime = 1
    this.secondHalf = true
  }

  endBlink() {
    this.videoDone = true
    this.isTransition = 0
    AppService.send("ENTER");
  }

  drawVideo() {
    if (AppStore.mediaEnabled) {
      if (!this.started) return
      if (!Visage.isReady) return
    }
    if ((this.isBlink === 0 || this.secondHalf) && Date.now() - this.currentTime > FPS6) {
      this.currentFrame = clamp(this.currentFrame + this.addCurrentTime, 0, 15)
      this.currentTime = Date.now()
    }

    if (this.currentFrame !== this.actualFrame) {
      const f = this.resources.get("frame-" + this.currentFrame)
      this.videoTex.fromImage(f)
      this.actualFrame = this.currentFrame
    }

    if (this.actualFrame >= 7 && this.isBlink === 0) {
      this.isBlink = 1
    }

    if (this.actualFrame === 15 && this.secondHalf) {
      this.secondHalf = false
      this.endBlink()
    }
  }

  drawVineVideo() {
    if (this.videoVine.currentTime !== this.videoVineTime) {
      this.videoVineContext.drawImage(this.videoVine, 0, 0, this.videoVineCanvas.width, this.videoVineCanvas.height)
      this.vineTex.fromImage(this.videoVineCanvas)
      this.videoVineTime = this.videoVine.currentTime
    }

  }

  preRender() {
    this.drawVideo()
    this.drawVineVideo()
  }

  render() {
    this.blinkProgram.use()
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.blinkProgram.uMVP(M4);
    this.blinkProgram.uVideo(this.videoTex);
    // this.blinkProgram.uTransition(this.isTransition);
    this.blinkProgram.uWall(this.wallTex)
    this.blinkProgram.uVine(this.vineTex)
    this.blinkProgram.uVineAspect(this.videoVineAspect)
    this.blinkProgram.uAspect(this.aspect);
    this.blinkProgram.uBeige(BEIGE);
    this.blinkProgram.uBlue(BLUE);
    // this.blinkProgram.uBlink(1);
    this.blinkProgram.uBlink(this.currentFrame / 15);
    this.blinkProgram.uTex(this.endTex);
    this.planeGeom.bind(this.blinkProgram);
    this.planeGeom.draw();
  }

  resize(aspect, width, height) {
    this.dimensions = {
      width,
      height,
      aspect
    }

    if (width > height) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)

    this.videoVineCanvas.width = width / 10
    this.videoVineCanvas.height = height / 10

    if (this.videoVine.videoWidth && this.videoVine.videoHeight) {
      this.videoVineAspect = width > height
        ? [1, this.videoVine.videoWidth / this.videoVine.videoHeight]
        : [this.videoVine.videoHeight / this.videoVine.videoWidth, 1]
    }
  }

  destroy() {
    this.blinkWatcher()
    this.blinkProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.blinkProgram = this.planeGeom = null
    this.videoTex.dispose()

    URL.revokeObjectURL(this.videoVine.src)
    this.videoVine = null
    this.videoVineCanvas.width = 1
    this.videoVineCanvas.height = 1
    this.videoVineCanvas = null
    this.videoVineContext = null
    this.vineTex.dispose()
  }
}