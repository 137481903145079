import '@/styles/index.styl';

import i18n from '@/core/i18n';
import { createApp } from 'vue'
import App from './App.vue'
import GlobalComponents from '@/components'
import Browser from "@/utils/Browser";
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import { createAudioState } from '@/core/audio/AudioState';

createAudioState();

const app = createApp(App);

GlobalComponents.register(app);

app.use(i18n);

app.use(YoutubeIframe);

app.mount('#app');
