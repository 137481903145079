
import Flag               from 'nanogl-pbr/Flag'
import Precision          from 'nanogl-pbr/ShaderPrecision'
import Version            from 'nanogl-pbr/ShaderVersion'
import mat4               from 'gl-matrix/src/gl-matrix/mat4'

import MaterialPass, { MaterialPassId } from 'nanogl-pbr/MaterialPass'
import Program from 'nanogl/program'
import Node from 'nanogl-node'
import Camera from 'nanogl-camera'

import vShader from './shader.vert'
import fShader from './shader.frag'

var M4           = mat4.create();
var MAT_ID = 'refd';



export default class ReflectDistPass extends MaterialPass {

  version: Version
  precision: Precision
  shaderid: Flag<string>
  distScale = 1.3

  constructor( name : string = 'reflect-dist-pass' ){

    super( {
      uid  : MAT_ID,
      vert : vShader(),
      frag : fShader(),
    } );

    this.version         = this.inputs.add( new Version( '100' ) );
    this.precision       = this.inputs.add( new Precision( 'mediump' ) );
    this.shaderid        = this.inputs.add( new Flag ( 'id_'+MAT_ID,  true  ) );


    this.glconfig
      .enableDepthTest()
      // .depthFunc( gl.EQUAL)
    

  }



  prepare( prg: Program, node: Node, camera: Camera ) : void {

    if( prg.uMVP ){
      camera.modelViewProjectionMatrix( M4, node._wmatrix );
      prg.uMVP(          M4            );
    }

    prg.uWorldMatrix( node._wmatrix );
    prg.uVP( camera._viewProj );

    if( prg.uCamPos ) prg.uCamPos( camera._wposition );
    
    prg.uDistScale( this.distScale );

  }


};
