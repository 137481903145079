import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Flag from "nanogl-pbr/Flag";
import Input, { Uniform } from "nanogl-pbr/Input";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import FragCode from "./code.frag"
import FragPreCode from "./pre-code.frag"


export default class FloorReflectionChunk extends Chunk {

  static enabled: Flag = new Flag(  'enableFReflect',  true );

  reflectionTexture : Texture2D = null

  strength : Input
  ssinput: Input;
  ssuniform: Uniform;

  
  constructor(){
    super( true, true )
    this.addChild( FloorReflectionChunk.enabled );  


    this.ssinput = new Input( "ScreenSize", 2 )
    this.ssuniform = this.ssinput.attachUniform('uscreensize')


    this.addChild( this.ssinput );  

    this.addChild( this.strength = new Input( "ReflectionStrength", 1));
    // this.strength.attachConstant( 1.1 )  
  }

  setSize( w:number, h:number ){
    this.ssuniform.set( w, h )
  }


  protected _genCode(slots: ChunksSlots): void {
    slots.add( 'lightsf', FragCode() )
    slots.add( 'pf', FragPreCode() )
  }


  setup(prg: Program): void{
    if( this.reflectionTexture === null ) throw 'missing reflection texture'
    prg.tFloorReflect( this.reflectionTexture );
  }
}
