import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d04e4ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "animate-sprite" }

export function render(_ctx: any,_cache: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frameCollection, (frame) => {
      return (_openBlock(), _createElementBlock("div", {
        key: frame.id,
        class: "frame",
        ref: "frame",
        style: _normalizeStyle({ 'background-image': `url(${frame})` })
      }, null, 4))
    }), 128))
  ]))
}