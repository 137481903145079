import { reactive } from "@vue/runtime-core";
import Browser from "@/utils/Browser";

type Viewport = {
  windowWidth: number;
  windowHeight: number;
  isDesktop: boolean;
  isLandscape: boolean;
  isTouch: boolean;
  webpSupport: boolean;
  isVirtualKeyboardOpen: boolean;
};

export const enum BREAKPOINTS {
  DESKTOP = "1050px",
}

export const Viewport = reactive<Viewport>({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  isDesktop: false,
  isLandscape: false,
  isTouch: false,
  webpSupport: false,
  isVirtualKeyboardOpen: false,
}) as Viewport;

Viewport.isDesktop = isDesktop();
Viewport.isLandscape = isLandscape();
Viewport.isTouch = isTouch();

function isDesktop() {
  return Viewport.windowWidth >= parseInt(BREAKPOINTS.DESKTOP);
}

function isLandscape() {
  return Viewport.windowWidth > Viewport.windowHeight;
}

function isTouch() {
  return (
    !Viewport.isDesktop &&
    ("ontouchstart" in window || navigator?.maxTouchPoints > 0)
  );
}

function onResize() {
  checkVirtualKeyboardVisibility();
  Viewport.windowWidth = window.innerWidth;
  Viewport.windowHeight = window.innerHeight;
  Viewport.isDesktop = isDesktop();
  Viewport.isLandscape = isLandscape();
}

function checkVirtualKeyboardVisibility() {
  let isOpen = false;
  if (
    window.innerWidth === Viewport.windowWidth &&
    window.innerHeight < Viewport.windowHeight
  ) {
    // keyboard is likely open if mobile device
    isOpen = Browser.mobile;
  }

  Viewport.isVirtualKeyboardOpen = isOpen;
}

export function initViewport() {
  window.addEventListener("resize", onResize);
}

export function disposeViewport() {
  window.removeEventListener("resize", onResize);
}

export function isWebpSupport() {
  Viewport.webpSupport = true;
}
