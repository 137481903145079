import Delay from "@/core/Delay";
import { getTexture } from "@/core/TextureURL";
import { Viewport } from "@/store/modules/Viewport";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import { ImageResource } from "@/webgl/assets/Net";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import GLApp from "@/webgl/main";
import { DEG2RAD } from "@/webgl/math";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import MuseumActivity from "../MuseumActivity";
import StateHelper from "../StateHelper";
import DoDrawing from "./DoDrawing";


/**
 * Noop activity, loading in VUE
 */
export default class DoActivity implements IActivity {

  readonly priority: number = 2;
  readonly name: string = "do";

  stateHelper: StateHelper;

  private _fov: number = 50;

  private _camera: Camera

  private _resources: ResourceGroup

  private root: Node

  private _doDrawing: DoDrawing

  constructor(public scene: Scene) {
    this.root = new Node()
    this.stateHelper = new StateHelper([
      // { match: 'step_do.outro', enter: this.enterOutro },
    ])
  }

  get resources(): ResourceGroup {
    return this._resources
  }

  load(): Promise<any> {
    this._resources = new ResourceGroup()
    console.log("DoActivity.load()");

    this._resources.add(new ImageResource(getTexture(`do-limit${Viewport.isDesktop ? "" : "-mobile"}.jpg`)), "limit")
    this._resources.add(new ImageResource(require("@/assets/webgl/museum2/do-follow.png")), "follow")


    return this._resources.load()
  }

  onStateUpdate(state: AppState): void {
    if (state.value === "outro") this.enterOutro()
  }

  start() {
    this.makeCamera()
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    this._doDrawing = new DoDrawing(this.scene, this._camera, this.root, museumAct.camera, this._resources, this.gotoOutro)
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    this.scene.glview.onResize.on(this._resize)
    this._resize()
    this.stateHelper.start()

    const canvas = GLApp.getInstance().glview.canvas
    canvas.addEventListener("mousemove", this.mousemove)
    canvas.addEventListener("mousedown", this.mousedown)
    canvas.addEventListener("mouseup", this.mouseup)
    canvas.addEventListener("touchmove", this.touchmove)
    canvas.addEventListener("touchstart", this.touchstart)
    canvas.addEventListener("touchend", this.touchend)
  }

  stop(): void {
    console.log("stop feel")
    this.scene.glview.onResize.off(this._resize)
    this.stateHelper.stop()
    const canvas = GLApp.getInstance().glview.canvas
    canvas.removeEventListener("mousemove", this.mousemove)
    canvas.removeEventListener("mousedown", this.mousedown)
    canvas.removeEventListener("mouseup", this.mouseup)
    canvas.removeEventListener("touchmove", this.touchmove)
    canvas.removeEventListener("touchstart", this.touchstart)
    canvas.removeEventListener("touchend", this.touchend)
  }


  enterIntro = () => {

  }

  enterDefault = () => {

  }

  gotoOutro = () => {
    AppService.send("ENTER")
  }


  enterOutro = async () => {
    console.log("enter outro")
    await Delay(1000)
    AppService.send("ENTER")
  }

  touchmove = (event: TouchEvent) => {
    this._doDrawing.mousemove(event.touches[0].clientX, event.touches[0].clientY)
  }

  touchstart = (event: TouchEvent) => {
    this._doDrawing.mousedown(event.touches[0].clientX, event.touches[0].clientY)
  }

  touchend = () => {
    this._doDrawing.mouseup()
  }

  mousemove = (event: MouseEvent) => {
    this._doDrawing.mousemove(event.clientX, event.clientY)
  }

  mousedown = (event: MouseEvent) => {
    this._doDrawing.mousedown(event.clientX, event.clientY)
  }

  mouseup = () => {
    this._doDrawing.mouseup()
  }

  unload(): void { }
  preFrame(): void { }
  render(): void {
    this._doDrawing.render()
  }
  renderUI(): void { }
  preRender(): void {
    this._doDrawing.preRender()
  }
  rttPass(): void { }

  private _resize = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    const pr = this.scene.glview.pixelRatio

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._doDrawing._resize(w > h ? h / w : w / h)
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 250

    this._camera = camera;

  }

}