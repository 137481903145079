import { AppState } from "@/store/states/AppStateMachine";
import Node from "../../lib/nanogl-gltf/lib/elements/Node";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import { AbortController } from "@azure/abort-controller";
import StateHelper from "@/webgl/activities/StateHelper";
import MuseumCameraController from "@/webgl/activities/MuseumActivity/MuseumCameraController";
import AppService from "@/store/states/AppService";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { vec2, vec3 } from "gl-matrix";
import { Viewport } from "@/store/modules/Viewport";
import { UserCard } from "@/store/modules/UserCard";
import { MUSEUM_ANIMATION_ID_NB } from "./CameraAnimations";

const V3A = vec3.create()
const V3A2 = vec3.create()

export default class MuseumActivity implements IActivity {

  static buttons: number[][]
  static ageGatePos: vec2
  readonly name: string = "museum";

  readonly priority: number = 3;

  root: Node;

  private _startAbortController: AbortController;
  stateHelper: StateHelper;

  private cameraCtrl: MuseumCameraController;

  private buttons: Node[]

  private _isPaused = false

  private inAgeGate = false

  private lkAgeGate

  get camera() {
    return this.cameraCtrl.Camera
  }

  constructor(public scene: Scene) {

    this.root = new Node()

    MuseumActivity.buttons = []
    MuseumActivity.ageGatePos = vec2.create()

    this.cameraCtrl = new MuseumCameraController(this);


    this.stateHelper = new StateHelper([
      { match: 'step_me.intro', enter: this.enterIntroOutro },
      { match: 'step_me.default', enter: this.enterAgeGate },
      { match: 'step_me.default', exit: this.leaveAgeGate },
      { match: 'museum.intro', enter: this.enterFromMe },
      { match: 'museum.from_artwork', enter: this.backFromArtwork },
      { match: 'museum.from_feel', enter: this.backFromXP },
      { match: 'museum.default', exit: this.leaveMuseum },
      { match: 'artwork.tuto', enter: this.enterArtworkTuto },
      { match: 'artwork.intro', enter: this.enterArtwork },
      { match: 'artwork.default', enter: this.enterArtwork },
      { match: 'step_feel.intro', enter: this.enterFeel },
      { match: 'step_feel.requestCamera', enter: this.enterMainTitle },
      { match: 'step_feel.outro', enter: this.outFeel },
      { match: 'step_do.intro', enter: this.enterDo },
      { match: 'step_do.outro', enter: this.enterLove },
      { match: 'step_love.default', enter: this.enterLove },
      { match: 'step_speak.tuto', enter: this.enterLove },
      { match: 'collab', enter: this.enterCollab },
      { match: 'museum.from_collab', enter: this.backFromCollab },
    ])


  }

  onStateUpdate(state: AppState): void {

  }

  syncTo = (from = 0) => {
    this.cameraCtrl.syncTo(from)
  }

  load(): Promise<void> {
    return Promise.all([
      this.scene.loadMuseumScene(),
    ]).then(() => this.loaded())

  }

  loaded() {
    this.buttons = [
      this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "BtnCollab"),
      this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "BtnArtwork"),
      this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "BtnXp"),
    ]
    const lookat = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "lookats")
    this.cameraCtrl.ready(lookat)
  }

  unload(): void {

  }

  start(): void {
    this.scene.root.add(this.root);
    this.stateHelper.start()

    if (AppService.state.context.museum_dev) {
      this.cameraCtrl.debugCam();
    }

    this.lkAgeGate = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "lookAt1")

    this._startAbortController = new AbortController()

    // this.enterIntroOutro()

    this.scene.ilayer.addEventListener('mousemove', this.onMouseMove)

    this._isPaused = false

    this.cameraCtrl.init()

  }

  stop(): void {

    this._startAbortController.abort()
    this._startAbortController = null

    this.scene.root.remove(this.root)
    this.stateHelper.stop()

    this.scene.ilayer.removeEventListener('mousemove', this.onMouseMove)
  }

  pause(): void {
    this._isPaused = true

  }

  unpause(): void {
    this._isPaused = false

  }

  setCameraCtrlMouseMove(b = false) {
    this.cameraCtrl.setMoveMouse(b)
  }

  setCameraMove() {
    this.scene.glview.canvas.removeEventListener("touchstart", this.touchStart)
    this.scene.glview.canvas.removeEventListener("touchmove", this.touchMove)
    this.scene.glview.canvas.addEventListener("touchstart", this.touchStart)
    this.scene.glview.canvas.addEventListener("touchmove", this.touchMove)
  }

  removeCameraMove() {
    this.scene.glview.canvas.removeEventListener("touchstart", this.touchStart)
    this.scene.glview.canvas.removeEventListener("touchmove", this.touchMove)
  }

  touchStart = (event: TouchEvent) => {
    const x = event.touches[0].clientX
    this.cameraCtrl.mouseXPrev = x
  }

  touchMove = (event: TouchEvent) => {
    const x = event.touches[0].clientX
    this.cameraCtrl.mouseY += (x - this.cameraCtrl.mouseXPrev) * 0.0025
    this.cameraCtrl.mouseY = Math.max(-0.572836189270018, Math.min(0.38048631668090876, this.cameraCtrl.mouseY))
    this.cameraCtrl.mouseXPrev = x
  }


  enterIntroOutro = async () => {

    const age = UserCard.age
    if (age !== 0) {
      await this.cameraCtrl.skipAgeGate()
      AppService.send("SKIP_AGE_GATE")
      if (!Viewport.isDesktop) this.setCameraMove()
      this.cameraCtrl.setMoveMouse(true)
    }
    else {
      await this.cameraCtrl.playIntro()
      AppService.send("INTRO_COMPLETE")
    }


    // this.pause()
  }

  enterAgeGate = () => {
    this.inAgeGate = true
  }

  leaveAgeGate = () => {
    this.inAgeGate = false
  }

  enterFromMe = async () => {
    // this.unpause()
    await this.cameraCtrl.goToHome();
    AppService.send("INTRO_COMPLETE")
    if (!Viewport.isDesktop) this.setCameraMove()
  }

  leaveMuseum = () => {
    if (!Viewport.isDesktop) this.removeCameraMove()
  }

  backFromArtwork = async () => {
    await this.cameraCtrl.goToHomeFromArtwork();
    this.cameraCtrl.setMoveMouse(true)
    AppService.send("INTRO_COMPLETE")
    if (!Viewport.isDesktop) this.setCameraMove()
  }

  enterArtwork = async () => {
    if (this.cameraCtrl.currentPosition !== MUSEUM_ANIMATION_ID_NB.Artwork) {
      this.cameraCtrl.setMoveMouse(false)
      await this.cameraCtrl.goToArtwork();
    }

    AppService.send("INTRO_COMPLETE")
  }

  enterArtworkTuto = async () => {
    this.unpause()
    this.cameraCtrl.setMoveMouse(false)
    await this.cameraCtrl.goToArtwork(0);
  }

  enterCollab = async () => {
    this.cameraCtrl.setMoveMouse(false)
    if (!this._isPaused) {
      await this.cameraCtrl.goToCollab();
      AppService.send("INTRO_COMPLETE")
    } else {
      await this.cameraCtrl.goToCollab(0);
      AppService.send("INTRO_COMPLETE")
    }
  }

  backFromCollab = async () => {
    this.unpause()
    await this.cameraCtrl.goToHomeFromCollab();
    this.cameraCtrl.setMoveMouse(true)
    AppService.send("INTRO_COMPLETE")
    if (!Viewport.isDesktop) this.setCameraMove()
  }

  backFromXP = async () => {
    this.unpause()
    await this.cameraCtrl.goToHomeFromXP();
    this.cameraCtrl.setMoveMouse(true)
    AppService.send("INTRO_COMPLETE")
    if (!Viewport.isDesktop) this.setCameraMove()
  }

  // backFromFeel = async () => {
  //   this.unpause()
  //   await this.cameraCtrl.goToHomeFromFeel();
  //   AppService.send("INTRO_COMPLETE")
  // }

  enterFeel = async () => {
    await this.cameraCtrl.goToFeel();
    AppService.send("INTRO_COMPLETE")
    // this.pause()
  }

  enterMainTitle = () => {
    console.log("enter main title")
    this.pause()
  }

  outFeel = () => {
    this.unpause()
  }

  enterDo = async () => {
    this.unpause()
    await this.cameraCtrl.goToDo();
    AppService.send("INTRO_COMPLETE")
  }

  doCamZoom = async () => {
    await this.cameraCtrl.setDoZoom()
  }

  enterLove = () => {
    this.pause()
  }

  onMouseMove = (event: MouseEvent) => {
    vec2.set(this.cameraCtrl.mousePos, event.clientX, event.clientY)
  }

  preFrame(): void {
    if (this._isPaused) return

  }

  preRender(): void {
    if (this._isPaused) return

    this.scene.museumScene.preRender(this.scene.dt);
    const idRoll = this.cameraCtrl.update(this.scene.dt);

    this.fillButtonsPosition(idRoll)

    if (this.inAgeGate) {
      const w = (this.scene.gl.canvas as HTMLElement).clientWidth
      const h = (this.scene.gl.canvas as HTMLElement).clientHeight
      vec3.set(V3A, this.lkAgeGate._wmatrix[12], this.lkAgeGate._wmatrix[13], this.lkAgeGate._wmatrix[14])
      vec3.transformMat4(V3A2, V3A, this.camera._viewProj)
      vec2.set(MuseumActivity.ageGatePos, V3A2[0] * (w / 2), -V3A2[1] * (h / 2))
    }

  }

  fillButtonsPosition(idRoll) {
    const w = (this.scene.gl.canvas as HTMLElement).clientWidth
    const h = (this.scene.gl.canvas as HTMLElement).clientHeight
    for (let i = 0; i < this.buttons.length; i++) {
      const node: Node = this.buttons[i] as Node
      node.lookAt(this.camera.position)
      vec3.set(V3A, node._wmatrix[12], node._wmatrix[13], node._wmatrix[14])
      vec3.transformMat4(V3A2, V3A, this.camera._viewProj)

      const x = (V3A2[0] + 1) / 2 * w
      const y = (-1 * V3A2[1] + 1) / 2 * h

      MuseumActivity.buttons[i] = [x, y, idRoll === i ? 1 : 0]
    }
  }


  render(): void {
    if (this._isPaused) return
    // this.scene.gl.bindFramebuffer(this.scene.gl.FRAMEBUFFER, null)
    // this.scene.gl.viewport(0, 0, this.scene.glview.width, this.scene.glview.height)
    this.camera.updateViewProjectionMatrix(this.scene.glview.width, this.scene.glview.height);
    // this.scene.gl.clear(this.scene.gl.DEPTH_BUFFER_BIT | this.scene.gl.COLOR_BUFFER_BIT)
    this.scene.museumScene.render(this.camera);

  }

  renderUI(): void {
  }


  rttPass(): void {
    if (this._isPaused) return
    this.scene.museumScene.reflectPass(this.camera);
  }


}