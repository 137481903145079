
import { defineComponent } from "vue";
import gsap from "gsap";
export default defineComponent({
  setup() {},

  props: {
    doodles: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.fromTo(el, { opacity: 0 }, { opacity: 1 });
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.fromTo(el, { opacity: 1 }, { opacity: 0 });
    },

    beforeAppear() {
      gsap.set(this.$el, {
        y: 80,
        rotate: "-25deg",
      });
      gsap.set(this.$refs.background as Element, {
        scaleX: 0,
        scaleY: 0,
      });
      gsap.set(this.$refs.content as Element, {
        scaleX: 0,
        scaleY: 0,
      });
      gsap.set(this.$el.querySelectorAll(".inner"), {
        opacity: 0,
      });
    },

    appear() {
      const tl = gsap.timeline();

      tl.addLabel("start");

      tl.to(
        [this.$el],
        {
          y: 0,
          rotate: "0deg",
          duration: 1,
          ease: "cta.y.out",
        },
        "start"
      );

      tl.to(
        this.$refs.background as Element,
        {
          scaleX: 1,
          duration: 1,
          ease: "cta.x.out",
        },
        "start"
      );

      tl.to(
        this.$refs.background as Element,
        {
          scaleY: 1,
          duration: 1,
          ease: "cta.y.out",
        },
        "start"
      );

      tl.to(
        this.$refs.content as Element,
        {
          scaleX: 1,
          scaleY: 1,
          duration: 0.45,
          ease: "cubic.inOut",
        },
        `start+=0.01`
      );

      tl.to(
        Array.from(this.$el.querySelectorAll(".inner")),
        {
          opacity: 1,
          duration: 0.45,
          stagger: 0.1,
          ease: "linear",
        },
        `start+=0.01`
      );
    },
  },
});
