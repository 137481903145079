import { AUDIO_ID } from "@/core/audio/AudioManager";
import { reactive } from "vue";

export const voiceKeyPoints: Partial<Record<AUDIO_ID, Array<number>>> = {
  [AUDIO_ID.CHAPTER_FEEL]: [0, 5, 14],
  [AUDIO_ID.CHAPTER_DO]: [0, 8, 14],
  [AUDIO_ID.CHAPTER_LOVE]: [0, 8],
  [AUDIO_ID.CHAPTER_SPEAK]: [0, 6, 12],
  [AUDIO_ID.CHAPTER_SEE]: [0, 8],
  [AUDIO_ID.CHAPTER_ARTWORK]: [0, 11],
}

export const Sound = reactive({
  currentVoice: null,
});

export function setCurrentVoice(voice: AUDIO_ID | null) {
  Sound.currentVoice = voice
}