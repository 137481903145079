import { Viewport } from "@/store/modules/Viewport";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { TextureFiltering, TextureSrcSet, TextureWrap } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Programs from "@/webgl/gl/Programs";
import Scene from "@/webgl/Scene";
import { BlobResource, ImageResource } from "./assets/Net";
import MuseumAssets from '@/assets/webgl/museum2';
import { getTexture } from "@/core/TextureURL";
export default class GlobalResources {

  static setupTextures(group: ResourceGroup, scene: Scene) {

    const LOADLIST = [
      { src: "do-end.jpg", name: "do-end" },
      { src: "love-end.jpg", name: "love-end" },
      { src: "speak-end.jpg", name: "speak-end" },
      { src: "see-end.jpg", name: "see-end" },
      { src: "wall_tex.jpg", name: "wall-tex" },
    ]

    const opts = {
      bbc: false,
      flipY: false,
      genMips: false,
      wrap: TextureWrap.CLAMP,
      filtering: TextureFiltering.LINEAR
    }
    const addMobile = Viewport.isDesktop ? "" : "-mobile"
    // let srcSet = new TextureSrcSet("assets/webgl/noise_8x8.png");
    // group.add(new TextureResource(srcSet, scene), "noise3D_4x4");
    group.add(new BlobResource(require("@/assets/webgl/videos/video-transition-ink"+ addMobile + ".mp4")), "video-transition")
    // group.add(new BlobResource("assets/webgl/t1-small.mp4"), "ink01")
    // group.add(new BlobResource("assets/webgl/t2-small.mp4"), "ink02")
    // group.add(new BlobResource("assets/webgl/t3-small.mp4"), "ink03")
    // group.add(new BlobResource("assets/webgl/t4-small.mp4"), "ink04")
    // group.add(new BlobResource("assets/webgl/t5-small.mp4"), "ink05")
    group.add(new BlobResource(require("@/assets/webgl/videos/transition-faces-i-feel"+ addMobile + ".mp4")), "feel-transition")
    group.add(new BlobResource(require("@/assets/webgl/videos/shadows.mp4")), "vine")
    group.add(new BlobResource(require("@/assets/webgl/videos/i-love-rgb-mask"+ addMobile + ".mp4")), "love01")
    group.add(new BlobResource(require("@/assets/webgl/videos/i-love-rgb-mask-1"+ addMobile + ".mp4")), "love02")
    group.add(new BlobResource(require("@/assets/webgl/videos/i-love-rgb-mask-2"+ addMobile + ".mp4")), "love03")
    group.add(new ImageResource(getTexture("wall_tex.jpg")), "wall-tex-img")

    for (let i = 0; i < LOADLIST.length; i++) {
      const c = LOADLIST[i];
      if(Viewport.webpSupport) c.src = c.src.substr(0, c.src.lastIndexOf(".")) + ".webp";
      let srcSet = new TextureSrcSet(MuseumAssets.getPath(c.src));
      srcSet.options = {...opts}
      group.add(new TextureResource(srcSet, scene), c.name);
      
    }

    


    // srcSet = new TextureSrcSet("assets/webgl/ray_line.png");
    // srcSet.options = {
    //   bbc: false,
    //   flipY: true,
    //   genMips: false,
    //   wrap: TextureWrap.CLAMP,
    //   filtering: TextureFiltering.LINEAR
    // }
    // group.add(new TextureResource(srcSet, scene), "ray_line");


  }

  static setupPrograms(programs: Programs) {

  }

}