
import gsap from "gsap";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},

  data() {
    const tlCollection: gsap.core.Timeline[] = [];
    const scrollTriggerCollection: ScrollTrigger[] = [];
    return {
      tlCollection,
      scrollTriggerCollection,
    };
  },

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl.clear() && tl.kill();
      });
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) =>
        scrollTrigger.kill()
      );
    }
  },

  mounted() {
    this.$nextTick(this.initScrollAnimation);
  },

  methods: {
    initScrollAnimation() {
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el as Element,
              scrub: 1,
              start: "80px 80px",
            },
          })
          .fromTo(
            this.$refs.img as Element,
            {
              scale: 1,
              y: 0,
              opacity: 1,
            },
            {
              scale: 1.05,
              // y: -80,
              // opacity: 0,
              duration: 2,
            }
          )
      );
    },
  },
});
