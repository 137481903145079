import { IActivity } from "@/webgl/activities/Activity";
import LoadingActivity from "@/webgl/activities/LoadingActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class LoadingActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new LoadingActivity(scene)
  }
}

let LoadingActivityService = new LoadingActivityServiceImpl('loading')

export default LoadingActivityService


/////////////
/////////////////
//////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////
 
//////////

