import { getTexture } from "@/core/TextureURL";
import { AppStore } from "@/store/modules/AppStore";
import { UserCard } from "@/store/modules/UserCard";
import { Viewport } from "@/store/modules/Viewport";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import { ImageResource } from "@/webgl/assets/Net";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import PlaneGeometry from "@/webgl/lib/PlaneGeometry";
import GLApp from "@/webgl/main";
import { DEG2RAD } from "@/webgl/math";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import { watch } from "vue";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import StateHelper from "../StateHelper";
import Mantra from "./Mantra";
import VolumeSpectrum from "./VolumeSpectrum";


/**
 * Noop activity, loading in VUE
 */
export default class SpeakActivity implements IActivity {

  readonly priority: number = 2;
  readonly name: string = "speak";

  stateHelper: StateHelper;

  private _fov: number = 90;

  private _camera: Camera

  private root: Node

  private _volumeSpectrum: VolumeSpectrum

  private _resources: ResourceGroup

  private _mantras: Mantra[]

  private mantraGeom: PlaneGeometry

  private xy: number[]

  private width: number
  private height: number

  private speakWatcher

  private currentMantra

  constructor(public scene: Scene) {
    this.root = new Node()
    this.stateHelper = new StateHelper([
      { match: 'step_speak.outro', enter: this.enterOutro },
    ])
  }

  get resources(): ResourceGroup {
    return this._resources
  }

  load(): Promise<any> {
    console.log("SpeakActivity.load()");
    this._resources = new ResourceGroup()

    this._resources.add(new ImageResource(getTexture("_mantra-reflection.jpg")), "mantra-reflection")
    this._resources.add(new ImageResource(getTexture("what-if-this.png")), "mantra-01")
    this._resources.add(new ImageResource(getTexture("right-here.png")), "mantra-02")
    this._resources.add(new ImageResource(getTexture("you-are-magic.png")), "mantra-03")
    this._resources.add(new ImageResource(getTexture("the-answer.png")), "mantra-04")
    this._resources.add(new ImageResource(getTexture("you-change.png")), "mantra-05")
    this._resources.add(new ImageResource(getTexture("say-it-all.png")), "mantra-06")
    this._resources.add(new ImageResource(getTexture("be-aware-of.png")), "mantra-07")


    return this._resources.load()
  }

  onStateUpdate(state: AppState): void {
  }

  start() {
    this.currentMantra = -1
    this.makeCamera()
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    this.xy = [0, 0]
    this._volumeSpectrum = new VolumeSpectrum(this.scene, this._camera, this.root)
    this.mantraGeom = new PlaneGeometry(this.scene.gl, 1, 1, 1, 1)
    this._mantras = Array.from({ length: 7 }).map((m, i) => {
      return new Mantra(i + 1, this.scene, this.mantraGeom, this._camera, this.resources, this.root)
    })
    // this._mantras[0].isRender = true
    window.addEventListener("mousemove", this.mousemove);
    this.scene.glview.onResize.on(this._resize)
    this._resize()
    this.stateHelper.start()

    this.speakWatcher = watch(() => UserCard.mantraId, async (id) => {
      this.transition(UserCard.mantraId, this.currentMantra)
      this.currentMantra = UserCard.mantraId

    })
  }

  stop(): void {
    console.log("stop speak")
    this.speakWatcher()
    this.scene.glview.onResize.off(this._resize)
    this._volumeSpectrum.destroy()
    for (let i = 0; i < this._mantras.length; i++) {
      this._mantras[i].destroy()
    }
    window.removeEventListener("mousemove", this.mousemove);
    this._mantras = []
    this.mantraGeom.ibuffer.dispose()
    this.mantraGeom.vbuffer.dispose()
    this.mantraGeom = null
    this.stateHelper.stop()
  }

  transition = (id: number, prevId: number) => {
    if (prevId !== -1) this._mantras[prevId].leave()
    this._mantras[id].enter()
  }


  enterIntro = () => {

  }

  enterDefault = () => {

  }


  enterOutro = () => {
    // console.log("enter outro")
    // AppService.send("ENTER")
  }

  unload(): void { }
  preFrame(): void { }
  render(): void {
    this._volumeSpectrum.render()
    for (let i = 0; i < this._mantras.length; i++) {
      this._mantras[i].render()
    }
  }
  renderUI(): void { }
  preRender(): void {
    this._volumeSpectrum.preRender()
    for (let i = 0; i < this._mantras.length; i++) {
      this._mantras[i].preRender(this.xy[0], this.xy[1])
    }
  }
  rttPass(): void { }

  private mousemove = (event: MouseEvent) => {
    this.xy[0] = event.clientX / this.width * 2 - 1
    this.xy[1] = event.clientY / this.height * 2 - 1
  }

  private _resize = () => {
    const w = GLApp.getInstance().glview.width
    const h = GLApp.getInstance().glview.height

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    this.width = window.innerWidth
    this.height = window.innerHeight

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._volumeSpectrum.resize(w > h ? h / w : w / h, this.width, this.height)
    for (let i = 0; i < this._mantras.length; i++) {
      this._mantras[i].resize(this.width, this.height)
    }
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 250

    this._camera = camera;

  }

}