
import gsap from "gsap";
import Delay from "@/core/Delay";
import {
  AppStore,
  setAudioListen,
  setMicroEnabled,
  setWrongAnswer,
} from "@/store/modules/AppStore";
import { Viewport } from "@/store/modules/Viewport";
import { setMantraId, UserCard } from "@/store/modules/UserCard";
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";
import { defineComponent, ref } from "vue";
import CardDataSender from "@/api/carddatasender";
import mantras from "@/store/datas/mantras.json";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      isRecognizer: false,
      recognizer: null,
      hasMatch: false,
      noMatch: false,
      isStarted: false,
      mantras,
    };
  },

  computed: {
    isMicro(): boolean {
      return AppStore.microEnabled;
    },

    microDisabled() {
      return AppStore.microDisabled;
    },

    btnDoneLabel(): string {
      return Viewport.isDesktop ? "Validate the mantra" : "Validate";
    },

    isStateDefault(): boolean {
      return this.state?.value["step_speak"] === "default";
    },

    // mantras(): { match: boolean; phrase: string; imgid: string, id: number }[] {
    //   return mantras;
    // },
  },

  watch: {
    isMicro: function () {
      if (AppStore.microEnabled) {
        this.startRecognizer();
      } else {
        this.isStarted = false;
        if (this.recognizer !== null) {
          this.recognizer.stop();
          this.recognizer = null;
        }

        if (UserCard.mantraId === -1) this.chooseMantra(0);
      }

      const tl: gsap.core.Timeline = gsap.timeline();
      tl.to(".MentraNav", { opacity: 0 });
      tl.call(() => {
        if (this.isMicro) {
          this.$el.classList.remove("HasNoUserMedia");
        } else {
          this.$el.classList.add("HasNoUserMedia");
        }
      });
      tl.to(".MentraNav", { opacity: 1 });
    },
  },

  mounted() {
    this.isRecognizer = this.testRecognizer();

    if (this.isRecognizer && AppStore.microEnabled) {
      this.startRecognizer();
    }

    if (!this.isRecognizer) setMicroEnabled(false);

    if (!AppStore.microEnabled) {
      this.chooseMantra(0);
      this.$el.classList.add("HasNoUserMedia");
    }
    // if (!this.recognizer) {
    //   // TODO no support, enable fallback
    //   console.warn("recognizer not working");
    // } else {
    //   // Recogniser doesn't stop listening even if the user pauses
    //   console.log("create recognizer", this.recognizer);
    // }
  },

  beforeUnmount() {
    if (this.recognizer) {
      this.isStarted = false;
      this.recognizer.stop();
      this.recognizer = null;
    }
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=2");

      tl.fromTo(
        el,
        {
          opacity: 0,
          // yPercent: 100,
        },
        {
          opacity: 1,
          // yPercent: 0,
          stagger: 0.1,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        el,
        {
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    onDone() {
      if (!this.hasMatch) return;
      CardDataSender.occurence.prepareCard();
      this.send("ENTER");
    },

    levenshteinDistance(str1 = "", str2 = "") {
      const track = Array(str2.length + 1)
        .fill(null)
        .map(() => Array(str1.length + 1).fill(null));
      for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
      }
      for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
      }
      for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
          const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
          track[j][i] = Math.min(
            track[j][i - 1] + 1, // deletion
            track[j - 1][i] + 1, // insertion
            track[j - 1][i - 1] + indicator // substitution
          );
        }
      }
      return track[str2.length][str1.length];
    },

    async handleResult(event) {
      let hasMatch = false;
      this.hasMatch = false;
      let matchId = -1;

      for (let i = event.resultIndex; i < event.results.length; i++) {
        // abort if not finished
        if (!event.results[i].isFinal) {
          continue;
        }

        const threshold = 10;
        hasMatch = this.mantras.some((mantra) => {
          const distance = this.levenshteinDistance(
            mantra.phrase,
            event.results[i][0].transcript.toLowerCase()
          );
          if (distance <= threshold) {
            mantra.match = true;
            matchId = mantra.id;
            return true;
          }

          mantra.match = false;
        });

        // stop checking if successful
        if (hasMatch) {
          this.hasMatch = true;
          setMantraId(matchId);
          // await Delay(1000);
          // this.send("ENTER");
          // TODO success
          break;
        }
      }

      if (!hasMatch) {
        this.noMatch = true;
        this.isStarted = false;
        setWrongAnswer(true);
        await Delay(3000);
        setWrongAnswer(false);
        this.noMatch = false;
        this.isStarted = true;
        // this.recognizer.start();
      }
    },

    chooseMantra(index) {
      if (AppStore.microEnabled) return;
      for (let i = 0; i < this.mantras.length; i++) {
        this.mantras[i].match = false;
      }
      this.mantras[index].match = true;
      AudioManager.playUI(AUDIO_ID.XP_SPEAK_SLIDE);
      setMantraId(index);
      this.hasMatch = true;
    },

    previous() {
      let index = UserCard.mantraId - 1;
      if (index < 0) {
        index = this.mantras.length - 1;
      }
      this.chooseMantra(index);
    },

    next() {
      let index = UserCard.mantraId + 1;
      if (index > this.mantras.length - 1) {
        index = 0;
      }
      this.chooseMantra(index);
    },

    setMedia() {
      setMicroEnabled(!AppStore.microEnabled);
    },

    testRecognizer() {
      const Recognition =
        window.SpeechRecognition || window.webkitSpeechRecognition || null;
      return Recognition !== null;
    },

    async startRecognizer() {
      if (!this.recognizer) {
        let audioStream = null;
        try {
          audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
        } catch (e) {
          console.warn("error media", e);
        }

        // setMicroEnabled(audioStream !== null);

        if (audioStream && audioStream.getTracks()) {
          audioStream.getTracks().forEach(function (track) {
            track.stop();
          });
          audioStream = null;
        }
        const Recognition =
          window.SpeechRecognition || window.webkitSpeechRecognition || null;
        const recognizer = Recognition ? new Recognition() : null;
        // const recognizer = new window.webkitSpeechRecognition();

        if (recognizer === null) {
          setMicroEnabled(false);
          console.warn("Cant start recognizer, switch to fallback");
          return;
        }

        this.isStarted = true;

        recognizer.continuous = true;
        // recognizer.interimResults = true;

        recognizer.lang = "en-US"; // force english

        recognizer.start();
        console.log("start recognizer !!", recognizer);

        // recognizer.onsoundstart = (event) => {
        //   console.log("sound start");
        // };

        // recognizer.onsoundend = (event) => {
        //   console.log("sound end");
        // };

        // recognizer.onspeechstart = (event) => {
        //   console.log("speech start");
        // };

        // recognizer.onspeechend = (event) => {
        //   console.log("speech end");
        // };

        // recognizer.onstart = (event) => {
        //   console.log("reco start");
        // };

        recognizer.onaudiostart = (event) => {
          console.log("audio start");
          setAudioListen(true);
        };

        recognizer.onaudioend = (event) => {
          console.log("audio end");
          setAudioListen(false);
        };

        recognizer.onend = (event) => {
          // console.log("reco end");
          if (!Viewport.isDesktop && this.isStarted) recognizer.start();
        };

        recognizer.onerror = (event) => {
          console.error("reco error", event.error);
        };

        recognizer.onresult = (event) => {
          // console.log("get result event");
          this.handleResult(event);
        };

        this.recognizer = recognizer;
      }
    },

    back() {
      this.send("BACK");
    },
  },
});
