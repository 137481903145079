import GLApp from "@/webgl/main";
import FrontResources from "@/core/FrontResources";
// import { AudioManager } from "@/core/Audio";
import { assign, createMachine, State } from "xstate";
import MuseumActivityService from "./MuseumActivityService";
import FeelActivityService from "./FeelActivityService";
import DoActivityService from "./DoActivityService";

import { webpSupport } from "@/webgl/lib/nanogl-gltf/lib/extensions/EXT_texture_webp/webpSupport";
import { isWebpSupport } from "@/store/modules/Viewport";
import {
  AppStore,
  AppThemeEnum,
  setAppLoadProgress,
} from "@/store/modules/AppStore";
import LoadingActivityService from "./LoadingActivityService";
import UrlParams from "@/utils/UrlParams";
import SpeakActivityService from "./SpeakActivityService";
import ArtworkActivityService from "./ArtworkActivityService";
import GlobalResources from "@/webgl/GlobalResources";

import ArtworkFaces from "@/api/artworkfaces";
import SeeActivityService from "./SeeActivityService";
// import { VoiceEnum } from "../modules/Sound";

import Tracking from "@/core/Tracking"

export enum AppStateEnum {
  LOADING = "loading",
  AGE_GATE = "age_gate",
  HOME = "home",
  INTRO = "intro",
  MUSEUM = "museum",
  STEP_ME = "step_me",
  STEP_FEEL = "step_feel",
  STEP_DO = "step_do",
  STEP_LOVE = "step_love",
  STEP_SEE = "step_see",
  STEP_SPEAK = "step_speak",
  ARTWORK = "artwork",
  AGENDA = "agenda",
  COLLAB = "collab"
}

export enum TabStateEnum {
  EMPTY = "empty",
  MANIFESTO = "manifesto",
  STEP_ME = "step_me",
  STEP_FEEL = "step_feel",
  STEP_DO = "step_do",
  STEP_LOVE = "step_love",
  STEP_SEE = "step_see",
  STEP_SPEAK = "step_speak",
  STEP_UNDERSTAND = "step_understand",
  STEP_THANKYOU = "thank_you",
}

export type AppStateValue = `${AppStateEnum}`;

export const enum PERMISSION {
  AUDIO = "audio",
  VIDEO = "video",
}

export interface AppStateContext {
  ageGatePassed: boolean;
  museum_dev: boolean;
  permissions: PERMISSION[];
  tab: TabStateEnum;
  // audioId: VoiceEnum | "";
  theme: AppThemeEnum;
  skipTutoIntro: boolean;
  timeline: AppStateEnum[] | boolean;
}

export type AppStateType = {
  value: string;
  context: AppStateContext;
};

/**
 * Generic Events
 */
type BaseEvent = {
  type:
  | "INIT"
  | "SUBMIT"
  | "CANCEL"
  | "ENTER"
  | "BACK"
  | "EXIT"
  | "ARTWORK"
  | "COLLAB"
  | "AGENDA"
  | "INTRO_COMPLETE"
  | "SKIP_AGE_GATE"
  | "INTRO_OUTRO_COMPLETE"
  | "LOADING_INTRO_COMPLETE"
  | "LOADING_OUTRO_COMPLETE"
  | "LOADING_COMPLETE"
};

type AddPermissionEvent = {
  type: "ADD_PERMISSION";
  value: PERMISSION;
};
type BackEvents = {
  type:
  | "home_fromcollab"
  | "home_fromartwork"
  | "home_fromfeel"
};

/////////////
///
//////////////////
///////
////////////////
////////////
//////////////
////////////
//////////////
/////////////
///////////////
//////////////////
//

///
/////////
type DevEvents = {type: never}
//////////
//*/

export type AppStateEvent = BaseEvent | DevEvents | BackEvents;
const getAccessParam = () => {
  if (UrlParams.getString("access")) {
    return "#app." + UrlParams.getString("access");
  }

  return null;
};



function trackPageAction(name: string) {
  return () => Tracking.event(name)
}

function trackPageView(name: string) {
  return () => Tracking.event(name)
}

function trackEventPush(name: string, cat: string = "") {
  return () => Tracking.eventPush(name, cat)
}

Tracking.eventPush("validate", "xp-cta-age-gate")

export type AppState = State<AppStateContext, AppStateEvent, any, AppStateType>;

const AppStateMachine = createMachine<
  AppStateContext,
  AppStateEvent,
  AppStateType
>(
  {
    id: "app",

    initial: "initial",

    context: {
      ageGatePassed: false,
      permissions: [],
      museum_dev: false,
      tab: TabStateEnum.EMPTY,
      // audioId: "",
      theme: AppThemeEnum.DARK,
      skipTutoIntro: false,
      timeline: false,
    },

    on: {
      home_fromcollab: {
        target: "#app.museum.from_collab"
      },
      home_fromartwork: {
        target: "#app.museum.from_artwork"
      },
      home_fromfeel: {
        target: "#app.museum.from_feel"
      },
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////////////////
////////
////////////////////////
////////////////////////////
////////////////////////
////////////////////////////
//////////////////////////
//////////////////////////////
/////////////////////////////
////////////////////////////////
//////////////////////////////////
////////////////
      COLLAB: {
        target: AppStateEnum.COLLAB,
      },
      AGENDA: {
        target: AppStateEnum.AGENDA,
      },
    },

    states: {
      initial: {
        on: {
          INIT: AppStateEnum.LOADING,
        },
      },

      [AppStateEnum.LOADING]: {
        meta: {
          views: ["LoadingVue"],
          activities: ["loading"],
        },

        initial: "intro",

        states: {
          intro: {
            on: {
              LOADING_INTRO_COMPLETE: "default",
            },
          },

          default: {
            invoke: {
              src: "loadActivities",
              onDone: "outro",
            },

            // DEBUG
            on: {
              LOADING_COMPLETE: "outro",
            },
          },

          outro: {
            entry: ["notifyAppLoadComplete"],

            on: {
              ENTER: getAccessParam() || "#app.step_me.intro",
            },
          },
        },
      },

      [AppStateEnum.STEP_ME]: {
        entry: [
          trackPageView('agegate'),
          assign({
            theme: (context, event) => AppThemeEnum.DARK,
          }),
        ],

        states: {
          intro: {
            meta: {
              views: ["LoadingVue"],
            },
            on: {
              INTRO_COMPLETE: {
                target: "default",
              },
              SKIP_AGE_GATE: {
                target: "#app.museum.tuto",
              },
            },
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_ME,
              }),
            ],
            meta: {
              activities: ["museum"],
              views: ["StepMeVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("validate", "xp-cta-age-gate")],
                target: "#app.museum.intro"
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
              }),
            ],
          },
        },
      },

      [AppStateEnum.MUSEUM]: {
        entry: [
          assign({
            theme: (context, event) => AppThemeEnum.DARK,
          }),
        ],

        initial: "intro",

        states: {
          intro: {
            on: {
              INTRO_COMPLETE: {
                target: "tuto",
              },
            },
          },

          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.MANIFESTO,
                // audioId: (context, event) => VoiceEnum.MANIFESTO,
              }),
            ],
            meta: {
              activities: ["museum"],
              views: ["ManifestoVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              trackPageView('homepage'),
              assign({
                // audioId: (context, event) => "",
              }),
            ],
            meta: {
              activities: ["museum"],
              views: ["ManifestoVue", "MuseumVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("full-experience", "xp-cta-home")],
                target: "#app.step_feel",
              },
              ARTWORK: {
                actions: [trackEventPush("participatory-art-piece", "xp-cta-home")],
                target: "#app.artwork.intro",
              },
              COLLAB: {
                target: "#app.collab.intro",
              },
            },
          },

          from_artwork: {
            on: {
              INTRO_COMPLETE: "default",
            },
          },

          from_collab: {
            on: {
              INTRO_COMPLETE: "default",
            },
          },

          from_feel: {
            on: {
              INTRO_COMPLETE: "default",
            },
          },
        },
      },

      [AppStateEnum.STEP_FEEL]: {
        entry: [
          trackPageView('I feel'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        initial: "intro",

        states: {
          intro: {
            on: {
              INTRO_COMPLETE: "requestCamera",
            },
          },

          maintitle: {
            meta: {
              activities: ["museum", "background"],
              views: ["RequestCameraVue"],
            },
            on: {
              ENTER: {
                target: "requestCamera",
              },
            },
          },

          requestCamera: {
            meta: {
              activities: ["museum", "background"],
              views: ["RequestCameraVue"],
            },
            on: {
              ENTER: {
                target: "tuto",
              },
            },
          },

          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_FEEL,
                // audioId: (context, event) => VoiceEnum.STEP_FEEL,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL],
              }),
            ],
            meta: {
              activities: ["museum", "background"],
              views: ["RequestCameraVue", "TutoFeelVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_FEEL,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL],
              }),
            ],
            meta: {
              activities: ["museum", "feel", "background"],
              views: ["TutoFeelVue", "StepFeelVue"],
            },
            on: {
              ENTER: {
                target: "outro",
                actions: [trackEventPush("validate", "xp-cta-i-feel")],
              },
              BACK: {
                target: "tuto",
                actions: [
                  assign({
                    skipTutoIntro: (context, event) => true,
                  }),
                ],
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                timeline: (context, event) => false,
              }),
            ],
          },

          outro: {
            entry: [
              assign({
                theme: (context, event) => AppThemeEnum.DARK,
              }),
            ],
            meta: {
              activities: ["museum", "feel"],
              views: ["StepFeelVue"],
            },
            on: {
              ENTER: {
                target: "#app.step_do",
              },
            },
          },
        },
      },

      [AppStateEnum.STEP_DO]: {
        entry: [
          trackPageView('I do'),
          assign({
            theme: (context, event) => AppThemeEnum.DARK,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        initial: "intro",

        states: {
          intro: {
            on: {
              INTRO_COMPLETE: {
                target: "tuto",
              },
            },
          },

          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_DO,
                // audioId: (context, event) => VoiceEnum.STEP_DO,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO],
              }),
            ],
            meta: {
              activities: ["museum"],
              views: ["TutoDoVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_DO,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO],
              }),
            ],
            meta: {
              activities: ["museum", "do"],
              views: ["TutoDoVue", "StepDoVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("validate", "xp-cta-i-do")],
                target: "outro"
              },
              BACK: {
                target: "tuto",
                actions: [
                  assign({
                    skipTutoIntro: (context, event) => true,
                  }),
                ],
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                timeline: (context, event) => false,
              }),
            ],
          },

          outro: {
            meta: {
              activities: ["museum", "do", "background"],
              views: ["OutroVue"],
            },
            on: {
              ENTER: {
                target: "#app.step_love.tuto",
              },
            },
          },
        },
      },

      [AppStateEnum.STEP_LOVE]: {
        entry: [
          trackPageView('I love'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        states: {
          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_LOVE,
                // audioId: (context, event) => VoiceEnum.STEP_LOVE,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE],
              }),
            ],
            meta: {
              activities: ["background", "do"],
              views: ["OutroVue", "TutoLoveVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_LOVE,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE],
              }),
            ],
            meta: {
              activities: ["love", "background"],
              views: ["TutoLoveVue", "StepLoveVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("validate", "xp-cta-i-love")],
                target: "outro",
              },
              BACK: {
                target: "tuto",
                actions: [
                  assign({
                    skipTutoIntro: (context, event) => true,
                  }),
                ],
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                timeline: (context, event) => false,
              }),
            ],
          },

          outro: {
            entry: [
              assign({
                theme: (context, event) => AppThemeEnum.DARK,
              }),
            ],
            meta: {
              activities: ["love", "background"],
              views: ["OutroVue"],
            },
            on: {
              ENTER: {
                target: "#app.step_speak.tuto",
              },
            },
          },
        },
      },

      [AppStateEnum.STEP_SPEAK]: {
        entry: [
          trackPageView('I speak'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        states: {
          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_SPEAK,
                // audioId: (context, event) => VoiceEnum.STEP_SPEAK,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK],
              }),
            ],
            meta: {
              activities: ["background"],
              views: ["OutroVue", "TutoSpeakVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_SPEAK,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK],
              }),
            ],
            meta: {
              activities: ["speak", "background"],
              views: ["TutoSpeakVue", "StepSpeakVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("validate", "xp-cta-i-speak")],
                target: "outro",
              },
              BACK: {
                target: "tuto",
                actions: [
                  assign({
                    skipTutoIntro: (context, event) => true,
                  }),
                ],
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                timeline: (context, event) => false,
              }),
            ],
          },

          outro: {
            entry: [
              assign({
                theme: (context, event) => AppThemeEnum.DARK,
              }),
            ],
            meta: {
              activities: ["speak", "background"],
              views: ["OutroVue"],
            },
            on: {
              ENTER: {
                target: "#app.step_see.tuto",
              },
            },
          },
        },
      },

      [AppStateEnum.STEP_SEE]: {
        entry: [
          trackPageView('I see'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        states: {
          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_SEE,
                // audioId: (context, event) => VoiceEnum.STEP_SEE,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK, AppStateEnum.STEP_SEE],
              }),
            ],
            meta: {
              activities: ["background"],
              views: ["OutroVue", "TutoSeeVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_SEE,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK, AppStateEnum.STEP_SEE],
              }),
            ],
            meta: {
              activities: ["background", "see"],
              views: ["TutoSeeVue", "StepSeeVue"],
            },
            on: {
              ENTER: {
                target: "blink",
              },
              BACK: {
                target: "tuto",
                actions: [
                  assign({
                    skipTutoIntro: (context, event) => true,
                  }),
                ],
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
              }),
            ],
          },

          blink: {
            entry: [
              assign({
                // audioId: (context, event) => VoiceEnum.SEE_WDYS,
              }),
            ],
            meta: {
              activities: ["background", "see"],
              views: ["StepSeeVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("validate", "xp-cta-i-see")],
                target: "outro",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
                timeline: (context, event) => false,
              }),
            ],
          },

          outro: {
            entry: [
              assign({
                theme: (context, event) => AppThemeEnum.DARK,
              }),
            ],
            meta: {
              activities: ["background", "see"],
              views: ["OutroVue"],
            },
            on: {
              ENTER: {
                target: "#app.artwork.tuto",
              },
            },
          },
        },
      },

      [AppStateEnum.ARTWORK]: {
        entry: [
          trackPageView('I understand'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
          }),
        ],

        states: {
          intro: {
            meta: {
              activities: ["museum", "artwork"],
              views: ["OutroVue", "ArtworkVue"],
            },
            on: {
              INTRO_COMPLETE: {
                target: "default",
              },
            },
          },

          tuto: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_UNDERSTAND,
                // audioId: (context, event) => VoiceEnum.ARTWORK,
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK, AppStateEnum.STEP_SEE, AppStateEnum.ARTWORK],
              }),
            ],
            meta: {
              activities: ["museum", "artwork", "background", "see"],
              // activities: ['museum'],
              views: ["TutoUnderstandVue"],
            },
            on: {
              ENTER: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          default: {
            entry: [
              assign({
                tab: (context, event) => TabStateEnum.STEP_UNDERSTAND,
                // audioId: (context, event) => "",
                timeline: (context, event) => [AppStateEnum.STEP_ME, AppStateEnum.STEP_FEEL, AppStateEnum.STEP_DO, AppStateEnum.STEP_LOVE, AppStateEnum.STEP_SPEAK, AppStateEnum.STEP_SEE, AppStateEnum.ARTWORK],
              }),
            ],
            meta: {
              activities: ["background", "museum", "artwork"],
              views: ["TutoUnderstandVue", "ArtworkVue", "ShareVue"],
            },
            on: {
              ENTER: {
                actions: [trackEventPush("share-your-card", "xp-cta-i-understand-1")],
                target: "share",
              },
              EXIT: {
                // actions: [trackEventPush("back-to-home", "xp-cta-i-understand-2")],
                target: "fadeout",
              },
            },
            exit: [
              assign({
                tab: (context, event) => TabStateEnum.EMPTY,
                timeline: (context, event) => false,
              }),
            ],
          },

          fadeout: {
            entry: [
              assign({
                theme: (context, event) => AppThemeEnum.DARK,
                // audioId: (context, event) => "",
              }),
            ],
            meta: {
              activities: ["background", "museum", "artwork"],
              views: ["ArtworkVue"],
            },
            on: {
              ENTER: {
                target: "#app.museum.from_artwork",
              },
            },
          },

          share: {
            entry: [
              trackPageView('Thank you'),
              assign({
                theme: (context, event) => AppThemeEnum.LIGHT,
                tab: (context, event) => TabStateEnum.STEP_THANKYOU,
                // audioId: (context, event) => VoiceEnum.THANK_YOU,
              }),
            ],
            meta: {
              activities: ["background", "artwork"],
              views: ["ArtworkVue", "ShareVue"],
            },
            on: {
              EXIT: {
                target: "default",
              },
            },
            exit: [
              assign({
                // audioId: (context, event) => "",
              }),
            ],
          },

          // outro: {
          //   meta: {
          //     activities: ['see'],
          //   },
          //   on: {
          //     ENTER: '#app.step_artwork'
          //   }
          // },
        },
      },

      [AppStateEnum.COLLAB]: {
        initial: "intro",

        entry: [
          trackPageView('Collaboration'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
            tab: (context, event) => TabStateEnum.EMPTY,
            // audioId: (context, event) => "",
            timeline: (context, event) => false,
          }),
        ],

        states: {
          intro: {
            meta: {
              activities: ["museum"],
            },
            on: {
              INTRO_COMPLETE: {
                target: "default",
              },
            },
          },
          default: {
            meta: {
              views: ["CollaborationVue"],
            },
            on: {
              EXIT: {
                target: "#app.museum.from_collab",
              }
            }
          }
        }
      },

      [AppStateEnum.AGENDA]: {
        initial: "default",

        entry: [
          trackPageView('Agenda'),
          assign({
            theme: (context, event) => AppThemeEnum.LIGHT,
            skipTutoIntro: (context, event) => false,
            tab: (context, event) => TabStateEnum.EMPTY,
            // audioId: (context, event) => "",
            timeline: (context, event) => false,
          }),
        ],

        states: {
          default: {
            meta: {
              views: ["AgendaVue"],
            },
            on: {
              EXIT: {
                target: "#app.museum.from_collab",
              }
            }
          },
        },
      },
    },
  },
  {
    services: {
      async loadActivities() {
        const iswebp = await webpSupport();
        if (iswebp) isWebpSupport();
        FrontResources.setupLotties();
        FrontResources.setupImages();
        FrontResources.setupFonts();
        GlobalResources.setupTextures(
          GLApp.getInstance().scene.resources,
          GLApp.getInstance().scene
        );
        // await AudioManager.start();

        const promises = [
          () => FrontResources.load(),
          () => GLApp.getInstance().scene.load(),
          () => SpeakActivityService.load(),
          () => SeeActivityService.load(),
          () => LoadingActivityService.load(),
          () => MuseumActivityService.load(),
          () => FeelActivityService.load(),
          () => DoActivityService.load(),
          () => ArtworkActivityService.load(),
          () => ArtworkFaces.occurence.loadFaces(),
        ];
        return Promise.all(
          promises.map(async (loadFunc) => {
            const loaded = await loadFunc();
            setAppLoadProgress(AppStore.load_progress + 1 / promises.length);

            return loaded;
          })
        );
      },
    },

    actions: {
      addPermission: assign<AppStateContext, any>({
        permissions: (context, event) => [...context.permissions, event.value],
      }),

      notifyAppLoadComplete: () => {
        setAppLoadProgress(1);
      },

      // triggerVoice: assign((context, event, { state }) => {
      //   console.log(">>> triggerVoice", state.value);
      //   return {
      //     audio_id: TabStateEnum.STEP_FEEL,
      //   };
      // }),
    },

    guards: {
      // tutorialCompleted: (ctx: AppStateContext) => {
      //   return ctx.paintingTutorialCompleted
      // },
      // needTutorial: (ctx: AppStateContext) => {
      //   return !ctx.paintingTutorialCompleted
      // },
      // needPhotoTutorial: (ctx: AppStateContext) => {
      //   return !ctx.paintingPhotoTutorialCompleted
      // },
      // isOpeningPhoto: (ctx: AppStateContext) => {
      //   return ctx.openPhotoPending
      // }
    },
  }
);

export default AppStateMachine;

export function collectAppStateViews(state: AppState): string[] {
  const metas = state.meta;
  const res = [];
  for (const meta of Object.values(metas)) {
    const m: any = meta;
    if (m.views) res.push(...m.views);
  }
  return Array.from(new Set(res).values());
}

export function collectAppStateActivities(state: AppState): string[] {
  const metas = state.meta;
  const res = [];
  for (const meta of Object.values(metas)) {
    const m: any = meta;
    if (m.activities) res.push(...m.activities);
  }
  return Array.from(new Set(res).values());
}
