import GLApp from "@/webgl/main";
import loader, { licenseName } from "./visageLoading"

import AnalysisWorker from "worker-loader!./analysis.js";

import { Visage as VisageStore, setAllEmotions, setCamera, setemotion, setEyeClosure, setReady } from "@/store/modules/Visage";
import { watch } from "vue";
import { AppStore } from "@/store/modules/AppStore";
import { Viewport } from "@/store/modules/Viewport";
import Tracking from "@/core/Tracking";

const DEBUG_VISAGE = false

const EMOTIONS_ORDER = ["anger", "disgust", "fear", "happiness", "sadness", "surprise", "neutral"]

const maxFacesTracker = 1;

// const MODE_TRACK = 0;

const m_Width = 640;
const m_Height = 480;

// const minFaceScale = 0;


const fps = 30;
const interval = 1/fps;

function copy(src)  {
	var dst = new ArrayBuffer(src.byteLength);
	new Uint8Array(dst).set(new Uint8Array(src));
	return dst;
}

function logDebug(...args) {
  if(DEBUG_VISAGE) console.log(...args)
}


// Emotions order: anger, disgust, fear, happiness, sadness, surprise and neutral

export default class Visage {

  static _self:Visage

  isEnabled = false

  ppixels;
	pixels;

  m_Tracker;

  TfaceDataArray;

  trackFrameStart = false
  startTracking = false
  firstRun = false

  eyeTrackEnabled = false
  emotionsEnabled = false

  workerAnalysisAvailable;
  workerAnalysis;
  analyserInitialized;

  delta:number

  video
  canvas:HTMLCanvasElement
  canCon:CanvasRenderingContext2D

  gotStream = false
  
  trackerReturnState

  actuallyStarted = false

  deltaAcc = 0
  
  static get occurence():Visage {
    if(!Visage._self) Visage._self = new Visage()
    return Visage._self
  }

  constructor() {
    this.delta = Viewport.isDesktop ? 1 / 10 : 1 / 2
  }

  doLoadLib() {
    return new Promise<void>((resolve) => {
      window['VisageModule'] = loader(() => {
        return resolve()
      })
      const script1 = document.createElement("script")
      script1.src = "js/visagesdk/visageSDK.js"
      document.body.appendChild(script1)
    })
  }

  startCamera(video, eyeTrackEnabled = false, emotionsEnabled = false) {
    if(VisageStore.isCamera) {
      console.warn("already a camera")
      return
    }
    this.eyeTrackEnabled = eyeTrackEnabled
    this.emotionsEnabled = emotionsEnabled
    this.video = video
    setReady(false)
    logDebug("Visage => Start camera", video, "eyeTrackEnabled", eyeTrackEnabled, "emotionsEnabled", emotionsEnabled)
    setCamera(true)
    if(this.canvas) {
      this.canvas.width =  this.canvas.width
      this.canCon.clearRect(0, 0, this.canvas.width, this.canvas.height)
      const imageData = this.canCon.getImageData(0,0, m_Width, m_Height).data;
      this.pixels?.set(imageData)

    }
    try {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false
      }).then(this.startStream).catch(this.deniedStream);
      } catch (e) {
        this.errorStream(e);
      }
  }

  stopCamera() {
    setCamera(false)
    if(this.video.srcObject && this.video.srcObject.getVideoTracks()) this.video.srcObject.getVideoTracks()[0].stop();
    if(this.canvas) {
      this.canvas.width =  this.canvas.width
      this.canCon.clearRect(0, 0, this.canvas.width, this.canvas.height)
      const imageData = this.canCon.getImageData(0,0, m_Width, m_Height).data;
      this.pixels.set(imageData)
    }
  }

  startStream = (stream) => {
    logDebug("Visage => Start stream")

    Tracking.eventPush("use-face-recognition", this.emotionsEnabled ? "api-emotion-active" : "api-eye-active");

    this.video.srcObject = stream;
    this.video.play();

    this.gotStream = true

    GLApp.getInstance().glview.onRender.off(this.processFrame)
    GLApp.getInstance().glview.onRender.on(this.processFrame)
  }

  pause() {
    this.gotStream = false
    GLApp.getInstance().glview.onRender.off(this.processFrame)
    this.stopCamera()
    this.video = null
  }

  errorStream(e){
    if (e){
      console.error(e);
    }
  }

  deniedStream(){
    console.warn("Camera access denied!");
  }

  init(): Promise<void> {
    this.gotStream = false
    this.actuallyStarted = false
    
    this.deltaAcc = 0

    this.firstRun = true
    setReady(false)

    this.canvas = document.createElement("canvas")
    this.canvas.width = m_Width
    this.canvas.height = m_Height
    
    this.canCon = this.canvas.getContext("2d")

    this.ppixels = window['VisageModule']._malloc(m_Width*m_Height*4);
    this.pixels = new Uint8ClampedArray(window['VisageModule'].HEAPU16.buffer, this.ppixels, m_Width*m_Height*4);
    
    //set up tracker and licensing, valid license needs to be provided
    window['VisageModule'].initializeLicenseManager(licenseName);
    this.m_Tracker = new window['VisageModule'].VisageTracker("Face Detector.cfg");
    this.TfaceDataArray = new window['VisageModule'].FaceDataVector();
    this.TfaceDataArray.push_back(new window['VisageModule'].FaceData());
    
    //Use request animation frame mechanism - slower but with smoother animation
    this.trackFrameStart = true;

    this.StartTracker()

  
    if(typeof  this.workerAnalysis === 'undefined')
    {
      this.createWorkerAnalysis();
    }
    this.isEnabled = true
    GLApp.getInstance().glview.onRender.on(this.processFrame)

    return this.watchModuleReady()


  }

  watchModuleReady(): Promise<void> {
    return new Promise(resolve => {
      const readyWatcher = watch(() => VisageStore.isReady, async () => {
        if(VisageStore.isReady) {
          readyWatcher()
          logDebug("visage actually ready !!!")
          resolve()
        }
      })
    })
  }

  stop() {
   GLApp.getInstance().glview.onRender.off(this.processFrame)
   this.StopTracker()
   this.m_Tracker.delete()
   this.canCon = this.canvas = null
   delete this.pixels
   delete this.ppixels

  }
  
  StartTracker(){
    
    this.startTracking = true;
  }
  
  StopTracker(){
    this.startTracking = false;
  }
  
  async createWorkerAnalysis()
  {
    this.workerAnalysis = new AnalysisWorker()
    this.workerAnalysis.addEventListener('message', this.handleMessageFromWorkerAnalysis);
    this.workerAnalysis.postMessage(
    {
      aTopic: 'resolution',
      mWidth: m_Width, 
      mHeight: m_Height,
      maxFacesTracker: maxFacesTracker,
      maxFacesDetector: 0
    });
  }

  processFrame = (delta) =>
  {
    if(!AppStore.mediaEnabled && this.actuallyStarted) return
    //Limit frame rate according to the fps variable
    if (this.deltaAcc > this.delta)
    {
      this.deltaAcc = 0
      if(this.actuallyStarted && this.gotStream) {
        this.drawFrame()
        this.trackFrame();
      } else if(!this.actuallyStarted)  this.trackFrame();
    }

    this.deltaAcc += delta
  }


  drawFrame = () =>
  {
    this.canCon.drawImage(this.video,0,0, m_Width, m_Height);
  }

  /*
  *Takes the pixel data from canvas, sends them to the tracker and, depending on the result, draws the results.
  */
  trackFrame = () =>
  {
    // //update analysis display 
		if(this.startTracking && this.workerAnalysisAvailable && this.analyserInitialized ) {

      // //Access pixel data	
      const imageData = this.canCon.getImageData(0,0, m_Width, m_Height).data;
      
      // //Save pixel data to preallocated buffer
      this.pixels.set(imageData)
      this.trackerReturnState = this.m_Tracker.track(
        m_Width, m_Height, this.ppixels, this.TfaceDataArray,
        window['VisageModule'].VisageTrackerImageFormat.VISAGE_FRAMEGRABBER_FMT_RGBA.value,
        window['VisageModule'].VisageTrackerOrigin.VISAGE_FRAMEGRABBER_ORIGIN_TL.value
      );

			var trackerStatusJSON = "";
			trackerStatusJSON = JSON.stringify(this.trackerReturnState);
      // logDebug("Visage js status =>", trackerStatusJSON)

      if (this.eyeTrackEnabled && this.trackerReturnState[0] === window["VisageModule"].VisageTrackerStatus.TRACK_STAT_OK.value) {
        // console.log(this.TfaceDataArray.get(0).getFaceTranslation()[0].toFixed(2))
        setEyeClosure(this.TfaceDataArray.get(0).getEyeClosure()[0])

        if(!VisageStore.isReady && !this.emotionsEnabled) {
          logDebug("set as ready for eyes only !!")
          setReady(true)
        }
        
        if(!this.emotionsEnabled && this.actuallyStarted) return
      }

      if(!this.actuallyStarted || this.emotionsEnabled) {
        var imageDataBuffer = imageData.buffer;
        var imageDataBufferAnalysis = copy(imageDataBuffer);
        //serialize FaceData object to buffer
        var faceDataBuffer = this.TfaceDataArray.get(0).serializeBuffer();
        const faceDataBufferJS = new Float32Array(faceDataBuffer);
        var faceDataBufferAnalysis = copy(faceDataBufferJS.buffer);
        
        var analyserControlOptions = [true, false, false];
        var analyserControlOptionsJSON = JSON.stringify(analyserControlOptions);
        
        this.workerAnalysis.postMessage(
        {
          aTopic: 'sendFrameTrack',
          analyserControlOptions: analyserControlOptionsJSON,
          inFaceData : faceDataBufferAnalysis,
          imageData: imageDataBufferAnalysis,
          numFaces: trackerStatusJSON
        },
        [
          faceDataBufferAnalysis,
          imageDataBufferAnalysis
        ]);
        this.workerAnalysisAvailable = false;
      }
      
		}
  }

  getCurrentEmotion(emotions:number[][]) {

    if(!this.actuallyStarted) {
      console.log("VISAGE INITIALIZED")
      GLApp.getInstance().glview.onRender.off(this.processFrame)
      setReady(true)
      this.actuallyStarted = true
      return
    }
    setAllEmotions(emotions[0])
    const max = Math.max(...emotions[0]);

    const index = emotions[0].indexOf(max);

    logDebug("curerent emotion", EMOTIONS_ORDER[index])
    if(!EMOTIONS_ORDER[index]) {
      setReady(false)
      return
    }
    else if(!VisageStore.isReady) setReady(true)

    setemotion(EMOTIONS_ORDER[index])
  }

  handleMessageFromWorkerAnalysis = (msg) =>
  {
    // logDebug('Visage => handleMessageFromWorkerAnalysis =>', msg.data.aTopic)
    switch (msg.data.aTopic) 
    {
      case 'analysis results track':
        this.workerAnalysisAvailable = true;
        logDebug("Visage analysis results track => ", msg.data)
        this.getCurrentEmotion(msg.data.emotions)
        break;
      case 'initialization done':	
        this.analyserInitialized = true;
        this.workerAnalysisAvailable = true;

        break;
      case 'analysis reset':
        this.workerAnalysisAvailable = true;
        this.getCurrentEmotion(msg.data.emotions)
        break;
      default:
        throw 'no aTopic on incoming message to ChromeWorker';
      }
  }
}