import { reactive, computed } from "vue"
import Cookies from 'js-cookie'

import _rules from '@/store/datas/ageGateRules.json'
import { AppStore } from './AppStore'
import { setCountry, setAge } from './UserCard'


const COOKIE_NAME = 'age-gate'

export type AgeGateRules = {
  country_code: string
  country_name: string
  age: number
  allowed_country: string
  input_type: string
  year: number
  month_number: number
  day: number
  langs_list: string
  default_language: string
}

export type AgeGateStore = {
  legalAge: boolean
  countryCode: string
  birthdate: IBirthDate
  rules: AgeGateRules[]
}

interface IBirthDate {
  d?: number
  m?: number
  y: number
}

export const preferedCountryCodes: string[] = ['BE', 'DE', 'IT', 'ES', 'CH', 'GB', 'US']

export const AgeGate = reactive<AgeGateStore>({
  legalAge: false,
  countryCode: 'US',
  birthdate: {
    d: 1,
    m: 1,
    y: 2018
  },
  rules: _rules,
})

export const orderedRules = computed<AgeGateRules[]>(() => {
  const filteredRules: AgeGateRules[] = AgeGate.rules.filter((rule) => preferedCountryCodes.includes(rule.country_code))
  const otherRules: AgeGateRules[] = AgeGate.rules.filter((rule) => !preferedCountryCodes.includes(rule.country_code))
  return [...filteredRules, ...otherRules]
})

const selectedRule = computed<AgeGateRules>(() => {
  return AgeGate.rules.filter((rule) => rule.country_code === AgeGate.countryCode)[0] || null
})

const selectedCountryInputType = computed<string[]>(() => {
  return selectedRule.value.input_type.split('')
})

const hasLegalAge = computed<boolean>(() => {
  const birthdate: Date = new Date(AgeGate.birthdate.y, AgeGate.birthdate.m - 1, AgeGate.birthdate.d)
  const now: Date = new Date()
  const diff: number = now.getTime() - birthdate.getTime()
  return selectedRule.value.age * (1000 * 60 * 60 * 24 * 365) <= diff
})

const isAllowedCountry = computed<boolean>(() => {
  return selectedRule.value.allowed_country !== 'no'
})

const userCountryName = computed<string | boolean>(() => {
  const rule: AgeGateRules = selectedRule.value
  if (rule) {
    return rule['country_name']
  }
  return false
})

const userBirthdate = computed<string>(() => {
  const countryInputType: string[] = selectedCountryInputType.value
  const bdate: IBirthDate = { y: null, m: null, d: null }

  countryInputType.forEach((type) => {
    bdate[type] = AgeGate.birthdate[type]
  })

  const bdateValues: string[] = Object.values(bdate)
  return bdateValues.filter((type) => type !== null).join('.')
})

export function selectCountry(countryCode: string) {
  _SET_COUNTRY_CODE(countryCode)
}

export function selectBirthDateYear(year: number) {
  _SET_BIRTHDATE_YEAR(year)
}

export function selectBirthDateMonth(month: number) {
  _SET_BIRTHDATE_MONTH(month)
}

export function selectBirthDateDay(day: number) {
  _SET_BIRTHDATE_DAY(day)
}

export function validateLegalAge(valid = true) {
  _SET_LEGAL_AGE(valid)
  if (!AppStore.vivatech) {
    save()
  }
}

/**
 * Initialize store from cookie if exists
 */
export function init() {
  const cookie = (!AppStore.vivatech) ? Cookies.get(COOKIE_NAME) : undefined
  if (!cookie) {
    return
  }

  const { date, country, pass } = JSON.parse(cookie)
  // date format = Y.m.d
  const [y, m, d] = date.split('.')
  d && _SET_BIRTHDATE_DAY(d)
  m && _SET_BIRTHDATE_MONTH(m)
  y && _SET_BIRTHDATE_YEAR(y)

  pass !== undefined && _SET_LEGAL_AGE(pass)
  country && _SET_COUNTRY_CODE(country.toUpperCase())

  setCountry(AgeGate.countryCode)
  setAge(new Date().getFullYear() - AgeGate.birthdate.y)
}

/**
 * Save results to cookie
 */
export function save() {
  // {"date":"1987.1.1","country":"fr","language":"fr-e","pass":true,"format":"Y"}
  const datas = {
    date: `${AgeGate.birthdate.y}.${AgeGate.birthdate.m}.${AgeGate.birthdate.d}`,
    country: selectedRule.value.country_code.toLowerCase(),
    language: selectedRule.value.default_language.toLowerCase(),
    pass: AgeGate.legalAge,
    format: selectedCountryInputType.value.join('/').replace('m', 'F').replace('y', 'Y')
  }

  setCountry(selectedRule.value.country_code.toLowerCase())
  setAge(new Date().getFullYear() - AgeGate.birthdate.y)

  Cookies.set(COOKIE_NAME, JSON.stringify(datas))
}

function _SET_COUNTRY_CODE(countryCode: string) {
  AgeGate.countryCode = countryCode
}

function _SET_BIRTHDATE_YEAR(year: number) {
  AgeGate.birthdate.y = year
}

function _SET_BIRTHDATE_MONTH(month: number) {
  AgeGate.birthdate.m = month
}

function _SET_BIRTHDATE_DAY(day: number) {
  AgeGate.birthdate.d = day
}

function _SET_LEGAL_AGE(valid: boolean) {
  AgeGate.legalAge = valid
}

init()

/////////////
//////////////////////////////////////////////////////
////////////////////////
 
//////////
