
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import Visage from "@/api/visage/visage";
import {
  setEyeClosure,
  setReady,
  Visage as VisageStore,
} from "@/store/modules/Visage";
import { AppStore, setMediaEnabled } from "@/store/modules/AppStore";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { Viewport } from "@/store/modules/Viewport";
import Delay from "@/core/Delay";
import Browser from "@/utils/Browser";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  computed: {
    eyeClosure(): number {
      return VisageStore.eyeClosure;
    },

    visageReady(): boolean {
      return VisageStore.isReady;
    },

    visageCamera(): boolean {
      return VisageStore.isCamera;
    },

    isMedia(): boolean {
      return AppStore.mediaEnabled;
    },

    playSound(): boolean {
      return AppStore.playSound;
    },

    isStateDefault(): boolean {
      return (this as any).state.matches("step_see.default");
    },

    isStateBlink(): boolean {
      return (this as any).state.matches("step_see.blink");
    },
  },

  watch: {
    isMedia: async function () {
      if (AppStore.mediaEnabled) {
        await this.$nextTick();
        Visage.occurence.startCamera(this.$refs.video, true, false);
      } else if (VisageStore.isCamera) {
        Visage.occurence.pause();
      }
    },

    playSound: function () {
      if (AppStore.playSound) {
        AudioManager.play(AUDIO_ID.VOICE_WDYS);
      }
    },
  },

  data: () => ({
    isMobile: false,
  }),

  created() {
    setReady(false);
  },

  async mounted() {
    setEyeClosure(1);
    this.isMobile = Browser.mobile;

    if (this.isMobile) {
      setMediaEnabled(false);
    }
    if (AppStore.mediaEnabled) {
      if (Viewport.isDesktop) {
        Visage.occurence.startCamera(this.$refs.video, true, false);
      } else {
        await Delay(1000);
        Visage.occurence.startCamera(this.$refs.video, true, false);
      }
    }
  },

  beforeUnmount() {
    if (AppStore.mediaEnabled && Visage.occurence.gotStream)
      Visage.occurence.pause();
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=2");

      tl.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );

      tl.fromTo(
        Array.from(this.$el.querySelectorAll(".trnstn-el")),
        {
          opacity: 0,
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: "cubic.out",
        },
        "start"
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        el,
        {
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    onDone() {
      if (AppStore.mediaEnabled && Visage.occurence.gotStream)
        Visage.occurence.pause();
      this.send("ENTER");
    },

    setMedia() {
      setMediaEnabled(!AppStore.mediaEnabled);
    },

    back() {
      this.send("BACK");
    },

    hold() {
      setEyeClosure(0);
    },

    release() {
      setEyeClosure(1);
    },
  },
});
