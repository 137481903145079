import Texture2D from "nanogl/texture-2d";
import { Texture } from "nanogl/texture-base";
import { TextureResource } from "../assets/TextureResource";

export default class TextureLibrary {

  map : Map<string, TextureResource> = new Map()
  list : TextureResource[] = []

  constructor(){

  }

  add( id:string, t:TextureResource ){
    if( this.map.has( id ) ) throw `texture ${id} already exist`
    this.map.set( id, t )
    this.list.push( t )
  }
  
  getResource( id:string ) : TextureResource {
    if( !this.map.has( id ) ) throw `texture ${id} doesn't exist`
    return this.map.get( id )
  }

  get( id:string ) : Texture2D {
    return this.getResource( id ).texture
  }
  
  has( id:string ) : boolean {
    return this.map.has( id )
  }

  load():Promise<void>{
    return Promise.all( this.list.map( t=>t.load() ) ).then()
  }

  unload(){
    this.list.forEach( t=>t.unload() )
  }

  clear(){
    this.list.length = 0
    this.map.clear()
  }
}