var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.frag" )() )+
'\n'+
( require( "../includes/blends.glsl" )() )+
'\n\nuniform sampler2D uTex;\nuniform vec2 uAspect;\nuniform float uSize;\nuniform float uFullScreenSize;\nuniform float uFullScreen;\nuniform float uOffsetX;\nuniform float uMobile;\n\nIN vec2 vTexCoord;\n\nvoid main(void){\n  vec2 centeredUvs = vec2(1. - (vTexCoord.x + uOffsetX), vTexCoord.y) - vec2(0.5);\n\n  float size = mix(uSize, uFullScreenSize, uFullScreen);\n  vec2 origin = mix(vec2(0.508, 0.505), vec2(0.5), uFullScreen);\n  vec4 color = texture2D(uTex, (centeredUvs * uAspect / (size * 1.08)) + origin);\n\n  float shadowLeft = smoothstep(0., 0.2, 1. - (vTexCoord.x + uOffsetX - 0.1));\n  float shadowRight = smoothstep(0., 0.2, vTexCoord.x + uOffsetX + 0.1);\n  float shadowStep = step(1., 1. - (vTexCoord.x + uOffsetX - 0.1));\n  float shadowAlpha = mix(shadowLeft, shadowRight, shadowStep);\n  vec4 finalColor = mix(vec4(color.rgb, color.a * 0.5), vec4(0., 0., 0., shadowAlpha), uMobile);\n\n  gl_FragColor = finalColor;\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;