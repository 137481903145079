import Delay from "@/core/Delay";
import { AppStore, setPlaySound } from "@/store/modules/AppStore";
import { Viewport } from "@/store/modules/Viewport";
import { Visage } from "@/store/modules/Visage";
import { AppState } from "@/store/states/AppStateMachine";
import { ImageResource } from "@/webgl/assets/Net";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { DEG2RAD } from "@/webgl/math";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import { watch } from "vue";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import StateHelper from "../StateHelper";
import EyeBlink from "./EyeBlink";


/**
 * Noop activity, loading in VUE
 */
export default class SeeActivity implements IActivity {

  readonly priority: number = 2;
  readonly name: string = "see";

  stateHelper: StateHelper;

  private _fov: number = 50;

  private _camera: Camera

  private root: Node

  private _blink: EyeBlink

  private _resources: ResourceGroup

  constructor(public scene: Scene) {
    this.root = new Node()
    this.stateHelper = new StateHelper([
      // { match: 'step_see.outro', enter: this.enterOutro },
      { match: 'step_see.blink', enter: this.enterBlink },
    ])
  }

  load(): Promise<any> {
    console.log("SeeActivity.load()");
    this._resources = new ResourceGroup()


    for (let i = 0; i < 16; i++) {
      const frame = i
      const url = require(`@/assets/webgl/transition-i-see/frame-0000${frame}.jpg`)
      this._resources.add(new ImageResource(url), "frame-" + frame)

    }

    return this._resources.load()
  }

  onStateUpdate(state: AppState): void {
  }

  start() {
    this.makeCamera()
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    if (!AppStore.mediaEnabled) this.startEyeBlink()
    const visageWatch = watch(() => Visage.isReady, async (b) => {
      visageWatch()
      this.startEyeBlink()
    })
  }

  startEyeBlink() {
    console.log("visage is ready create eye blink")
    this._blink = new EyeBlink(this.scene, this._camera, this.root, this.scene.resources.get("see-end"), this._resources)
    this.scene.glview.onResize.on(this._resize)
    this._resize()
    this.stateHelper.start()

    // if(!Viewport.isDesktop) await Delay(3000)
    this._blink.startWatchEyeBlink()
  }

  stop(): void {
    console.log("stop see")
    this.scene.glview.onResize.off(this._resize)
    this._blink?.destroy()
    this.stateHelper.stop()
  }

  enterBlink = async () => {
    setPlaySound(true)
    await Delay(3000)
    setPlaySound(false)
    this._blink?.blinkSoundDone()
  }

  enterIntro = () => {

  }

  enterDefault = () => {

  }

  // enterOutro = ()=>{
  //   AppService.send("ENTER")
  // }

  unload(): void { }
  preFrame(): void { }
  render(): void {
    this._blink?.render()
  }
  renderUI(): void { }
  preRender(): void {
    this._blink?.preRender()
  }
  rttPass(): void { }

  private _resize = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._blink.resize(w > h ? h / w : w / h, w, h)
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 250

    this._camera = camera;

  }

}