import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44bf68b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collaboration-cover ViewCover" }
const _hoisted_2 = {
  src: "assets/images/collaboration-cover.png",
  alt: "Cover",
  loading: "lazy",
  ref: "img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", _hoisted_2, null, 512)
  ]))
}