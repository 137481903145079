import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setCurrentVoice } from "@/store/modules/Sound";
import { UserCard } from "@/store/modules/UserCard";
import StateHelper from "@/webgl/activities/StateHelper";

export default class AudioState {
  _stateHelper: StateHelper;

  constructor() {
    this._stateHelper = new StateHelper([
      {
        match: "collab",
        enter: this.playMainTrack,
        // exit: this.stopMainTrack
      },

      {
        match: "agenda",
        enter: this.playMainTrack,
        // exit: this.stopMainTrack
      },

      {
        match: "step_me",
        enter: this.playMainTrack,
        // exit: this.stopMainTrack
      },

      {
        match: "museum",
        enter: this.playMainTrack,
        // exit: this.stopMainTrack
      },

      {
        match: "museum.tuto",
        enter: this.enterMuseumTuto,
        exit: this.exitMuseumTuto,
      },

      {
        match: "step_feel",
        exit: this.exitStepFeel,
      },

      {
        match: "step_feel.tuto",
        enter: this.enterStepFeelTuto,
      },

      {
        match: "step_do",
        exit: this.exitStepDo,
      },

      {
        match: "step_do.tuto",
        enter: this.enterStepDoTuto,
      },

      {
        match: "step_do.outro",
        enter: this.enterStepDoOutro,
      },

      {
        match: "step_love",
        exit: this.exitStepLove,
      },

      {
        match: "step_love.tuto",
        enter: this.enterStepLoveTuto,
      },

      {
        match: "step_love.outro",
        enter: this.enterStepLoveOutro,
      },

      {
        match: "step_speak",
        exit: this.exitStepSpeak,
      },

      {
        match: "step_speak.tuto",
        enter: this.enterStepSpeakTuto,
      },

      {
        match: "step_speak.outro",
        enter: this.enterStepSpeakOutro,
      },

      {
        match: "step_see",
        exit: this.exitStepSee,
      },

      {
        match: "step_see.tuto",
        enter: this.enterStepSeeTuto,
      },

      {
        match: "step_see.outro",
        enter: this.enterStepSeeOutro,
      },

      {
        match: "artwork",
        exit: this.exitArtwork,
      },

      {
        match: "artwork.tuto",
        enter: this.enterArtworkTuto,
      },

      {
        match: "artwork.default",
        enter: this.enterArtworkDefault,
      },
    ]);

    this._stateHelper.start();
  }

  playMainTrack = () => {
    AudioManager.play(AUDIO_ID.MAIN);
    AudioManager.fadeIn(AUDIO_ID.MAIN, 0.5);
  };

  stopMainTrack = () => {
    AudioManager.fadeOut(AUDIO_ID.MAIN);
  };

  enterMuseumTuto = () => {
    // AudioManager.play(AUDIO_ID.VOICE_MANIFESTO);
    // setCurrentVoice(AUDIO_ID.VOICE_MANIFESTO);
  };

  exitMuseumTuto = () => {
    AudioManager.fadeOutStop(AUDIO_ID.VOICE_MANIFESTO);
  };

  enterStepFeelTuto = () => {
    AudioManager.play(AUDIO_ID.CHAPTER_FEEL);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_FEEL, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_FEEL);
  };

  exitStepFeel = () => {
    this.playMainTrack();
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_FEEL);
  };

  enterStepDoTuto = () => {
    this.stopMainTrack();

    AudioManager.play(AUDIO_ID.CHAPTER_DO);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_DO, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_DO);
  };

  enterStepDoOutro = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_DO);

    AudioManager.play(AUDIO_ID.AMB_OUTRO_DO);
    AudioManager.fadeIn(AUDIO_ID.AMB_OUTRO_DO, 1, 50);
  };

  exitStepDo = () => {
    AudioManager.fadeOutStop(AUDIO_ID.AMB_OUTRO_DO, 1000);
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_DO);
  };

  enterStepLoveTuto = () => {
    this.stopMainTrack();

    AudioManager.play(AUDIO_ID.CHAPTER_LOVE);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_LOVE, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_LOVE);
  };

  enterStepLoveOutro = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_LOVE);

    AudioManager.play(AUDIO_ID.AMB_OUTRO_LOVE);
    AudioManager.fadeIn(AUDIO_ID.AMB_OUTRO_LOVE, 1, 50);
  };

  exitStepLove = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_LOVE);

    AudioManager.fadeOutStop(AUDIO_ID.AMB_OUTRO_LOVE, 1000);
  };

  enterStepSpeakTuto = () => {
    this.stopMainTrack();

    AudioManager.play(AUDIO_ID.CHAPTER_SPEAK);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_SPEAK, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_SPEAK);
  };

  enterStepSpeakOutro = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_SPEAK);

    AudioManager.play(AUDIO_ID.AMB_OUTRO_SPEAK);
    AudioManager.fadeIn(AUDIO_ID.AMB_OUTRO_SPEAK, 1, 50);
  };

  exitStepSpeak = () => {
    AudioManager.fadeOutStop(AUDIO_ID.AMB_OUTRO_SPEAK, 1000);

    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_SPEAK);
  };

  enterStepSeeTuto = () => {
    this.stopMainTrack();

    AudioManager.play(AUDIO_ID.CHAPTER_SEE);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_SEE, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_SEE);
  };

  enterStepSeeOutro = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_SEE);

    AudioManager.play(AUDIO_ID.AMB_OUTRO_SEE);
    AudioManager.fadeIn(AUDIO_ID.AMB_OUTRO_SEE, 1, 50);
  };

  exitStepSee = () => {
    AudioManager.fadeOutStop(AUDIO_ID.AMB_OUTRO_SEE, 1000);

    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_SEE);
  };

  enterArtworkTuto = () => {
    this.stopMainTrack();

    AudioManager.play(AUDIO_ID.CHAPTER_ARTWORK);
    AudioManager.fadeIn(AUDIO_ID.CHAPTER_ARTWORK, 1, 50);

    setCurrentVoice(AUDIO_ID.CHAPTER_ARTWORK);
  };

  enterArtworkDefault = () => {
    if (!UserCard.generated) {
      setCurrentVoice(null);
    }
  };

  exitArtwork = () => {
    AudioManager.fadeOutStop(AUDIO_ID.CHAPTER_ARTWORK);
  };
}

let state = null;

export function createAudioState() {
  if (state === null) state = new AudioState();
}
