
import AudioManager from "@/core/audio/AudioManager";
import gsap from "gsap";
import { defineComponent } from "vue";

enum SoundStatusEnum {
  ON = "on",
  OFF = "off",
}

export default defineComponent({
  setup() {},

  data() {
    return {
      isMuted: false,
    };
  },

  computed: {
    soundKey(): string {
      return this.isMuted ? SoundStatusEnum.ON : SoundStatusEnum.OFF;
    },
  },

  methods: {
    enterStatus(el, onComplete) {
      gsap.fromTo(
        el,
        { yPercent: 100, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1,
          ease: "quart.inOut",
          onComplete,
        }
      );
    },

    leaveStatus(el, onComplete) {
      gsap.fromTo(
        el,
        { yPercent: 0, opacity: 1 },
        {
          yPercent: -100,
          opacity: 0,
          duration: 1,
          ease: "quart.inOut",
          onComplete,
        }
      );
    },

    toggleAudio() {
      this.isMuted = !this.isMuted;
      AudioManager.setMute(this.isMuted);
    },
  },
});
