
import { Viewport } from "@/store/modules/Viewport";
import gsap from "gsap";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},

  data() {
    const tlCollection: gsap.core.Timeline[] = [];
    const scrollTriggerCollection: ScrollTrigger[] = [];
    return {
      slideIndex: 0,
      tlCollection,
      scrollTriggerCollection,
    };
  },

  computed: {
    slides(): { src: string; width: number; height: number }[] {
      return [
        {
          src: "/assets/images/collaboration-slide-0000.jpg",
          width: 578,
          height: 360,
        },
        {
          src: "/assets/images/collaboration-slide-0001.jpg",
          width: 578,
          height: 360,
        },
        {
          src: "/assets/images/collaboration-slide-0002.jpg",
          width: 578,
          height: 360,
        },
        {
          src: "/assets/images/collaboration-slide-0003.jpg",
          width: 578,
          height: 360,
        },
        {
          src: "/assets/images/collaboration-slide-0004.jpg",
          width: 578,
          height: 360,
        },
      ];
    },
  },

  watch: {
    slideIndex: {
      handler(index) {
        const $slides: Element[] = Array.from(
          this.$el.querySelectorAll(".ViewSliderSection__Slide")
        );
        const $target: Element = $slides[index];
        const target = $target.getBoundingClientRect();
        const slidesBCR = (
          this.$refs.slides as Element
        ).getBoundingClientRect();
        const x =
          slidesBCR.x + slidesBCR.width * 0.5 - target.x - target.width * 0.5;
        gsap.to($slides, {
          x: `+=${x}`,
          rotation: function (i) {
            return i === index ? "-2deg" : "0deg";
          },
          ease: "cubic.inOut",
          duration: 1,
        });
      },
    },
  },

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl.clear() && tl.kill();
      });
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) =>
        scrollTrigger.kill()
      );
    }
  },

  mounted() {
    this.$nextTick(this.initScrollAnimation);
  },

  methods: {
    initScrollAnimation() {
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewBioSection__Heading"),
              //   scrub: 1,
              start: "top center",
            },
          })
          .addLabel("start")
          .fromTo(
            Array.from(
              this.$el.querySelectorAll(".ViewBioSection__Heading span")
            ),
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration: 1,
              ease: "cubic.out",
              stagger: 0.1,
            },
            "start"
          )
      );

      const body: Element = this.$el.querySelector(".ViewBioSection__Body");
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: body,
              //   scrub: 1,
              start: "top center",
            },
          })
          .addLabel("start")
          .fromTo(
            body,
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
            "start"
          )
      );

      const figure: Element = this.$el.querySelector(".ViewBioSection__Figure");
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: figure,
              scrub: 1,
              start: "top bottom",
              end: "center center",
            },
          })
          .addLabel("start")
          .fromTo(
            figure,
            {
              rotate: "0deg",
              y: 80,
            },
            {
              rotate: "5deg",
              y: 0,
              duration: 1.25,
            },
            "start"
          )
      );

      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewSliderSection"),
              //   scrub: 1,
              start: "top center",
            },
          })
          .addLabel("start")
          .fromTo(
            Array.from(this.$el.querySelectorAll(".ViewSliderSection__Slide")),
            {
              x: Viewport.windowWidth,
              rotate: "5deg",
            },
            {
              x: 0,
              rotate: function(index: number) {
                return index === 0 ? "-2deg" : "0deg";
              },
              duration: 1,
              ease: "cubic.out",
              stagger: 0.1,
            },
            "start"
          )
      );
    },

    previous() {
      this.slideIndex--;
      if (this.slideIndex < 0) {
        this.slideIndex = this.slides.length - 1;
      }
    },

    next() {
      this.slideIndex++;
      if (this.slideIndex >= this.slides.length) {
        this.slideIndex = 0;
      }
    },
  },
});
