
import gsap from "gsap";
import { defineComponent } from "vue";
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";
import { PERMISSION } from "@/store/states/AppStateMachine";
import { Viewport } from "@/store/modules/Viewport";

import { TutoArrowEnum } from "@/components/Tuto/Tuto.vue";
import Tuto from "@/components/Tuto/Tuto.vue";

export default defineComponent({
  components: {
    Tuto,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      tutoSlides: [
        {
          text: "I talk freely, I sing, I am heard,\nand I speak my truth, always.",
          buttonLabel: "Skip",
          isIntro: true,
          arrow: TutoArrowEnum.STRAIGHT,
          rotate: -1,
        },
        {
          text: "Tell me with your voice if one of my\nmantras is also your truth.",
          buttonLabel: "Skip",
          isIntro: true,
          arrow: TutoArrowEnum.SWIRL,
          rotate: 1,
        },
        {
          doodle: {
            path: "tutorials/I_Speak",
            frames: 8,
            autoplay: true,
            loop: true,
          },
          // image: "/assets/speak-1.png",
          text: "Say aloud one of\nthe mantras you see\nto select it",
          buttonLabel: "Okay!",
          border: true,
          rotate: -2,
          requires: PERMISSION.AUDIO,
          fallback: {
            doodle: {
              path: "tutorials/I_Speak_Fallback",
              frames: 8,
              autoplay: true,
              loop: true,
            },
            // image: "/assets/speak-1-fallback.png",
            text: "Choose your Mantra\nwith the arrows",
            buttonLabel: "Okay!",
            rotate: -2,
            border: true,
          },
        },
      ],
    };
  },

  computed: {
    skipIntro(): boolean {
      return this.state?.context?.skipTutoIntro || false;
    },

    isStateTuto(): boolean {
      return this.state?.value["step_speak"] === "tuto";
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=1");

      tl.fromTo(
        el,
        {
          x: !this.skipIntro ? Viewport.windowWidth : 0,
          opacity: !this.skipIntro ? 1 : 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: !this.skipIntro ? 2 : 0.5,
          ease: !this.skipIntro ? "quart.inOut" : "sine.out",
        },
        "start"
      );
    },

    afterEnter() {},

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        el,
        {
          x: Viewport.isDesktop
            ? -Viewport.windowWidth
            : -el.querySelector(".Tuto .Tuto__Inner").clientWidth,
          duration: 2,
          ease: "quart.inOut",
        },
        "start"
      );
    },

    tutoComplete() {
      this.send("ENTER");
    },
  },
});
