import PlaneGeometry from "@/webgl/lib/PlaneGeometry";
import Scene from "@/webgl/Scene";
import Program from "nanogl/program";

import vShader from "@/webgl/glsl/facedrawing/facedrawing.vert";
import fShader from "@/webgl/glsl/facedrawing/facedrawing.frag";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import GltfNode from "../../lib/nanogl-gltf/lib/elements/Node";
import gsap, { Quart } from "gsap";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { vec2, vec3 } from "gl-matrix";
import GLConfig from "nanogl-state/config";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { Visage } from "@/store/modules/Visage";
import { watch } from "vue";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import { Passes } from "@/webgl/glsl/Passes";
import { StandardPass } from "nanogl-pbr/StandardPass";
import { Sampler } from "nanogl-pbr/Input";
import { smoothstep } from "@/webgl/math";
import Texture2D from "nanogl/texture-2d";
import { Viewport } from "@/store/modules/Viewport";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

const V3A = vec3.create()

export default class FaceDrawing {

  planeGeom: PlaneGeometry
  faceProgram:Program

  outroVal = 0
  aspect:vec2

  lk:GltfNode

  glconfig: GLConfig

  emotionWatcher

  currentEmotion:string = "happiness"
  prevEmotion:string = "happiness"

  totemMat:BaseMaterial

  video:HTMLVideoElement
  videoCanvas:HTMLCanvasElement
  videoContext:CanvasRenderingContext2D
  videoTex:Texture2D
  videoTime:number

  inTransition = false

  waitTransition = false

  newEmotion:string = ""


  randEmo = 1

  constructor(public scene:Scene, public camera:Camera, public root:Node, private museumCamera:Camera, private resources:ResourceGroup) {
    const gl = this.scene.gl

    this.glconfig = new GLConfig();

    this.aspect = vec2.create()

    this.glconfig
      .enableDepthTest(false)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.planeGeom = new PlaneGeometry(this.scene.gl, 1, 1, 1, 1)
    this.faceProgram = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())

    this.lk = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "lookAt4")

    this.newEmotion = ""
    this.randEmo = Math.random() > 0.5 ? 1 : 2

    this.emotionWatcher = watch(() => Visage.currentEmotion, async () => {
      if(Visage.currentEmotion === "neutral") return
      this.prevEmotion = this.currentEmotion
      await this.video.pause()
      this.videoTime = -1
      this.video.currentTime = 0
      await this.video.play()
      this.newEmotion = Visage.currentEmotion
      AudioManager.playUI(AUDIO_ID.XP_FEEL_EXPRESSION);
      if(!this.inTransition) this.transition()
    })

    this.createVideo()
  }

  createVideo() {
    this.video = document.createElement("video")
    this.video.playsInline = true
    this.video.muted = true
    this.video.src = URL.createObjectURL(this.scene.resources.get("feel-transition"))
    
    this.videoCanvas = document.createElement("canvas")
    this.videoContext = this.videoCanvas.getContext("2d")
    this.videoContext.imageSmoothingEnabled = false
    this.videoTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.videoTex.setFilter(true, false, false)
    this.videoTex.clamp()
    // document.body.appendChild(this.videoCanvas)
    // this.videoCanvas.style.position = "fixed"
    // this.videoCanvas.style.width = "20%"
    // this.videoCanvas.style.left = "0"
    // this.videoCanvas.style.top = "0"
    // this.videoCanvas.style.pointerEvents = "none"
  }

  async outro() {
    this.outroVal = 0
    this.totemMat = this.scene.museumScene.materials.faceMaterial
    const pass = this.totemMat.getPass(Passes.DEFAULT).pass as StandardPass
    const sampler = pass.surface.baseColor.param as Sampler
    sampler.set(this.resources.get(this.currentEmotion + "-small" + "-" + this.randEmo));
    sampler.invalidateCode();
    sampler.invalidateList();

    await gsap.to(this, { duration: 2, outroVal: 1, ease: Quart.easeInOut })

    // this.outro()
  }

  transition() {
    this.inTransition = true
  }

  endTransition() {
    this.inTransition = false
    this.prevEmotion = this.currentEmotion
    console.log("end transition")

  }

  preRender() {
    if(this.inTransition) this.drawVideo()
    vec3.set(V3A, this.lk._wmatrix[12], this.lk._wmatrix[13],this.lk._wmatrix[14])
    vec3.transformMat4(V3A, V3A, this.museumCamera._viewProj)
    // quat.copy(this.root.rotation, this.lk.rotation)
    vec3.lerp(this.root.position, [0, 0, 0], V3A, smoothstep(0.2, 0.6, this.outroVal))
    this.root.invalidate()
    this.root.updateWorldMatrix()
  }

  drawVideo() {
    if(this.video.currentTime !== this.videoTime) {
      this.videoContext.drawImage(this.video, 0, 0, this.videoCanvas.width, this.videoCanvas.height)
      this.videoTex.fromImage(this.videoCanvas)
      if(this.video.currentTime === this.video.duration) this.endTransition()
      if(this.newEmotion !== "") {
      this.currentEmotion = this.newEmotion
      this.newEmotion = ""
      }
    }

    this.videoTime = this.video.currentTime
  }

  render() {
    this.scene.glstate.now(this.glconfig)
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.faceProgram.use()
    this.faceProgram.uMVP(M4);
    this.faceProgram.uTex(this.resources.get(this.currentEmotion + "-" + this.randEmo));
    if(this.inTransition) {
      this.faceProgram.uVideo(this.videoTex);
      this.faceProgram.uTransition(this.video.currentTime / this.video.duration);
      this.faceProgram.uTex(this.resources.get(this.prevEmotion + "-" + this.randEmo));
      this.faceProgram.uTex1(this.resources.get(this.currentEmotion + "-" + this.randEmo));
    } else {
      this.faceProgram.uTransition(0);
      this.faceProgram.uTex(this.resources.get(this.currentEmotion + "-" + this.randEmo));
    }
    this.faceProgram.uCircleSize(Viewport.isDesktop ? 0.024 : 0.014);
    this.faceProgram.uOutro(this.outroVal);
    this.faceProgram.uAspect(this.aspect);
    this.faceProgram.uIsMobile(Viewport.isDesktop ? 0 : 1);
    this.planeGeom.bind(this.faceProgram);
    this.planeGeom.draw();
  }

  _resize(aspect, width, height) {
    if(width > height) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)
    this.videoCanvas.width = width / 2
    this.videoCanvas.height = height / 2
  }

  destroy() {
    this.faceProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.planeGeom = null
    this.emotionWatcher()
    URL.revokeObjectURL(this.video.src)
    this.video = null
    this.videoCanvas.width = 1
    this.videoCanvas.height = 1
    this.videoCanvas = null
    this.videoContext = null
    this.videoTex.dispose()
  }
}