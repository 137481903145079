import { reactive } from "vue"
enum Emotion {
  "anger", 
  "disgust", 
  "fear", 
  "happiness", 
  "sadness", 
  "surprise", 
  "neutral"
}

type VisageStore = {
  isReady: boolean
  isCamera: boolean
  eyeClosure: number
  currentEmotion: "anger" |  "disgust" |  "fear" |  "happiness" |  "sadness" |  "surprise" |  "neutral" 
  emotionArray:number[]
}

export const Visage = reactive<VisageStore>({
  isReady: false,
  isCamera: false,
  eyeClosure: -1,
  currentEmotion: "neutral",
  emotionArray: []
})

export function setReady(b:boolean) {
  Visage.isReady = b
}

export function setCamera(b:boolean) {
  Visage.isCamera = b
}

export function setAllEmotions(emotions:number[]) {
  Visage.emotionArray = emotions
}

export function setemotion(emotion:string) {
  Visage.currentEmotion = emotion as "anger" |  "disgust" |  "fear" |  "happiness" |  "sadness" |  "surprise" |  "neutral"
  // console.log("set emotion", Visage.currentEmotion)
}

export function setEyeClosure(n:number) {
  Visage.eyeClosure = n
}