import { IActivity } from "@/webgl/activities/Activity";
import LoveActivity from "@/webgl/activities/LoveActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class LoveActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new LoveActivity(scene);
  }
}

const LoveActivityService = new LoveActivityServiceImpl('love')

export default LoveActivityService

/////////////
/////////////////
/////////////////////
//////////////////////////////////////
///////////////////////////////////////////////
 
//////////