import vShader from "@/webgl/glsl/volumespectrum/volumespectrum.vert";
import fShader from "@/webgl/glsl/volumespectrum/volumespectrum.frag";

import PlaneGeometry from "@/webgl/lib/PlaneGeometry"
import Program from "nanogl/program"
import Camera from "nanogl-camera";
import Scene from "@/webgl/Scene";
import Node from "nanogl-node";
import Texture2D from "nanogl/texture-2d";
import { vec2, vec3 } from "gl-matrix";
import { AppStore } from "@/store/modules/AppStore";
import { watch } from "vue";
import gsap, { Quart } from "gsap";
import { smoothstep } from "@/webgl/math";
import Browser from "@/utils/Browser";
import { Viewport } from "@/store/modules/Viewport";

const BEIGE = [236 / 255, 233 / 255, 228 / 255]
const ROTATE_AMP = Math.PI * 0.05
const HEIGHT_BAR = 100
const HEIGHT_BAR_MOBILE = 40
export default class VolumeSpectrum {

  planeGeom: PlaneGeometry
  volProgram: Program
  intro: number

  dimensions: {
    width: number,
    height: number,
    aspect: number
  }

  stream: MediaStream
  audioCtx: AudioContext
  audioSrc: MediaStreamAudioSourceNode
  analyser: AnalyserNode
  data: Uint8Array
  dataLerp: number[]
  datafakeLerp: number[]
  rotate: number[]
  scaleY: number[]
  length: number
  aspect: vec2
  lerpIsMedia: number

  drawCanvas: HTMLCanvasElement
  drawCtx: CanvasRenderingContext2D
  canvasTex: Texture2D
  wallTex: Texture2D

  vineTex: Texture2D
  videoVineTime: number
  heightSpectrum: number
  addValue: number
  ySpectrum: number

  heightBar: number


  videoVine: HTMLVideoElement
  videoVineCanvas: HTMLCanvasElement
  videoVineContext: CanvasRenderingContext2D
  videoVineAspect: number[]

  gotStream = false

  mediaWatcher


  constructor(private scene: Scene, private camera: Camera, private root: Node) {
    const gl = this.scene.gl
    this.planeGeom = new PlaneGeometry(gl, 1, 1, 1, 1)
    this.volProgram = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())
    this.drawCanvas = document.createElement("canvas")
    this.drawCtx = this.drawCanvas.getContext("2d")

    this.aspect = vec2.create()

    this.heightSpectrum = 1

    this.addValue = Viewport.isDesktop ? 25 : 60
    this.ySpectrum = Viewport.isDesktop ? 0 : -0.1;

    // document.body.appendChild(this.drawCanvas)
    // this.drawCanvas.style.position = "fixed"
    // this.drawCanvas.style.left = "0"
    // this.drawCanvas.style.top = "0"
    // this.drawCanvas.style.pointerEvents = "none"
    this.canvasTex = new Texture2D(gl, gl.RGBA)
    this.canvasTex.bind()
    this.canvasTex.setFilter(true, false, false)
    this.canvasTex.clamp()
    this.lerpIsMedia = 0
    this.intro = 0
    this.dataLerp = []
    this.datafakeLerp = []
    this.rotate = []
    this.scaleY = []
    this.length = 1024
    this.heightBar = Viewport.isDesktop ? HEIGHT_BAR : HEIGHT_BAR_MOBILE
    for (let i = 0; i < this.length; i++) {
      this.dataLerp[i] = 0
      this.datafakeLerp[i] = 5 + Math.random() * 10
      this.rotate[i] = -ROTATE_AMP * 0.5 + Math.random() * ROTATE_AMP
      this.scaleY[i] = 0.2 + Math.random() * 0.8
    }
    if (AppStore.microEnabled) {
      if (this.allowRequestAudio) this.requestAudio()
    }

    this.mediaWatcher = watch(() => AppStore.microEnabled, async () => {
      if (AppStore.microEnabled && !this.gotStream) {

        if (this.allowRequestAudio) this.requestAudio()
      } else if (!AppStore.microEnabled && this.gotStream) {
        this.analyser.disconnect()
        this.audioSrc.disconnect()
        this.audioCtx.close()

        this.stream.getTracks().forEach(function (track) {
          track.stop();
        });
        this.stream = null
        this.audioCtx = null
        this.gotStream = false
      }
    })

    this.wallTex = this.scene.resources.get("wall-tex")
    this.wallTex.bind()
    this.wallTex.repeat()

    this.createVideoVine()

    gsap.to(this, { duration: 2, intro: 1, ease: Quart.easeOut, delay: 1 })
  }

  get allowRequestAudio() {
    return !Browser.mobile
  }

  createVideoVine() {
    this.videoVine = document.createElement("video")
    this.videoVine.src = URL.createObjectURL(this.scene.resources.get("vine"))
    this.videoVine.playsInline = true
    this.videoVine.muted = true
    this.videoVine.loop = true
    this.videoVineAspect = [1, 1]
    this.videoVine.onloadedmetadata = () => this.resize(this.dimensions.aspect, this.dimensions.width, this.dimensions.height)

    this.videoVineCanvas = document.createElement("canvas")
    this.videoVineContext = this.videoVineCanvas.getContext("2d")
    this.videoVineContext.imageSmoothingEnabled = false
    this.vineTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.vineTex.setFilter(true, false, false)
    this.vineTex.clamp()
    this.videoVine.play()
  }

  async requestAudio() {
    if (navigator.mediaDevices.getUserMedia !== null) {
      const options = {
        video: false,
        audio: true,
      };
      try {
        this.stream = await navigator.mediaDevices.getUserMedia(options);
        var AudioContext = window.AudioContext // Default
          || window["webkitAudioContext"] // Safari and old versions of Chrome
          || false;
        this.audioCtx = new AudioContext();
        this.analyser = this.audioCtx.createAnalyser();
        this.analyser.fftSize = 2048;
        this.audioSrc = this.audioCtx.createMediaStreamSource(this.stream);
        this.audioSrc.connect(this.analyser);
        this.data = new Uint8Array(this.analyser.frequencyBinCount);
        this.dataLerp = []
        this.rotate = []
        this.scaleY = []
        this.length = this.gotStream ? this.data.length : 1024
        for (let i = 0; i < this.data.length; i++) {
          this.dataLerp[i] = 0
          this.rotate[i] = -ROTATE_AMP * 0.5 + Math.random() * ROTATE_AMP
          this.scaleY[i] = 0.2 + Math.random() * 0.8
        }
        this.gotStream = true

      } catch (err) {
        console.error(err)
        // error handling
      }
    }
  }

  drawVineVideo() {
    if (this.videoVine.currentTime !== this.videoVineTime) {
      this.videoVineContext.drawImage(this.videoVine, 0, 0, this.videoVineCanvas.width, this.videoVineCanvas.height)
      this.vineTex.fromImage(this.videoVineCanvas)
      this.videoVineTime = this.videoVine.currentTime
    }

  }

  preRender() {
    if (this.gotStream) this.analyser.getByteFrequencyData(this.data);
    this.drawVineVideo()

    this.drawCtx.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height)
    this.length = this.gotStream ? this.data.length : 1024
    this.lerpIsMedia += ((AppStore.microEnabled && !AppStore.wrongAnswer ? 1 : 0) - this.lerpIsMedia) * 0.05
    this.drawCtx.beginPath()
    this.drawCtx.lineWidth = 4
    this.drawCtx.lineJoin = "round"
    this.drawCtx.lineCap = "round"
    for (let i = 0; i < this.length; i += this.addValue) {
      let v = this.gotStream ? this.data[i] : 1;
      if (!this.allowRequestAudio && AppStore.audioListen) {
        v = this.datafakeLerp[i]
        if (Math.abs(this.dataLerp[i] - this.datafakeLerp[i]) < 0.1) this.datafakeLerp[i] = Math.max(0, -40 + Math.random() * 80)
      }
      this.dataLerp[i] += (v - this.dataLerp[i]) * 0.1

      this.drawBar(i / this.length, this.dataLerp[i], this.rotate[i], this.scaleY[i] * this.lerpIsMedia)
    }
    this.drawCtx.stroke()

    this.canvasTex.fromImage(this.drawCanvas)
  }

  drawBar(x, height, rotate, scaleY) {
    this.drawCtx.strokeStyle = `rgba(22, 46, 123, ${smoothstep(Math.max(0, x - 0.1), x, this.intro)})`
    this.drawCtx.translate(x * this.drawCanvas.width, this.drawCanvas.height / 2)
    this.drawCtx.rotate(rotate)
    const y = Math.min(188, (this.heightBar * scaleY + height))
    this.drawCtx.moveTo(0, 0 - y)
    this.drawCtx.lineTo(0, 0 + y)
    this.drawCtx.setTransform(1, 0, 0, 1, 0, 0);
  }

  render() {
    this.volProgram.use()
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.volProgram.uMVP(M4);
    this.volProgram.uBeige(BEIGE);
    this.volProgram.uAspect(this.aspect);
    this.volProgram.uWall(this.wallTex)
    this.volProgram.uYSpectrum(this.ySpectrum)
    this.volProgram.uVine(this.vineTex)
    this.volProgram.uVineAspect(this.videoVineAspect)
    this.volProgram.uHeightSpectrum(this.heightSpectrum);
    this.volProgram.uTex(this.canvasTex);
    this.planeGeom.bind(this.volProgram);
    this.planeGeom.draw();
  }

  resize(aspect, width, height) {
    this.dimensions = {
      width,
      height,
      aspect
    }

    if (width > height) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)
    this.drawCanvas.width = width
    this.drawCanvas.height = Math.min(400, height)
    this.heightSpectrum = height / this.drawCanvas.height
    this.videoVineCanvas.width = width / 10
    this.videoVineCanvas.height = height / 10
    this.videoVineContext.fillStyle = "white"
    this.videoVineContext.fillRect(0, 0, width, height)

    if (this.videoVine.videoWidth && this.videoVine.videoHeight) {
      this.videoVineAspect = width > height
        ? [1, this.videoVine.videoWidth / this.videoVine.videoHeight]
        : [this.videoVine.videoHeight / this.videoVine.videoWidth, 1]
    }
  }

  destroy() {
    this.mediaWatcher()
    this.volProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.volProgram = this.planeGeom = null
    this.drawCanvas.width = 1
    this.drawCanvas.height = 1
    this.drawCanvas = null
    if (this.gotStream) {
      this.analyser.disconnect()
      this.audioSrc.disconnect()
      this.audioCtx.close()

      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });

      this.stream = null
      this.audioCtx = null

      URL.revokeObjectURL(this.videoVine.src)
      this.videoVine = null
      this.videoVineCanvas.width = 1
      this.videoVineCanvas.height = 1
      this.videoVineCanvas = null
      this.videoVineContext = null
      this.vineTex.dispose()
    }
  }
}