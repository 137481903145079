export default {

  pageView( name:string ){
    console.debug( '[gtm] vpv', name)
    window.dataLayer.push({
      'event': 'virtualPageView',
      'VPV': name
    });
  },
  
  event( action:string ){
    console.debug( '[gtm] event', action )
    window.dataLayer.push({
      'event': 'virtualPageView',
      'pageTitle': action,
      'pageUrl': window.location.href+action
    })
  },
  
  eventPush( action:string, category: string = "" ){
    // console.debug( '[gtm] event push', action, category )
    // window.dataLayer.push({
    //   'event': 'eventPush',
    //   'eventAction': action,
    //   'eventLabel': '',
    //   'eventValue': '',
    //   'eventCategory': category
    // })
  }
}
