import { AppState } from "@/store/states/AppStateMachine";
import Node from "../../lib/nanogl-gltf/lib/elements/Node";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import { AbortController } from "@azure/abort-controller";
import { vec3 } from "gl-matrix";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import WaterColor from "./WaterColor";
import StateHelper from "../StateHelper";
import Camera from "nanogl-camera";
import { DEG2RAD } from "@/webgl/math";
import GLApp from "@/webgl/main";



export default class LoveActivity implements IActivity {

  readonly priority: number = 2;
  readonly name: string = "love";

  root: Node;
  aspect: number

  private _startAbortController: AbortController;

  stateHelper: StateHelper;

  waterColor: WaterColor

  private _fov: number = 50;

  private _camera: Camera

  constructor(public scene: Scene) {
    this.root = new Node()
    this.makeCamera()
    this.stateHelper = new StateHelper([
      { match: 'step_love.outro', enter: this.leaveLove },
    ])

  }

  onStateUpdate(state: AppState): void {


  }

  load(): Promise<void> {
    return Promise.all([]).then()

  }

  unload(): void {

  }

  start(): void {
    this.scene.root.add(this.root)
    const videos = [this.scene.resources.get("love01"), this.scene.resources.get("love02")]
    this.waterColor = new WaterColor(this.scene, this._camera, this.root, videos)
    this._startAbortController = new AbortController()
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    this._camera.invalidate()
    this.scene.glview.onResize.on(this._resize)
    this._resize()
    this.stateHelper.start()
  }

  async stop() {
    this.scene.root.remove(this.root)
    this._startAbortController.abort()
    this._startAbortController = null
    this.scene.glview.onResize.off(this._resize)
    await this.waterColor.destroy()
    this.waterColor = null

    this.stateHelper.stop()
  }

  leaveLove = () => {
    console.log("love activity stop")
    this.waterColor.saveCanvas()
  }

  enterOutro = () => {
    // console.log("enter outro")
    // AppService.send("ENTER")
  }

  preFrame(): void {

  }

  preRender(): void {
    this.waterColor.preRender()
  }


  render(): void {
    this.waterColor.render()
  }

  renderUI(): void {

  }


  rttPass(): void {

  }

  _resize = (): void => {
    const w = GLApp.getInstance().glview.width
    const h = GLApp.getInstance().glview.height

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    const pr = this.scene.glview.pixelRatio

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this.waterColor.resize(w > h ? h / w : w / h, window.innerWidth, window.innerHeight)
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 50

    this._camera = camera;

  }


}