import PlaneGeometry from "@/webgl/lib/PlaneGeometry";

import Program from "nanogl/program";

import vShader from "@/webgl/glsl/watercolor/watercolor.vert";
import fShader from "@/webgl/glsl/watercolor/watercolor.frag";
import mainVShader from "@/webgl/glsl/main.vert";
import fShaderLoop from "@/webgl/glsl/watercolor/watercolor-loop.frag";

import Scene from "@/webgl/Scene";
import { vec2, vec3, vec4 } from "gl-matrix";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import Fbo from "nanogl/fbo";
import PixelFormats from "nanogl-pf";
import Texture2D from "nanogl/texture-2d";
import GLArrayBuffer from 'nanogl/arraybuffer';
import { HexToVec } from "@/webgl/lib/color";
import { shuffle } from "@/utils/array-utils";
import colorGradient from "javascript-color-gradient";
import { Viewport } from "@/store/modules/Viewport";
import { setBackground } from "@/store/modules/UserCard";
import Browser from "@/utils/Browser";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

const GRADIENTS = [
  "#FF6187",
  "#FFC774",
  "#E7EB7B",
  "#79D1CF",
  "#5CA7E1",
  "#3182C9",
  "#BC81B4",
]

const STARTINGPOS = [
  [-0.3, -0.3],
  [0, -0.3],
  [0.3, -0.3],
  [-0.3, 0],
  [0.3, 0],
  [-0.3, 0.3],
  [0, 0.3],
  [0.3, 0.3],
]

const BIG_COL = 3

export default class WaterColor {
  planeGeom: PlaneGeometry
  wcProgram: Program
  wcLoopProgram: Program

  drawCanvas: HTMLCanvasElement

  wcTargets: Array<Fbo>

  pingpong = false
  canvasTex: Texture2D
  wallTex: Texture2D
  fsPlane: GLArrayBuffer

  viewport: vec2
  videoPos: vec2
  currentChannel: vec3
  channelChoose: number
  currCol

  ready = false

  gradientArray
  gradientId = 0
  currVideo = 0
  gradRand
  flipUVS: vec2

  video: HTMLVideoElement
  videoCanvas: HTMLCanvasElement
  videoContext: CanvasRenderingContext2D
  videoTex: Texture2D
  videoTime: number
  playVideo = false
  color: vec3
  videoSpeed
  isSpacePressed = false

  aspect: vec2


  countVids

  constructor(public scene: Scene, public camera: Camera, public root: Node, private videoBlob: Blob[]) {
    const gl = this.scene.gl
    this.generateGradient()

    this.aspect = vec2.create()

    const fsData = new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]);
    this.fsPlane = new GLArrayBuffer(gl, fsData);
    this.fsPlane.attrib('aTexCoord0', 2, gl.FLOAT);
    this.drawCanvas = document.createElement("canvas")
    // shuffle(this.videoBlob)
    // document.body.appendChild(this.drawCanvas)
    // this.drawCanvas.style.position = "fixed"
    // this.drawCanvas.style.width = "20%"
    // this.drawCanvas.style.left = "20%"
    // this.drawCanvas.style.top = "0"
    // this.drawCanvas.style.pointerEvents = "none"
    this.color = vec3.create()
    this.currentChannel = vec3.create()

    this.canvasTex = new Texture2D(gl, gl.RGBA)
    this.canvasTex.setFilter(true, false, false)
    this.canvasTex.clamp()

    this.wallTex = this.scene.resources.get("wall-tex")
    this.wallTex.bind()
    this.wallTex.repeat()

    this.planeGeom = new PlaneGeometry(gl, 1, 1, 1, 1)
    this.wcProgram = new Program(gl, vShader(), fShader(), scene.programs.getGlobalDefinitions())
    this.wcTargets = []
    this.wcTargets[0] = new Fbo(gl)
    this.wcTargets[1] = new Fbo(gl)
    scene.programs.getGlobalDefinitions()
    this.wcLoopProgram = new Program(scene.gl, mainVShader(), fShaderLoop(), scene.programs.getGlobalDefinitions())
    this.countVids = 0

    shuffle(STARTINGPOS)
    STARTINGPOS.unshift([0, 0])

    // this.gradientArray = colorGradient.setGradient(
    //   "#853D7D",
    //   "#162E7B",
    //   "#0087BA",
    //   "#00B7BC",
    //   "#FDDA38",
    //   "#FFA600",
    //   "#CB3234",
    //   ).setMidpoint(5);
    // this.gradientArray = GRADIENTS
    this.gradientId = 0
    // shuffle(this.gradientArray)
    this.ready = false

    this.viewport = vec2.create()
    this.videoPos = vec2.create()
    this.flipUVS = vec2.create()

    // this.drawCircle()
    this.playVideo = false
    this.createVideo()
    this.startdrawVideo()


    window.addEventListener("keydown", this.spacePress)
    window.addEventListener("keyup", this.spaceUp)
    this.scene.glview.canvas.addEventListener("touchstart", this.touchPress)
    this.scene.glview.canvas.addEventListener("touchend", this.touchUp)
  }

  spacePress = (event: KeyboardEvent) => {
    if (event.key === " ") {
      this.startHold()
    }
  }

  spaceUp = (event: KeyboardEvent) => {
    this.stopHold()
  }

  touchPress = (event: TouchEvent) => {
    event.preventDefault()
    this.startHold()
  }

  touchUp = (event: TouchEvent) => {
    event.preventDefault()
    this.stopHold()
  }

  startHold() {
    if (!Viewport.isDesktop) {
      this.videoSpeed = 2
    } else if (Browser.name === "firefox" || Browser.name === "safari") {
      this.videoSpeed = 2
      this.video.playbackRate = 2
    } else {
      this.isSpacePressed = true
    }
  }

  stopHold() {
    if (!Viewport.isDesktop) {
      this.videoSpeed = 0
    } else if (Browser.name === "firefox" || Browser.name === "safari") {
      this.videoSpeed = 0
      this.video.playbackRate = 0
    } else {
      this.isSpacePressed = false
    }
    AudioManager.fadeOutStop(AUDIO_ID.XP_LOVE_LOOP, 1000);
  }

  generateGradient() {
    this.gradientId = 0
    if (!this.gradRand) this.gradRand = Math.floor(Math.random() * GRADIENTS.length)
    // this.countVids = 0
    const next = (this.gradRand + 1) % GRADIENTS.length
    const curr = this.gradRand % GRADIENTS.length
    console.log("generate gradient", curr, next)
    this.gradientArray = colorGradient.setGradient(GRADIENTS[curr], GRADIENTS[next]).setMidpoint(4)
      .getArray();
    this.gradRand += 1
  }

  createVideo() {
    this.video = document.createElement("video")
    this.video.muted = true
    this.video.playsInline = true
    this.video.src = URL.createObjectURL(this.scene.resources.get("love03"))
    this.setRandomChannel()

    this.videoSpeed = 0
    this.video.playbackRate = this.videoSpeed

    this.videoCanvas = document.createElement("canvas")
    this.videoContext = this.videoCanvas.getContext("2d")
    this.videoContext.imageSmoothingEnabled = false
    this.videoTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.videoTex.setFilter(true, false, false)
    this.videoTex.clamp()
    // document.body.appendChild(this.videoCanvas)
    // this.videoCanvas.style.position = "fixed"
    // this.videoCanvas.style.width = "20%"
    // this.videoCanvas.style.left = "0"
    // this.videoCanvas.style.top = "0"
    // this.videoCanvas.style.pointerEvents = "none"
  }

  async startdrawVideo() {
    this.videoContext.fillStyle = "white"
    this.videoContext.fillRect(0, 0, this.videoCanvas.width, this.drawCanvas.height)
    this.videoTex.fromImage(this.videoCanvas)
    this.clearFBOs()
    this.videoTime = 0
    this.video.currentTime = 0
    await this.video.play()
    vec2.set(this.flipUVS, Math.random() > 0.5 ? 0 : 1, Math.random() > 0.5 ? 0 : 1)
    let xVideo = -this.videoCanvas.width * 0.2 + Math.random() * this.videoCanvas.width * 0.4
    let yVideo = -this.videoCanvas.height * 0.2 + Math.random() * this.videoCanvas.height * 0.4
    if (this.countVids < STARTINGPOS.length) {
      xVideo = STARTINGPOS[this.countVids][0] * this.videoCanvas.width
      yVideo = STARTINGPOS[this.countVids][1] * this.videoCanvas.height
      vec2.set(this.flipUVS, this.countVids % 2 === 0 ? 0 : 1, this.countVids % 3 === 0 ? 0 : 1)
    }
    vec2.set(this.videoPos, this.countVids < BIG_COL ? 0 : xVideo, this.countVids < BIG_COL ? 0 : yVideo)
    this.currCol = this.gradientArray[this.gradientId]
    HexToVec(Number("0x" + this.currCol.substring(1)), this.color)
    this.gradientId++
    if (this.gradientId > this.gradientArray.length - 1) {
      this.gradientId = 0
      // shuffle(this.gradientArray)
    }
    this.playVideo = true
    this.countVids++
    if (this.countVids % 6 === 0) this.generateGradient()
    if (this.countVids === 20) {
      this.countVids = 0
      this.generateGradient()
    }
  }

  async endVideo() {
    this.render()
    this.drawCanvas.getContext('2d').drawImage(this.scene.gl.canvas, 0, 0, this.videoCanvas.width, this.videoCanvas.height)
    await this.video.pause()
    URL.revokeObjectURL(this.video.src)
    this.currVideo = Math.floor(Math.random() * this.videoBlob.length)
    this.video.src = URL.createObjectURL(this.countVids < BIG_COL ? this.scene.resources.get("love03") : this.videoBlob[this.currVideo])
    this.setRandomChannel()
    // if(this.currVideo > this.videoBlob.length - 1) {
    //   // shuffle(this.videoBlob)
    //   this.currVideo = 0
    // }
    this.startdrawVideo()
    this.canvasTex.fromImage(this.drawCanvas)
  }

  setRandomChannel() {
    const rand = Math.random()
    if (rand < 0.33) {
      this.channelChoose = 1
      vec3.set(this.currentChannel, 0, 1, 0)
    }
    else if (rand < 0.75) {
      vec3.set(this.currentChannel, 0, 0, 1)
      this.channelChoose = 2
    }
    else {
      vec3.set(this.currentChannel, 1, 0, 0)
      this.channelChoose = 0
    }
  }

  drawVideo() {
    let cSpeed = 0
    let targetSpeed = 0
    if (this.isSpacePressed) {
      targetSpeed = 3
      cSpeed = this.countVids < BIG_COL ? 0 : 1
      AudioManager.play(AUDIO_ID.XP_LOVE_LOOP)?.volume(1);
    }

    if (Viewport.isDesktop && Browser.name !== "firefox" && Browser.name !== "safari") this.videoSpeed += (targetSpeed - this.videoSpeed) * 0.05
    if (this.videoSpeed < 0.1) this.videoSpeed = 0
    if (this.channelChoose !== 0) this.currentChannel[0] += (cSpeed - this.currentChannel[0]) * 0.01
    if (this.channelChoose !== 1) this.currentChannel[1] += (cSpeed - this.currentChannel[1]) * 0.01
    if (this.channelChoose !== 2) this.currentChannel[2] += (cSpeed - this.currentChannel[2]) * 0.01
    try {
      this.video.playbackRate = this.videoSpeed
    } catch (e) { }
    if (this.video.currentTime !== this.videoTime) {
      this.videoContext.fillStyle = "white"
      this.videoContext.fillRect(0, 0, this.videoCanvas.width, this.videoCanvas.height)
      this.videoContext.drawImage(this.video, this.videoPos[0], this.videoPos[1], this.videoCanvas.width, this.videoCanvas.height)
      this.videoTex.fromImage(this.videoCanvas)
      if (this.video.currentTime >= this.video.duration) this.endVideo()
    }

    this.videoTime = this.video.currentTime
  }

  preRender(forcewhite = false) {
    if (!this.ready) return
    if (this.playVideo) this.drawVideo()
    const gl = this.scene.gl
    const prg = this.wcLoopProgram
    this.pingpong = !this.pingpong
    const entry = this.wcTargets[this.pingpong ? 0 : 1]
    const output = this.wcTargets[this.pingpong ? 1 : 0]
    output.bind()
    output.defaultViewport()
    output.clear()
    prg.use()
    prg.uCol(this.color)
    prg.uChannel(this.currentChannel)
    prg.uFlipUVs(this.flipUVS)
    prg.uTex(forcewhite ? this.videoTex : entry.getColor(0))
    prg.uCanvas(this.videoTex)
    prg.uViewportScale(1, 1)
    this.fsPlane.attribPointer(prg)
    this.fsPlane.drawTriangleStrip()
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  render() {
    if (!this.ready) return
    const output = this.wcTargets[this.pingpong ? 1 : 0].getColor(0) as Texture2D
    this.wcProgram.use()
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.wcProgram.uMVP(M4);
    this.wcProgram.uWall(this.wallTex)
    this.wcProgram.uViewportScale(this.viewport)
    this.wcProgram.uTex(output);
    this.wcProgram.uAspect(this.aspect)
    this.wcProgram.uIsMobile(Viewport.isDesktop ? 0 : 1);
    this.wcProgram.uCanvas1(this.canvasTex)
    this.planeGeom.bind(this.wcProgram);
    this.planeGeom.draw();
  }

  clearFBOs() {
    const pf = PixelFormats.getInstance(this.scene.gl)
    const configs = [pf.RGBA8, pf.RGB16F, pf.RGBA16F, pf.RGB32F, pf.RGBA32F, pf.RGB8,
    pf.RGBA8,]
    const cfg = pf.getRenderableFormat(configs)
    this.wcTargets[0].bind()
    this.wcTargets[0].attachColor(cfg.format, cfg.type, cfg.internal)
    this.wcTargets[0].resize(this.drawCanvas.width, this.drawCanvas.height)
    let color = this.wcTargets[0].getColor(0);
    (color as any).setFilter(true, false, false);
    (color as any).clamp();

    this.wcTargets[1].bind()
    this.wcTargets[1].attachColor(cfg.format, cfg.type, cfg.internal)
    this.wcTargets[1].resize(this.drawCanvas.width, this.drawCanvas.height)
    color = this.wcTargets[1].getColor(0);
    (color as any).setFilter(true, false, false);
    (color as any).clamp();
  }

  resize(aspect, width, height) {
    this.drawCanvas.width = width / 2
    this.drawCanvas.height = height / 2
    this.videoCanvas.width = width / 2
    this.videoCanvas.height = height / 2
    vec2.set(this.viewport, 1, 1)
    const ctx = this.drawCanvas.getContext('2d')
    const wallImg = this.scene.resources.get("wall-tex-img")
    const ptrn = ctx.createPattern(wallImg, 'repeat');
    ctx.fillStyle = "rgb(236, 233, 228)"
    ctx.fillRect(0, 0, this.drawCanvas.width, this.drawCanvas.height)
    ctx.globalCompositeOperation = "overlay"
    ctx.fillStyle = ptrn
    ctx.fillRect(0, 0, this.drawCanvas.width, this.drawCanvas.height)
    ctx.globalCompositeOperation = "source-over";
    this.canvasTex.fromImage(this.drawCanvas)
    this.clearFBOs()
    this.ready = true
    if (width > height) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)
  }

  saveCanvas() {
    const saveCanvas = document.createElement("canvas")
    saveCanvas.width = this.drawCanvas.width
    saveCanvas.height = this.drawCanvas.height
    this.render()
    saveCanvas.getContext('2d').drawImage(this.scene.gl.canvas, 0, 0, this.videoCanvas.width, this.videoCanvas.height)
    setBackground(saveCanvas)
    console.log("canvas saved")
  }

  async destroy() {
    window.removeEventListener("keydown", this.spacePress)
    window.removeEventListener("keyup", this.spaceUp)
    this.scene.glview.canvas.removeEventListener("touchstart", this.touchPress)
    this.scene.glview.canvas.removeEventListener("touchend", this.touchUp)
    this.ready = false
    await this.video.pause()
    URL.revokeObjectURL(this.video.src)
    this.video = null
    this.videoCanvas.width = 1
    this.videoCanvas.height = 1
    this.videoCanvas = null
    this.videoContext = null
    this.drawCanvas.width = 1
    this.drawCanvas.height = 1
    this.drawCanvas = null
    this.wcProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.planeGeom = null
    this.wcLoopProgram.dispose()
    this.fsPlane.dispose()
    this.wcTargets[0].dispose()
    this.wcTargets[1].dispose()
    this.canvasTex.dispose()
    this.videoTex.dispose()
  }
}