import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52738218"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-user-media-switch" }
const _hoisted_2 = {
  class: "ui-btn-toggle__lottie",
  ref: "anim"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_tip = _resolveComponent("ui-tip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "ui-btn-rounded",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickTip && _ctx.clickTip(...args)))
    }, "i"),
    _createElementVNode("button", {
      class: "ui-btn-toggle",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickToggle && _ctx.clickToggle(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, null, 512),
      _createVNode(_Transition, {
        name: "fade-transition-cmn",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("span", {
            class: "ui-btn-toggle__label",
            key: _ctx.checked
          }, _toDisplayString(_ctx.toggleLabel), 1))
        ]),
        _: 1
      })
    ]),
    _withDirectives(_createVNode(_component_ui_tip, {
      ref: "uiTip",
      onClose: _ctx.closeTip
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tipText), 1)
      ]),
      _: 1
    }, 8, ["onClose"]), [
      [_vShow, _ctx.showTip]
    ])
  ]))
}