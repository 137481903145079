
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { setMenuOpen } from "@/store/modules/Menu";

import ViewCover from "@/components/Agenda/ViewCover.vue";
import ViewHeading from "@/components/Agenda/ViewHeading.vue";
import ViewAgenda from "@/components/Agenda/ViewAgenda.vue";
import PageFooter from "@/components/PageFooter/PageFooter.vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  components: {
    ViewCover,
    ViewHeading,
    ViewAgenda,
    "page-footer": PageFooter,
  },

  computed: {
    isStateDefault(): boolean {
      return this.state.matches("agenda.default");
    },
  },

  mounted() {
    setMenuOpen(false);
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      gsap.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "cubic.out",
        }
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      gsap.to(el, {
        opacity: 0,
        duration: 1,
      });
    },

    onBackClick() {
      this.send("EXIT");
    },
  },
});
