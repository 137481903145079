import { reactive } from "@vue/runtime-core";

import UrlParams from "@/utils/UrlParams";

export enum AppThemeEnum {
  LIGHT = "light",
  DARK = "dark",
}

export const AppStore = reactive({
  load_progress: 0,
  recaptchaToken: null,
  mediaTip: true,
  microDisabled: false,
  mediaDisabled: false,
  microEnabled: false,
  mediaEnabled: false,
  wrongAnswer: false,
  audioListen: false,
  playSound: false,
  theme: AppThemeEnum.DARK,
  debug: process.env.VUE_APP_DEBUG === "true",
  vivatech: UrlParams.getBool("vivatech")
});

export function setAppLoadProgress(v: number) {
  AppStore.load_progress = v;
}

export function setMediaEnabled(b: boolean) {
  AppStore.mediaEnabled = b;
}

export function setMicroEnabled(b: boolean) {
  AppStore.microEnabled = b
}

export function setMediaDisabled() {
  AppStore.mediaDisabled = true;
}

export function setMicroDisabled() {
  AppStore.microDisabled = true
}

export function setWrongAnswer(b: boolean) {
  AppStore.wrongAnswer = b;
}

export function setAudioListen(b: boolean) {
  AppStore.audioListen = b;
}

export function setMediaTip(b: boolean) {
  AppStore.mediaTip = b;
}

export function setAppTheme(theme: AppThemeEnum) {
  AppStore.theme = theme;
}

export function setRecaptchaToken(token: string) {
  AppStore.recaptchaToken = token
}

export function setPlaySound(b: boolean) {
  AppStore.playSound = b
}
