

import Config from 'nanogl-state/config'
import Fbo    from 'nanogl/fbo'
import Blur   from '@/webgl/glsl/blur'
import {mat4} from 'gl-matrix'
import Scene from '@/webgl/Scene';
import Plane from '@/webgl/math/Plane';
import Camera from 'nanogl-camera';
import { MsaaFbo } from "@/webgl/gl/MsaaFbo";
import getViewport from '@/webgl/camera/getViewport';
import { Pane } from 'tweakpane';


const M4A   = mat4.create();
const M4B   = mat4.create();



const SUBSCALE = 0.5;

export default class Reflect {

  private size: number;

  viewprojCopy: mat4;
  scene: Scene;
  plane: Plane;
  blur: Blur;
  globalCfg: Config;
  fbo: MsaaFbo;

  msaa = false;

  groundHeight = 0.0

  setQuality( size:number, aa:boolean ){
    if( size !== this.size ){
      this.size = size;
      this.blur.setSize( size );
      this.fbo.setSize( size, size )
    }

    if( aa !== this.msaa ){
      this.msaa = aa;
      if( this.fbo._useMsaa !== aa ){
        this.allocateFbo()
      }
    }
  }

  constructor( scene:Scene, plane?:Plane ){


    this.viewprojCopy = mat4.create()
    this.scene = scene;
    this.plane = plane;

    this.size = 512;
    this.blur = new Blur( scene, this.size * SUBSCALE )
    this.blur.spread = 0.85
    
    this.globalCfg = new Config()
      .frontFace( scene.gl.CW )
    

    this.allocateFbo()

  }

///////////////
///////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///
///////////

  allocateFbo(){
    const gl = this.scene.gl;
    
    const fbo = new MsaaFbo( gl, this.msaa?16:0 );
    fbo.setSize( this.size, this.size );
    
    const color = fbo.getColorTexture()
    color.bind()
    color.clamp()
    color.setFilter( true, false, false )

    this.fbo = fbo;

  }


  blitRenderBuffer(){
    this.fbo.blitMsaa();
  }


  bindAndClear(){
    var gl = this.scene.gl;
    // console.log(  this.scene.renderer.width, this.scene.renderer.height );
    // this.fbo.resize( nextPOT(w), nextPOT(h) );

    this.fbo.renderFbo.bind();
    gl.viewport( 0, 0, this.size, this.size );
    gl.clearColor( 1, 1, 1, 0 );
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT );

  }


  processOutput(){
    this.scene.gl.viewport(0, 0, this.size * SUBSCALE, this.size * SUBSCALE);
    this.blur.process( this.fbo.getColorTexture() )
  }

  getRawOutput(){
    return this.fbo.getColorTexture();
  }

  getOutput(){
    return this.blur.getBlurredTex()
    // return this.fbo.getColorTexture();
  }

  getOutputFbo(){
    return this.blur.getBlurredFbo()
  }


  clear(){
    this.blur.clear()
  }


  processCamera( cam:Camera ){

    // const vp = getViewport(cam, this.scene)

    const sw = this.size / this.scene.glview.width
    const sh = this.size / this.scene.glview.height

    // const relativeVP = {
    //   x: vp.x*sw,
    //   y: vp.x*sh,
    //   width: vp.width*sw,
    //   height: vp.height*sh,
    // }

    this.scene.gl.viewport( 0, 0, this.scene.glview.width * sw, this.scene.glview.height * sh )

    this.viewprojCopy.set( cam._viewProj )

    mat4.identity( M4A )
    M4A[5] = -1
    M4A[13] = 2 * this.groundHeight

    mat4.multiply( M4B, M4A, cam._wmatrix );
    mat4.invert( M4B, M4B );
    mat4.multiply( cam._viewProj, cam.lens.getProjection(), M4B );

  }
  
  restoreCamera( cam:Camera ){
    cam._viewProj.set( this.viewprojCopy );
  }

}

