
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";

import SplitText from "@/utils/SplitText";

import AnimateSprite from "@/components/AnimateSprite/AnimateSprite.vue";

import UIBtnPrimary from "@/components/UIBtn/UIBtnPrimary.vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setCurrentVoice } from "@/store/modules/Sound";
import Delay from "@/core/Delay";

export default defineComponent({
  components: {
    AnimateSprite,
  },

  data() {
    return {
      threshold: 0,
      split: null,
      tl: null,
    };
  },

  computed: {
    btnDoneLabel() {
      return "Skip";
    },

    isStateTuto(): boolean {
      return this.state?.value["museum"] === "tuto";
    },
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  mounted() {
    if (this.isStateTuto) {
      this.$nextTick(() => {
        this.createTimeline();
        this.tl?.pause();
      });
    }
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );

      tl.add((this.$refs.btnDone as typeof UIBtnPrimary)?.enter(), "-=0.25");
    },

    async afterEnter() {
      AudioManager.play(AUDIO_ID.VOICE_MANIFESTO);
      setCurrentVoice(AUDIO_ID.VOICE_MANIFESTO);
      if (this.isStateTuto) {
        if (!this.tl) {
          this.createTimeline();
        } else {
          this.tl?.restart();
        }
        this.tl?.play();

        await Delay(200);
        const container = this.$refs.outerContainer as HTMLElement;
        // container.style.display = "none";
        container.style.width = "0";
        await Delay(200);
        container.style.width = "auto";
      }
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        el,
        {
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    onDone() {
      this.tl?.pause();

      (this.$refs.btnDone as typeof UIBtnPrimary)
        ?.leave()
        .eventCallback("onReverseComplete", () => {
          this.send("ENTER");
        });
    },

    splitText() {
      this.split?.revert();

      this.split = new SplitText(
        Array.from(this.$el.querySelectorAll(".split-text")),
        {
          type: "lines",
          linesClass: "line line++",
          lineThreshold: 0.5,
        }
      );
    },

    createTimeline() {
      this.tl?.kill();
      this.tl?.clear();
      this.tl = null;

      this.splitText();

      const tl: gsap.core.Timeline = gsap.timeline({
        onComplete: this.onDone,
      });

      // tl.set(this.$refs.copy as Element, {
      //   y: this.$el.querySelector(".split-text").clientHeight,
      // });

      const splitTextCollection = Array.from(
        this.$el.querySelectorAll(".split-text"),
        (split: HTMLElement) => {
          const lines: Element[] = Array.from(split.querySelectorAll(".line"));
          const stagger: number = 0.1;
          const duration: number = 1;
          const position = parseFloat(split.dataset.twStart);

          tl.fromTo(
            lines,
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration,
              ease: "quart.inOut",
              stagger,
            },
            position
          );

          tl.to(
            this.$refs.copy as Element,
            {
              y: `-=${split.clientHeight}`,
              duration:
                parseFloat(split.dataset.twEnd) -
                parseFloat(split.dataset.twStart),
              ease: "linear",
            },
            position
          );

          return split;
        }
      );

      this.tl = tl;
    },
  },
});
