import { Texture, TextureType } from "nanogl/texture-base";
import { Resource } from "./Resource";
import Texture2D from "nanogl/texture-2d";
import TextureCube from "nanogl/texture-cube";
import { ITextureRequest, ITextureRequestLod } from "./TextureRequest";
import { BytesResource, loadBytes } from "./Net";
import { TextureCodecs } from "./TextureCodec";
import { IGLContextProvider } from "./IGLContextProvider";
import { GLContext } from "nanogl/types";
import TexturesLoader from "./TextureLoader";
import ResourceGroup from "./ResourceGroup";


export enum TextureWrap {
  CLAMP,
  REPEAT,
  MIRROR,
}

class TextureFiltering {
  smooth: boolean = false
  mip: boolean = false
  mipl: boolean = false

  wrap: TextureWrap = TextureWrap.REPEAT

  setFilter(smooth: boolean = false, mip: boolean = false, mipl: boolean = false) {
    this.smooth = smooth;
    this.mip = mip;
    this.mipl = mipl;
  }

}

class TextureOptions {
  bbc: boolean = true
  flipY: boolean = false
  genMips: boolean = false
  filtering: TextureFiltering = new TextureFiltering();
}





export abstract class BaseTextureResource<T extends Texture = Texture> extends Resource<T> {

  texture: T = null;

  glp: IGLContextProvider;

  protected _request: ITextureRequest;
  private _sourceGroup: ResourceGroup<ArrayBuffer>



  static _tlmap: WeakMap<GLContext, TexturesLoader> = new WeakMap()
  static getTextureLoader(gl: GLContext): TexturesLoader {
    let res = this._tlmap.get(gl);
    if (!res) {
      res = new TexturesLoader(gl)
      this._tlmap.set(gl, res);
    }
    return res;
  }


  constructor(request: ITextureRequest, glp: IGLContextProvider ) {
    super();
    this.glp = glp;
    this._request = request;
  }

  set request(request: ITextureRequest) {
    // TODO: invalidate stuff here
    this._request = request;
  }

  get request(): ITextureRequest {
    return this._request;
  }

  get value(): T {
    return this.texture;
  }


  doLoad(): Promise<T> {
    this.texture = this.createTexture()
    return this.loadLevelAsync(0);
  }


  doUnload() {
    this.texture.dispose()
    this.texture = null;
    this._sourceGroup?.unload()
  }


  async loadLevelAsync(level: number): Promise<T> {

    const loader = BaseTextureResource.getTextureLoader(this.glp.gl);

    const extensions = loader.extTextures;
    // find which source is available based on codecs and extensions
    // TODO: test if null
    const [codec, source] = await TextureCodecs.getCodecForRequest(this._request, extensions);
    

    // load files for a given request source based on lod
    await this.loadSourceLod(source.lods[level]);
    // run codec to create or setup TextureData
    await codec.decodeLod(source, level, extensions);
    // use texture loader to upload data to texture
    loader.upload((this as any), source.datas);

    // for (let i = 0; i < source.lods[level].files.length; i++) {
    //   this.group.removeResourceByName(source.lods[level].files[i]);
    // }

    return this.texture;

  }


  async loadSourceLod(lod: ITextureRequestLod): Promise<any> {

    this._sourceGroup?.unload()

    this._sourceGroup = new ResourceGroup()

    for (let i = 0; i < lod.files.length; i++) {
      const res = new BytesResource(lod.files[i]);
      this._sourceGroup.add(res as any)
    }

    const buffers = await this._sourceGroup.load();
    lod.buffers = buffers;
    return buffers;
  }

  abstract createTexture(): T;

}


export class TextureResource extends BaseTextureResource<Texture2D> {

  createTexture(): Texture2D {
    // const tex = new Texture2D(this.glp.gl);
    return new Texture2D(this.glp.gl);
  }

}



export class TextureCubeResource extends BaseTextureResource<TextureCube> {

  createTexture(): TextureCube {
    return new TextureCube(this.glp.gl);
  }


  async loadLevelAsync(level: number): Promise<TextureCube> {

    const loader = BaseTextureResource.getTextureLoader(this.glp.gl);
    const extensions = loader.extTextures;
    // find which source is available based on codecs and extensions
    // TODO: test if null
    const [codec, source] = await TextureCodecs.getCodecForRequest(this._request, extensions);

    for (let i = 0; i < source.lods.length; i++) {
      // load files for a given request source based on lod
      await this.loadSourceLod(source.lods[i]);
      // console.log(source.datas);
    }
    // run codec to create or setup TextureData
    await codec.decodeCube(source, extensions);
    // use texture loader to upload data to texture
    loader.upload(this as any, source.datas);


    //
    return this.texture;

  }

}