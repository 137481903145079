import Node from "nanogl-node";
import Camera from "nanogl-camera";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import GLConfig from "nanogl-state/config"
import { vec2, vec3 } from "gl-matrix";

import Scene from "@/webgl/Scene";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import PlaneGeometry from "@/webgl/lib/PlaneGeometry";
import artworkBgVert from "@/webgl/glsl/artworkBg/artworkBg.vert";
import artworkBgFrag from "@/webgl/glsl/artworkBg/artworkBg.frag";
import artworkYouVert from "@/webgl/glsl/artworkBg/artworkYou.vert";
import artworkYouFrag from "@/webgl/glsl/artworkBg/artworkYou.frag";
import artworkFaceVert from "@/webgl/glsl/artworkBg/artworkFace.vert";
import artworkFaceFrag from "@/webgl/glsl/artworkBg/artworkFace.frag";
import artworkHexVert from "@/webgl/glsl/artworkBg/artworkHex.vert";
import artworkHexFrag from "@/webgl/glsl/artworkBg/artworkHex.frag";
import artworkShadowVert from "@/webgl/glsl/artworkBg/artworkShadow.vert";
import artworkShadowFrag from "@/webgl/glsl/artworkBg/artworkShadow.frag";

import ArtworkFaces from "@/api/artworkfaces";
import { UserCard } from "@/store/modules/UserCard";
import { Viewport } from "@/store/modules/Viewport";
import { noRoll, setFace } from "@/store/modules/Faces";

import { clamp } from "@/webgl/math";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import gsap, { Sine, Quart, Quint } from "gsap";

interface circleType {
  x: number,
  y: number,
  w: number,
  h: number,
  id: number
  add: number,
  roll?: number,
  type: number,
  rotate: number,
  isRoll?: boolean,
  isFace?: boolean,
  faceId?: number
}

const CELLSIZE = 65
const CELLSIZE_MOBILE = 25
const W_SLOTS = 20
const H_SLOTS = 20
const SCROLL_SPEED = 6.5
const BASE_SIZE = 2
const MARGIN_FACES_LOAD = 10

const FACES_LIST = [
  'anger01',
  'anger02',
  'disgust01',
  'disgust02',
  'fear01',
  'fear02',
  'happiness01',
  'happiness02',
  'sadness01',
  'sadness02',
  'surprise01',
  'surprise02']

export default class ArtworkBg {
  faces: circleType[]
  rollV: number
  aspect: number
  canvas: HTMLCanvasElement
  origin: number[]
  loaded: boolean
  youTex: Texture2D
  started: boolean
  wallTex: Texture2D
  circles: circleType[]
  closest: number
  texture: Texture2D
  zoomVal: number = 0
  youNode: Node
  youGeom: PlaneGeometry
  offsetX: number
  zoomBase: number
  faceTexs: Texture2D[]
  faceGeom: PlaneGeometry
  faceNode: Node
  glconfig: GLConfig
  faceRoll: any
  aspectVec: vec2
  shadowTex: Texture2D
  planeGeom: PlaneGeometry
  allPieces: number[][]
  youAspect: number
  zoomEffect: number
  faceRotate: number
  lerporigin: number[]
  placements: number[][]
  circlesImg: string[]
  hexagonTex: Texture2D
  youProgram: Program
  faceProgram: Program
  isScrolling: boolean
  hexShadowTex: Texture2D
  introStarted: boolean
  glconfigMask: GLConfig
  planeProgram: Program
  zoomBaseLerp: number
  mouseOnCanvas: boolean
  reflectionTex: Texture2D
  currentFaceId: number
  mousePosition: number[]
  fullScreenVal: number
  shadowProgram: Program
  glconfigMasked: GLConfig
  hexagonProgram: Program
  zoomEffectLerp: number
  generalOpacity: number
  hexagonArtworkSize: number
  isTransitionPlaying: boolean
  glconfigMaskedReverse: GLConfig
  hexagonFullScreenSize: number
  transitionNeedsShadow: boolean

  cellsize: number

  constructor(public scene: Scene, public camera: Camera, public root: Node, public museumCamera: Camera,
    private resources: ResourceGroup, private onIntroOver: Function) {
    const gl = this.scene.gl

    this.cellsize = Viewport.isDesktop ? CELLSIZE : CELLSIZE_MOBILE

    this.planeGeom = new PlaneGeometry(gl, 1, 1, 1, 1)
    this.planeProgram = new Program(gl, artworkBgVert(), artworkBgFrag(), this.scene.programs.getGlobalDefinitions())
    this.faceNode = new Node()
    this.faceGeom = new PlaneGeometry(gl, 1, 1, 10, 10)
    this.faceProgram = new Program(gl, artworkFaceVert(), artworkFaceFrag(), this.scene.programs.getGlobalDefinitions())
    this.youNode = new Node()
    this.youGeom = new PlaneGeometry(gl, 1, 1, 10, 10)
    this.youProgram = new Program(gl, artworkYouVert(), artworkYouFrag(), this.scene.programs.getGlobalDefinitions())
    this.hexagonProgram = new Program(gl, artworkHexVert(), artworkHexFrag(), this.scene.programs.getGlobalDefinitions())
    this.shadowProgram = new Program(gl, artworkShadowVert(), artworkShadowFrag(), this.scene.programs.getGlobalDefinitions())

    this.canvas = document.createElement("canvas")

    this.aspectVec = vec2.create()
    this.started = false

    this.texture = new Texture2D(gl, gl.RGBA)
    this.texture.setFilter(true, false, false)
    this.texture.clamp()

    this.shadowTex = new Texture2D(gl, gl.RGBA)
    this.shadowTex.fromImage(this.resources.get("shadow"))
    this.shadowTex.setFilter(true, false, false)
    this.shadowTex.clamp()

    const youTextSrc = Viewport.isDesktop ? 'youText' : 'youTextMobile'
    const youText = this.resources.get(youTextSrc)
    this.youAspect = youText.height / youText.width
    this.youTex = new Texture2D(gl, gl.RGBA)
    this.youTex.fromImage(youText)
    this.youTex.setFilter(true, false, false)
    this.youTex.clamp()

    this.wallTex = this.scene.resources.get("wall-tex")
    this.wallTex.bind()
    this.wallTex.repeat()

    this.hexagonTex = new Texture2D(gl, gl.RGBA)
    this.hexagonTex.fromImage(this.resources.get("hexagon"))
    this.hexagonTex.setFilter(true, false, false)
    this.hexagonTex.clamp()

    this.hexShadowTex = new Texture2D(gl, gl.RGBA)
    this.hexShadowTex.fromImage(this.resources.get("hexagon-shadow"))
    this.hexShadowTex.setFilter(true, false, false)
    this.hexShadowTex.clamp()

    this.reflectionTex = new Texture2D(gl, gl.RGBA)
    this.reflectionTex.fromImage(this.resources.get("reflection"))
    this.reflectionTex.bind()
    this.reflectionTex.mirror()

    this.loaded = false
    this.closest = -1
    this.origin = [0, 0]
    this.lerporigin = [0, 0]

    this.zoomVal = 0
    this.zoomBase = 0
    this.zoomBaseLerp = 0
    this.zoomEffect = 0
    this.zoomEffectLerp = 0
    this.faceRotate = 0
    this.rollV = 0

    this.isScrolling = false
    this.mouseOnCanvas = false

    this.allPieces = []

    this.circlesImg = []

    this.circles = []
    this.placements = []
    this.faces = []

    this.currentFaceId = 0
    this.faceRoll = {
      id: -1,
      x: -1,
      y: -1,
      width: 0
    }

    this.mousePosition = [0, 0]

    this.generalOpacity = 0
    this.offsetX = 0

    this.hexagonArtworkSize = 1
    this.hexagonFullScreenSize = 1
    this.fullScreenVal = 0
    this.isTransitionPlaying = false
    this.transitionNeedsShadow = false

    this.root._parent.add(this.faceNode)

    this.faceTexs = FACES_LIST.map(s => {
      const t = new Texture2D(gl, gl.RGBA)
      t.fromImage(this.resources.get(s))
      t.bind()
      t.clamp()
      t.setFilter(true, false, false)
      return t
    })

    this.circlesImg = [
      'circle01', 'circle02', 'circle03', 'circle04', 'circleSmall'
    ]

    this.glconfigMask = new GLConfig();
    this.glconfigMask
      .enableStencil(true)
      .stencilFunc(gl.ALWAYS, 1, 0xFF)
      .stencilOp(gl.KEEP, gl.KEEP, gl.REPLACE)
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.glconfigMasked = new GLConfig();
    this.glconfigMasked
      .enableStencil(true)
      .stencilFunc(gl.EQUAL, 1, 0xFF)
      .stencilOp(gl.KEEP, gl.KEEP, gl.KEEP)
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.glconfigMaskedReverse = new GLConfig();
    this.glconfigMaskedReverse
      .enableStencil(true)
      .stencilFunc(gl.NOTEQUAL, 1, 0xFF)
      .stencilOp(gl.KEEP, gl.KEEP, gl.KEEP)
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.glconfig = new GLConfig();
    this.glconfig
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)


    const hasMyFace = UserCard.guid !== ''
    if (hasMyFace && ArtworkFaces.occurence.myFace === undefined) {
      ArtworkFaces.occurence.addUserFace({
        age: UserCard.age,
        guid: UserCard.guid,
        mood: UserCard.emotion,
        mantra: UserCard.mantraId.toString(),
        country: UserCard.country,
        isMyFace: true
      })
    }

    this.loaded = true
    this.generatePiece(0, 0, 0, hasMyFace)
    this.drawTex()
    this.placeAround()
  }

  getFaceMood(id: number) {
    const face = ArtworkFaces.occurence.faces[id]
    const mood = face.mood
    const facesMood = FACES_LIST.filter((face) => face.includes(mood))
    const number = facesMood.length
    const index = Math.floor(Math.random() * number)
    const type = FACES_LIST.indexOf(facesMood[index])

    return type === -1 ? Math.floor(Math.random() * FACES_LIST.length) : type
  }

  getNextFace() {
    this.currentFaceId = (this.currentFaceId + 1) % ArtworkFaces.occurence.faces.length
    if (this.currentFaceId === ArtworkFaces.occurence.faces.length - MARGIN_FACES_LOAD) {
      ArtworkFaces.occurence.loadFaces()
    }
  }

  rollFace(index: number, x: number = -1, y: number = -1, width: number = 0) {
    if (index === this.faceRoll.id && x === this.faceRoll.x && y === this.faceRoll.y && width === this.faceRoll.width) return

    this.faceRoll = {
      id: index,
      x,
      y,
      width
    }

    if (index === -1) {
      noRoll()
      return
    }

    const face = ArtworkFaces.occurence.faces[index]
    const screenX = x + this.canvas.width / 2
    const screenY = y + this.canvas.height / 2
    setFace({
      x: screenX,
      y: screenY,
      age: face.age,
      guid: face.guid,
      mood: face.mood,
      width,
      mantra: face.mantra,
      country: face.country,
      isMyFace: face.isMyFace
    })
  }

  centerMyFace() {
    const myFaceId = this.circles.findIndex(c => c.faceId !== null && ArtworkFaces.occurence.faces[c.faceId].isMyFace)
    const center = this.circles.reduce((acc, c, id) => {
      if (c.w < this.cellsize * BASE_SIZE) return acc
      const x = c.x + c.w / 2
      const y = c.y + c.h / 2
      const dist = Math.sqrt(x * x + y * y)

      return (acc.dist === -1
        || (dist < acc.dist && (acc.dist > this.cellsize * 2 || acc.circle.w === c.w))
        || (dist < acc.dist + this.cellsize * 2 && c.w > acc.circle.w))
        ? { dist, circle: c, id }
        : acc

    }, { dist: -1, circle: this.circles[0], id: 0 })

    const cloneMyFace = { ...this.circles[myFaceId] }
    const cloneCenter = { ...center.circle }

    this.circles[myFaceId].faceId = cloneCenter.faceId
    this.circles[myFaceId].isFace = cloneCenter.isFace
    this.circles[myFaceId].type = cloneCenter.type

    this.circles[center.id].faceId = cloneMyFace.faceId
    this.circles[center.id].isFace = cloneMyFace.isFace
    this.circles[center.id].type = cloneMyFace.type

    this.lerporigin[0] = this.origin[0] = - (cloneCenter.x + cloneCenter.w * 0.5)
    this.lerporigin[1] = this.origin[1] = - (cloneCenter.y + cloneCenter.h * 0.5)

    for (let i = 0; i < this.circles.length; i++) {
      const circle = this.circles[i]
      if (!circle.isFace || i === center.id) continue

      const x = circle.x + circle.w / 2
      const y = circle.y + circle.h / 2
      const centerX = cloneCenter.x + cloneCenter.w / 2
      const centerY = cloneCenter.y + cloneCenter.h / 2

      const radius = Math.max(cloneCenter.w, cloneCenter.h) / 2
      const minDist = radius + this.cellsize * (BASE_SIZE + 1)

      const dist = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2))
      if (dist <= minDist) {
        this.circles[i].faceId = null
        this.circles[i].isFace = false
        this.circles[i].type = Math.floor(Math.random() * 3)
      }
    }
  }

  preRender() {
    if (!this.loaded) return
    this.origin[0] += (this.lerporigin[0] - this.origin[0]) * 0.1
    this.origin[1] += (this.lerporigin[1] - this.origin[1]) * 0.1

    this.zoomBase += (this.zoomBaseLerp - this.zoomBase) * 0.1
    this.zoomEffect += (this.zoomEffectLerp - this.zoomEffect) * 0.05
    this.zoomVal = this.zoomBase + this.zoomEffect

    this.faces = []
    this.drawTex()
  }

  drawTex() {
    const closest = this.closest
    let minDistance = 1000000
    const toDelete = []

    for (let i = 0; i < this.placements.length; i++) {
      if (this.placements[i] === null) continue
      const x = this.origin[0] + this.placements[i][0]
      const y = this.origin[1] + this.placements[i][1]

      const dist = Math.sqrt(x * x + y * y)
      if (dist > W_SLOTS * this.cellsize * 3) toDelete.push(i)
      if (dist < minDistance) {
        minDistance = dist
        this.closest = i
      }
    }

    for (let i = 0; i < toDelete.length; i++) {
      this.removePiece(toDelete[i])
    }

    const ctx = this.canvas.getContext('2d')
    ctx.fillStyle = 'black'
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

    this.drawPiece()

    this.texture.fromImage(this.canvas)

    if (closest !== this.closest) {
      this.placeAround()
    }
  }

  placeGrid(x, y) {
    if (!this.alreadyExist(x, y)) this.generatePiece(this.placements.length, x, y)
  }

  placeAround() {
    //top left
    this.placeGrid(
      this.placements[this.closest][0] + -1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + -1 * W_SLOTS * this.cellsize
    )

    //top
    this.placeGrid(
      this.placements[this.closest][0] + 0 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + -1 * W_SLOTS * this.cellsize
    )

    //top right
    this.placeGrid(
      this.placements[this.closest][0] + 1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + -1 * W_SLOTS * this.cellsize
    )

    //left
    this.placeGrid(
      this.placements[this.closest][0] + -1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + 0 * W_SLOTS * this.cellsize
    )

    //right
    this.placeGrid(
      this.placements[this.closest][0] + 1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + 0 * W_SLOTS * this.cellsize
    )

    //bottom left
    this.placeGrid(
      this.placements[this.closest][0] + -1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + 1 * W_SLOTS * this.cellsize
    )

    //bottom
    this.placeGrid(
      this.placements[this.closest][0] + 0 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + 1 * W_SLOTS * this.cellsize
    )

    //bottom right
    this.placeGrid(
      this.placements[this.closest][0] + 1 * W_SLOTS * this.cellsize,
      this.placements[this.closest][1] + 1 * W_SLOTS * this.cellsize
    )
  }

  alreadyExist(x, y) {
    for (let i = 0; i < this.placements.length; i++) {
      if (this.placements[i] === null) continue
      if (Math.floor(this.placements[i][0] * 100) / 100 === Math.floor(x * 100) / 100 &&
        Math.floor(this.placements[i][1] * 100) / 100 === Math.floor(y * 100) / 100)
        return true
    }

    return false
  }

  isSpotEmpty(piece, x, y) {
    return piece[y * W_SLOTS + x] === -1
  }

  hasEnoughSpace(size, piece, currentX, currentY) {
    for (let y = 0; y < size; y++) {
      if (!this.isSpotEmpty(piece, currentX, currentY + y)) {
        return false
      }
    }
    return true
  }

  prevSpotsEmpty(piece: number[], maxX: number, currentX: number, currentY: number, size: number) {
    // top right slot if end of grid
    if (currentX + size >= maxX) {
      if (this.isSpotEmpty(piece, maxX - 1, currentY - 1)) {
        return true
      }
    }

    // left slot & top slot
    return this.isSpotEmpty(piece, currentX - 1, currentY) || this.isSpotEmpty(piece, currentX, currentY - 1)
  }

  willBe3x3(piece: number[], maxX: number, maxY: number, currentX: number, currentY: number, size: number) {
    // if there is no next line or column -> 2x2
    if (currentX + size >= maxX || currentY + size >= maxY) {
      return false
    }

    // if next slot is taken -> 2x2
    if (!this.isSpotEmpty(piece, currentX, currentY + size)) {
      return false
    }

    // if there are only 3 spots left -> 3x3
    if (maxX - currentX === BASE_SIZE + 1 || maxY - currentY === BASE_SIZE + 1) {
      return true
    }

    // if there are only 4 spots left -> 2x2
    if (maxX - currentX === BASE_SIZE * 2 || maxY - currentY === BASE_SIZE * 2) {
      return false
    }

    // else -> can be 2x2 or 3x3
    return Math.random() > 0.5
  }

  generatePiece(id, startX, startY, firstTime?: boolean) {
    const piece = this.allPieces[id] = []
    this.placements[id] = [startX, startY]
    for (let i = 0; i < W_SLOTS * H_SLOTS; i++) {
      piece[i] = -1
    }
    const centerX = W_SLOTS / 2
    const centerY = H_SLOTS / 2
    let currentX = 0
    let currentY = 0

    //Draw big one first 2x2 or 3x3
    while (currentX + 1 < centerX * 2) {
      let size = BASE_SIZE

      if (this.hasEnoughSpace(size, piece, currentX, currentY)) {
        if (this.willBe3x3(piece, centerX * 2, centerY * 2, currentX, currentY, size))
          size++

        const isFace = size === BASE_SIZE ? Math.random() > 0.85 : Math.random() > 0.65
        const type = isFace
          ? this.getFaceMood(this.currentFaceId)
          : Math.floor(Math.random() * 3)
        this.circles.push({
          x: startX + (-centerX + currentX) * this.cellsize,
          y: startY + (-centerY + currentY) * this.cellsize,
          w: size * this.cellsize,
          h: size * this.cellsize,
          add: Math.random() * size * this.cellsize * 0.1,
          roll: 0,
          isRoll: false,
          type,
          rotate: isFace ? -Math.PI * 0.25 + Math.PI * 0.5 * Math.random() : Math.random() * Math.PI * 2,
          id,
          isFace,
          faceId: isFace ? this.currentFaceId : null
        })

        for (let x = 0; x < size; x++) {
          for (let y = 0; y < size; y++) {
            piece[(currentY + y) * W_SLOTS + currentX + x] = type
          }
        }

        if (isFace) {
          this.getNextFace()
        }
      }

      if (currentY + size >= centerY * 2) {
        currentX++
        currentY = 0
      } else currentY++
    }

    // Then fill the rest with 1x1
    for (let y = 0; y < H_SLOTS; y++) {
      for (let x = 0; x < W_SLOTS; x++) {
        const s = y * W_SLOTS + x
        if (piece[s] === -1 && Math.random() > 0.25) {
          const type = 4
          piece[s] = type
          this.circles.push({
            x: startX + (-centerX + x) * this.cellsize,
            y: startY + (-centerY + y) * this.cellsize,
            w: 1 * this.cellsize,
            h: 1 * this.cellsize,
            add: Math.random() * 1 * this.cellsize * 0.2,
            type,
            rotate: Math.random() * Math.PI * 2,
            id
          })
        }
      }
    }

    if (firstTime) {
      this.centerMyFace()
    }
  }

  removePiece(id) {
    this.circles = this.circles.filter(c => c.id !== id)

    this.placements[id] = null
    this.allPieces[id] = null
  }

  drawPiece() {
    const ctx = this.canvas.getContext('2d')
    const zoomV = (1 + this.zoomVal * 0.3)

    for (let i = 0; i < this.circles.length; i++) {
      const c = this.circles[i];

      if (!this.inBoundaries(c) || c.isRoll) continue
      if (c.isFace) {
        this.faces.push(c)
        continue
      }

      const image = this.circlesImg[c.type]
      const w = (c.w + c.add) * zoomV
      const h = (c.h + c.add) * zoomV
      ctx.translate(
        this.canvas.width / 2 + this.origin[0] * zoomV + c.x * zoomV + w * 0.5,
        this.canvas.height / 2 + this.origin[1] * zoomV + c.y * zoomV + h * 0.5)
      ctx.rotate(c.rotate)
      ctx.drawImage(this.resources.get(image), -w * 0.5, -h * 0.5, w, h)
      ctx.resetTransform()
    }

    ctx.resetTransform()
  }

  inBoundaries(circle) {
    const x = this.origin[0]
    const y = this.origin[1]
    const boundX = this.canvas.width * 0.75
    const boundY = this.canvas.height * 0.75
    return circle.x + x > -boundX && circle.x + x < boundX && circle.y + y > -boundY && circle.y + y < boundY
  }

  setHexagonSize() {
    const p1 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "UnderstandPoint1")
    const p2 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "UnderstandPoint2")
    const p3 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "UnderstandPoint3")
    const p4 = this.scene.museumScene.gltf.getElementByName(GltfTypes.NODE, "UnderstandPoint4")
    this.museumCamera.updateWorldMatrix()
    p1.updateWorldMatrix()
    p2.updateWorldMatrix()
    p3.updateWorldMatrix()
    p4.updateWorldMatrix()

    const pos1 = vec3.create()
    const pos2 = vec3.create()
    const pos3 = vec3.create()
    const pos4 = vec3.create()
    vec3.set(pos1, p1._wmatrix[12], p1._wmatrix[13], p1._wmatrix[14])
    vec3.set(pos2, p2._wmatrix[12], p2._wmatrix[13], p2._wmatrix[14])
    vec3.set(pos3, p3._wmatrix[12], p3._wmatrix[13], p3._wmatrix[14])
    vec3.set(pos4, p4._wmatrix[12], p4._wmatrix[13], p4._wmatrix[14])
    vec3.transformMat4(pos1, pos1, this.museumCamera._viewProj)
    vec3.transformMat4(pos2, pos2, this.museumCamera._viewProj)
    vec3.transformMat4(pos3, pos3, this.museumCamera._viewProj)
    vec3.transformMat4(pos4, pos4, this.museumCamera._viewProj)

    const windowHeight = this.canvas.height
    const windowWidth = this.canvas.width

    const y1 = (-1 * pos1[1] + 1) / 2 * windowHeight
    const y2 = (-1 * pos2[1] + 1) / 2 * windowHeight
    const x1 = (pos3[0] + 1) / 2 * windowWidth
    const x2 = (pos4[0] + 1) / 2 * windowWidth

    const height = Math.abs(y1 - y2)
    const width = Math.abs(x1 - x2)
    const ratio = height * 1.02 / width

    const fullScreenHeight = 2
    const fullScreenWidth = windowWidth * ratio / windowHeight

    this.hexagonArtworkSize = height * 1.01 / windowHeight
    this.hexagonFullScreenSize = Math.max(fullScreenHeight, fullScreenWidth)
  }

  render() {
    const M4 = this.camera.getMVP(this.root._wmatrix);

    if (this.isTransitionPlaying) {
      this.scene.glstate.push(this.glconfigMask)
      this.scene.glstate.apply()

      this.hexagonProgram.use()
      this.hexagonProgram.uMVP(M4);
      this.hexagonProgram.uTex(this.hexagonTex);
      this.hexagonProgram.uAspect([this.canvas.width / this.canvas.height, 1]);
      this.hexagonProgram.uSize(this.hexagonArtworkSize);
      this.hexagonProgram.uFullScreenSize(this.hexagonFullScreenSize);
      this.hexagonProgram.uFullScreen(this.fullScreenVal);
      this.hexagonProgram.uOffsetX(this.offsetX);
      this.hexagonProgram.uMobile(Viewport.isDesktop ? 0 : 1);
      this.planeGeom.bind(this.hexagonProgram);
      this.planeGeom.draw();

      this.scene.glstate.pop()

      this.scene.glstate.push(this.glconfigMasked)
      this.scene.glstate.apply()
    } else {
      this.scene.glstate.push(this.glconfig)
      this.scene.glstate.apply()
    }

    this.planeProgram.use()
    this.planeProgram.uMVP(M4);
    this.planeProgram.uTex(this.texture);
    this.planeProgram.uRoll(this.rollV);
    this.planeProgram.uGeneralOpacity(this.generalOpacity);
    this.planeProgram.uOffsetX(this.offsetX);
    this.planeProgram.uWall(this.wallTex);
    this.planeProgram.uZoom(this.zoomVal);
    this.planeProgram.uOrigin([this.origin[0] / this.canvas.width, this.origin[1] / this.canvas.height]);
    this.planeProgram.uReflection(this.reflectionTex);
    this.planeProgram.uAspect(this.aspectVec);
    this.planeProgram.uMouse([
      this.mousePosition[0] / this.canvas.width,
      this.mousePosition[1] / this.canvas.height
    ]);

    this.planeGeom.bind(this.planeProgram);
    this.planeGeom.draw();

    this.drawFaces()

    this.scene.glstate.pop()

    if (this.transitionNeedsShadow) {
      const M4Shadow = this.camera.getMVP(this.root._wmatrix);
      this.scene.glstate.push(this.glconfigMaskedReverse)
      this.scene.glstate.apply()

      this.shadowProgram.use()
      this.shadowProgram.uMVP(M4Shadow);
      this.shadowProgram.uTex(this.hexShadowTex);
      this.shadowProgram.uAspect([this.canvas.width / this.canvas.height, 1]);
      this.shadowProgram.uSize(this.hexagonArtworkSize);
      this.shadowProgram.uFullScreenSize(this.hexagonFullScreenSize);
      this.shadowProgram.uFullScreen(this.fullScreenVal);
      this.shadowProgram.uOffsetX(this.offsetX);
      this.shadowProgram.uMobile(Viewport.isDesktop ? 0 : 1);
      this.planeGeom.bind(this.shadowProgram);
      this.planeGeom.draw();

      this.scene.glstate.pop()
    }
  }

  drawFaces() {
    const zoomV = (1 + this.zoomVal * 0.3)
    let isRoll = false


    for (let i = 0; i < this.faces.length; i++) {
      const f = this.faces[i];
      const ox = this.origin[0] * zoomV
      const oy = this.origin[1] * zoomV

      const fx = f.x * zoomV
      const fy = f.y * zoomV
      const fw = (f.w + f.add) * zoomV
      const fh = (f.h + f.add) * zoomV
      const x = fx + ox + this.canvas.width * this.offsetX
      const y = fy + oy

      this.faceNode.x = (x + fw * 0.5) / (this.canvas.width / 2)
      this.faceNode.y = -(y + fh * 0.5) / (this.canvas.height / 2)
      if (this.faceNode.x < -1.4 || this.faceNode.x > 1.4 || this.faceNode.y < -1.4 || this.faceNode.y > 1.4)
        continue

      if (this.mousePosition[0] > x &&
        this.mousePosition[0] < x + fw &&
        this.mousePosition[1] > y &&
        this.mousePosition[1] < y + fh &&
        this.mouseOnCanvas &&
        !this.isScrolling &&
        this.started) {
        f.roll += (1 - f.roll) * 0.1
        isRoll = true
        this.rollFace(f.faceId, x, y, fw)
      } else f.roll += (0 - f.roll) * 0.1
      vec3.set(this.faceNode.scale,
        (fw / this.canvas.width * 2 + f.roll * 0.07 * this.aspect) * 1.5,
        (fh / this.canvas.height * 2 + f.roll * 0.07) * 1.5, 1)

      this.faceNode.invalidate()
      this.faceNode.updateWorldMatrix()

      const M4Face = this.camera.getMVP(this.faceNode._wmatrix);
      this.faceProgram.use()
      this.faceProgram.uMVP(M4Face);
      this.faceProgram.uTime(this.scene.time * 3)
      this.faceProgram.uRoll(f.roll)
      this.faceProgram.uShadow(this.shadowTex)
      this.faceProgram.uTex(this.faceTexs[f.type])
      this.faceProgram.uRotate(this.faceRotate)
      this.faceProgram.uGeneralRoll(this.rollV)
      this.faceProgram.uGeneralOpacity(this.generalOpacity);

      this.faceGeom.bind(this.faceProgram);
      this.faceGeom.draw();

      if (ArtworkFaces.occurence.faces[f.faceId].isMyFace) {
        const size = Viewport.isDesktop ? 2.5 : 2
        const width = this.cellsize * size * zoomV
        const height = width * this.youAspect

        this.youNode.x = Viewport.isDesktop
          ? (x + fw * 0.5 + width * 0.5) / (this.canvas.width / 2)
          : (x - width * 0.5) / (this.canvas.width / 2)
        this.youNode.y = Viewport.isDesktop
          ? -(y - height * 0.5) / (this.canvas.height / 2)
          : -(y + fh * 0.5 - height * 0.4) / (this.canvas.height / 2)
        this.youNode.z = 0.1
        vec3.set(this.youNode.scale,
          (width / this.canvas.width * 2),
          (height / this.canvas.height * 2),
          1)
        this.youNode.invalidate()
        this.youNode.updateWorldMatrix()

        const M4You = this.camera.getMVP(this.youNode._wmatrix);
        this.youProgram.use()
        this.youProgram.uMVP(M4You);
        this.youProgram.uTex(this.youTex)
        this.youProgram.uRoll(f.roll)
        this.youProgram.uGeneralRoll(this.rollV)
        this.youProgram.uGeneralOpacity(this.generalOpacity)

        this.youGeom.bind(this.youProgram);
        this.youGeom.draw();
      }
    }

    let rv = isRoll ? 1 : 0
    this.rollV += (rv - this.rollV) * 0.1
    if (!isRoll) this.rollFace(-1)
  }

  mouseMove(position: number[], target: HTMLElement) {
    this.mousePosition = [
      position[0] - this.canvas.width / 2,
      position[1] - this.canvas.height / 2
    ]
    this.mouseOnCanvas = target === this.scene.glview.canvas
  }

  scroll(scrollValue: number[]) {
    const scrollX = scrollValue[0] * SCROLL_SPEED
    const scrollY = scrollValue[1] * SCROLL_SPEED

    this.lerporigin = [this.origin[0] + scrollX, this.origin[1] + scrollY]
  }

  scrollStart() {
    this.zoomEffectLerp -= 0.1
    this.isScrolling = true
  }

  scrollEnd() {
    this.zoomEffectLerp += 0.1
    this.isScrolling = false
  }

  zoom(zoomValue: number, pinching: boolean) {
    const zoomFactor = pinching ? 0.2 : 0.05

    this.zoomBaseLerp = clamp(this.zoomBaseLerp - zoomValue * zoomFactor, 0, 4)
  }

  resize(w, h) {
    if (!this.canvas) return
    this.canvas.width = w
    this.canvas.height = h
    this.aspect = h / w
    if (w > h) vec2.set(this.aspectVec, 1, h / w)
    else vec2.set(this.aspectVec, w / h, 1)

    if (this.isTransitionPlaying) {
      this.setHexagonSize()
    }
  }

  setup() {
    if (!this.introStarted) {
      this.generalOpacity = 1
      this.started = true
      this.fullScreenVal = 1
      this.isTransitionPlaying = false
    }
  }

  slideIn(from: number, to: number) {
    this.introStarted = true

    const tl = gsap.timeline()
    tl
      .addLabel('start')
      .fromTo(this, {
        offsetX: from,
      }, {
        duration: 2,
        offsetX: to,
        ease: Quart.easeInOut,
        onStart: () => {
          if (Viewport.isDesktop) {
            this.setHexagonSize()
          }
          this.isTransitionPlaying = true
          this.transitionNeedsShadow = true
          this.generalOpacity = 1
        }
      }, 'start')
      .to(this, {
        fullScreenVal: 1,
        duration: 1.5,
        ease: Quint.easeIn,
        onStart: () => {
          this.started = true
        },
        onComplete: () => {
          this.isTransitionPlaying = false
          this.transitionNeedsShadow = false
          this.onIntroOver()
        }
      }, 'start+=0.6')
  }

  slideOut(from: number, to: number) {
    this.started = false
    this.isTransitionPlaying = true
    this.transitionNeedsShadow = true
    this.generalOpacity = 1

    const tl = gsap.timeline()
    return tl
      .set(this, {
        offsetX: from,
      })
      .addLabel('start')
      .to(this, {
        fullScreenVal: 0,
        duration: 1.5,
        ease: Quint.easeOut,
      }, 'start')
      .to(this, {
        duration: 2,
        offsetX: to,
        ease: Quart.easeInOut,
        onComplete: () => {
          this.isTransitionPlaying = false
          this.transitionNeedsShadow = false
          this.generalOpacity = 0
        }
      }, 'start+=0.1')
  }

  fadeIn(delay) {
    this.introStarted = true
    this.generalOpacity = 0
    const tl = gsap.timeline()
    tl
      .to(this, {
        duration: 0.5,
        delay,
        generalOpacity: 1,
        ease: Sine.easeOut,
        onStart: () => {
          this.setHexagonSize()
          this.isTransitionPlaying = true
          this.transitionNeedsShadow = false
        }
      })
      .to(this, {
        fullScreenVal: 1,
        duration: 1.5,
        ease: Quint.easeIn,
        onStart: () => {
          this.started = true
        },
        onComplete: () => {
          this.isTransitionPlaying = false
          this.transitionNeedsShadow = false
          this.onIntroOver()
        }
      })
  }

  fadeOut() {
    this.started = false
    this.setHexagonSize()
    this.isTransitionPlaying = true
    this.transitionNeedsShadow = false

    const tl = gsap.timeline()
    return tl
      .to(this, {
        fullScreenVal: 0,
        duration: 1.5,
        ease: Quint.easeOut,
      })
      .to(this, {
        duration: 0.5,
        generalOpacity: 0,
        ease: Sine.easeOut,
        onComplete: () => {
          this.isTransitionPlaying = false
          this.transitionNeedsShadow = false
        }
      })
  }

  destroy() {
    // dispose programs
    this.planeProgram.dispose()
    this.faceProgram.dispose()
    this.youProgram.dispose()
    // dispose geometries
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.planeGeom = null
    this.faceGeom.ibuffer.dispose()
    this.faceGeom.vbuffer.dispose()
    this.faceGeom = null
    this.youGeom.ibuffer.dispose()
    this.youGeom.vbuffer.dispose()
    this.youGeom = null
    // dispose textures
    this.texture.dispose()
    this.youTex.dispose()
    this.shadowTex.dispose()
    this.reflectionTex.dispose()
    this.faceTexs.forEach(tex => tex.dispose())
    // destroy canvas
    this.canvas.width = 1
    this.canvas.height = 1
    this.canvas = null
  }
}