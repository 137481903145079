import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import PlaneGeometry from "@/webgl/lib/PlaneGeometry";
import GLApp from "@/webgl/main";
import { DEG2RAD } from "@/webgl/math";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import GLConfig from "nanogl-state/config";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import StateHelper from "../StateHelper";
import Background from "./Background";

import vShader from "@/webgl/glsl/background/background.vert";
import fShader from "@/webgl/glsl/background/background.frag";
import Delay from "@/core/Delay";
import { Viewport } from "@/store/modules/Viewport";
import MuseumActivity from "../MuseumActivity";
import gsap, { Sine } from "gsap";

const PLAIN_BEIGE = [236 / 255, 233 / 255, 228 / 255, 1]
/**
 * Noop activity, loading in VUE
 */
export default class BackgroundActivity implements IActivity {

  readonly priority: number = 1;
  readonly name: string = "background";

  stateHelper: StateHelper;

  private _fov: number = 50;

  private _camera: Camera

  private root: Node

  private _resources: ResourceGroup

  private _background: Background

  private glconfig: GLConfig
  private planeGeom: PlaneGeometry
  private bgProgram: Program

  kenBurnsV: number
  kenBurnsT: gsap.core.Tween
  isKenBurns: boolean

  constructor(public scene: Scene) {
    this.root = new Node()
    this.makeCamera()
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    this.stateHelper = new StateHelper([
      { match: 'step_feel.maintitle', enter: this.enterMainTitle },
      { match: 'step_feel.requestCamera', enter: this.enterRequestCamera },
      { match: 'step_feel.requestCamera', exit: this.exitRequestCamera },
      { match: 'step_feel.tuto', enter: this.enterFeelTuto },
      { match: 'step_feel.tuto', exit: this.exitFeelTuto },
      { match: 'step_do.outro', enter: this.enterDoOutro },
      { match: 'step_do.outro', exit: this.exitDoOutro },
      { match: 'step_love.tuto', enter: this.enterLoveTuto },
      { match: 'step_love.tuto', exit: this.exitLoveTuto },
      { match: 'step_love.outro', enter: this.enterLoveOutro },
      { match: 'step_love.outro', exit: this.exitLoveOutro },
      { match: 'step_speak.tuto', enter: this.enterSpeakTuto },
      { match: 'step_speak.default', enter: this.exitSpeakTuto },
      { match: 'step_speak.outro', enter: this.enterSpeakOutro },
      { match: 'step_speak.outro', exit: this.exitSpeakOutro },
      { match: 'step_see.tuto', enter: this.enterSeeTuto },
      { match: 'step_see.default', enter: this.exitSeeTuto },
      { match: 'step_see.outro', enter: this.enterSeeOutro },
      { match: 'step_see.outro', exit: this.exitSeeOutro },
      { match: 'artwork.intro', exit: this.exitArtworkIntro },
      { match: 'artwork.tuto', enter: this.enterArtworkTuto },
      { match: 'artwork.tuto', exit: this.exitArtworkTuto },
      { match: 'artwork.default', exit: this.exitArtworkDefault },
      { match: 'artwork.share', enter: this.enterArtworkShare },
      { match: 'artwork.share', exit: this.exitArtworkShare },
    ])
  }

  load(): Promise<any> {
    this._resources = new ResourceGroup()



    return this._resources.load()
  }

  onStateUpdate(state: AppState): void {
    // if(state.value === "outro") this.enterOutro()
  }

  start() {
    const gl = this.scene.gl

    this.glconfig = new GLConfig();

    this.glconfig
      .enableDepthTest(false)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.planeGeom = new PlaneGeometry(this.scene.gl, 1, 1, 1, 1)
    this.bgProgram = new Program(gl, vShader(), fShader(), this.scene.programs.getGlobalDefinitions())
    this.stateHelper.start()
  }

  stop(): void {
    this.stateHelper.stop()
    this.bgProgram.dispose()
    this.planeGeom.ibuffer.dispose()
    this.planeGeom.vbuffer.dispose()
    this.planeGeom = null

  }

  startXP = () => {
    this._background = new Background(this.planeGeom, this.bgProgram, this.glconfig, this.scene, this._camera, this.root, this.scene.resources.get("video-transition"))
    this.scene.glview.onResize.on(this._resize)
    this._resize()
  }

  stopXP = (): void => {
    console.log("stop background")
    this.scene.glview.onResize.off(this._resize)
    this._background.destroy()
    this._background = null
  }

  enterMainTitle = () => {
    console.log("Background => enterMainTitle")
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    this.startXP()
    this._background.set(null, null, [trans, PLAIN_BEIGE])
    this._background.transition(2)
  }

  enterRequestCamera = () => {
    console.log("Background => enterRequestCamera")
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    this.startXP()
    this._background.set(null, null, [trans, PLAIN_BEIGE])
    this._background.transition(2, () => {
      this._background.transition(3, null, true)
    })
  }

  exitRequestCamera = () => {
    console.log("Background => exitRequestCamera")
    this._background.transition(3, null, false)
  }

  enterFeelTuto = () => {
    if (!this._background) {
      console.log("Background => enterFeelTuto")
      const trans = PLAIN_BEIGE.slice()
      trans[3] = 0
      this.startXP()
      this._background.set(null, null, [trans, PLAIN_BEIGE])
      this._background.transition(2)
    }
  }

  exitFeelTuto = () => {
    console.log("Background => exitFeelTuto")
    // this.killTransition()
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    // this.startXP()
    if (!this._background) this.startXP()
    this._background.set(null, null, [PLAIN_BEIGE, trans])
    this._background.transition(2, this.killTransition, false, 1.5)
  }

  enterDoOutro = () => {
    console.log("Background => enterDoOutro")
    this.startXP()
    this._background.set(this.scene.resources.get("do-end"), null, [null, this.scene.resources.get("do-end")])
    this.resetKenBurns()
    this.startKenBurns()

  }

  exitDoOutro = () => {
    console.log("Background => exitDoOutro")
    // this.startXP()
    this._background.set(this.scene.resources.get("do-end"), null, [null, PLAIN_BEIGE])
    this._background.transition(0, () => { this.resetKenBurns() })
  }

  enterLoveTuto = () => {
    if (!this._background) {
      console.log("Background => enterLoveTuto")
      const trans = PLAIN_BEIGE.slice()
      trans[3] = 0
      this.startXP()
      this._background.set(null, null, [trans, PLAIN_BEIGE])
      this._background.transition(2)
    }
  }

  exitLoveTuto = () => {
    console.log("Background => exitLoveTuto")
    // this.killTransition()
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    // this.startXP()
    this._background.set(null, null, [PLAIN_BEIGE, trans])
    this._background.transition(2, this.killTransition)
  }

  enterLoveOutro = () => {
    console.log("Background => enterLoveOutro")
    this.startXP()
    this._background.set(null, this.scene.resources.get("love-end"), [[1, 1, 1, 0], null])
    this._background.transition(0)
    this.resetKenBurns()
    this.startKenBurns()
  }

  exitLoveOutro = () => {
    console.log("Background => exitLoveOutro")
    // this.killTransition()
    // this.startXP()
    this._background.set(this.scene.resources.get("love-end"), null, [null, PLAIN_BEIGE])
    this._background.transition(0, () => { this.resetKenBurns() })
  }

  enterSpeakTuto = () => {
    if (!this._background) {
      console.log("Background => enterSpeakTuto")
      const trans = PLAIN_BEIGE.slice()
      trans[3] = 0
      this.startXP()
      this._background.set(null, null, [trans, PLAIN_BEIGE])
      this._background.transition(2)
    }

  }

  exitSpeakTuto = async () => {
    console.log("Background => exitSpeakTuto")
    await Delay(1000)
    // this.killTransition()
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    // this.startXP()
    this._background.set(null, null, [PLAIN_BEIGE, trans])
    this._background.transition(2, this.killTransition)
  }

  enterSpeakOutro = () => {
    console.log("Background => enterSpeakOutro")
    this.startXP()
    this._background.set(null, this.scene.resources.get("speak-end"), [[1, 1, 1, 0], null])
    this._background.transition(0)
    this.resetKenBurns()
    this.startKenBurns()
  }

  exitSpeakOutro = () => {
    console.log("Background => exitSpeakOutro")
    // this.killTransition()
    // this.startXP()
    this._background.set(this.scene.resources.get("speak-end"), null, [null, PLAIN_BEIGE])
    this._background.transition(0, () => { this.resetKenBurns() })
  }

  enterSeeTuto = () => {
    if (!this._background) {
      console.log("Background => enterSeeTuto")
      const trans = PLAIN_BEIGE.slice()
      trans[3] = 0
      this.startXP()
      this._background.set(null, null, [trans, PLAIN_BEIGE])
      this._background.transition(2)
    }
  }

  exitSeeTuto = async () => {
    if (!this._background) return
    console.log("Background => exitSeeTuto")
    await Delay(1000)
    const trans = PLAIN_BEIGE.slice()
    trans[3] = 0
    // this.startXP()
    this._background.set(null, null, [PLAIN_BEIGE, trans])
    this._background.transition(2, this.killTransition)

  }

  enterSeeOutro = () => {
    console.log("Background => enterSeeOutro")
    this.startXP()
    this._background.set(this.scene.resources.get("see-end"), null, [null, this.scene.resources.get("see-end")])
    this.resetKenBurns()
    this.startKenBurns()
    // this.startXP()
    // this._background.set(null, this.scene.resources.get("speak-end"), [[1, 1, 1, 0], null])
    // this._background.transition(0)
  }

  exitSeeOutro = () => {
    console.log("Background => exitSeeOutro")
    // this.killTransition()
    // this.startXP()
    this._background.set(this.scene.resources.get("see-end"), null, [null, PLAIN_BEIGE])
    this._background.transition(0, () => { this.resetKenBurns() })
  }

  exitArtworkIntro = () => {
    console.log("Background => exitArtworkIntro")
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct?.pause()

  }

  enterArtworkTuto = () => {
    console.log("Background => enterArtworkTuto")
    if (!this._background) {
      const trans = PLAIN_BEIGE.slice()
      trans[3] = 0
      this.startXP()
      this._background.set(null, null, [trans, PLAIN_BEIGE])
      this._background.transition(2)
    }
  }

  exitArtworkTuto = () => {
    console.log("Background => exitArtworkTuto")
    // this.killTransition()
    // this.startXP()
    this._background.set(null, null, [PLAIN_BEIGE, [1, 1, 1, 0]])
    this._background.transition(4, () => {
      const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
      museumAct?.pause()
      this.killTransition()
    }, true)

  }

  exitArtworkDefault = () => {
    console.log("Background => exitArtworkDefault")
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct?.unpause()
  }

  enterArtworkShare = () => {
    console.log("Background => enterArtworkShare")
    if (!this._background) {
      this.startXP()
    }
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct?.pause()
    this._background.set(null, null, [PLAIN_BEIGE, PLAIN_BEIGE])
    this._background.transition(4, null, true, 0.1)

  }

  exitArtworkShare = () => {
    console.log("Background => exitArtworkShare")
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct?.unpause()
    this._background.set(null, null, [PLAIN_BEIGE, [1, 1, 1, 0]])
    this._background.transition(4, this.killTransition, false)
  }

  killTransition = () => {
    if (this._background) this.stopXP()
  }

  slideWall() {
    this._background?.slideWall()
  }


  enterIntro = () => {

  }

  enterDefault = () => {

  }


  enterOutro = async () => {
    await this._background.outro()
    AppService.send("ENTER")

  }

  resetKenBurns() {
    if (this.kenBurnsT) this.kenBurnsT.kill()
    this.isKenBurns = false
    this._background.kenBurnsV = 0
  }

  startKenBurns() {
    if (this.kenBurnsT) this.kenBurnsT.kill()
    this.isKenBurns = true
    this.kenBurnsT = gsap.to(this._background, { duration: 10, kenBurnsV: 1, ease: Sine.easeOut })
  }



  unload(): void { }
  preFrame(): void {
    if (this._background) this._background.preRender()
  }
  render(): void {
    if (this._background) this._background.render()
  }
  renderUI(): void { }
  preRender(): void { }
  rttPass(): void { }

  private _resize = () => {

    const w = GLApp.getInstance().glview.width
    const h = GLApp.getInstance().glview.height

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    const pr = this.scene.glview.pixelRatio

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._background._resize(w > h ? h / w : w / h, window.innerWidth, window.innerHeight)
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 50

    this._camera = camera;

  }

}