import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager"

export default class ArtworkMouseController {
  mouseUp: EventListener
  mouseMove: EventListener
  mouseDown: EventListener
  prevPinch: number
  mouseWheel: EventListener
  isPinching: boolean
  startPinch: number
  prevScroll: number[]
  isScrolling: boolean

  constructor(public element: HTMLElement, public onScroll: Function, public onScrollStart: Function, public onScrollEnd: Function, public onZoom: Function, public onMouseMove: Function) {
    this.isScrolling = false
    this.prevScroll = [0, 0]

    this.isPinching = false
    this.prevPinch = -1
    this.startPinch = 0

    this.mouseMove = this.handleMouseMove.bind(this)
    this.mouseDown = this.handleMouseDown.bind(this)
    this.mouseUp = this.handleMouseUp.bind(this)
    this.mouseWheel = this.handleMouseWheel.bind(this)

    this.setScroll()
  }

  setScroll() {
    this.element.addEventListener('mousedown', this.mouseDown)
    this.element.addEventListener('touchstart', this.mouseDown)

    document.addEventListener('mousemove', this.mouseMove)

    document.addEventListener('mouseup', this.mouseUp)
    document.addEventListener('touchend', this.mouseUp)

    this.element.addEventListener('wheel', this.mouseWheel)
  }

  getMousePosition(evt) {
    if (evt.clientX && evt.clientY) {
      return [evt.clientX, evt.clientY]
    }

    if (evt.touches && evt.touches.length > 0) {
      return [
        evt.touches[0].clientX,
        evt.touches[0].clientY,
      ]
    }

    return []
  }

  handlePinch(evt) {
    if (!evt.touches || evt.touches.length < 2) {
      this.isPinching = false
      return
    }

    const x = evt.touches[0].clientX - evt.touches[1].clientX
    const y = evt.touches[0].clientY - evt.touches[1].clientY
    const dist = Math.sqrt(x * x + y * y)

    if (!this.isPinching) {
      this.isPinching = true
      this.startPinch = dist
      this.prevPinch = dist
    } else {
      const basePinch = dist < this.prevPinch ? Math.max(this.startPinch, this.prevPinch) : Math.min(this.startPinch, this.prevPinch)
      this.prevPinch = dist
      this.onZoom(
        1 - dist / basePinch,
        true
      )
    }
  }

  handleMouseDown(evt) {
    document.addEventListener('touchmove', this.mouseMove)

    this.handlePinch(evt)
    if (this.isPinching) return

    AudioManager.playUI(AUDIO_ID.XP_UNDERSTAND_DRAG)

    this.isScrolling = true
    this.prevScroll = this.getMousePosition(evt)
    this.onScrollStart()
  }

  handleMouseMove(evt) {
    this.handlePinch(evt)
    if (this.isPinching) return

    const pos = this.getMousePosition(evt)
    if (pos.length === 0) return

    this.onMouseMove(pos, evt.target)
    if (!this.isScrolling) return

    const scrollValue = [pos[0] - this.prevScroll[0], pos[1] - this.prevScroll[1]]
    this.prevScroll = pos
    this.onScroll(scrollValue)
  }

  handleMouseUp(evt) {
    document.removeEventListener('touchmove', this.mouseMove)

    this.handlePinch(evt)
    if (this.isPinching || !this.isScrolling) return
    this.isScrolling = false
    this.onScrollEnd()
  }

  handleMouseWheel(evt) {
    const zoomValue = Math.abs(evt.deltaX) > Math.abs(evt.deltaY) ? evt.deltaX : evt.deltaY
    this.onZoom(zoomValue)
  }

  destroy() {
    this.element.removeEventListener('mousedown', this.mouseDown)
    this.element.removeEventListener('touchstart', this.mouseDown)

    document.removeEventListener('mousemove', this.mouseMove)
    document.removeEventListener('touchmove', this.mouseMove)

    document.removeEventListener('mouseup', this.mouseUp)
    document.removeEventListener('touchend', this.mouseUp)

    this.element.removeEventListener('wheel', this.mouseWheel)
  }
}