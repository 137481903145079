import { Resource } from "./Resource";
import { fetchSafe } from "./Net";

export class ArrayBufferResource extends Resource<ArrayBuffer>{

  url: string;

  constructor(url: string) {
    super();
    this.url = url;
  }


  async doLoad(): Promise<ArrayBuffer> {

    return new Promise(async (resolve, reject) => {

      try {
        const response = await fetchSafe(this.url);
        const result: ArrayBuffer = await response.arrayBuffer();
        resolve(result);
        return result;
      }
      catch (e) {
        console.log(e);
        reject(e);
        return null;
      }
      finally {

      }
    })

  }

  doUnload(): void { }

  unload(): void {
    this._abortCtrl.abort();
  }


}