import { IActivity } from "@/webgl/activities/Activity";
import SpeakActivity from "@/webgl/activities/SpeakActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class SpeakActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new SpeakActivity(scene)
  }
}

let SpeakActivityService = new SpeakActivityServiceImpl('feel')

export default SpeakActivityService


/////////////
/////////////////
//////////////////////
//////////////////////////////////////
///////////////////////////////////////////////
 
//////////

