function getByLocation() {
  const location = window.location;
  if (location.hostname.includes("uat"))
    return "793-980-524-821-335-498-340-383-160-340-383.vlc";
  if (location.hostname.includes("stg"))
    return "470-367-564-268-433-190-387-021-943-187-320.vlc";
  return "834-521-695-492-049-519-411-447-778-711-444.vlc";
}

const licence =
  process.env.VUE_APP_ENVCONFIG === "development"
    ? "621-110-494-100-028-278-218-276-299-618-272.vlc"
    : getByLocation();

export const licenseName = licence;
export const licenseURL = "js/visagesdk/" + licence;
export const locateFile = function (dataFileName) {
  var relativePath = "js/visagesdk/" + dataFileName;
  return relativePath;
};
export default function (callback) {
  return {
    locateFile: locateFile,
    preRun: [
      function () {
        // VisageModule.FS_createPreloadedFile(
        //   "/",
        //   "Facial Features Tracker.cfg",
        //   "js/visagesdk/Facial Features Tracker.cfg",
        //   true,
        //   false
        // );
        // VisageModule.FS_createPreloadedFile(
        //   "/",
        //   "Facial Features Tracker - With Ears.cfg",
        //   "js/visagesdk/Facial Features Tracker - With Ears.cfg",
        //   true,
        //   false
        // );
        // VisageModule.FS_createPreloadedFile(
        //   "/",
        //   "Face Detector.cfg",
        //   "js/visagesdk/Face Detector.cfg",
        //   true,
        //   false
        // );
        VisageModule.FS_createPreloadedFile(
          "/",
          "Face Detector.cfg",
          "js/visagesdk/Face Detector.cfg",
          true,
          false
        );
        // VisageModule.FS_createPreloadedFile(
        //   "/",
        //   "NeuralNet.cfg",
        //   "js/visagesdk/NeuralNet.cfg",
        //   true,
        //   false
        // );
        VisageModule.FS_createPreloadedFile(
          "/",
          licenseName,
          licenseURL,
          true,
          false,
          function () {},
          function () {
            alert("Loading License Failed!");
          }
        );
      },
    ],

    onRuntimeInitialized: function () {
      callback();
    },
  };
}
