import { IActivity } from "@/webgl/activities/Activity";
import BackgroundActivity from "@/webgl/activities/BackgroundActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class BackgroundActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new BackgroundActivity(scene)
  }
}

let LoadingActivityService = new BackgroundActivityServiceImpl('Background')

export default LoadingActivityService


/////////////
/////////////////
//////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
 
//////////

