
import gsap from "gsap";
import lottie, { AnimationItem } from "lottie-web";
import { defineComponent } from "vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

import { AppStore, setMediaTip } from "@/store/modules/AppStore";
import FrontResources from "@/core/FrontResources";
import { Viewport } from "@/store/modules/Viewport";
import UITip from "@/components/UITip/UITip.vue";

export enum UserMediaSwitchIcon {
  CAMERA = "toggle-camera",
  MIC = "toggle-mic",
}

export default defineComponent({
  setup() {},

  data() {
    return {
      animation: null,
    };
  },

  props: {
    checked: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: UserMediaSwitchIcon.CAMERA,
    },
  },

  computed: {
    showTip(): boolean {
      return AppStore.mediaTip && Viewport.isDesktop;
    },

    tipText(): string {
      return "Find the explanatory tutorials \nand enable or disable \nyour microphone or camera \nby clicking here";
    },

    toggleLabel(): string {
      if (this.icon === UserMediaSwitchIcon.CAMERA) {
        return `Camera ${this.checked ? "ON" : "OFF"}`;
      } else {
        return `Mic ${this.checked ? "ON" : "OFF"}`;
      }
    },
  },

  watch: {
    checked: {
      handler() {
        this.toggle();
      },
    },
  },

  mounted() {
    this.animation = lottie.loadAnimation({
      container: this.$refs.anim as Element,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: FrontResources.Group.get(this.icon as any),
      // path: Paths.resolve('./assets/lottie/close-menu.json')
    });

    this.animation?.addEventListener("DOMLoaded", this.toggle.bind(this));

    gsap.delayedCall(20, this.closeTip);
  },

  beforeDestroy() {
    this.animation?.removeEventListener("DOMLoaded", this.toggle.bind(this));
  },

  methods: {
    beforeAppear() {
      (this.$refs.uiTip as typeof UITip).beforeAppear();
    },
    appear() {
      (this.$refs.uiTip as typeof UITip).appear();
    },
    clickTip() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);

      this.$emit("tip");
    },

    clickToggle() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);

      this.$emit("toggle");
    },

    toggle() {
      if (!this.checked) {
        this.animation?.playSegments([0, 65], true);
      } else {
        this.animation?.playSegments([65, 106], true);
      }
    },

    closeTip() {
      setMediaTip(false);
    },
  },
});
