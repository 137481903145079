var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.frag" )() )+
'\n'+
( require( "../includes/blends.glsl" )() )+
'\n\nuniform sampler2D uTex;\nuniform vec2 uAspect;\nuniform float uSize;\nuniform float uFullScreenSize;\nuniform float uFullScreen;\nuniform float uOffsetX;\nuniform float uMobile;\n\nIN vec2 vTexCoord;\n\nvoid main(void){\n  vec2 centeredUvs = vec2(1. - (vTexCoord.x + uOffsetX), vTexCoord.y) - vec2(0.5);\n\n  float size = mix(uSize, uFullScreenSize, uFullScreen);\n  vec2 origin = mix(vec2(0.508, 0.505), vec2(0.5), uFullScreen);\n  vec4 color = texture2D(uTex, (centeredUvs * uAspect / size) + origin);\n\n  vec4 finalColor = mix(vec4(color.rgb, 0.), vec4(vec3(vTexCoord.x + uOffsetX).rgb, 1.), uMobile);\n\n  if (finalColor.r <= 0. || finalColor.r > 1.) {\n    discard;\n  }\n\n  gl_FragColor = finalColor;\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;