import { Resource } from "./Resource";
import ResourceGroup from "./ResourceGroup";
// import { FontFaceDescriptors } from "css-font-loading-module";
import type { } from "css-font-loading-module";
import Deferred from "@/core/Deferred";

export class FontResource extends Resource<FontFace>{

  url: string;
  name: string;
  font: FontFace;

  constructor(family: string, url: string, descriptors: FontFaceDescriptors) {
    super();
    this.name = (family + url).replace(" ", "_");
    this.url = url;
    this.font = new FontFace(family, url, descriptors);
  }


  doLoad(): Promise<FontFace> {

    const def = new Deferred<FontFace>();

    try{
      this.font.load().then((font) => {
        document.fonts.add(this.font);
        def.resolve(this.font);
      })
    } catch(e){
      console.warn("[FontResource] Not loaded : ", e);
      def.resolve();
    }

    return def.promise;

  }


  doUnload(): void {
    this.font = null;
    this._abortCtrl.abort();
  }


}