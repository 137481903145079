/*
 * register components globally
 */

import { App } from "vue";
import UIBtnPrimary from "./UIBtn/UIBtnPrimary.vue";
import UIBtnSecondary from "./UIBtn/UIBtnSecondary.vue";
import UIBtnRound from "./UIBtn/UIBtnRound.vue";
import UIIcon from "./UIIcon/UIIcon.vue";
import UIBtnIcon from "./UIBtnIcon/UIBtnIcon.vue";
import UIUserMediaSwitch from "./UIUserMediaSwitch/UIUserMediaSwitch.vue";
import UITip from "./UITip/UITip.vue";
import UIHoldBtn from "./UIHoldBtn/UIHoldBtn.vue";
import UIShareLink from "./UIShareLink/UIShareLink.vue";
import UISoundBtn from "./UISoundBtn/UISoundBtn.vue";
import UIFaceTip from "./UIFaceTip/UIFaceTip.vue";
import UIEnterBtn from "./UIEnterBtn/UIEnterBtn.vue";
import UIBigLink from "./UIBigLink/UIBigLink.vue";
import UILink from "./UILink/UILink.vue";
import UIBtnClose from "./UIBtnClose/UIBtnClose.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const register = (app: App<Element>): void => {
  app.component("ui-btn", UIBtnPrimary); // alias of UIBtnPrimary
  app.component("ui-btn-primary", UIBtnPrimary);
  app.component("ui-btn-secondary", UIBtnSecondary);
  app.component("ui-btn-round", UIBtnRound);
  app.component("ui-btn-icon", UIBtnIcon);
  app.component("ui-icon", UIIcon);
  app.component("ui-user-media-switch", UIUserMediaSwitch);
  app.component("ui-tip", UITip);
  app.component("ui-hold-btn", UIHoldBtn);
  app.component("ui-share-link", UIShareLink);
  app.component("ui-sound-btn", UISoundBtn);
  app.component("ui-face-tip", UIFaceTip);
  app.component("ui-enter-btn", UIEnterBtn);
  app.component("ui-big-link", UIBigLink);
  app.component("ui-link", UILink);
  app.component("ui-btn-close", UIBtnClose);

  app.component("v-select", vSelect);
};

export default { register };
