import Visage from "@/api/visage/visage";
import Delay from "@/core/Delay";
import { setRecaptchaToken } from "@/store/modules/AppStore";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import Scene from "../Scene";
import { IActivity } from "./Activity";
import StateHelper from "./StateHelper";


/**
 * Noop activity, loading in VUE
 */
export default class LoadingActivity implements IActivity {

  readonly priority : number = 0;
  readonly name : string = "loading";

  stateHelper: StateHelper;

  constructor( public scene: Scene ) {

  }
 
  async load(): Promise<void> {
    console.log( "LoadingActivity.load()" );
    await this.greCaptchaReady()    
    if(process.env.VUE_APP_SKIP_VISAGE === "true") return Promise.resolve()
    return Visage.occurence.doLoadLib().then(() => {
      return Visage.occurence.init().then(() => {
        return Promise.resolve()
      })
    })  
  }

  greCaptchaReady():Promise<void> {
    return new Promise(resolve => {
      grecaptcha.ready(function() {
        return resolve()
      });
    })
  }

  onStateUpdate(state: AppState): void {

  }

  start() {

  }
  
  stop(): void {
  }


  enterIntro = ()=>{

  }

  enterDefault = ()=>{

  }


  enterOutro = ()=>{

  }

  unload(): void {}
  preFrame(): void {}
  render(): void {}
  renderUI(): void {}
  preRender(): void {}
  rttPass(): void {}

}