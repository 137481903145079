
import gsap from "gsap";
import { computed, defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";
import { AppStore } from "@/store/modules/AppStore";
import { Viewport } from "@/store/modules/Viewport";
import Delay from "@/core/Delay";

export default defineComponent({
  setup() {
    const loadProgress = computed(() => {
      return AppStore.load_progress;
    });

    const loadComplete = computed(() => {
      return AppStore.load_progress === 1;
    });

    return {
      ...useActor(AppService),
      loadProgress,
      loadComplete,
    };
  },

  data() {
    return {
      isSuspended: false,
      done: false,
      xpReady: false,
      loaderReady: false,
      animateProgress: 0,
      videoAnimationReady: false,
    };
  },

  computed: {
    progress(): string {
      return Math.round(this.animateProgress * 100) + "%";
    },
    startButtonLabel(): string {
      return Viewport.isDesktop ? "Start the experience" : "Start";
    },
  },

  mounted() {
    (this.$refs.videoAnimation as HTMLVideoElement).onloadedmetadata = () => {
      this.videoAnimationReady = true;
    };
    (this.$refs.videoLoop as HTMLVideoElement).onloadedmetadata = () => {
      console.log("loaded metadata !");
      this.loaderReady = true;

      if (Viewport.isDesktop) {
        this.isSuspended = false;
        // (this.$refs.videoLoop as HTMLVideoElement).play();
      } else {
        (this.$refs.videoLoop as HTMLVideoElement)
          .play()
          .then(() => {})
          .catch((error) => {
            console.error(error);
            this.isSuspended = true;
          });
      }
    };
    (this.$refs.videoLoop as HTMLVideoElement).onended = () => this.videoLoop();

    (this.$refs.videoLoop as HTMLVideoElement).load();
  },

  methods: {
    show() {
      this.send("LOADING_INTRO_COMPLETE");
    },

    hide() {
      if (this.isSuspended) return;
      this.send("LOADING_OUTRO_COMPLETE");
    },

    onDone() {
      if (this.isSuspended) return;

      this.send("ENTER");
      this.done = true;
      (
        this.$refs.videoAnimation as HTMLVideoElement as HTMLVideoElement
      ).pause();
      console.log("on done !!!!");
    },

    videoLoop() {
      if (this.videoAnimationReady && this.loadComplete) {
        this.xpReady = true;
        (
          this.$refs.videoAnimation as HTMLVideoElement as HTMLVideoElement
        ).play();
      } else {
        (this.$refs.videoLoop as HTMLVideoElement as HTMLVideoElement).play();
      }
    },
  },

  watch: {
    state: {
      immediate: true,

      handler() {
        if (this.state.matches("loading.intro")) {
          this.show();
        }
      },
    },
    loadProgress: {
      immediate: true,

      handler(newValue) {
        gsap.to(this, {
          animateProgress: newValue,
          duration: 0.3,
          ease: "linear",
          onComplete: async () => {
            if (newValue === 1) {
              await Delay(500);
              this.xpReady = true;
              (
                this.$refs
                  .videoAnimation as HTMLVideoElement as HTMLVideoElement
              ).play();
            }
          },
        });
      },
    },
  },
});
