import { IActivity } from "@/webgl/activities/Activity";
import SeeActivity from "@/webgl/activities/SeeActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class SeeActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new SeeActivity(scene)
  }
}

let SeeActivityService = new SeeActivityServiceImpl('feel')

export default SeeActivityService


/////////////
/////////////////
//////////////////////
////////////////////////////////////
/////////////////////////////////////////////
 
//////////

