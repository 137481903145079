

export const enum Passes {
  COLOR = 'color',
  DEPTH = 'depth',
  MIRROR = 'mirror',
  STENCIL = 'stencil',
  REFLECT_DEPTH = 'reflect-depth',
  GLOW = 'GLOW',
  DEFAULT = COLOR
}