
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import { AppStore } from "@/store/modules/AppStore";
import AppService from "@/store/states/AppService";
import { AppStateEnum } from "@/store/states/AppStateMachine";
import { setMenuOpen } from "@/store/modules/Menu";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },
  computed: {
    logo(): string {
      return `/assets/brand-logo-theme-${AppStore.theme}.png`;
    },
    isButton(): boolean {
      const key = Object.keys(this.state.value)[0];
      return key !== AppStateEnum.STEP_ME && key !== AppStateEnum.MUSEUM;
    },
  },
  methods: {
    backHome() {
      setMenuOpen(false);

      const key = Object.keys(this.state.value)[0];
      // const state = key + "." + this.state.value[key];
      if (key !== AppStateEnum.STEP_ME && key !== AppStateEnum.MUSEUM) {
        if (key === AppStateEnum.COLLAB)
          return AppService.send("home_fromcollab");
        if (key === AppStateEnum.ARTWORK)
          return AppService.send("home_fromartwork");
        return AppService.send("home_fromfeel");
      }
    },
  },
});
