import Paths from "@/core/Paths";
import { CreatePane } from "@/dev/Tweak";
import GLTFResource from "@/webgl/assets/GLTFResource";
import { IGLContextProvider } from "@/webgl/assets/IGLContextProvider";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import IBL from "nanogl-pbr/lighting/Ibl";
import LightSetup from "nanogl-pbr/lighting/LightSetup";

const INITIAL_IDX = 0;

const ENVS = [
  'shanghai_bund',
  'epping_forest_01',
  'empty_warehouse_01_2k',
  'circus_arena',
  'HDR_Studio_01',
  'HDR_Studio_03',
  'kloofendal_48d_partly_cloudy',
  'lakes',
  'lakeside',
  'noon_grass',
  'panorama',
  'photo_studio_01_2k',
  'pool',
  'royal_esplanade',
  'sky',
  'studio_small_02_4k',
  'studio_small_05'
]


export default class IBLManager {

  public ENV_ID: number = INITIAL_IDX;
  public envname: string = "";

  private gltfres: GLTFResource;
  private ibl: IBL;

  get ActiveIBL(): IBL {
    return this.ibl;
  }

  constructor(
    public readonly setup: LightSetup,
    public readonly ctx: IGLContextProvider
  ) {

    this.envname = ENVS[this.ENV_ID];

/////////////////
////////////////
//////////////

  }

  loadResource() {

    if (this.gltfres) {
      this.gltfres.unload();
    }

    this.gltfres = null;
    this.gltfres = new GLTFResource(Paths.resolve(`assets/webgl/envs/${this.envname}.gltf`), this.ctx);

    this.gltfres.load()
      .then(this.onLoaded);

  }


  onLoaded = (gltf: Gltf) => {

    if (this.ibl)
      this.setup.remove(this.ibl);

    this.ibl = gltf.extras.lightsImageBased.list[0].ibl;
    this.setup.add(this.ibl);

  }


  _gui() {

    const pane = CreatePane('HDRI', true);

    let eo = { envname: ENVS[INITIAL_IDX] }
    let options = [];
    for (let i = 0; i < ENVS.length; i++) {
      options.push({
        text: ENVS[i], value: ENVS[i]
      })
    }
    pane.addInput(this, 'envname', { view: "select", options: options }).on("change", v => {
      this.loadResource();
    })

  }

}
