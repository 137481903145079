
import { defineComponent, ref } from 'vue'
import GLApp from "@/webgl/main";

export default defineComponent({

  setup() {

    const loaded = ref<Boolean>(false);
    const isdestroyed = ref<Boolean>(false);
    const resLoaded = 0 as number;
    const resTotal = 0 as number;

    return {
      isdestroyed,
      loaded,
      glapp: GLApp.getInstance(),
      resLoaded,
      resTotal
    }

  },


  mounted() {
    this.$el.appendChild(GLApp.getInstance().canvas);
    GLApp.getInstance().start();
  },


})


if (module.hot) {
  module.hot.decline();
}

