
import { gsap } from "gsap";
import { defineComponent } from "vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setFPS } from "@/utils/FrameToTime";
const frames = setFPS(60);

export default defineComponent({
  setup() {},

  data() {
    return {
      tl: null,
      hover: false,
    };
  },

  props: {
    outline: {
      type: Boolean,
      default: false,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.autoplay) {
      this.createTimeline({
        onComplete: () => {
          this.hover = true;
        },
      });
    }
  },

  methods: {
    createTimeline(vars: gsap.TimelineVars = {}): gsap.core.Timeline {
      const tl = gsap.timeline(vars);

      tl.addLabel("start", `+=${frames(0)}`);

      tl.fromTo(
        [this.$el],
        {
          y: 80,
          rotate: "-25deg",
        },
        {
          y: 0,
          rotate: "0deg",
          duration: frames(60),
          ease: "cta.y.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.background as Element,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: frames(60),
          ease: "cta.x.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.background as Element,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          duration: frames(60),
          ease: "cta.y.out",
        },
        `start+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.label as Element,
        {
          scaleX: 0,
          scaleY: 0,
        },
        {
          scaleX: 1,
          scaleY: 1,
          duration: frames(27),
          ease: "cubic.inOut",
        },
        `start+=${frames(3)}`
      );

      return tl;
    },

    enter(vars: gsap.TimelineVars = {}): gsap.core.Timeline {
      gsap.set(this.$refs.checkmark as Element, { opacity: 0 });

      const tl: gsap.core.Timeline = this.createTimeline({
        ...vars,
        onComplete: () => {
          this.hover = true;
        },
      });

      return tl;
    },

    leave(vars: gsap.TimelineVars = {}): gsap.core.Timeline {
      gsap.set(this.$refs.checkmark as Element, { opacity: 0 });
      this.hover = false;

      const tl: gsap.core.Timeline = this.createTimeline({ ...vars });
      tl?.reverse(0);

      return tl;
    },

    onClick() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_MAIN);
      // this.$emit("click");
    },

    mouseEnter() {
      if (!this.hover) {
        return;
      }

      if (
        !this.$refs.background ||
        !this.$refs.label ||
        !this.$refs.checkmark
      ) {
        return;
      }

      AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      const tl = gsap.timeline({});

      tl.addLabel("start");

      if (this.$refs.background) {
        const scaleX: number = 165 / 156;
        const scaleY: number = 69 / 64;
        tl.fromTo(
          this.$refs.background as Element,
          {
            scaleX: 1,
            scaleY: 1,
          },
          {
            scaleX,
            scaleY,
            duration: frames(30),
            ease: gsap.parseEase("0.40, 2.00, 0.29, 1.00"),
          },
          "start"
        );
      }

      if (this.$refs.label) {
        tl.fromTo(
          this.$refs.label as Element,
          {
            scale: 1,
          },
          {
            scale: 1.15,
            duration: frames(30),
            ease: "cubic.out",
          },
          `start+=${frames(0)}`
        );
      }

      if (this.$refs.checkmark) {
        tl.fromTo(
          this.$refs.checkmark as Element,
          {
            transformOrigin: "100% 100%",
            rotate: "45deg",
            yPercent: 100,
            scale: 0,
            opacity: 0,
          },
          {
            rotate: "0deg",
            yPercent: 0,
            scale: 1,
            opacity: 1,
            duration: frames(30),
            ease: "cubic.inOut",
          },
          "start"
        );
      }
    },

    mouseLeave() {
      if (!this.hover) {
        return;
      }

      if (
        !this.$refs.background ||
        !this.$refs.label ||
        !this.$refs.checkmark
      ) {
        return;
      }

      // AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      const tl = gsap.timeline({});

      tl.addLabel("start");

      tl.to(
        this.$refs.background as Element,
        {
          scale: 1,
          duration: frames(30),
          ease: gsap.parseEase("0.40, 2.00, 0.29, 1.00"),
        },
        "start"
      );

      tl.to(
        this.$refs.label as Element,
        {
          scale: 1,
          duration: frames(30),
          ease: "cubic.out",
        },
        `start`
      );

      tl.to(
        this.$refs.checkmark as Element,
        {
          transformOrigin: "100% 100%",
          rotate: "45deg",
          yPercent: 100,
          scale: 0,
          opacity: 0,
          duration: frames(30),
          ease: "cubic.inOut",
        },
        "start"
      );
    },
  },
});
