import UrlParams from "@/utils/UrlParams"
import { interpret, State, StateValue } from "xstate"
import AppStateMachine, { AppStateContext, collectAppStateActivities } from "./AppStateMachine"
import MuseumActivityService from "./MuseumActivityService"
import LoadingActivityService from "./LoadingActivityService"
import FeelActivityService from "./FeelActivityService"
import DoActivityService from "./DoActivityService"
import LoveActivityService from "./LoveActivityService"
import SpeakActivityService from "./SpeakActivityService"
import SeeActivityService from "./SeeActivityService"
import BackgroundActivityService from "./BackgroundActivityService"
import ArtworkActivityService from "./ArtworkActivityService"


const AppService = interpret(AppStateMachine)

AppService.onTransition((state) => {

  const alist = collectAppStateActivities(state)
  //  if(!alist.includes('museum')) {

  //   MuseumActivityService.stop()
  // }

  console.log(state)

  if (alist.includes('loading')) {
    LoadingActivityService.start()
  } else {
    LoadingActivityService.stop()
    MuseumActivityService.start()
  }
  if (alist.includes('feel')) {
    FeelActivityService.start()
  } else {
    FeelActivityService.stop()
  }
  if (alist.includes('do')) {
    DoActivityService.start()
  } else {
    DoActivityService.stop()
  }
  if (alist.includes('love')) {
    LoveActivityService.start()
  } else {
    LoveActivityService.stop()
  }
  if (alist.includes('speak')) {
    SpeakActivityService.start()
  } else {
    SpeakActivityService.stop()
  }
  if (alist.includes('see')) {
    SeeActivityService.start()
  } else {
    SeeActivityService.stop()
  }
  if (alist.includes('background')) {
    BackgroundActivityService.start()
  } else {
    BackgroundActivityService.stop()
  }
  if (alist.includes('artwork')) {
    ArtworkActivityService.start()
  } else {
    ArtworkActivityService.stop()
  }

  MuseumActivityService.onStateUpdate(state)
  LoadingActivityService.onStateUpdate(state)
  FeelActivityService.onStateUpdate(state)
  DoActivityService.onStateUpdate(state)
  LoveActivityService.onStateUpdate(state)
  SpeakActivityService.onStateUpdate(state)
  SeeActivityService.onStateUpdate(state)
  ArtworkActivityService.onStateUpdate(state)
  BackgroundActivityService.onStateUpdate(state)

})



AppService.start()


/////////////
//////////////////////////////////////

////////
/////////////////
//////////////////////////////////////////////////////
 


////////////////////////////////////
//////////////////////////////////////////
//

//////////


export default AppService