
import { defineComponent } from "vue";
import { Viewport } from "@/store/modules/Viewport";

export default defineComponent({
  computed: {
    show(): boolean {
      return !Viewport.isDesktop && Viewport.isLandscape && !Viewport.isVirtualKeyboardOpen
    }
  }
})
