import { openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58e26c74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-brand-logo" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade-transition-cmn",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: "fade-transition-cmn",
          mode: "in-out",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass({ 'is-button': _ctx.isButton }),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backHome && _ctx.backHome(...args)))
            }, [
              (_openBlock(), _createElementBlock("img", {
                class: "ui-brand-logo__img",
                src: _ctx.logo,
                key: _ctx.logo,
                alt: "Ruinart"
              }, null, 8, _hoisted_2))
            ], 2)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}