import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fff7ed5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "StepLove view-default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_btn = _resolveComponent("ui-btn")!
  const _component_ui_user_media_switch = _resolveComponent("ui-user-media-switch")!

  return (_openBlock(), _createBlock(_Transition, {
    onEnter: _ctx.enter,
    onLeave: _ctx.leave,
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isStateDefault)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ui_btn, {
              class: "BtnDone",
              autoplay: true,
              onClick: _ctx.onDone
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnDoneLabel), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ui_user_media_switch, { onTip: _ctx.back }, null, 8, ["onTip"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onEnter", "onLeave"]))
}