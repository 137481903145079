
import gsap from "gsap";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},

  data() {
    const tlCollection: gsap.core.Timeline[] = [];
    const scrollTriggerCollection: ScrollTrigger[] = [];
    return {
      tlCollection,
      scrollTriggerCollection,
    };
  },

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl.clear() && tl.kill();
      });
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) =>
        scrollTrigger.kill()
      );
    }
  },

  mounted() {
    this.$nextTick(this.initScrollAnimation);
  },

  methods: {
    initScrollAnimation() {
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewSection__Heading"),
              //   scrub: 1,
              start: "center bottom",
            },
          })
          .addLabel("start")
          .fromTo(
            Array.from(this.$el.querySelectorAll(".ViewSection__Heading span")),
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration: 1,
              ease: "cubic.out",
              stagger: 0.1,
            },
            "start"
          )
      );

      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewSection__Body"),
              //   scrub: 1,
              start: "top bottom-=80",
            },
          })
          .addLabel("start")
          .fromTo(
            this.$el.querySelector(".ViewSection__Body"),
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
            "start"
          )
      );

      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewSection__Figure"),
              scrub: 1,
              start: "top bottom",
              end: "center center",
            },
          })
          .addLabel("start")
          .fromTo(
            this.$el.querySelector(".ViewSection__Figure"),
            {
              rotate: "0deg",
              y: 80,
            },
            {
              rotate: "-5deg",
              y: 0,
              duration: 1.25,
            },
            "start"
          )
      );
    },
  },
});
