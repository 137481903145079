import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { vec3 } from "gl-matrix";
import { DEG2RAD } from "@/webgl/math";
import { AbortController } from "@azure/abort-controller";

import Node from "../../lib/nanogl-gltf/lib/elements/Node";
import Scene from "../../Scene";
import GLApp from "@/webgl/main";
import ArtworkBg from "./ArtworkBg";
import ArtworkMouseController from "./ArtworkMouseController";
import { AppState } from "@/store/states/AppStateMachine";
import { IActivity } from "../Activity";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { ImageResource } from "@/webgl/assets/Net";
import { getTexture } from "@/core/TextureURL";
import StateHelper from "../StateHelper";
import AppService from "@/store/states/AppService";
import MuseumActivity from "../MuseumActivity";
import { Viewport } from "@/store/modules/Viewport";


export default class ArtworkActivity implements IActivity {
  readonly priority: number = 0;
  readonly name: string = "artwork";

  stateHelper: StateHelper;

  private _fov: number = 60;
  private _camera: Camera;

  root: Node;
  aspect: number

  private _startAbortController: AbortController;

  artworkBg: ArtworkBg
  artworkMouse: ArtworkMouseController

  private _resources: ResourceGroup

  museumAct: MuseumActivity

  constructor(public scene: Scene) {
    this.root = new Node()
    this.stateHelper = new StateHelper([
      { match: 'artwork.intro', enter: this.enterArtworkIntro },
      { match: 'artwork.tuto', exit: this.leaveArtworkTuto },
      { match: 'artwork.share', enter: this.enterArtworkShare, exit: this.leaveArtworkShare },
      { match: 'artwork.default', enter: this.enterArtworkDefault },
      { match: 'artwork.fadeout', enter: this.enterFadeOut },
    ])
  }

  makeCamera() {
    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 50
    camera.z = -1
    camera.y = 0
    camera.x = 0

    this._camera = camera;
  }

  onStateUpdate(state: AppState): void {
  }

  load(): Promise<any> {
    this._resources = new ResourceGroup()

    this._resources.add(new ImageResource(getTexture('_artwork-reflection.jpg')), 'reflection')
    this._resources.add(new ImageResource(getTexture('shadow-face-artboard.png')), 'shadow')
    this._resources.add(new ImageResource(getTexture('hexagon.png')), 'hexagon')
    this._resources.add(new ImageResource(getTexture('hexagon-shadow.png')), 'hexagon-shadow')
    this._resources.add(new ImageResource(getTexture('circle_01.png')), 'circle01')
    this._resources.add(new ImageResource(getTexture('circle_02.png')), 'circle02')
    this._resources.add(new ImageResource(getTexture('circle_03.png')), 'circle03')
    this._resources.add(new ImageResource(getTexture('circle_04.png')), 'circle04')
    this._resources.add(new ImageResource(getTexture('circle_small.png')), 'circleSmall')
    this._resources.add(new ImageResource(getTexture('angry_01.png')), 'anger01')
    this._resources.add(new ImageResource(getTexture('angry_02.png')), 'anger02')
    this._resources.add(new ImageResource(getTexture('disgust_01.png')), 'disgust01')
    this._resources.add(new ImageResource(getTexture('disgust_02.png')), 'disgust02')
    this._resources.add(new ImageResource(getTexture('fear_01.png')), 'fear01')
    this._resources.add(new ImageResource(getTexture('fear_02.png')), 'fear02')
    this._resources.add(new ImageResource(getTexture('happy_01.png')), 'happiness01')
    this._resources.add(new ImageResource(getTexture('happy_02.png')), 'happiness02')
    this._resources.add(new ImageResource(getTexture('sad_01.png')), 'sadness01')
    this._resources.add(new ImageResource(getTexture('sad_02.png')), 'sadness02')
    this._resources.add(new ImageResource(getTexture('surprise_01.png')), 'surprise01')
    this._resources.add(new ImageResource(getTexture('surprise_02.png')), 'surprise02')
    this._resources.add(new ImageResource(getTexture('you_text.png')), 'youText')
    this._resources.add(new ImageResource(getTexture('you_text_mobile.png')), 'youTextMobile')

    return this._resources.load()
  }

  unload(): void {
  }

  start(): void {
    this.scene.camera = this._camera
    this.scene.root.add(this.root);
    this._startAbortController = new AbortController()
    this.scene.glview.onResize.on(() => this._resize())

    this.makeCamera()
    this.museumAct = this.scene.activities.getActivity("museum") as MuseumActivity
    this.artworkBg = new ArtworkBg(this.scene, this._camera, this.root, this.museumAct.camera, this._resources, this._introOver.bind(this))
    this.artworkMouse = new ArtworkMouseController(
      this.scene.glview.canvas, this._scroll.bind(this), this._scrollStart.bind(this), this._scrollEnd.bind(this), this._zoom.bind(this),
      this._mouseMove.bind(this)
    )

    this._resize()
    this.stateHelper.start()
  }

  stop(): void {
    this._startAbortController.abort()
    this._startAbortController = null
    this.scene.camera = null
    this.artworkBg.destroy()
    this.artworkMouse.destroy()
    this.scene.glview.onResize.off(this._resize)
    this.scene.root.remove(this.root)
    this.stateHelper.stop()
  }

  enterArtworkIntro = () => {
    this.artworkBg.fadeIn(4)
  }

  enterArtworkShare = () => {
    this.artworkBg.slideOut(0, Viewport.isDesktop ? -1 : -1.1)
  }

  leaveArtworkShare = () => {
    this.artworkBg.slideIn(Viewport.isDesktop ? -1 : -1.1, 0)
  }

  leaveArtworkTuto = () => {
    this.artworkBg.slideIn(Viewport.isDesktop ? 1 : 1.1, 0)
  }

  enterFadeOut = async () => {
    await this.artworkBg.fadeOut()
    AppService.send("ENTER")
  }

  enterArtworkDefault = () => {
    this.artworkBg.setup()
  }

  preFrame(): void {
  }

  preRender(): void {
    this.artworkBg.preRender()
  }


  render(): void {
    this.artworkBg.render()
  }

  renderUI(): void {
  }


  rttPass(): void {
  }

  _resize(): void {
    const w = window.innerWidth
    const h = window.innerHeight

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this.artworkBg.resize(w, h)
  }

  _scroll(scrollValue: number[]): void {
    this.artworkBg.scroll(scrollValue)
  }

  _scrollStart(): void {
    this.artworkBg.scrollStart()
  }

  _scrollEnd(): void {
    this.artworkBg.scrollEnd()
  }

  _zoom(zoomValue: number, pinching: boolean = false): void {
    this.artworkBg.zoom(zoomValue, pinching)
  }

  _mouseMove(position: number[], target: HTMLElement): void {
    this.artworkBg.mouseMove(position, target)
  }

  _introOver(): void {
    this.museumAct.pause()
  }
}