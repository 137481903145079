
import { CreatePane } from "@/dev/Tweak";
import IBLManager from "@/webgl/entities/lights/IBLManager";
import IIblMaterialPass from "@/webgl/glsl/IblMaterialPass";
import DepthPass from "@/webgl/lib/nanogl-pbr/src/DepthPass";
import Enum from "@/webgl/lib/nanogl-pbr/src/Enum";
import { GammaModes } from "@/webgl/lib/nanogl-pbr/src/GammaModeEnum";
import Input, { Uniform } from "@/webgl/lib/nanogl-pbr/src/Input";
import DirectionalLight from "@/webgl/lib/nanogl-pbr/src/lighting/DirectionalLight";
import Light from "@/webgl/lib/nanogl-pbr/src/lighting/Light";
import LightSetup from "@/webgl/lib/nanogl-pbr/src/lighting/LightSetup";
import PunctualLight from "@/webgl/lib/nanogl-pbr/src/lighting/PunctualLight";
import Scene from "@/webgl/Scene";
import IBL from "nanogl-pbr/lighting/Ibl";

let EXPO = 1.1;
let GAMMA = 1.99;

export default class LightsManager {

  setup: LightSetup;
  EXPO: number = EXPO;
  GAMMA: number = GAMMA;
  expoUniform: Uniform;
  gammaUniform: Uniform;
  gammaMode: Enum<readonly ["GAMMA_NONE", "GAMMA_STD", "GAMMA_2_2", "GAMMA_TB"]>;
  gamma: Input;
  exposure: Input;

  dirlight: DirectionalLight;
  list: PunctualLight[] = [];
  iblMngr: IBLManager;

  constructor(
    public scene: Scene
  ) {

    this.setup = new LightSetup();
    this.iblMngr = new IBLManager(this.setup, this.scene);

    this.initialize();


/////////////////
///////////////
//////////////

  }

  add(light: Light) {
    this.list.push(light as PunctualLight);
    this.setup.add(light);
  }

  remove(l: Light) {
    this.setup.remove(l);
  }

  setupMat(pass: IIblMaterialPass) {
    pass.setLightSetup(this.setup);
    pass.iGamma?.proxy(this.gamma);
    pass.iExposure?.proxy(this.exposure);
    pass.gammaMode?.proxy(this.gammaMode);
  }

  setupExpoGamma(m: IIblMaterialPass) {

    m.iGamma.proxy(this.gamma)
    m.iExposure.proxy(this.exposure)
    m.gammaMode.proxy(this.gammaMode)

  }

  addIbl(ibl: IBL) {
    this.setup.add(ibl);
  }

  initialize() {

    const setup = this.setup;

    setup.bounds.fromMinMax(
      [-5, -5, -5],
      [5, 5, 5]
    );

    setup.stdModel.shadowFilter.set('PCF4x4')
    setup.stdModel.iblShadowing.enable()
    setup.depthFormat.set("D_RGB");

    const depthPass = new DepthPass(this.scene.gl);
    depthPass.setLightSetup(setup);

    const gammaMode = this.gammaMode = new Enum('gammaMode', GammaModes);
    const gamma = this.gamma = new Input('gamma', 1, Input.ALL);
    const exposure = this.exposure = new Input('exposure', 1, Input.ALL);

    gammaMode.set('GAMMA_STD');
    const expoUniform = this.expoUniform = exposure.attachUniform('utmExpo');
    const gammaUniform = this.gammaUniform = gamma.attachUniform('uTMGamma');

    expoUniform.set(this.EXPO);
    gammaUniform.set(1 / this.GAMMA);

    // this.iblMngr.loadResource();
    
    // const dirlight = new DirectionalLight();
    // // const dirlight = this.dirlight = new PointLight();
    // // dirlight.castShadows(true);
    // dirlight._color[0] = 0.4 * 1.0;
    // dirlight._color[1] = 0.5 * 1.0;
    // dirlight._color[2] = 0.8 * 1.0;
    // dirlight.y = 2;
    // dirlight.z = 1;
    // dirlight.x = 2;
    // dirlight.lookAt(vec3.create());

    // this.scene.root.add(dirlight);
    // dirlight.invalidate();
    // this.add(dirlight);

    // const plight = new SpotLight();
    // plight.castShadows(true);
    // plight._shadowmapSize = 1024;
    // plight._color[0] = 2.0 * 10.0;
    // plight._color[1] = 0.5 * 10.0;
    // plight._color[2] = 0.9 * 10.0;
    // plight.radius = 20;
    // // plight.x = -0.3;
    // plight.z = -2;
    // plight.y = 3.0;
    // plight.lookAt(vec3.create());
    // this.scene.root.add(plight);
    // this.add(plight);


    // const plight2 = new SpotLight();
    // plight2.castShadows(true);
    // plight2._shadowmapSize = 1024;
    // plight2._color[0] = 0.5 * 10.0;
    // plight2._color[1] = 0.5 * 10.0;
    // plight2._color[2] = 2.0 * 10.0;
    // plight2.radius = 20;
    // plight2.x = -0.5;
    // plight2.z = 2;
    // plight2.y = 3.0;
    // plight2.lookAt(vec3.create());
    // this.scene.root.add(plight2);
    // this.add(plight2);

    // const neon = this.neonLight = new PointLight();
    // this.scene.root.add(neon);
    // this.add(neon);

  }

  gui() {

    const pane = CreatePane('Tonemapping');

    const o = {
      iblexpo: 1,
      expo: EXPO,
      gamma: GAMMA
    }

    var eParam = this.expoUniform;
    var gParam = this.gammaUniform;
    pane.addInput(o, 'expo', { min: 0, max: 5 }).on("change", (v) => {
      if (eParam) eParam.set(o.expo);
    });

    pane.addInput(o, 'gamma', { min: 0, max: 3 }).on("change", (v) => {
      if (gParam) gParam.set(1.0 / o.gamma)
    });
  }

}