
import { gsap } from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { AppStateEnum } from "@/store/states/AppStateMachine";
import { setMenuOpen } from "@/store/modules/Menu";
import { setFPS } from "@/utils/FrameToTime";

import AnimateSprite from "@/components/AnimateSprite/AnimateSprite.vue";
import { BigLinkVariant } from "@/components/UIBigLink/UIBigLink.vue";
import Tracking from "@/core/Tracking";

const frames = setFPS(60);

export default defineComponent({
  components: {
    AnimateSprite,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  computed: {
    visitRuinartURL(): string {
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },

    variantEmpty(): string {
      return BigLinkVariant.COLLAB;
    },

    variantCollaboration(): string {
      return BigLinkVariant.COLLAB;
    },

    variantAgenda(): string {
      return BigLinkVariant.AGENDA;
    },

    variantNewsletter(): string {
      return BigLinkVariant.NEWSLETTER;
    },
  },

  methods: {
    onEnter(el: HTMLElement, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({
        autoRemoveChildren: true,
        onComplete,
      });

      tl.addLabel("reveal");

      tl.fromTo(
        [".glviewer", ".view-default"],
        {
          y: 0,
        },
        {
          y: 250,
          duration: frames(75),
          ease: "cubic.in",
        },
        `reveal+=${frames(0)}`
      );

      tl.fromTo(
        [".glviewer", ".view-default"],
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: frames(55),
          ease: "linear",
        },
        `reveal+=${frames(20)}`
      );

      if (this.$refs.background) {
        tl.add(
          (this.$refs.background as typeof AnimateSprite).enter({
            duration: frames(60),
          }),
          `reveal+=${frames(0)}`
        );
      }

      tl.addLabel("link");

      const $UIBigLinkCollection = Array.from(
        el.querySelectorAll(".UIBigLink")
      );

      tl.fromTo(
        $UIBigLinkCollection,
        {
          x: 25,
          y: -110,
          rotation: 15,
        },
        {
          x: 0,
          y: 0,
          rotation: 0,
          duration: frames(60),
          stagger: frames(6),
          ease: gsap.parseEase("0.12, 1.30, 0.29, 1.00"),
        },
        `link+=${frames(0)}`
      );

      tl.fromTo(
        $UIBigLinkCollection,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: frames(30),
          stagger: frames(6),
          ease: "linear",
        },
        `link+=${frames(0)}`
      );

      tl.fromTo(
        this.$refs.footer as Element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        `link+=${frames(0)}`
      );
    },

    onLeave(el: HTMLElement, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({
        autoRemoveChildren: true,
        onComplete,
      });

      tl.addLabel("link");

      const $UIBigLinkCollection = Array.from(
        el.querySelectorAll(".UIBigLink")
      );

      tl.to(
        $UIBigLinkCollection,
        {
          x: -25,
          y: -110,
          rotation: -15,
          duration: frames(60),
          stagger: frames(6),
          ease: gsap.parseEase("0.12, 1.30, 0.29, 1.00"),
        },
        `link+=${frames(0)}`
      );

      tl.to(
        $UIBigLinkCollection,
        {
          opacity: 0,
          duration: frames(30),
          stagger: frames(6),
          ease: "linear",
        },
        `link+=${frames(0)}`
      );

      tl.to(
        this.$refs.footer as Element,
        {
          opacity: 0,
        },
        `link+=${frames(0)}`
      );

      tl.addLabel("reveal", `-=${frames(20)}`);

      if (this.$refs.background) {
        tl.add(
          (this.$refs.background as typeof AnimateSprite).leave({
            duration: frames(60),
          }),
          `reveal+=${frames(0)}`
        );
      }

      tl.to(
        [".glviewer", ".view-default"],
        {
          y: 0,
          opacity: 1,
          duration: frames(75),
          ease: "cubic.out",
        },
        `reveal+=${frames(20)}`
      );

      // tl.duration(frames(30));
    },

    onCollabClick() {
      setMenuOpen(false);
      this.send("COLLAB");
    },

    onAgendaClick() {
      setMenuOpen(false);
      this.send("AGENDA");
    },

    onHomeClick() {
      setMenuOpen(false);
      const key = Object.keys(this.state.value)[0];
      // const state = key + "." + this.state.value[key];
      if (key !== AppStateEnum.STEP_ME && key !== AppStateEnum.MUSEUM) {
        if (key === AppStateEnum.COLLAB)
          return AppService.send("home_fromcollab");
        if (key === AppStateEnum.ARTWORK)
          return AppService.send("home_fromartwork");
        return AppService.send("home_fromfeel");
      }
    },

    eventPush(action, cat = "") {
      Tracking.eventPush(action, cat);
    },
  },
});
