import { IActivity } from "@/webgl/activities/Activity";
import FeelActivity from "@/webgl/activities/FeelActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class FeelActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new FeelActivity(scene)
  }
}

let LoadingActivityService = new FeelActivityServiceImpl('feel')

export default LoadingActivityService


/////////////
/////////////////
//////////////////////
/////////////////////////////////////
/////////////////////////////////////////////////
 
//////////

