
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";

import UIBtnPrimary from "@/components/UIBtn/UIBtnPrimary.vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      title: "This is the Outro",
    };
  },

  computed: {
    isOutro(): boolean {
      return (
        this.state.matches("step_do.outro") ||
        this.state.matches("step_love.outro") ||
        this.state.matches("step_speak.outro") ||
        this.state.matches("step_see.outro")
      );
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=1");

      tl.fromTo(
        [el.querySelector(".Title") as Element],
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: "sine.out" },
        "start"
      );

      tl.add((this.$refs.btnNext as typeof UIBtnPrimary)?.enter(), "-=0.25");
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        [el.querySelector(".Title") as Element],
        { opacity: 0, duration: 1, ease: "sine.out" },
        "start"
      );
    },

    next() {
      (this.$refs.btnNext as typeof UIBtnPrimary)?.leave();
      this.send("ENTER");
    },
  },

  watch: {
    state: {
      immediate: true,

      handler(newState: AppState, oldState: AppState) {
        if (this.state.matches("step_do.outro")) {
          this.title = "The earth";
        }
        if (this.state.matches("step_love.outro")) {
          this.title = "The water";
        }
        if (this.state.matches("step_speak.outro")) {
          this.title = "The air";
        }
        if (this.state.matches("step_see.outro")) {
          this.title = "The sun";
        }

        // this.$nextTick(this.enter);
      },
    },
  },
});
