
import Program   from 'nanogl/program'

import debugFboVs from '@/webgl/glsl/debug-fbo.vert';
import debugFboFs from '@/webgl/glsl/debug-fbo.frag';

import blitVs from '@/webgl/glsl/blit.vert';
import blitFs from '@/webgl/glsl/blit.frag';

import unlitTextureVs from '@/webgl/glsl/unlit-texture.vert';
import unlitTextureFs from '@/webgl/glsl/unlit-texture.frag';


import unlitColorVs from '@/webgl/glsl/unlit-color.vert';
import unlitColorFs from '@/webgl/glsl/unlit-color.frag';


import unlitTextureLineVs from '@/webgl/glsl/unlit-texture-line.vert';
import unlitTextureLineFs from '@/webgl/glsl/unlit-texture-line.frag';

import skyboxVs from '@/webgl/glsl/skybox/skybox.vert';
import skyboxFs from '@/webgl/glsl/skybox/skybox.frag';

import faderVs from "@/webgl/glsl/fader/camera-fader.vert";
import faderFs from "@/webgl/glsl/fader/camera-fader.frag";

import textWorldVs from "@/webgl/glsl/text-world/text-world.vert";
import textWorldFs from "@/webgl/glsl/text-world/text-world.frag";

import { GLContext, isWebgl2 } from 'nanogl/types';
import Scene from '@/webgl/Scene';

/**
 * checkHighP
 * @param {WebGLRenderingContext} gl
 */
function checkHighP( gl:GLContext ){

  const hv = gl.getShaderPrecisionFormat( gl.VERTEX_SHADER, gl.HIGH_FLOAT );
  const hf = gl.getShaderPrecisionFormat( gl.FRAGMENT_SHADER, gl.HIGH_FLOAT );
  return  hf.precision > 0 && hv.precision > 0;

}


type PEntry = {
  id:string
  prg:Program
  vert:string
  frag:string
  ext: string
}



export default class Programs {

  hasHighp: boolean;

  programsMap : Map<string, PEntry> = new Map()
  programs: PEntry[] = [];


  /**
   *
   * @param {import('scene').default} scene
   */
  constructor( private scene:Scene ){

    const gl = scene.gl;

    // TODO: test if available
    // gl.getExtension('OES_standard_derivatives');

    this.hasHighp = checkHighP( gl );

    this.create( 'debugFbo'           , debugFboVs         (), debugFboFs         () )
    this.create( 'blit'               , blitVs             (), blitFs             () )
    this.create( 'unlit-texture'      , unlitTextureVs     (), unlitTextureFs     () )
    this.create( 'unlit-color'        , unlitColorVs       (), unlitColorFs       () )
    this.create( 'unlit-texture-line' , unlitTextureLineVs (), unlitTextureLineFs () )
    this.create( "skybox"             , skyboxVs           (), skyboxFs           () )
    this.create( "fader"              , faderVs            (), faderFs            () )

    const isGL2 = isWebgl2(gl);
    let extstr = "";
    if(isGL2){
      extstr += "#version 300 es\n"
    } else {
      const ext = gl.getExtension("OES_standard_derivatives");
      extstr += "#extension GL_OES_standard_derivatives : enable\n"
    }

    this.create( 'text-world'         , textWorldVs ()       , textWorldFs          (), extstr )

    this.compile();
  }


  create( id:string, vert:string, frag:string, ext: string = null, compile = false ) : Program {
    // if( this.programsMap.has(id)) throw `${id} already exist`

    if( this.programsMap.has(id)){
      return this.programsMap.get(id).prg;
    }

    const prg = new Program(this.scene.gl);
    const entry = {prg, vert, frag, id, ext};
    this.programsMap.set( id , entry );
    this.programs.push( entry )

    if( compile )
      entry.prg.compile( entry.vert, entry.frag,  this.getGlobalDefinitions() );
    return prg;
  }

  update( id:string, vert?:string, frag?:string ) : void {
    const entry = this.programsMap.get(id)
    const v = entry.vert;
    const f = entry.frag;
    vert = vert || v;
    frag = frag || f;
    let defs = this.getGlobalDefinitions()
    if(entry.ext){
      defs = entry.ext + defs;
    }
    if( ! entry.prg.compile( vert , frag , defs ) ) {
      // restore
      entry.prg.compile( v, f, defs )
    } else {
      entry.vert = vert;
      entry.frag = frag;
    }
  }


  get( id:string):Program {
    return this.programsMap.get(id).prg;
  }


  precision(){
    return this.hasHighp ? 'highp' : 'mediump';
  }


  getGlobalDefinitions() : string {
    var defs = '\n';
    defs += 'precision ' + this.precision() + ' float;\n';
    return defs;
  }

  compile( ){

    for (const entry of this.programs) {
      let defs = this.getGlobalDefinitions()
      if(entry.ext){
        defs = entry.ext + defs;
      }
      entry.prg.compile( entry.vert, entry.frag, defs );
    }


    this.process();

  }


  process(){
    for (var entry of this.programs ) {
      entry.prg.use();
    }
  }


  dispose(){

    for (var entry of this.programs ) {
      entry.prg.dispose();
    }
    this.programs = null;
    this.programsMap = null

  }

}

