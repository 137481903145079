import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92edd0e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AppHeader z-ui" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBrandLogo = _resolveComponent("UIBrandLogo")!
  const _component_UIMenuToggleBtn = _resolveComponent("UIMenuToggleBtn")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _withDirectives(_createVNode(_component_UIBrandLogo, null, null, 512), [
      [_vShow, _ctx.showBrand]
    ]),
    _withDirectives(_createVNode(_component_UIMenuToggleBtn, {
      onClick: _ctx.toggleMenu,
      menuOpen: _ctx.openMenu,
      disabled: _ctx.disabledMenu
    }, null, 8, ["onClick", "menuOpen", "disabled"]), [
      [_vShow, _ctx.showMenu]
    ])
  ]))
}