
import { defineComponent } from "vue";
import mantras from "@/store/datas/mantras.json";
import rules from "@/store/datas/ageGateRules.json";
import Paths from "@/core/Paths";

export default defineComponent({
  props: {
    country: {
      type: String,
      required: true,
    },
    age: {
      type: Number,
      required: true,
    },
    mantra: {
      type: String,
      required: true,
    },
    mood: {
      type: String,
      required: true,
    },
    guid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      backgroundLoad: false,
    };
  },

  computed: {
    mantraImg(): string {
      if (this.mantra) {
        const mantra = mantras.find(
          (m) => m.id === parseInt(this.mantra as string)
        );
        if (mantra) {
          return Paths.resolve(`./assets/mantras/${mantra.imgid}.png`);
        }
      }
      return Paths.resolve(`./assets/mantras/${mantras[0].imgid}.png`);
    },

    formatCountry(): string {
      return (rules.find((rule) => rule.country_code === this.country)
        ?.country_name || this.country) as string;
    },

    formatAge(): string {
      return `${this.age} years`;
    },

    backgroundURL(): string | boolean {
      if (this.guid) {
        return `/api/background/${this.guid}.png`;
      }
      return false;
    },
  },

  setup() {},
});
