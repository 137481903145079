import { reactive } from "@vue/runtime-core";

export interface IFace {
  x?: number,
  y?: number,
  age?: number,
  guid?: string,
  mood?: string,
  width?: number,
  mantra?: string
  country?: string,
  isMyFace?: boolean
}

export const Face = reactive({
  x: 0,
  y: 0,
  age: 0,
  mood: '',
  guid: '',
  width: 0,
  mantra: '',
  isRoll: false,
  country: '',
  isMyFace: false
})

export function setAge(age: number) {
  Face.age = age
}

export function setCountry(country: string) {
  Face.country = country
}

export function setMood(mood: string) {
  Face.mood = mood
}

export function setMantra(mantra: string) {
  Face.mantra = mantra
}

export function setGuid(guid: string) {
  Face.guid = guid
}

export function setX(x: number) {
  Face.x = x
}

export function setY(y: number) {
  Face.y = y
}

export function setWidth(width: number) {
  Face.width = width
}

export function setIsRoll(isRoll: boolean) {
  Face.isRoll = isRoll
}

export function setIsMyFace(isMyFace: boolean) {
  Face.isMyFace = isMyFace
}

export function noRoll() {
  setIsRoll(false)
}

export function setFace(face: IFace) {
  setX(face.x)
  setY(face.y)
  setAge(face.age)
  setMood(face.mood)
  setGuid(face.guid)
  setWidth(face.width)
  setIsRoll(true)
  setMantra(face.mantra)
  setCountry(face.country)
  setIsMyFace(!!face.isMyFace)
}