import {Viewport} from "@/store/modules/Viewport";
import browser from "browser-detect";

const browserInfo = browser();

export default {
  ...browserInfo,
  ipad: !!(
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === "MacIntel" && "standalone" in window.navigator)
  ),
  // determines if user has access only to simplified mobile version of the website
  mobileVersion: browserInfo.mobile && Viewport?.windowWidth < 600,
  oculus: !!navigator.userAgent.match(/(OculusBrowser)/),
  isFacebook:
    (
      navigator.userAgent.indexOf("FBAN") > -1 ||
      navigator.userAgent.indexOf("FBAV") > -1
    ) &&
    navigator.userAgent.indexOf("MessengerLiteForiOS") < 0,
  isInsta: navigator.userAgent.indexOf("Instagram") > -1,
  isSnap: navigator.userAgent.indexOf("Snapchat") > -1,
};
