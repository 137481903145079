import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { CreatePane, Presetpane } from "@/dev/Tweak";
import { Viewport } from "@/store/modules/Viewport";
import { setFPS } from "@/utils/FrameToTime";
import MuseumActivity from "@/webgl/activities/MuseumActivity";
import CameraAnimations, { MUSEUM_ANIMATION_ID_NB } from "@/webgl/activities/MuseumActivity/CameraAnimations";
import MaxController from "@/webgl/camera/MaxController";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import GLApp from "@/webgl/main";
import { DEG2RAD } from "@/webgl/math";
import { mat4, vec2, vec3 } from "gl-matrix";
import gsap, { Quart } from "gsap";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";

const V3A = vec3.create()
const V3A2 = vec3.create()
const V3A3 = vec3.create()
const FORWARD = vec3.create()
vec3.set(FORWARD, 0, 0, 1)

export default class MuseumCameraController {

  public mousePos: vec2
  public mouseXPrev: number
  public currentPosition: MUSEUM_ANIMATION_ID_NB

  private _init = false

  private _debugMode: boolean = false;
  private _debugCtrl: MaxController;
  private _camera: Camera;
  private _animations: CameraAnimations;
  private _fov: number = 50;

  private _mX = 0
  private _mY = 0
  private _lerpMouseY: number
  private _doZoom: boolean
  private _zoomDo: number
  private _camZoomStart: vec3

  private _buttons: Node[]

  private canMoveMouse: boolean

  get Camera(): Camera {
    return this._camera;
  }

  set mouseY(n: number) {
    this._animations.mouseY = n
  }

  get mouseY(): number {
    return this._animations.mouseY
  }

  constructor(
    public activity: MuseumActivity
  ) {
    this._camZoomStart = vec3.create()
    this.makeCamera();
    this._animations = new CameraAnimations(activity.scene);
    this.activity.root.add(this._camera);

    this._debugCtrl = new MaxController(this.activity.scene.ilayer);

    this.mousePos = vec2.create()

    this._mX = this._mY = this._lerpMouseY = 0

    this.mouseY = 0

    this.canMoveMouse = true

/////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
/////////////////////////////////
//////////////////
//////////////
//////////////
///////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////
///////////////
/////////////
//////
/////////////////////////////////////////////
///////////////
//////////////
//////
////////////////////////////////////////
//////////////

  }

  public init() {
    const gltf = GLApp.getInstance().scene.museumScene.gltf
    this._buttons = [gltf.getElementByName(GltfTypes.NODE, "BtnCollab"), gltf.getElementByName(GltfTypes.NODE, "BtnArtwork"), gltf.getElementByName(GltfTypes.NODE, "BtnXp")]

    this._init = true
  }

  public syncTo = (from = 0) => {
    this._animations.syncTo(from)
    vec3.copy(this._camera.position, this._animations.camPos)

    this._camera.lookAt(this._animations.lookatv)

  }

  public mainCam = () => {
    this._debugMode = false;
    this._debugCtrl.stop();
  }

  public debugCam = () => {
    this._debugMode = true;
    this._debugCtrl.start(this._camera);
  }

  public ready(lookat: Node) {
    this._animations.ready(lookat)
  }

  playIntro() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Intro, MUSEUM_ANIMATION_ID_NB.AgeGate)
  }

  skipAgeGate() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Intro, MUSEUM_ANIMATION_ID_NB.Home, true)
  }

  goToHome() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.AgeGate, MUSEUM_ANIMATION_ID_NB.Home)
  }

  goToFeel() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Home, MUSEUM_ANIMATION_ID_NB.Feel)
  }

  goToDo() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Feel2, MUSEUM_ANIMATION_ID_NB.Do)
  }

  goToArtwork(duration = 4) {
    gsap.to(this, { duration, _mX: 0, _mY: 0, ease: Quart.easeInOut })
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Home, MUSEUM_ANIMATION_ID_NB.Artwork, false, duration)
  }

  goToCollab(duration = 4) {
    gsap.to(this, { duration, _mX: 0, _mY: 0, ease: Quart.easeInOut })
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Home, MUSEUM_ANIMATION_ID_NB.Collab, false, duration)
  }

  goToHomeFromArtwork() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Artwork, MUSEUM_ANIMATION_ID_NB.Home)
  }

  goToHomeFromCollab() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Collab, MUSEUM_ANIMATION_ID_NB.Home)
  }

  goToHomeFromXP() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Feel, MUSEUM_ANIMATION_ID_NB.Home)
  }

  goToHomeFromFeel() {
    return this.playFromTo(MUSEUM_ANIMATION_ID_NB.Feel, MUSEUM_ANIMATION_ID_NB.Home)
  }

  async playFromTo(from: MUSEUM_ANIMATION_ID_NB, to: MUSEUM_ANIMATION_ID_NB, isSkipAgeGate = false, duration?: number) {
    AudioManager.playUI(AUDIO_ID.UI_TRANSITION)
    const anim = await this._animations.goto(from, to, isSkipAgeGate, duration)
    this.currentPosition = to
    return anim
    // return Promise.resolve()

  }

  stop() {

  }

  async setDoZoom() {
    this._doZoom = true
    this._zoomDo = 0
    vec3.copy(this._camZoomStart, this._camera.position)
    await gsap.to(this, { duration: 2, _zoomDo: 1, ease: Quart.easeIn })
    this._doZoom = false
  }

  update(dt: number) {
    if (this._debugMode)
      this._debugCtrl.update(dt);

    if (this._animations.ready) {

      vec3.copy(this._camera.position, this._animations.camPos)

      if (this._doZoom) {
        vec3.sub(V3A, this._animations.lookatv, this._camera.position)
        vec3.normalize(V3A, V3A)
        vec3.scale(V3A2, V3A, this._zoomDo * 5)
        vec3.add(this._camera.position, this._camZoomStart, V3A2)
      }

      this._camera.lookAt(this._animations.lookatv)

      if (this.canMoveMouse && Viewport.isDesktop) {
        const w = this.activity.scene.glview.canvasWidth
        const h = this.activity.scene.glview.canvasHeight

        const x = (this.mousePos[0] - w / 2) / (w / 2)
        const y = (this.mousePos[1] - h / 2) / (h / 2)

        this._mX += (x - this._mX) * 0.1
        this._mY += (y - this._mY) * 0.1
      }

      this._camera.rotateX(-this._mY * 0.025)
      this._lerpMouseY += (this.mouseY - this._lerpMouseY) * 0.1
      this._camera.rotateY(-this._mX * 0.025 + this._lerpMouseY)

    }

    if (!Viewport.isDesktop) {
      this._camera.updateWorldMatrix()
      vec3.set(V3A3, this._camera._wmatrix[12], this._camera._wmatrix[13], this._camera._wmatrix[14])
      vec3.copy(V3A, FORWARD)
      vec3.transformQuat(V3A2, V3A, this._camera.rotation)
      vec3.normalize(V3A, V3A2)

      for (let i = 0; i < this._buttons.length; i++) {
        const node = this._buttons[i];
        vec3.set(V3A2, node._wmatrix[12], node._wmatrix[13], node._wmatrix[14])
        vec3.sub(V3A2, V3A3, V3A2)
        vec3.normalize(V3A2, V3A2)
        const dot = vec3.dot(V3A, V3A2)
        if (dot > 0.985) {
          return i
        }
      }
    }
    return -1
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 250

    this._camera = camera;

  }

  setMoveMouse(canMoveMouse: boolean) {
    this.canMoveMouse = canMoveMouse
  }

}