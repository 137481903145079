
import { defineComponent } from "vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import Tracking from "@/core/Tracking";

export default defineComponent({
  setup() {},

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  methods: {
    share() {
      const url: string = this.url as string;
      if (navigator.share) {
        Tracking.eventPush("footer-link", "share");
        navigator
          .share({
            title: "Jeppe Hein x ruinart 2022",
            text: "Visit jeppehein.ruinart.com",
            url,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.error("Error sharing", error));
      } else {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(url)
            .then(() => {
              console.log("link copied");
              AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);
              this.$emit("copied");
            })
            .catch((error) => console.error("Error coping", error));
        } else {
          console.warn(
            "no fallback for navigator.share and navigator.clipboard apis"
          );
        }
      }
    },
  },
});
