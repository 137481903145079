import PlaneGeometry from "@/webgl/lib/PlaneGeometry"
import Scene from "@/webgl/Scene"
import Camera from "nanogl-camera"
import GLConfig from "nanogl-state/config"
import Program from "nanogl/program"
import Texture2D from "nanogl/texture-2d"
import Node from "nanogl-node"
import gsap, { Quart, Sine } from "gsap"
import { vec2 } from "gl-matrix"
import { Viewport } from "@/store/modules/Viewport"

export default class Background {

  public paused: boolean
  public kenBurnsV: number

  video: HTMLVideoElement
  videoCanvas: HTMLCanvasElement
  videoContext: CanvasRenderingContext2D
  videoTex: Texture2D
  wallTex: Texture2D
  vineTex: Texture2D
  videoTime: number
  videoVineTime: number

  dimensions: {
    width: number,
    height: number,
    aspect: number
  }


  videoVine: HTMLVideoElement
  videoVineCanvas: HTMLCanvasElement
  videoVineContext: CanvasRenderingContext2D
  videoVineAspect: number[]

  inTransition = false
  inTransitionSlide = false
  inTransitionFade = false
  inTransitionInside = false
  fade = 0
  transitionV = 0
  aspect: vec2

  isPlain1: boolean

  isPlain2: boolean

  showInsideFrame: boolean

  tex1: Texture2D = null;
  tex2: Texture2D = null;
  plainValues = [];

  scrollX: number

  callback: Function

  constructor(private planeGeom: PlaneGeometry, private bgProgram: Program, private glconfig: GLConfig, public scene: Scene, public camera: Camera, public root: Node, private videoBlob: Blob) {

    this.wallTex = this.scene.resources.get("wall-tex")
    this.wallTex.bind()
    this.wallTex.repeat()

    this.aspect = vec2.create()

    this.kenBurnsV = 0

    this.scrollX = 0

    this.createVideo()
    this.createVideoVine()

  }

  set(tex1: Texture2D = null, tex2: Texture2D = null, plainValues = []) {
    this.tex1 = tex1
    this.tex2 = tex2
    this.plainValues = plainValues

    this.inTransition = this.inTransitionSlide = this.inTransitionFade = false
    this.transitionV = 0

    this.isPlain1 = this.tex1 === null && this.plainValues[0]?.length > 0
    this.isPlain2 = this.tex2 === null && this.plainValues[1]?.length > 0

  }

  createVideoVine() {
    this.videoVine = document.createElement("video")
    this.videoVine.src = URL.createObjectURL(this.scene.resources.get("vine"))
    this.videoVine.playsInline = true
    this.videoVine.muted = true
    this.videoVine.loop = true
    this.videoVineAspect = [1, 1]
    this.videoVine.onloadedmetadata = () => this._resize(this.dimensions.aspect, this.dimensions.width, this.dimensions.height)

    this.videoVineCanvas = document.createElement("canvas")
    this.videoVineContext = this.videoVineCanvas.getContext("2d")
    this.videoVineContext.imageSmoothingEnabled = false
    this.vineTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.vineTex.setFilter(true, false, false)
    this.vineTex.clamp()
    this.videoVine.play()
  }

  createVideo() {
    this.video = document.createElement("video")
    this.video.src = URL.createObjectURL(this.videoBlob)
    this.video.playsInline = true
    this.video.muted = true

    this.videoCanvas = document.createElement("canvas")
    this.videoContext = this.videoCanvas.getContext("2d")
    this.videoContext.imageSmoothingEnabled = false
    this.videoTex = new Texture2D(this.scene.gl, this.scene.gl.RGBA)
    this.videoTex.setFilter(true, false, false)
    this.videoTex.clamp()
    // document.body.appendChild(this.videoCanvas)
    // this.videoCanvas.style.position = "fixed"
    // this.videoCanvas.style.width = "20%"
    // this.videoCanvas.style.left = "0"
    // this.videoCanvas.style.top = "0"
    // this.videoCanvas.style.pointerEvents = "none"
  }

  transition(type: number = 0, callback: Function = null, appear = false, delay = 0) {
    switch (type) {
      case 0:
        this.transitionVideo(callback)
        break;
      case 1:
        this.transitionSlide(callback, appear)
        break;
      case 2:
        this.transitionFade(callback, delay)
        break;
      case 3:
        this.transitionInside(callback, appear)
        break;
      case 4:
        this.transitionFakeSlide(callback, appear, delay)
        break;
    }
  }

  async transitionFade(callback: Function = null, delay: number) {
    this.inTransitionFade = true
    this.fade = 0
    await gsap.to(this, { duration: 0.5, fade: 1, delay, ease: Sine.easeOut })
    this.inTransitionFade = false
    if (this.plainValues.length > 1) this.plainValues[0] = this.plainValues[1]
    this.tex1 = this.tex2
    this.isPlain1 = this.isPlain2
    if (callback) callback()
  }

  async transitionInside(callback: Function = null, appear: boolean) {
    this.inTransitionInside = true
    if (appear) {
      this.showInsideFrame = true
      this.transitionV = Viewport.isDesktop ? -1 : -1.5
    }

    await gsap.to(this, { duration: 2, transitionV: appear ? 0 : Viewport.isDesktop ? 1 : 1.5, ease: Quart.easeInOut })

    if (!appear) this.showInsideFrame = false
    if (callback) callback()
  }

  async transitionSlide(callback: Function = null, appear: boolean) {
    this.inTransitionInside = true
    if (appear) this.root.x = 2
    this.root.invalidate()
    this.root.updateWorldMatrix()
    await gsap.to(this.root, {
      duration: 2, x: appear ? 0 : -2,
      ease: Quart.easeInOut,
      onUpdate: () => {
        this.root.invalidate()
        this.root.updateWorldMatrix()
      }
    })
    this.inTransitionInside = false
    if (callback) callback()
  }

  async transitionFakeSlide(callback: Function = null, left: boolean, delay: number = 0) {
    this.inTransitionInside = true

    const slideFactor = Viewport.isDesktop ? 1 : 1.1
    await this.slideWall(
      left ? 1 * slideFactor : -1 * slideFactor,
      2,
      delay,
      Quart.easeInOut
    )

    this.inTransitionInside = false
    if (callback) callback()
  }

  transitionVideo(callback: Function = null) {
    this.videoTime = 0
    this.video.currentTime = 0
    this.video.play()
    this.inTransition = true
    this.callback = callback
  }

  async endTransition() {
    if (this.plainValues.length > 1) this.plainValues[0] = this.plainValues[1]
    this.tex1 = this.tex2
    this.isPlain1 = this.isPlain2
    this.inTransition = false
    await this.video.pause()
    if (this.callback) this.callback()
  }

  async slideWall(val: number = 0.5, duration: number = 1.2, delay: number = 0, easeFunc = Quart.easeOut) {
    await gsap.to(this, { duration, scrollX: this.scrollX + val, delay, ease: easeFunc })
  }

  preRender() {
    if (this.paused) return
    if (this.inTransition) this.drawVideo()
  }

  outro() {

  }

  drawVideo() {
    if (this.video.currentTime !== this.videoTime) {
      this.videoContext.drawImage(this.video, 0, 0, this.videoCanvas.width, this.videoCanvas.height)
      this.videoTex.fromImage(this.videoCanvas)
      if (this.video.currentTime === this.video.duration) this.endTransition()
      this.videoTime = this.video.currentTime
    }

  }

  drawVineVideo() {
    if (this.videoVine.currentTime !== this.videoVineTime) {
      this.videoVineContext.drawImage(this.videoVine, 0, 0, this.videoVineCanvas.width, this.videoVineCanvas.height)
      this.vineTex.fromImage(this.videoVineCanvas)
      this.videoVineTime = this.videoVine.currentTime
    }

  }

  render() {
    // if(this.paused || this.videoTime === 0) return
    this.drawVineVideo()
    this.scene.glstate.now(this.glconfig)
    const M4 = this.camera.getMVP(this.root._wmatrix);
    this.bgProgram.use()
    this.bgProgram.uMVP(M4);
    this.bgProgram.uAspect(this.aspect);
    this.bgProgram.uIsMobile(Viewport.isDesktop ? 0 : 1);
    this.bgProgram.uWall(this.wallTex)
    this.bgProgram.uScrollX(this.scrollX)
    this.bgProgram.uVine(this.vineTex)
    this.bgProgram.uVineAspect(this.videoVineAspect)
    this.bgProgram.uKenBurn(this.kenBurnsV)
    this.bgProgram.uPlain1(this.isPlain1 ? 1 : 0)
    this.bgProgram.uPlain2(this.isPlain2 ? 1 : 0)
    if (this.inTransitionFade) {
      this.bgProgram.uTransitionFade(1)
      this.bgProgram.uFade(this.fade)
      this.bgProgram.uCol1(this.plainValues[1]);
    } else {
      this.bgProgram.uTransitionFade(0)
    }

    if (this.showInsideFrame) {
      this.bgProgram.uInsideFrame(1)
      this.bgProgram.uLimitX(Viewport.isDesktop ? 0.33 : 1)
      this.bgProgram.uTransitionInside(this.transitionV)
    } else {
      this.bgProgram.uInsideFrame(0)
    }

    if (this.inTransition) {
      this.bgProgram.uVideo(this.videoTex);
      this.bgProgram.uTransition(this.video.currentTime / this.video.duration);

      if (!this.isPlain1) this.bgProgram.uTex(this.tex1);
      else this.bgProgram.uCol(this.plainValues[0]);
      if (!this.isPlain2) {
        this.bgProgram.uTex1(this.tex2);
      }
      else this.bgProgram.uCol1(this.plainValues[1]);
    } else {
      this.bgProgram.uTransition(0);
      if (!this.isPlain1) this.bgProgram.uTex(this.tex1);
      else this.bgProgram.uCol(this.plainValues[0]);
    }
    this.planeGeom.bind(this.bgProgram);
    this.planeGeom.draw();
  }

  _resize(aspect, width, height) {
    this.dimensions = {
      width,
      height,
      aspect
    }

    if (width > height) vec2.set(this.aspect, 1, aspect)
    else vec2.set(this.aspect, aspect, 1)
    this.videoCanvas.width = width / 2
    this.videoCanvas.height = height / 2

    this.videoVineCanvas.width = width / 10
    this.videoVineCanvas.height = height / 10

    if (this.videoVine.videoWidth && this.videoVine.videoHeight) {
      this.videoVineAspect = width > height
        ? [1, this.videoVine.videoWidth / this.videoVine.videoHeight]
        : [this.videoVine.videoHeight / this.videoVine.videoWidth, 1]
    }
  }

  destroy() {
    URL.revokeObjectURL(this.video.src)
    this.video = null
    this.videoCanvas.width = 1
    this.videoCanvas.height = 1
    this.videoCanvas = null
    this.videoContext = null
    this.videoTex.dispose()

    URL.revokeObjectURL(this.videoVine.src)
    this.videoVine = null
    this.videoVineCanvas.width = 1
    this.videoVineCanvas.height = 1
    this.videoVineCanvas = null
    this.videoVineContext = null
    this.vineTex.dispose()
  }



}