



import MuseumAssets from '@/assets/webgl/museum2';
import Masks from '@/webgl/gl/Masks';
import Input, { Sampler, Uniform } from 'nanogl-pbr/Input';

import { TextureResource } from '@/webgl/assets/TextureResource';

import ReflectDistPass from '@/webgl/glsl/reflect_dist';
import UnlitPass    from 'nanogl-pbr/UnlitPass'                      ;

import BaseMaterial from 'nanogl-pbr/BaseMaterial';
import { Passes } from '@/webgl/glsl/Passes';
import GLConfig from 'nanogl-state/config';

import { TextureSrcSet } from '@/webgl/assets/TextureRequest';
import TexCoord from 'nanogl-pbr/TexCoord';
import { StandardMetalness, StandardSpecular } from 'nanogl-pbr/StandardPass';
import LightmapChunk from '@/webgl/glsl/standard_lm/LightmapChunk';
import FloorReflectionChunk from '@/webgl/glsl/standard_ssr/FloorReflectionChunk';
import TextureLibrary from '@/webgl/assets/TextureLibrary';
import Scene from '@/webgl/Scene';
import Texture2D from 'nanogl/texture-2d';
import { HexToVec, MakeFromHex } from '@/webgl/lib/color';
import { Pane } from 'tweakpane';
import { CreatePane } from '@/dev/Tweak';
import GL from '@/webgl/gl/GL';
import Flag from 'nanogl-pbr/Flag';
import TexturesLoader from '@/webgl/assets/TextureLoader';
import MuseumScene from '@/webgl/scenes/MuseumScene';
import ShaderVersion from 'nanogl-pbr/ShaderVersion';
import { isWebgl2 } from 'nanogl/types';
import GLApp from '@/webgl/main';
import { Viewport } from '@/store/modules/Viewport';


type MaterialName = 
  "Concrete_Wall" | 
  "Ground" | 
  "structure" |
  "Triangle_mirror" |
  "White_boards" |
  "Face_circle" |
  "Jeppe_Wall" |
  "Nuage" |
  "Purple" |
  "BtnMat" |
  "Plinthes"

const LM_GAMMA = 2.9
const LM_EXPO = 1.25


export default class MaterialFactory {
  
///////////////
//////////
////////////

  glslVersion: any;
  lightmapMultiplyer: Input;
  lightmapMultiplyerUniform: Uniform;
  lightmap_mul : number   = LM_EXPO
  lightmap_gamma : number = LM_GAMMA

  faceMaterial:BaseMaterial;

  lightmapGamma: Input;
  lightmapGammaUniform: Uniform;
  
  reflectDistPass: ReflectDistPass;
  texs: TextureLibrary;
  lightmaps: TextureLibrary;
  private _isInit : boolean = false;

  floorReflectivity = 2.0
  floorAlbedoBoost = .85

  floorAlbedoBoostUniform: Uniform;
  floorReflectivityUniform: Uniform;
  matByNames: Map<string, BaseMaterial>;
  floorColorMultiplierUniform: Uniform;
  reflChunk: FloorReflectionChunk;
  
  static makeBBCTextureSet( path: string, bbc = false ): TextureSrcSet {
  
    const sources: [string, string][] = [
      ['std' , MuseumAssets.getPath(path) ],
    ] 
    
    return new TextureSrcSet( sources )
  }

  
  constructor( private museum: MuseumScene  ) {
    
    if( this._isInit ) return;

    this.glslVersion = isWebgl2(museum.scene.gl) ? '300 es' : '100';

    this.texs = new TextureLibrary()
    this.lightmaps = new TextureLibrary()
    this.matByNames = new Map<string, BaseMaterial>()
    
    this.reflectDistPass = new ReflectDistPass();
    this.reflectDistPass.mask = Masks.REFLECTED;
  
    this.lightmapMultiplyer = new Input( 'LightmapMultiplier'  , 1 )
    this.lightmapMultiplyerUniform = new Uniform('ulmlum', 1 )
    this.lightmapMultiplyerUniform.set( this.lightmap_mul)
    
    this.lightmapGamma = new Input( 'LightmapGamma'  , 1 )
    this.lightmapGammaUniform = new Uniform('ulmgam', 1 )
    this.lightmapGammaUniform.set( this.lightmap_gamma)

    // / #if DEBUG
    this.lightmapMultiplyer.attach( this.lightmapMultiplyerUniform );
    this.lightmapGamma.attach( this.lightmapGammaUniform );
    // /// #else
    // this.lightmapMultiplyer.attachConstant( this.lightmap_mul );
    // this.lightmapGamma.attachConstant( this.lightmap_gamma );
    // /// #endif
    if(this.museum.isReflect) {
      this.reflChunk = new FloorReflectionChunk();
      this.reflChunk.reflectionTexture = this.museum.reflect.getOutput();
      this.floorAlbedoBoostUniform = new Uniform("albedoMult", 3)
      this.floorReflectivityUniform = new Uniform("fru", 1)
      this.reflChunk.strength.attach(this.floorReflectivityUniform);
      console.log("museum reflect chunk created")
      // this.floorAlbedoBoostUniform.set(this.floorAlbedoBoost, this.floorAlbedoBoost, this.floorAlbedoBoost)
      this.floorReflectivityUniform.set(this.floorReflectivity)

    }

    this.createTextures();


/////////////////
///////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////
  }



  // setQuality(level: QualityLevel) {
    // if( level.reflect ){
    //   this.floorAlbedoBoostUniform.set( this.floorAlbedoBoost, this.floorAlbedoBoost, this.floorAlbedoBoost)
    // } else {
    //   this.floorAlbedoBoostUniform.set( 1, 1, 1 )

    // }
  // }


  getMaterial = (name: string):BaseMaterial => {
    
    if( this.matByNames.has(name) ) {
      return this.matByNames.get(name)
    }

    const reg = /(.+)_LM_(\d+)$/
    const res = reg.exec( name )
    // console.log(res);
    let basename = name
    if( res !== null ){
      // has lightmap
      basename = res[1]
    }


    const mat = this.createMaterial(basename as MaterialName)

    if( res !== null ){
      const udim = Number(res[2]) + 1
      const lm_name = `LM.${udim}`

      const lm = this.getLightmapResource(lm_name)
      this.addLightmap( mat, lm )
    }

    this.matByNames.set( name, mat )
    return mat

  }


  getLightmapResource( name:string ){
    if( !this.lightmaps.has( name ) ){
      let ext = `${name}.png`;
      if(Viewport.webpSupport) ext = ext.substr(0, ext.lastIndexOf(".")) + ".webp";
      const lm = new TextureResource( MaterialFactory.makeBBCTextureSet(ext), this.museum.scene )

      lm.response().then(t=>{
        // t.clamp()
        t.setFilter(true)
      })
      this.lightmaps.add( name      ,  lm )
      lm.load()
    }
    return this.lightmaps.get( name )
  }
  // "Concrete_Wall" | 
  // "Ground" | 
  // "Other_pieces" | 
  // "Structure" |
  // "Triangle_mirror" |
  // "White_boards"
  createTextures() {

    
    const texs = this.texs

    this.addStandardMaterialTextures( 'Concrete_Wall', true, true, false )
    this.addStandardMaterialTextures( 'Ground', false, false, false )
    this.addStandardMaterialTextures( 'Purple', true, false, false )
    this.addStandardMaterialTextures( 'Nuage', true, false, false, ".png" )
    this.addStandardMaterialTextures( 'structure', true, true, true )
    this.addStandardMaterialTextures( 'Jeppe_Wall', true, false, true )
    this.addStandardMaterialTextures( 'Face_circle', true, false, false )
    
  }


  addStandardMaterialTextures( name:string, isBase = true, isNormal = true, isMRO = true, baseExt = ".jpg" ){
    if(isBase) this.addQuickTex( `${name}_Base_Color`     , `${name}_Base_Color${baseExt}`     , false )
    if(isNormal) this.addQuickTex( `${name}_Normal`         , `${name}_Normal.jpg`         , false )
    if(isMRO) this.addQuickTex( `${name}_MaterialParams` , `${name}_MRO.jpg` , false )

    if(isNormal) this.mipmapAndAniso(this.texs.getResource(`${name}_Normal`), 16);
    if(isMRO) this.mipmapAndAniso(this.texs.getResource(`${name}_MaterialParams`), 16);

  }


  addQuickTex( id:string, filename:string, bbc:boolean ){
    if(Viewport.webpSupport) filename = filename.substr(0, filename.lastIndexOf(".")) + ".webp";
    const r = new TextureResource( MaterialFactory.makeBBCTextureSet(filename  , bbc ), this.museum.scene ) 
    this.texs.add( id,  r )
    return r;
  }

  mipmapAndAniso( r:TextureResource, paniso = 0 ){

    const gl = this.museum.scene.gl
    const extAniso = TextureResource.getTextureLoader(gl).extAniso
    const maxAniso = extAniso ? gl.getParameter(extAniso.MAX_TEXTURE_MAX_ANISOTROPY_EXT) : 0

    r.response().then(t=>{
      t.bind()
      if( t.internal === gl.RGB )
        gl.generateMipmap(t._target)
      t.setFilter(true, true, false )
      const aniso = Math.min( maxAniso , paniso )
      if( aniso > 0 ){
        gl.texParameterf( gl.TEXTURE_2D, extAniso.TEXTURE_MAX_ANISOTROPY_EXT, aniso );
      }
    })
  }

  createMaterial( name : MaterialName ) : BaseMaterial {

    switch(name){

      case "Concrete_Wall": return this.createStandardMaterial("Concrete_Wall", false, true, true, false, [], false, 0, 0.9)
      case "Plinthes": return this.createStandardMaterial("Plinthes", false, false, false, false, [1, 1, 1], false, 0, 0.8)
      case "Jeppe_Wall": return this.createStandardMaterial("Jeppe_Wall", false, true, false, true, [], false, 0, 0.9)
      // case "Ground": return this.createGroundMaterial("Ground")
      case "Ground": return this.createStandardMaterial("Ground", false, false, false, false, [0.9, 0.9, 0.9], false, 0, 0.3)
      case "Nuage": return this.createStandardMaterial("Nuage", false, true, false, false, [], true, 0, 1)
      case "Purple": return this.createStandardMaterial("Purple", false, true, false, false, [], true, 0, 1)
      case "structure": return this.createStandardMaterial("structure", false, true, true, false, [], false, 0, 1)
      case "White_boards": return this.createStandardMaterial("White_boards", false, false, false, false, [1, 1, 1], false, 0, 0.99)
      case "Face_circle": return this.createStandardMaterial("Face_circle", false, true, false, false, [1, 1, 1], false, 0, 0.99)
      case "Triangle_mirror": return this.createStandardMaterial("Triangle_mirror", false, false, false, false, [1, 1, 1], false, 1, 0.05)
      case "BtnMat": return this.createUnlitMaterial()
      default: throw `missing material "${name}"`
    }
  }

  createStandardMaterial( name:string, occlu=true, isBase = true, isNormal = true, isMRO = true, baseColor = [1.0, 1.0, 1.0], isTransparent = false, metalness = 0.5, roughness = 0.5 ) : BaseMaterial {
    const m = this.createBaseMaterial();
    m.name = name;
    const pass = new StandardMetalness()

    pass.version.set(this.glslVersion);

    isTransparent ? this.blendConfig(pass.glconfig) : this.opaqueConfig( pass.glconfig );
    pass.mask = isTransparent ? Masks.BLENDED : Masks.OPAQUE | Masks.REFLECTED;


    const uv0 = TexCoord.create( 'aTexCoord0' );
    if(isBase) {
      const colorSampler = new Sampler( 'tColor', uv0 )
      colorSampler.set(this.texs.get(`${name}_Base_Color`))
      pass.surface.baseColor.attach(colorSampler);
      if(isTransparent) {
        
        pass.alphaMode.set("BLEND")
        pass.alpha.attach(colorSampler, 'a');
        
      }
    } else pass.surface.baseColor.attachConstant(baseColor);
    
    if(isNormal) {
      const normalSampler = new Sampler( 'tNormals', uv0 )
      normalSampler.set(this.texs.get(`${name}_Normal`))
      pass.normal.attach(normalSampler);
    }

    if(isMRO) {
      const paramsSampler = new Sampler( 'tParams', uv0 )
      paramsSampler.set(this.texs.get(`${name}_MaterialParams`))
      pass.surface.metalness.attach(paramsSampler, 'r');
      pass.surface.roughness.attach(paramsSampler, 'g');
      if( occlu) pass.occlusion.attach(paramsSampler, 'b');
    } else {
      pass.surface.metalness.attachConstant(metalness)
      pass.surface.roughness.attachConstant(roughness)
    }

    
    if(name === "Triangle_mirror") this.museum.scene.lights.setupMat( pass );
    m.addPass( pass, Passes.DEFAULT );

    if(name === "Ground") {
      m.inputs.add(this.reflChunk);
    }
    

    if(name === "Face_circle") this.faceMaterial = m;

    return m;

  }

  createUnlitMaterial() {
    const m = this.createBaseMaterial();
    const pass = new UnlitPass()
    pass.baseColor.attachConstant([1, 0, 0]);
    pass.alphaMode.set("BLEND")
    pass.alpha.attachConstant(0)
    this.blendConfig(pass.glconfig)

    m.addPass(pass, Passes.DEFAULT)

    return m
  }

  addLightmap( material: BaseMaterial, lightmap: Texture2D ){
    const lmChunk = new LightmapChunk()
    const sampler = lmChunk.iLightmap.attachSampler('tLightmap' , TexCoord.create('aTexCoord1') );
    sampler.set( lightmap );
    lmChunk.iAmbientMultiplier.attachConstant(0)
    lmChunk.iLightmapMultiplier.proxy( this.lightmapMultiplyer )
    lmChunk.iLightmapGamma.proxy( this.lightmapGamma )

    const pass = material.getPass(Passes.DEFAULT).pass
    pass.inputs.add( lmChunk )

  }


  opaqueConfig( glconfig : GLConfig ) : void {
    glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)
  }

  blendConfig(glconfig: GLConfig):void {
    const gl = GLApp.getInstance().glview.gl
    glconfig
      .enableBlend(true)
      .blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(false)
  }


  createBaseMaterial( ) : BaseMaterial {
    return new BaseMaterial( this.museum.scene.gl );
  }

  createBaseMetalnessPass(): StandardMetalness{
    const p = new StandardMetalness()
    p.version.set(this.glslVersion);
    return p;
  }

  createBaseSpecularPass(): StandardSpecular {
    const p = new StandardSpecular()
    p.version.set(this.glslVersion);
    return p;
  }
  
}


