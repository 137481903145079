import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input from "nanogl-pbr/Input";
import FragCode from "./code.frag"

export default class LightmapChunk extends Chunk {

  
  iLightmap          : Input
  iLightmapMultiplier: Input
  iAmbientMultiplier : Input
  iLightmapGamma     : Input;

  
  constructor(){
    super(true, false)
    
    this.addChild( this.iLightmap             = new Input( 'Lightmap'            , 3 ) );
    this.addChild( this.iLightmapGamma        = new Input( 'LightmapGamma'       , 1 ) );
    this.addChild( this.iLightmapMultiplier   = new Input( 'LightmapMultiplier'  , 1 ) );
    this.addChild( this.iAmbientMultiplier    = new Input( 'AmbientMultiplier'    , 1 ) );

    this.iLightmapGamma.attachConstant(2.2)
  }
  
  protected _genCode(slots: ChunksSlots): void {
    slots.add( 'lightsf', FragCode() )
  }

}