
import gsap from "gsap";
import AudioManager from "@/core/audio/AudioManager";
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    const tlCollection: gsap.core.Timeline[] = [];
    const scrollTriggerCollection: ScrollTrigger[] = [];
    return {
      tlCollection,
      scrollTriggerCollection,
      player: null,
      playerVars: {
        autoplay: false,
        controls: false,
        rel: false,
        showinfo: false,
        modestbranding: 1,
        iv_load_policy: 3,
      },
      isPlaying: false,
      showVideo: false,
    };
  },

  watch: {
    isPlaying: {
      handler(isPlaying: boolean) {
        if (isPlaying) {
          AudioManager.setMute(true);
        } else {
          AudioManager.setMute(false);
        }
      },
    },
  },

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl.clear() && tl.kill();
      });
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) =>
        scrollTrigger.kill()
      );
    }
  },

  mounted() {
    this.$nextTick(this.initScrollAnimation);
  },

  methods: {
    initScrollAnimation() {
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el.querySelector(".ViewRuinartSection__Heading"),
              //   scrub: 1,
              start: "top center",
            },
          })
          .addLabel("start")
          .fromTo(
            Array.from(
              this.$el.querySelectorAll(".ViewRuinartSection__Heading span")
            ),
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration: 1,
              ease: "cubic.out",
              stagger: 0.1,
            },
            "start"
          )
      );

      const body: Element = this.$el.querySelector(".ViewRuinartSection__Body");
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: body,
              //   scrub: 1,
              start: "top center",
            },
          })
          .addLabel("start")
          .fromTo(
            body,
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
            "start"
          )
      );

      const player: Element = this.$el.querySelector(
        ".ViewRuinartSectionPlayer"
      );
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: player,
              scrub: 1,
              start: "top bottom",
              end: "center center",
            },
          })
          .addLabel("start")
          .fromTo(
            player,
            {
              scale: 0.9,
            },
            {
              scale: 1,
              duration: 1,
            },
            "start"
          )
      );
    },

    goToAgenda() {
      this.send("AGENDA");
    },

    updateIsPlaying(data) {
      // https://developers.google.com/youtube/iframe_api_reference#Events
      if (data === 0) {
        this.isPlaying = false;
      } else if (data === 1) {
        this.isPlaying = true;
      } else if (data === 2) {
        this.isPlaying = false;
      }
    },

    play() {
      this.player && this.player.playVideo();
    },

    pause() {
      this.player && this.player.pauseVideo();
    },
  },
});
