import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { CATCH_ASYNC } from "@/core/Async";
// import { useStore } from "@/store";
import { ImageResource, JsonResource } from "@/webgl/assets/Net";
import { FontResource } from "@/webgl/assets/FontResource";
import { Viewport } from "@/store/modules/Viewport";

import Paths from "@/core/Paths";
import { Resource } from "@/webgl/assets/Resource";

export default class FrontResources {
  static Group: ResourceGroup = new ResourceGroup();

  static setupLotties() {
    const G = this.Group;

    G.add(
      <Resource>(
        new JsonResource(require("@/assets/images/lottie/close-menu.json"))
      ),
      "close-menu"
    );
    G.add(
      <Resource>new JsonResource(require("@/assets/images/lottie/cta.json")),
      "cta"
    );
    G.add(
      <Resource>(
        new JsonResource(require("@/assets/images/lottie/toggle-camera.json"))
      ),
      "toggle-camera"
    );
    G.add(
      <Resource>(
        new JsonResource(require("@/assets/images/lottie/toggle-mic.json"))
      ),
      "toggle-mic"
    );
    G.add(
      <Resource>(
        new JsonResource(require("@/assets/images/lottie/timeline-arrow.json"))
      ),
      "timeline-arrow"
    );
  }

  static setupImages() {
    const G = this.Group;

    const sprites: { path: string; frames: number }[] = [
      {
        path: "transition-menu",
        frames: 8,
      },
      {
        path: "manifesto-air",
        frames: 10,
      },
      {
        path: "manifesto-heart",
        frames: 10,
      },
      {
        path: "manifesto-water",
        frames: 11,
      },
      {
        path: "tutorials/I_Do",
        frames: 8,
      },
      {
        path: "tutorials/I_Feel_Fallback",
        frames: 8,
      },
      {
        path: "tutorials/I_Feel_Interaction_desktop",
        frames: 8,
      },
      {
        path: "tutorials/I_Feel_Interaction_mobile",
        frames: 8,
      },
      {
        path: "tutorials/I_Love_Desktop",
        frames: 8,
      },
      {
        path: "tutorials/I_Love_Mobile",
        frames: 8,
      },
      {
        path: "tutorials/I_See_Fallback",
        frames: 8,
      },
      {
        path: "tutorials/I_See_Interaction",
        frames: 8,
      },
      {
        path: "tutorials/I_Speak",
        frames: 8,
      },
      {
        path: "tutorials/I_Speak_Fallback",
        frames: 8,
      },
      {
        path: "tutorials/I_Understand_desktop",
        frames: 8,
      },
      {
        path: "tutorials/I_Understand_mobile",
        frames: 8,
      },
      {
        path: "tutorials/swipe_HP",
        frames: 8,
      },
    ];
    const ext = Viewport.webpSupport ? "webp" : "png"
    sprites.forEach(({ path, frames }) => {
      for (let i = 0; i < frames; i++) {
        const f = require(`@/assets/images/sprites/${path}/frame-${i.toString().padStart(5, "0000")}.${ext}`)
        const imageResource = new ImageResource(f);
        G.add(<Resource>imageResource);
      }
    });

    G.add(
      new ImageResource(
        Viewport.webpSupport
          ? require("@/assets/images/share-bottom.webp")
          : require("@/assets/images/share-bottom.png")
      ),
      "footer-texture"
    );
  }

  static setupFonts() {
    const G = this.Group;

    // Almarena
    G.add(
      new FontResource(
        "Almarena-Display-Light",
        `url('${require("@/assets/fonts/Almarena/Almarena-Display-Light.woff")}') format('woff'),
        url('${require("@/assets/fonts/Almarena/Almarena-Display-Light.woff2")}') format('woff2')`,
        {
          weight: "300",
          style: "normal",
        }
      )
    );
    G.add(
      new FontResource(
        "Almarena-Display-Regular",
        `url('${require("@/assets/fonts/Almarena/Almarena-Display-Regular.woff")}') format('woff'),
        url('${require("@/assets/fonts/Almarena/Almarena-Display-Regular.woff2")}') format('woff2')`,
        {
          weight: "400",
          style: "normal",
        }
      )
    );
    G.add(
      new FontResource(
        "Almarena-Display-Bold",
        `url('${require("@/assets/fonts/Almarena/Almarena-Display-Bold.woff")}') format('woff'),
        url('${require("@/assets/fonts/Almarena/Almarena-Display-Bold.woff2")}') format('woff2')`,
        {
          weight: "700",
          style: "normal",
        }
      )
    );

    // Foxglove
    G.add(
      new FontResource(
        "Foxglove",
        `url('${require("@/assets/fonts/Foxglove/Foxglove.woff")}') format('woff'),
        url('${require("@/assets/fonts/Foxglove/Foxglove.woff2")}') format('woff2')`,
        {
          weight: "normal",
          style: "normal",
        }
      )
    );
  }

  static load() {
    CATCH_ASYNC(
      this.Group.load().then(() => {
        // useStore().notifyFrontLoaded();
      })
    );
  }
}
