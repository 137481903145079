import { getTexture } from "@/core/TextureURL";
import { Viewport } from "@/store/modules/Viewport";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import { BlobResource } from "@/webgl/assets/Net";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { TextureFiltering, TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource, TextureWrap } from "@/webgl/assets/TextureResource";
import GLApp from "@/webgl/main";
import { DEG2RAD } from "@/webgl/math";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import MuseumActivity from "../MuseumActivity";
import StateHelper from "../StateHelper";
import FaceDrawing from "./FaceDrawing";


const TO_LOAD = ["anger", "disgust", "fear", "happiness", "sadness", "surprise"]
/**
 * Noop activity, loading in VUE
 */
export default class FeelActivity implements IActivity {

  readonly priority: number = 2;
  readonly name: string = "feel";

  stateHelper: StateHelper;

  private _faceDrawing: FaceDrawing
  private _fov: number = 50;

  private _camera: Camera

  private root: Node

  private _resources: ResourceGroup

  constructor(public scene: Scene) {
    this.root = new Node()
    this.stateHelper = new StateHelper([
      { match: 'step_feel.outro', enter: this.enterOutro },
    ])
  }

  load(): Promise<any> {
    const opts = {
      bbc: false,
      flipY: false,
      genMips: false,
      wrap: TextureWrap.CLAMP,
      filtering: TextureFiltering.LINEAR
    }
    this._resources = new ResourceGroup()

    const isMobile = Viewport.isDesktop ? '' : '-mobile'

    TO_LOAD.forEach(s => {
      let srcSet = new TextureSrcSet(getTexture(`${s}-1${isMobile}.jpg`));
      srcSet.options = opts
      let srcSet1 = new TextureSrcSet(getTexture(`${s}-2${isMobile}.jpg`));
      srcSet1.options = opts
      let srcSet2 = new TextureSrcSet(getTexture(`${s}-small-1.jpg`));
      srcSet2.options = opts
      let srcSet3 = new TextureSrcSet(getTexture(`${s}-small-2.jpg`));
      srcSet3.options = opts
      this._resources.add(new TextureResource(srcSet, this.scene), s + "-1");
      this._resources.add(new TextureResource(srcSet1, this.scene), s + "-2");
      this._resources.add(new TextureResource(srcSet2, this.scene), s + "-small-1");
      this._resources.add(new TextureResource(srcSet3, this.scene), s + "-small-2");
    })



    return this._resources.load()
  }

  onStateUpdate(state: AppState): void {
    if (state.value === "outro") this.enterOutro()
  }

  start() {
    this.makeCamera()
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    this._faceDrawing = new FaceDrawing(this.scene, this._camera, this.root, museumAct.camera, this._resources)
    this._camera.z = -1
    this._camera.y = 0
    this._camera.x = 0
    this.scene.glview.onResize.on(this._resize)
    this._resize()
    this.stateHelper.start()
  }

  stop(): void {
    console.log("stop feel")
    this._faceDrawing.destroy()
    this.scene.glview.onResize.off(this._resize)
    this.stateHelper.stop()
  }


  enterIntro = () => {

  }

  enterDefault = () => {

  }


  enterOutro = async () => {
    const museumAct: MuseumActivity = this.scene.activities.getActivity("museum") as MuseumActivity
    museumAct.syncTo(4)
    await this._faceDrawing.outro()
    AppService.send("ENTER")
  }

  unload(): void { }
  preFrame(): void {
    this._faceDrawing.preRender()
  }
  render(): void {
    this._faceDrawing.render()
  }
  renderUI(): void { }
  preRender(): void { }
  rttPass(): void { }

  private _resize = () => {

    const w = GLApp.getInstance().glview.width
    const h = GLApp.getInstance().glview.height

    this.root.y = this.root.z = this.root.x = this._camera.y = 0

    const pr = this.scene.glview.pixelRatio

    vec3.set(this.root.scale, 2, 2, 1)
    this.root.invalidate()
    this.root.updateWorldMatrix()

    this._camera.lookAt(this.root.position)
    this.root.lookAt(this._camera.position)

    this._faceDrawing._resize(w > h ? h / w : w / h, window.innerWidth, window.innerHeight)
    // this._camera.updateViewProjectionMatrix(w, h);
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());

    camera.lens.setVerticalFov(this._fov * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 50

    this._camera = camera;

  }

}