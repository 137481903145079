import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "UIBtnPrimary__Background",
  ref: "background"
}
const _hoisted_2 = {
  class: "UIBtnPrimary__Inner",
  ref: "inner"
}
const _hoisted_3 = {
  class: "UIBtnPrimary__Label",
  ref: "label"
}
const _hoisted_4 = {
  class: "UIBtnPrimary__Checkmark",
  ref: "checkmark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["UIBtn UIBtnPrimary", { 'UIBtnPrimary--outline': _ctx.outline }]),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args))),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, null, 512),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ], 512),
      _createElementVNode("span", _hoisted_4, null, 512)
    ], 512)
  ], 34))
}