import { ClosestPointOnSegment, Epsilon, kEpsilon } from "@/webgl/lib/cinemachine/SplineHelpers";
import { clamp, mix } from "@/webgl/math";
import { vec3, quat } from "gl-matrix";

const V3A = vec3.create();
const V3B = vec3.create();

/// <summary>How to interpret the Path Position</summary>
export enum PositionUnits {
  /// <summary>Use PathPosition units, where 0 is first waypoint, 1 is second waypoint, etc</summary>
  PathUnits,
  /// <summary>Use Distance Along Path.  Path will be sampled according to its Resolution
  /// setting, and a distance lookup table will be cached internally</summary>
  Distance,
  /// <summary>Normalized units, where 0 is the start of the path, and 1 is the end.
  /// Path will be sampled according to its Resolution
  /// setting, and a distance lookup table will be cached internally</summary>
  Normalized
}


export default abstract class CinemachinePathBase {

  /// <summary>"Path samples per waypoint.  This is used for calculating path distances."</summary>
  public m_Resolution: number = 20;

  /// <summary>The minimum value for the path position</summary>
  public abstract get MinPos(): number;

  /// <summary>The maximum value for the path position</summary>
  public abstract get MaxPos(): number;
  /// <summary>True if the path ends are joined to form a continuous loop</summary>
  public abstract get Looped(): boolean;

  /// <summary>Get a standardized path position, taking spins into account if looped</summary>
  /// <param name="pos">Position along the path</param>
  /// <returns>Standardized position, between MinPos and MaxPos</returns>
  public StandardizePos(pos: number): number {
    if (this.MaxPos == 0)
      return 0;
    if (this.Looped) {
      pos = pos % this.MaxPos;
      if (pos < 0)
        pos += this.MaxPos;
      return pos > this.MaxPos - Epsilon ? 0 : pos;
    }
    return clamp(pos, 0, this.MaxPos);
  }

  /// <summary>Get a worldspace position of a point along the path</summary>
  /// <param name="pos">Postion along the path.  Need not be standardized.</param>
  /// <returns>World-space position of the point along at path at pos</returns>
  public abstract EvaluatePosition(pos: number): vec3;

  /// <summary>Get the tangent of the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be standardized.</param>
  /// <returns>World-space direction of the path tangent.
  /// Length of the vector represents the tangent strength</returns>
  public abstract EvaluateTangent(pos: number): vec3;

  /// <summary>Get the orientation the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be standardized.</param>
  /// <returns>World-space orientation of the path</returns>
  public abstract EvaluateOrientation(pos: number): quat;


  /// <summary>Find the closest point on the path to a given worldspace target point.</summary>
  /// <remarks>Performance could be improved by checking the bounding polygon of each segment,
  /// and only entering the best segment(s)</remarks>
  /// <param name="p">Worldspace target that we want to approach</param>
  /// <param name="startSegment">In what segment of the path to start the search.
  /// A Segment is a section of path between 2 waypoints.</param>
  /// <param name="searchRadius">How many segments on either side of the startSegment
  /// to search.  -1 means no limit, i.e. search the entire path</param>
  /// <param name="stepsPerSegment">We search a segment by dividing it into this many
  /// straight pieces.  The higher the number, the more accurate the result, but performance
  /// is proportionally slower for higher numbers</param>
  /// <returns>The position along the path that is closest to the target point.
  /// The value is in Path Units, not Distance units.</returns>
  public FindClosestPoint(
    p: vec3, startSegment: number, searchRadius: number, stepsPerSegment: number
  ): number {

    let start: number = this.MinPos;
    let end: number = this.MaxPos;
    if (searchRadius >= 0) {
      let r = Math.floor(Math.min(searchRadius, (end - start) / 2));
      start = startSegment - r;
      end = startSegment + r + 1;
      if (!this.Looped) {
        start = Math.max(start, this.MinPos);
        end = Math.max(end, this.MaxPos);
      }
    }


    stepsPerSegment = Math.round(clamp(stepsPerSegment, 1.0, 100.0));
    let stepSize: number = 1 / stepsPerSegment;
    let bestPos: number = startSegment;
    let bestDistance: number = Number.MAX_VALUE;
    let iterations = (stepsPerSegment == 1) ? 1 : 3;
    for (let i = 0; i < iterations; ++i) {
      let v0 = this.EvaluatePosition(start);
      for (let f = start + stepSize; f <= end; f += stepSize) {
        let v = this.EvaluatePosition(f);
        let t = ClosestPointOnSegment(p, v0, v);

        vec3.lerp(V3A, v0, v, t);
        vec3.sub(V3B, p, V3A);

        let d = vec3.squaredLength(V3B);
        if (d < bestDistance) {
          bestDistance = d;
          bestPos = f - (1 - t) * stepSize;
        }
        v0 = v;
      }
      start = bestPos - stepSize;
      end = bestPos + stepSize;
      stepSize /= stepsPerSegment;
    }
    return bestPos;

  }


  /// <summary>Get the minimum value, for the given unit type</summary>
  /// <param name="units">The unit type</param>
  /// <returns>The minimum allowable value for this path</returns>
  public MinUnit(units: PositionUnits): number {
    if (units == PositionUnits.Normalized)
      return 0;
    return units == PositionUnits.Distance ? 0 : this.MinPos;
  }


  /// <summary>Get the maximum value, for the given unit type</summary>
  /// <param name="units">The unit type</param>
  /// <returns>The maximum allowable value for this path</returns>
  public MaxUnit(units: PositionUnits): number {
    if (units == PositionUnits.Normalized)
      return 1;
    return units == PositionUnits.Distance ? this.PathLength : this.MaxPos;
  }

  /// <summary>Standardize the unit, so that it lies between MinUmit and MaxUnit</summary>
  /// <param name="pos">The value to be standardized</param>
  /// <param name="units">The unit type</param>
  /// <returns>The standardized value of pos, between MinUnit and MaxUnit</returns>
  public StandardizeUnit(pos: number, units: PositionUnits): number {
    if (units == PositionUnits.PathUnits)
      return this.StandardizePos(pos);
    if (units == PositionUnits.Distance)
      return this.StandardizePathDistance(pos);
    let len = this.PathLength;
    if (len < Epsilon)
      return 0;
    return this.StandardizePathDistance(pos * len) / len;
  }


  /// <summary>Get a worldspace position of a point along the path</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <param name="units">The unit to use when interpreting the value of pos.</param>
  /// <returns>World-space position of the point along at path at pos</returns>
  public EvaluatePositionAtUnit(pos: number, units: PositionUnits): vec3 {
    return this.EvaluatePosition(this.ToNativePathUnits(pos, units));
  }



  /// <summary>Get the tangent of the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <param name="units">The unit to use when interpreting the value of pos.</param>
  /// <returns>World-space direction of the path tangent.
  /// Length of the vector represents the tangent strength</returns>
  public EvaluateTangentAtUnit(pos: number, units: PositionUnits): vec3 {
    return this.EvaluateTangent(this.ToNativePathUnits(pos, units));
  }

  /// <summary>Get the orientation the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <param name="units">The unit to use when interpreting the value of pos.</param>
  /// <returns>World-space orientation of the path</returns>
  public EvaluateOrientationAtUnit(pos: number, units: PositionUnits): quat {
    return this.EvaluateOrientation(this.ToNativePathUnits(pos, units));
  }


  /// <summary>When calculating the distance cache, sample the path this many
  /// times between points</summary>
  public abstract get DistanceCacheSampleStepsPerSegment(): number;

  /// <summary>Call this if the path changes in such a way as to affect distances
  /// or other cached path elements</summary>
  public InvalidateDistanceCache(): void {
    this.m_DistanceToPos = null;
    this.m_PosToDistance = null;
    this.m_CachedSampleSteps = 0;
    this.m_PathLength = 0;
  }


  /// <summary>See whether the distance cache is valid.  If it's not valid,
  /// then any call to GetPathLength() or ToNativePathUnits() will
  /// trigger a potentially costly regeneration of the path distance cache</summary>
  /// <returns>Whether the cache is valid</returns>
  public DistanceCacheIsValid(): boolean {
    return (this.MaxPos == this.MinPos)
      || (this.m_DistanceToPos != null && this.m_PosToDistance != null
        && this.m_CachedSampleSteps == this.DistanceCacheSampleStepsPerSegment
        && this.m_CachedSampleSteps > 0);
  }


  /// <summary>Get the length of the path in distance units.
  /// If the distance cache is not valid, then calling this will
  /// trigger a potentially costly regeneration of the path distance cache</summary>
  /// <returns>The length of the path in distance units, when sampled at this rate</returns>
  public get PathLength(): number {
    if (this.DistanceCacheSampleStepsPerSegment < 1)
      return 0;
    if (!this.DistanceCacheIsValid())
      this.ResamplePath(this.DistanceCacheSampleStepsPerSegment);
    return this.m_PathLength;
  }


  /// <summary>Standardize a distance along the path based on the path length.
  /// If the distance cache is not valid, then calling this will
  /// trigger a potentially costly regeneration of the path distance cache</summary>
  /// <param name="distance">The distance to standardize</param>
  /// <returns>The standardized distance, ranging from 0 to path length</returns>
  public StandardizePathDistance(distance: number): number {
    let length: number = this.PathLength;
    if (length < kEpsilon)
      return 0;
    if (this.Looped) {
      distance = distance % length;
      if (distance < 0)
        distance += length;
    }
    return clamp(distance, 0, length);
  }


  /// <summary>Get the path position (in native path units) corresponding to the psovided
  /// value, in the units indicated.
  /// If the distance cache is not valid, then calling this will
  /// trigger a potentially costly regeneration of the path distance cache</summary>
  /// <param name="pos">The value to convert from</param>
  /// <param name="units">The units in which pos is expressed</param>
  /// <returns>The length of the path in native units, when sampled at this rate</returns>
  public ToNativePathUnits(pos: number, units: PositionUnits): number {
    if (units == PositionUnits.PathUnits)
      return pos;
    if (this.DistanceCacheSampleStepsPerSegment < 1 || this.PathLength < Epsilon)
      return this.MinPos;
    if (units == PositionUnits.Normalized)
      pos *= this.PathLength;
    pos = this.StandardizePathDistance(pos);
    let d = pos / this.m_cachedDistanceStepSize;
    let i = Math.floor(d);
    if (i >= this.m_DistanceToPos.length - 1)
      return this.MaxPos;
    let t = d - i;
    return this.MinPos + mix(this.m_DistanceToPos[i], this.m_DistanceToPos[i + 1], t);
  }


  /// <summary>Get the path position (in path units) corresponding to this distance along the path.
  /// If the distance cache is not valid, then calling this will
  /// trigger a potentially costly regeneration of the path distance cache</summary>
  /// <param name="pos">The value to convert from, in native units</param>
  /// <param name="units">The units to convert toexpressed</param>
  /// <returns>The length of the path in distance units, when sampled at this rate</returns>
  public FromPathNativeUnits(pos: number, units: PositionUnits): number {
    if (units == PositionUnits.PathUnits)
      return pos;
    let length = this.PathLength;
    if (this.DistanceCacheSampleStepsPerSegment < 1 || length < Epsilon)
      return 0;
    pos = this.StandardizePos(pos);
    let d = pos / this.m_cachedPosStepSize;
    let i = Math.floor(d);
    if (i >= this.m_PosToDistance.length - 1)
      pos = this.m_PathLength;
    else {
      let t = d - i;
      pos = mix(this.m_PosToDistance[i], this.m_PosToDistance[i + 1], t);
    }
    if (units == PositionUnits.Normalized)
      pos /= length;
    return pos;
  }

  private m_DistanceToPos: number[];
  private m_PosToDistance: number[];
  private m_CachedSampleSteps: number;
  private m_PathLength: number;
  private m_cachedPosStepSize: number;
  private m_cachedDistanceStepSize: number;


  private ResamplePath(stepsPerSegment: number): void {
    this.InvalidateDistanceCache();

    let minPos = this.MinPos;
    let maxPos = this.MaxPos;
    let stepSize = 1.0 / Math.max(1, stepsPerSegment);

    // Sample the positions
    let numKeys = Math.round((maxPos - minPos) / stepSize) + 1;
    this.m_PosToDistance = [];
    this.m_CachedSampleSteps = stepsPerSegment;
    this.m_cachedPosStepSize = stepSize;

    let p0: vec3 = this.EvaluatePosition(0);
    this.m_PosToDistance[0] = 0;
    let pos = minPos;
    for (let i = 1; i < numKeys; ++i) {
      pos += stepSize;
      let p: vec3 = this.EvaluatePosition(pos);
      let d = vec3.distance(p0, p);
      this.m_PathLength += d;
      p0 = p;
      this.m_PosToDistance[i] = this.m_PathLength;
    }

    // Resample the distances
    this.m_DistanceToPos = [];
    this.m_DistanceToPos[0] = 0;
    if (numKeys > 1) {
      stepSize = this.m_PathLength / (numKeys - 1);
      this.m_cachedDistanceStepSize = stepSize;
      let distance: number = 0;
      let posIndex: number = 1;
      for (let i = 1; i < numKeys; ++i) {
        distance += stepSize;
        let d = this.m_PosToDistance[posIndex];
        while (d < distance && posIndex < numKeys - 1)
          d = this.m_PosToDistance[++posIndex];
        let d0 = this.m_PosToDistance[posIndex - 1];
        let delta = d - d0;
        let t = (distance - d0) / delta;
        this.m_DistanceToPos[i] = this.m_cachedPosStepSize * (t + posIndex - 1);
      }
    }
  }

}