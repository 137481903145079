
import gsap from "gsap";
import { defineComponent } from "vue";
import data from "@/store/datas/agenda.json";

enum WorkshopEnum {
  BE = "be",
  FEEL = "feel",
  DO = "do",
  LOVE = "love",
  SEE = "see",
  SPEAK = "speak",
  ARTWORK = "artwork",
}

interface IAgendaEvent {
  title: string;
  workshops: string[];
  date: string;
}

const WorkshopState = {
  [WorkshopEnum.BE]: {
    icon: "square",
    title: "I am",
  },
  [WorkshopEnum.FEEL]: {
    icon: "circle",
    title: "I Feel",
  },
  [WorkshopEnum.DO]: {
    icon: "triangle",
    title: "I Do",
  },
  [WorkshopEnum.LOVE]: {
    icon: "rectangle",
    title: "I Love",
  },
  [WorkshopEnum.SEE]: {
    icon: "diamond",
    title: "I See",
  },
  [WorkshopEnum.SPEAK]: {
    icon: "oval",
    title: "I Speak",
  },
  [WorkshopEnum.ARTWORK]: {
    icon: "hexagon",
    title: "I Understand",
  },
};

export default defineComponent({
  setup() {},

  data() {
    const tlCollection: gsap.core.Timeline[] = [];
    const scrollTriggerCollection: ScrollTrigger[] = [];
    return {
      tlCollection,
      scrollTriggerCollection,
    };
  },

  computed: {
    agendaData(): IAgendaEvent[] {
      return data;
    },

    workshopState() {
      return WorkshopState;
    },
  },

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl.clear() && tl.kill();
      });
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) =>
        scrollTrigger.kill()
      );
    }
  },

  mounted() {
    this.$nextTick(this.initScrollAnimation);
  },

  methods: {
    initScrollAnimation() {
      this.tlCollection.push(
        gsap
          .timeline({
            scrollTrigger: {
              scroller: ".view-scroll",
              trigger: this.$el,
              //   scrub: 1,
              start: "top bottom-=80",
            },
          })
          .addLabel("start")
          .fromTo(
            Array.from(this.$el.querySelectorAll(".ViewAgendaHeader__Row, .ViewAgendaBody__Row")),
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: 0,
              opacity: 1,
              duration: 1,
              ease: "cubic.out",
              stagger: 0.1,
            },
            "start"
          )
      );
    },
  },
});
