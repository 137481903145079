
import gsap from "gsap";
import { defineComponent } from "vue";
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";

import { TutoArrowEnum } from "@/components/Tuto/Tuto.vue";
import Tuto from "@/components/Tuto/Tuto.vue";

export default defineComponent({
  components: {
    Tuto,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      tutoSlides: [
        {
          text: "I do is a state of mind.\nTake the leap, draw with me and look\ninto the mirror to enter this journey.",
          buttonLabel: "Skip",
          isIntro: true,
          arrow: TutoArrowEnum.STRAIGHT,
          rotate: 1,
        },
        {
          text: "Follow me in the vineyards and the four\nelements to discover your senses.",
          buttonLabel: "Skip",
          isIntro: true,
          arrow: TutoArrowEnum.SWIRL,
          rotate: -2,
        },
        {
          doodle: {
            path: "tutorials/I_Do",
            frames: 8,
            autoplay: true,
            loop: true,
          },
          // image: '/assets/do-1.png',
          text: "Draw a triangle to see\ninto the mirror",
          buttonLabel: "Okay!",
          border: false,
          rotate: -2,
        },
      ],
    };
  },

  computed: {
    skipIntro(): boolean {
      return this.state?.context?.skipTutoIntro || false;
    },

    isStateTuto(): boolean {
      return this.state?.value["step_do"] === "tuto";
    },
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=1");

      tl.fromTo(
        this.$el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    afterEnter() {},

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        this.$el,
        {
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        "start"
      );
    },

    tutoComplete() {
      this.send("ENTER");
    },
  },
});
