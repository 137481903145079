
import gsap from "gsap";
import { defineComponent } from "vue";
import UIIcon from "@/components/UIIcon/UIIcon.vue";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { setFPS } from "@/utils/FrameToTime";
const frames = setFPS(60);

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  methods: {
    click() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);
    },

    mouseenter() {
      AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      gsap.to(
        [this.$refs.background as Element, this.$el.querySelector(".UIIcon")],
        {
          scale: 1.05,
          duration: frames(30),
          ease: gsap.parseEase("0.40, 2.00, 0.29, 1.00"),
          stagger: 0.1,
        }
      );
    },

    mouseleave() {
      // AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);
      if (!this.$refs.background || !this.$el) {
        return;
      }
      
      gsap.to(
        [this.$refs.background as Element, this.$el.querySelector(".UIIcon")],
        {
          scale: 1,
          duration: frames(30),
          ease: gsap.parseEase("0.40, 2.00, 0.29, 1.00"),
          stagger: -0.1,
        }
      );
    },
  },
});
