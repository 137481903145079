import { clamp01 } from "@/webgl/math";
import { vec3 } from "gl-matrix";

const ZERO = vec3.create();
const V30 = vec3.create();
const V31 = vec3.create();
const V32 = vec3.create();
const V33 = vec3.create();
const V3OUT = vec3.create();

export const Epsilon = 0.0001;

export const kEpsilon = 0.00001;

export function ClosestPointOnSegment(p: vec3, s0: vec3, s1: vec3): number {

  let s = vec3.create()
  vec3.sub(V30, s1, s0);
  let len2 = vec3.squaredLength(V30)
  if (len2 < Epsilon)
    return 0; // degenrate segment

  vec3.sub(V31, p, s0)
  return clamp01(vec3.dot(V31, V30) / len2);

}

export function Bezier3(t: number, p0: vec3, p1: vec3, p2: vec3, p3: vec3, out: vec3 = null): vec3 {

  t = clamp01(t);
  let d = 1.0 - t;

  /*
  Source
  return d * d * d * p0 + 3f * d * d * t * p1
      + 3f * d * t * t * p2 + t * t * t * p3;
  */

  // V30 -> d * d * d * p0 
  vec3.scale(V30, p0, d * d * d);

  // V31 -> + 3f * d * d * t * p1
  vec3.scale(V31, p1, 3.0 * d * d * t);

  // V32 -> + 3f * d * t * t * p2
  vec3.scale(V32, p2, 3.0 * d * t * t);

  // V33 -> + t * t * t * p3
  vec3.scale(V33, p3, t * t * t);

  if (out == null)
    out = vec3.create();

  vec3.add(out, ZERO, V30);
  vec3.add(out, out, V31);
  vec3.add(out, out, V32);
  vec3.add(out, out, V33);

  return out;

}

export function BezierTangent3(t: number, p0: vec3, p1: vec3, p2: vec3, p3: vec3, out: vec3 = null): vec3 {

  t = clamp01(t);

  /*
  Source
  return (-3f * p0 + 9f * p1 - 9f * p2 + 3f * p3) * t * t
    + (6f * p0 - 12f * p1 + 6f * p2) * t
      - 3f * p0 + 3f * p1;
  */

  // V30 -> (-3f * p0 + 9f * p1 - 9f * p2 + 3f * p3) * t * t
  vec3.scale(V30, p0, -3.0);
  vec3.scaleAndAdd(V30, V30, p1, 9.0);
  vec3.scaleAndAdd(V30, V30, p2, -9.0);
  vec3.scaleAndAdd(V30, V30, p3, 3.0);
  vec3.scale(V30, V30, t * t);

  // V31 -> + (6f * p0 - 12f * p1 + 6f * p2) * t
  vec3.scale(V31, p0, 6);
  vec3.scaleAndAdd(V31, V31, p1, -12.0);
  vec3.scaleAndAdd(V31, V31, p2, 6.0);
  vec3.scale(V31, V31, t);

  // V32 -> - 3f * p0
  vec3.scale(V32, p0, -3.0);

  // V33 -> 3f * p1
  vec3.scale(V33, p1, 3.0);

  if (out == null)
    out = vec3.create();

  vec3.add(out, ZERO, V30);
  vec3.add(out, out, V31);
  vec3.add(out, out, V32);
  vec3.add(out, out, V33);

  return out;

}
