
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import Browser from "@/utils/Browser";
import AppService from "@/store/states/AppService";
import { PERMISSION } from "@/store/states/AppStateMachine";
import { Viewport } from "@/store/modules/Viewport";
import { setMediaEnabled } from "@/store/modules/AppStore";

import { TutoArrowEnum } from "@/components/Tuto/Tuto.vue";
import Tuto from "@/components/Tuto/Tuto.vue";

export default defineComponent({
  components: {
    Tuto,
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      tutoSlides: [
        {
          text: "I am open to explore what cannot be seen.\nWith me, close your eyes, meditate\nand relax.",
          buttonLabel: "Skip",
          isIntro: true,
          arrow: TutoArrowEnum.SWIRL,
          rotate: -2,
        },
        {
          doodle: {
            path: "tutorials/I_See_Interaction",
            frames: 8,
            autoplay: true,
            loop: true,
          },
          // image: "/assets/see-1.png",
          text: "Close your eyes to\nmeditate and open them\nwhen you are ready.",
          buttonLabel: "Okay!",
          border: true,
          rotate: -2,
          requires: PERMISSION.VIDEO,
          fallback: {
            doodle: {
              path: "tutorials/I_See_Fallback",
              frames: 8,
              autoplay: true,
              loop: true,
            },
            // image: "/assets/see-1.png",
            text: "Hold the button and close\nyour eyes. Let go and open\nthem when you are ready.",
            buttonLabel: "Okay!",
            border: true,
            rotate: -2,
          },
        },
      ],
    };
  },

  computed: {
    skipIntro(): boolean {
      return this.state?.context?.skipTutoIntro || false;
    },

    isStateTuto(): boolean {
      return this.state?.value["step_see"] === "tuto";
    },
  },

  mounted() {
    if (Browser.mobile) {
      setMediaEnabled(false);
    }
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=1");

      tl.fromTo(
        this.$el,
        {
          x: !this.skipIntro ? Viewport.windowWidth : 0,
          opacity: !this.skipIntro ? 1 : 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: !this.skipIntro ? 2 : 0.5,
          ease: !this.skipIntro ? "quart.inOut" : "sine.out",
        },
        "start"
      );
    },

    afterEnter() {},

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start");

      tl.to(
        el,
        {
          x: Viewport.isDesktop
            ? -Viewport.windowWidth
            : -el.querySelector(".Tuto .Tuto__Inner").clientWidth,
          duration: 2,
          ease: "quart.inOut",
        },
        "start"
      );
    },

    tutoComplete() {
      this.send("ENTER");
    },
  },
});
