
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },
  methods: {
    onDoneDrawing() {
      this.send("ENTER");
    },
  },
});
