
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";
import AppService from "@/store/states/AppService";
import ScrollTrigger from "gsap/ScrollTrigger";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  data() {
    return {
      showTip: false,
      forceDarkTheme: false,
      scrollTrigger: null,
    };
  },

  computed: {
    shareURL(): string {
      return process.env.VUE_APP_WEBSITE_URL;
    },

    tipText(): string {
      return `The link is copied ! \nPaste it to share !`;
    },

    elClass() {
      return {
        "theme-dark":
          (this as any).state.matches("artwork.default") ||
          (this as any).state.matches("artwork.share") ||
          (this as any).state.matches("step_do.outro") ||
          (this as any).state.matches("step_love.outro") ||
          (this as any).state.matches("step_speak.outro") ||
          (this as any).state.matches("step_see.default") ||
          (this as any).state.matches("step_see.outro") ||
          (this as any).forceDarkTheme,
        // (this as any).state.matches("collab.default") ||
        // (this as any).state.matches("agenda.default"),
        // "theme-light":
        //   !(this as any).state.matches("artwork.default") &&
        //   !(this as any).state.matches("artwork.share") &&
        //   !(this as any).state.matches("step_do.outro") &&
        //   !(this as any).state.matches("step_love.outro") &&
        //   !(this as any).state.matches("step_speak.outro") &&
        //   !(this as any).state.matches("step_see.default") &&
        //   !(this as any).state.matches("step_see.outro"),
      };
    },
  },

  watch: {
    showTip(showTip) {},

    state() {
      if (
        (this as any).state.matches("collab.default") ||
        (this as any).state.matches("agenda.default")
      ) {
        this.$nextTick(() => {
          this.scrollTrigger = ScrollTrigger.create({
            scroller: ".view-scroll",
            trigger: ".Footer",
            onEnter: () => {
              this.forceDarkTheme = true;
            },
            onLeave: () => {
              this.forceDarkTheme = false;
            },
          });
        });
      }
    },
  },

  beforeDestroy() {
    this.scrollTrigger?.kill();
  },
});
