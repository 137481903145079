import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppVersion = _resolveComponent("AppVersion")!
  const _component_AppTimeline = _resolveComponent("AppTimeline")!
  const _component_UITab = _resolveComponent("UITab")!
  const _component_UIMenu = _resolveComponent("UIMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_GLViewer = _resolveComponent("GLViewer")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_RotateScreen = _resolveComponent("RotateScreen")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.debug)
      ? (_openBlock(), _createBlock(_component_AppVersion, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showTimeline)
      ? (_openBlock(), _createBlock(_component_AppTimeline, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "slide-y",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.showTab)
          ? (_openBlock(), _createBlock(_component_UITab, {
              step: _ctx.tab,
              audioId: _ctx.audioId,
              key: _ctx.tab
            }, null, 8, ["step", "audioId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_UIMenu, null, null, 512), [
      [_vShow, _ctx.menuOpen]
    ]),
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_GLViewer),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewList, (c) => {
      return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(c), { key: c }, null, 512)), [
        [_vShow, _ctx.viewVisible]
      ])
    }), 128)),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_AppFooter, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_RotateScreen)
  ]))
}