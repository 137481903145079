const URL = "/api/"

export interface face {
  age: number,
  guid: string,
  mood: string,
  mantra: string,
  country: string,
  isMyFace?: boolean,
}

export default class ArtworkFaces {
  static _self: ArtworkFaces

  nextToken: string = ''
  myFace: face
  faces: face[] = []

  static get occurence(): ArtworkFaces {
    if (!ArtworkFaces._self) ArtworkFaces._self = new ArtworkFaces()
    return ArtworkFaces._self
  }

  async getFaces() {
    if (this.nextToken === null) return

    const rawResponse = await fetch(`${URL}list?nextToken=${this.nextToken}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`RQbnec0M:u59qoQVrjsua`),
        'Access-Control-Allow-Origin': '*'
      },
    });
    const content = await rawResponse.json();
    this.nextToken = content.nextToken

    const records = this.myFace
      ? content.records.map(face => {
        if (this.myFace.guid === face.guid) {
          return {
            ...face,
            isMyFace: true
          }
        }
        return face
      })
      : content.records

    const faces = this.myFace ? [this.myFace, ...records] : records
    this.faces.push(...faces)
  }

  async loadFaces() {
    await this.getFaces()
    await this.getFaces()
  }

  addUserFace(face: face) {
    this.faces.unshift(face)
    this.myFace = face
  }

}