
import gsap from "gsap";
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import Delay from "@/core/Delay";
import Visage from "@/api/visage/visage";
import AppService from "@/store/states/AppService";
import { Viewport } from "@/store/modules/Viewport";
import { setemotion, Visage as VisageStore } from "@/store/modules/Visage";
import { AppStore, setMediaEnabled } from "@/store/modules/AppStore";
import { setEmotionCard } from "@/store/modules/UserCard";

import UIBtn from "@/components/UIBtn/UIBtnPrimary.vue";
import UIBtnCamera from "@/components/UIBtn/UIBtnCamera.vue";
import { AppState } from "@/store/states/AppStateMachine";
import Tracking from "@/core/Tracking";
import UIUserMediaSwitch from "@/components/UIUserMediaSwitch/UIUserMediaSwitch.vue";

const EMOTIONS = [
  "anger",
  "disgust",
  "fear",
  "happiness",
  "sadness",
  "surprise",
  "neutral",
];

export default defineComponent({
  components: { UIBtnCamera },

  computed: {
    emotionCollection() {
      return EMOTIONS.filter((emotion) => emotion !== "neutral");
    },

    emotion() {
      return VisageStore.currentEmotion;
    },

    visageReady() {
      return VisageStore.isReady;
    },

    visageCamera() {
      return VisageStore.isCamera;
    },

    isMedia() {
      return AppStore.mediaEnabled;
    },

    mediaDisabled() {
      return AppStore.mediaDisabled;
    },

    btnDoneLabel(): string {
      return Viewport.isDesktop ? "Validate this emotion" : "Validate";
    },

    isNeutral(): boolean {
      return EMOTIONS[this.emotionId] === "neutral";
    },

    isStateDefault(): boolean {
      return this.state.value["step_feel"] === "default";
    },
  },

  data() {
    return {
      emotionId: 0,
    };
  },

  setup() {
    return {
      ...useActor(AppService),
    };
  },

  mounted() {
    if (AppStore.mediaEnabled)
      Visage.occurence.startCamera(this.$refs.video, false, true);
    else setemotion(EMOTIONS[this.emotionId]);
  },

  beforeUnmount() {
    if (AppStore.mediaEnabled) Visage.occurence.pause();
  },

  methods: {
    enter(el: Element, onComplete: GSAPCallback) {
      const mediaSwitch = this.$refs.mediaSwitch as typeof UIUserMediaSwitch;
      if (mediaSwitch) {
        mediaSwitch.beforeAppear();
      }

      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });

      tl.addLabel("start", "+=2");

      tl.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: "sine.out",
          onStart: () => {
            (this.$refs.btnDone as typeof UIBtn).enter();
          },
        },
        "start"
      );

      tl.fromTo(
        [
          Array.from(
            this.$el.querySelectorAll(".trnstn-el, .MediaSwitch > button")
          ),
        ],
        {
          opacity: 0,
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: "cubic.out",
        },
        "start+=0.1"
      );

      tl.fromTo(
        [
          this.$el.querySelector(".EmotionSlider__ControlPrev"),
          this.$el.querySelector(".EmotionSlider__ControlNext"),
        ],
        {
          scale: 0,
        },
        {
          scale: 1,
          stagger: 0.1,
          duration: 1,
          ease: "cta.y.out",
          onStart: async () => {
            if (mediaSwitch) {
              await Delay(400);
              mediaSwitch.appear();
            }
          },
        },
        "start+=0.4"
      );
    },

    leave(el: Element, onComplete: GSAPCallback) {
      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.to(el, { opacity: 0, duration: 0.5, ease: "sine.out" });
    },

    onSelectFace() {
      if (this.emotion === "neutral") return;
      setEmotionCard(this.emotion);
      Tracking.eventPush("validate", "xp-cta-i-feel");
      this.send("ENTER");
    },

    back() {
      this.send("BACK");
    },

    previous() {
      this.emotionId--;
      if (EMOTIONS[this.emotionId] === "neutral") this.emotionId--;
      this.changeEmotion();
    },

    next() {
      this.emotionId++;
      if (EMOTIONS[this.emotionId] === "neutral") this.emotionId++;
      this.changeEmotion();
    },

    changeEmotion() {
      if (this.emotionId > EMOTIONS.length - 1) this.emotionId = 0;
      if (this.emotionId <= -1) this.emotionId = EMOTIONS.length - 2;
      setemotion(EMOTIONS[this.emotionId]);
    },

    async setMedia() {
      setMediaEnabled(!AppStore.mediaEnabled);
      await this.$nextTick();
      if (AppStore.mediaEnabled && !VisageStore.isCamera)
        Visage.occurence.startCamera(this.$refs.video, false, true);
    },
  },
});

if (module.hot) {
  module.hot.decline();
}
