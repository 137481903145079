import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c18aec9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "background",
  class: "ui-hold-btn__background"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "ui-hold-btn",
    onTouchstart: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args)), ["prevent"])),
    onMousedown: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args)), ["prevent"])),
    onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
    onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
    onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, null, 512),
    _createVNode(_Transition, {
      name: "fade-transition-cmn",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("span", {
          ref: "label",
          class: "ui-hold-btn__label",
          key: _ctx.state
        }, _toDisplayString(_ctx.label), 513))
      ]),
      _: 1
    })
  ], 32))
}