
import { defineComponent } from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";
import {
  collectAppStateViews,
  TabStateEnum,
} from "@/store/states/AppStateMachine";
import { initViewport } from "@/store/modules/Viewport";
import { AppStore, AppThemeEnum, setAppTheme } from "@/store/modules/AppStore";
import { Menu } from "@/store/modules/Menu";
import { Sound } from "@/store/modules/Sound";

import AppVersion from "@/components/AppVersion/AppVersion.vue";
import AppHeader from "@/components/AppHeader/AppHeader.vue";
import AppFooter from "@/components/AppFooter/AppFooter.vue";
import AppTimeline from "@/components/AppTimeline/AppTimeline.vue";
import GLViewer from "@/components/GLViewer/GLViewer.vue";
import RotateScreen from "@/components/RotateScreen/RotateScreen.vue";
import UIMenu from "@/components/UIMenu/UIMenu.vue";
import UITab from "@/components/UITab/UITab.vue";

import MuseumVue from "@/views/Museum.vue";
import LoadingVue from "@/views/Loading.vue";
import StepMeVue from "@/views/StepMe.vue";
import StepDoVue from "@/views/StepDo.vue";
import StepSpeakVue from "@/views/StepSpeak.vue";
import StepLoveVue from "@/views/StepLove.vue";
import StepFeelVue from "@/views/StepFeel.vue";
import StepSeeVue from "@/views/StepSee.vue";
import ArtworkVue from "@/views/Artwork.vue";
import ManifestoVue from "@/views/Manifesto.vue";
import TutoVue from "@/views/Tuto.vue";
import OutroVue from "@/views/Outro.vue";
import TutoDoVue from "@/views/TutoDo.vue";
import TutoFeelVue from "@/views/TutoFeel.vue";
import TutoLoveVue from "@/views/TutoLove.vue";
import TutoSeeVue from "@/views/TutoSee.vue";
import TutoSpeakVue from "@/views/TutoSpeak.vue";
import TutoUnderstandVue from "@/views/TutoUnderstand.vue";
import RequestCameraVue from "@/views/RequestCamera.vue";
import ShareVue from "@/views/Share.vue";
import AgendaVue from "@/views/Agenda.vue";
import CollaborationVue from "@/views/Collaboration.vue";

import "@/core/GSAPPlugins";
import "@/utils/Ease";

export default defineComponent({
  name: "App",

  setup() {
    initViewport();

    return {
      debug: AppStore.debug,
      ...useActor(AppService),
    };
  },

  components: {
    AppVersion,
    GLViewer,
    AppHeader,
    AppFooter,
    LoadingVue,
    MuseumVue,
    StepMeVue,
    StepDoVue,
    StepSpeakVue,
    StepLoveVue,
    StepFeelVue,
    StepSeeVue,
    TutoDoVue,
    TutoFeelVue,
    TutoLoveVue,
    TutoSeeVue,
    TutoSpeakVue,
    TutoUnderstandVue,
    ArtworkVue,
    ManifestoVue,
    TutoVue,
    OutroVue,
    RequestCameraVue,
    ShareVue,
    AgendaVue,
    CollaborationVue,
    UIMenu,
    UITab,
    AppTimeline,
    RotateScreen
  },

  async mounted() {
    this.send("INIT");
  },

  computed: {
    showHeader(): boolean {
      return AppStore.load_progress === 1 && !this.state.matches("loading");
    },

    showFooter(): boolean {
      return AppStore.load_progress === 1 && !this.state.matches("loading");
    },

    showTimeline(): boolean {
      return (
        AppStore.load_progress === 1 && !this.state.matches("loading")
      );
    },

    viewVisible(): boolean {
      return !this.state.context.museum_dev;
    },

    menuOpen(): boolean {
      return Menu.open;
    },

    showTab(): boolean {
      return this.tab !== TabStateEnum.EMPTY && !this.menuOpen;
    },

    tab(): TabStateEnum {
      // return TabStore.tab;
      return this.state.context.tab;
    },

    audioId(): string {
      return Sound.currentVoice;
    },

    viewList(): string[] {
      const viewList = collectAppStateViews(this.state);
      return viewList;
    },

    theme(): AppThemeEnum {
      return AppStore.theme;
    },
  },

  watch: {
    menuOpen: {
      immediate: true,
      handler(open) {
        if (open) {
          setAppTheme(AppThemeEnum.DARK);
        } else {
          setAppTheme(this.state.context.theme);
        }
      },
    },

    "state.context.theme": {
      immediate: true,
      handler(theme: AppThemeEnum) {
        setAppTheme(theme);
      },
    },

    theme: {
      immediate: true,
      handler(theme, oldTheme) {
        document.documentElement.classList.remove(`theme-${oldTheme}`);
        document.documentElement.classList.add(`theme-${theme}`);
      },
    },
  },
});
