import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f27d0ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "StepFeel view-default"
}
const _hoisted_2 = { class: "VisageCameraFeedback" }
const _hoisted_3 = { class: "VideoReturn trnstn-el" }
const _hoisted_4 = {
  ref: "video",
  autoplay: "",
  playsinline: "",
  muted: ""
}
const _hoisted_5 = {
  key: 0,
  class: "VisageCameraFeedback__Loading trnstn-el"
}
const _hoisted_6 = { class: "EmotionSlider" }
const _hoisted_7 = { class: "EmotionSlider__Indicators trnstn-el" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_btn = _resolveComponent("ui-btn")!
  const _component_ui_btn_icon = _resolveComponent("ui-btn-icon")!
  const _component_ui_user_media_switch = _resolveComponent("ui-user-media-switch")!

  return (_openBlock(), _createBlock(_Transition, {
    onEnter: _ctx.enter,
    onLeave: _ctx.leave,
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isStateDefault)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_Transition, {
              name: "fade-transition-cmn",
              duration: "1000",
              appear: ""
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(["StepFeel__UserMedia", { show: _ctx.isMedia }])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("video", _hoisted_4, null, 512)
                    ]),
                    (_ctx.visageCamera && !_ctx.visageReady)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Loading Visage SDK... Hold on! "))
                      : _createCommentVNode("", true)
                  ]),
                  (!_ctx.visageCamera || _ctx.visageReady)
                    ? (_openBlock(), _createBlock(_component_ui_btn, {
                        key: 0,
                        class: "BtnDone",
                        autoplay: false,
                        onClick: _ctx.onSelectFace
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.btnDoneLabel), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ], 2), [
                  [_vShow, _ctx.isMedia]
                ])
              ]),
              _: 1
            }),
            _createVNode(_Transition, {
              name: "fade-transition-cmn",
              duration: "1000",
              appear: ""
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(["StepFeel__NoUserMedia", { show: !_ctx.isMedia }])
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ui_btn_icon, {
                      class: "EmotionSlider__ControlPrev",
                      name: "arrow-left",
                      onClick: _ctx.previous
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emotionCollection, (emo) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["Emotion", [`Emotion--${emo}`, { active: emo === _ctx.emotion }]]),
                          key: emo.id
                        }, null, 2))
                      }), 128))
                    ]),
                    _createVNode(_component_ui_btn_icon, {
                      class: "EmotionSlider__ControlNext",
                      name: "arrow-right",
                      onClick: _ctx.next
                    }, null, 8, ["onClick"])
                  ]),
                  _createVNode(_component_ui_btn, {
                    class: "BtnDone",
                    autoplay: true,
                    disabled: _ctx.isNeutral,
                    onClick: _ctx.onSelectFace,
                    ref: "btnDone"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.btnDoneLabel), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ], 2), [
                  [_vShow, !_ctx.isMedia]
                ])
              ]),
              _: 1
            }),
            (!_ctx.mediaDisabled)
              ? (_openBlock(), _createBlock(_component_ui_user_media_switch, {
                  key: 0,
                  ref: "mediaSwitch",
                  onToggle: _ctx.setMedia,
                  onTip: _ctx.back,
                  checked: _ctx.isMedia,
                  class: "MediaSwitch"
                }, null, 8, ["onToggle", "onTip", "checked"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onEnter", "onLeave"]))
}