import GLTFResource from "@/webgl/assets/GLTFResource";
import Node from "nanogl-node";
// import Config from "@/webgl/Config";
import MaterialFactory from "./MaterialFactory";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import MaterialOverrideExtension2 from "@/webgl/lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension2";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import PointLight from "@/webgl/lib/nanogl-pbr/src/lighting/PointLight";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import Paths from "@/core/Paths";
import IBL from "nanogl-pbr/lighting/Ibl";
import { ArrayBufferResource } from "@/webgl/assets/ArrayBufferResource";
import ResourceGroup from "@/webgl/assets/ResourceGroup";

import { webpSupport } from "@/webgl/lib/nanogl-gltf/lib/extensions/EXT_texture_webp/webpSupport";
import { isWebpSupport, Viewport } from '@/store/modules/Viewport';
import Reflect from "./Reflect";
import { CreatePane } from "@/dev/Tweak";
import { Pane } from "tweakpane";

export default class MuseumScene {
  gltf: Gltf;

  materials: MaterialFactory;

  lightBall: PointLight;
  resources: ResourceGroup;

  readonly root = new Node()
  private _loadPromise: Promise<void>;
  private _loaded: boolean;
  private ibl: IBL;
  public reflect: Reflect;

  public isReflect = true

///////////////
////////////
///////////

  get ActiveIBL(): IBL {
    return this.ibl;
  }

  constructor(
    public readonly scene: Scene
  ) {
    this.resources = new ResourceGroup();
    this.setupResources()
    this.scene.root.add(this.root);
    
    if(this.isReflect) {
      this.reflect = new Reflect(this.scene);
      this.reflect.groundHeight = -0.04;
    }
  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
/////////////////
//////////////////////////////////
////////////////////////////////////
/////////////
    return this._loadPromise;
  }

  public getMaterial(name) {
    return this.materials.getMaterial(name)
  }

  private async _doLoad(): Promise<void> {

    const iswebp = await webpSupport()
    if (iswebp) isWebpSupport();
    this.materials = new MaterialFactory(this)

    await this._loadMuseumGltf()

    this._loaded = true

  }

  private async _loadMuseumGltf(){
    const gltfOverride = new MaterialOverrideExtension2();
    gltfOverride.getMaterial = this.materials.getMaterial
    await this.materials.texs.load()
    await this.resources.load()

    this.gltf = await new GLTFResource(
      require( '@/assets/webgl/museum2/Museum.glb'),
      this.scene,
      {
        extensions: [gltfOverride]
      }
    ).load()


    const iblGltf = await new GLTFResource(require(`@/assets/webgl/ibl/IBL.gltf`), this.scene).load();

    this.ibl = iblGltf.extras.lightsImageBased.list[0].ibl;

    this.scene.lights.addIbl(this.ibl)

    this.root.add(this.gltf.root);


    await this.materials.lightmaps.load()

  }


  unload(): void {
    if( !this._loaded ) return
    this.root.remove(this.gltf.root);
    this.gltf = null
    this.scene.lights.remove(this.lightBall);
    this.lightBall = null;
  }

  setupResources() {
    this.resources.add(
      new ArrayBufferResource(Viewport.isDesktop ? require(`@/assets/webgl/path/cinemapath.bin`) : require(`@/assets/webgl/path/cinemamobilepath.bin`)),
      "pathcam"
    )
    this.resources.add(
      new ArrayBufferResource(require(`@/assets/webgl/path/cinemacanvaspath.bin`)),
      "pathcanvascam"
    )
    this.resources.add(
      new ArrayBufferResource(require(`@/assets/webgl/path/cinemacollabpath.bin`)),
      "pathcollabcam"
    )
    this.resources.add(
      new ArrayBufferResource(require(`@/assets/webgl/path/cinemaskipagpath.bin`)),
      "pathskipag"
    )
  }
  

  preRender(dt: number) {
    if(this.isReflect) {
      this.materials.reflChunk.setSize(this.scene.glview.width, this.scene.glview.height);
    }
  }


  render(camera: Camera) {
    this.renderPass(camera, Masks.OPAQUE, Passes.DEFAULT);
    this.renderPass(camera, Masks.BLENDED, Passes.DEFAULT);
  }

  reflectPass(camera: Camera) {
    if(this.isReflect) {
      const glstate = this.scene.glstate
      const gl = this.scene.gl

      glstate.push(this.reflect.globalCfg)
      glstate.apply()

      this.reflect.bindAndClear();

      this.reflect.processCamera(camera);

      this.renderPass(camera, Masks.REFLECTED, Passes.DEFAULT);
      this.renderPass(camera, Masks.BLENDED, Passes.DEFAULT);

      this.reflect.blitRenderBuffer();
      this.reflect.processOutput();

      this.reflect.restoreCamera(camera);

      glstate.pop();
    }
  }


  renderPass(camera: Camera, mask: Masks, pass: Passes = Passes.DEFAULT) {
    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera, mask, pass)
    }
  }

}