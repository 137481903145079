var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.frag" )() )+
'\n'+
( require( "../includes/blends.glsl" )() )+
'\n\nuniform float uZoom;\nuniform float uRoll;\nuniform vec2 uAspect;\nuniform vec2 uOrigin;\nuniform sampler2D uTex;\nuniform sampler2D uWall;\nuniform sampler2D uReflection;\nuniform float uGeneralOpacity;\nuniform vec2 uMouse;\nuniform float uOffsetX;\n\nIN vec2 vTexCoord;\n\nvoid main(void){\n  vec3 background = vec3(0.52, 0.24, 0.49);\n  float zoom = (1. + uZoom * 0.3);\n  vec2 uvs = vec2(1. - (vTexCoord.x + uOffsetX), vTexCoord.y);\n  vec2 centeredUvs = vec2(1. - vTexCoord.x, vTexCoord.y) - vec2(0.5);\n  vec2 uvsMove = centeredUvs - uOrigin * zoom;\n\n  vec4 wallTex = texture2D(uWall, uvsMove * 8. / zoom);\n  vec3 wall = background - (0.8 - wallTex.rgb) * 0.3;\n\n  vec2 reflectionMove = (uMouse + vec2(0.5)) * 0.02;\n  vec4 reflection = texture2D(uReflection, ((centeredUvs + reflectionMove) * uAspect) / zoom + vec2(0.5));\n  vec3 wallReflection = blendOverlay(wall, reflection.rgb, 0.3);\n\n  vec3 light = mix(vec3(1., 1., 1.), vec3(0., 0., 0.), (vTexCoord.x + vTexCoord.y));\n  vec3 wallLight = wallReflection * (0.9 + light * 0.4);\n\n  vec4 tex = texture2D(uTex, uvs);\n  vec3 texOpacity = tex.xyz * (1. - uRoll * 0.3);\n\n  vec3 color = wallLight * (1. - texOpacity.x) + texOpacity;\n\n  gl_FragColor = vec4(color.x, color.y, color.z, 1. * uGeneralOpacity);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;