
import gsap from "gsap";
import { defineComponent, nextTick } from "vue";

import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";

export enum BigLinkVariant {
  EMPTY = "Empty",
  COLLAB = "Collaboration",
  AGENDA = "Agenda",
  NEWSLETTER = "Newsletter",
}

export default defineComponent({
  setup() {},

  data() {
    return {
      tl: null,
    };
  },

  props: {
    variant: {
      type: String,
      default: BigLinkVariant.COLLAB,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.tl = this.createTimeline();
    });
  },

  methods: {
    createTimeline(): gsap.core.Timeline {
      const tl: gsap.core.Timeline = gsap.timeline({ paused: true });
      tl.addLabel("start");

      tl.fromTo(
        this.$refs.doodle as Element,
        {
          transformOrigin: "0% 100%",
          rotate: "+=10deg",
          opacity: 0,
        },
        {
          rotate: "-=10deg",
          opacity: 1,
          duration: 1,
          ease: "cubic.inOut",
        },
        "start"
      );

      tl.fromTo(
        this.$refs.arrow as Element,
        {
          transformOrigin: "100% 0%",
          rotate: "+=45deg",
          opacity: 0,
        },
        {
          rotate: "-=45deg",
          opacity: 1,
          duration: 1,
          ease: "cubic.inOut",
        },
        "start"
      );

      tl.fromTo(
        this.$refs.underline as Element,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 1,
          ease: "cubic.inOut",
        },
        "start"
      );
      return tl;
    },

    mouseenter() {
      AudioManager.playUI(AUDIO_ID.UI_ROLLOVER);

      gsap.set(this.$refs.underline as Element, { transformOrigin: "0% 50%" });
      this.tl.restart();
    },

    mouseleave() {
      if (this.$refs.underline) {
        gsap.set(this.$refs.underline as Element, {
          transformOrigin: "100% 50%",
        });
      }
      this.tl.reverse();
    },

    click() {
      AudioManager.playUI(AUDIO_ID.UI_CLICK_SECONDARY);
    },
  },
});
