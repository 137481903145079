import { IActivity } from "@/webgl/activities/Activity";
import DoActivity from "@/webgl/activities/DoActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class DoActivityServiceImpl extends ActivityService {
  createActivity(): IActivity {
    const scene = GLApp.getInstance().scene
    return new DoActivity(scene)
  }
}

let DoActivityService = new DoActivityServiceImpl('feel')

export default DoActivityService


/////////////
/////////////////
//////////////////////
/////////////////////////////////////
////////////////////////////////////////////
 
//////////

