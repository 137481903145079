
import gsap from "gsap";
import AppService from "@/store/states/AppService";
import { useActor } from "@xstate/vue";
import { defineComponent } from "vue";
import { Viewport } from "@/store/modules/Viewport";
import {
  setMediaDisabled,
  setMediaEnabled,
  setMicroDisabled,
  setMicroEnabled,
} from "@/store/modules/AppStore";
import { AppState } from "@/store/states/AppStateMachine";
import GLApp from "@/webgl/main";
import BackgroundActivity from "@/webgl/activities/BackgroundActivity";
import UIBtnPrimary from "@/components/UIBtn/UIBtnPrimary.vue";

export default defineComponent({
  setup() {
    return {
      ...useActor(AppService),
    };
  },

  computed: {
    isRequestCamera(): boolean {
      return this.state.value["step_feel"] === "requestCamera";
    },
  },

  methods: {
    enterRequestCamera(el: Element, onComplete: GSAPCallback) {
      const access: Element = this.$el.querySelector(".StepRequest__Access");

      gsap.set(access, {
        clearProps: "display",
      });

      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.addLabel("start");
      tl.from(
        access,
        {
          x: Viewport.windowWidth,
          duration: 2,
          ease: "quart.inOut",
          onStart: () => this.slideWall(),
        },
        "start+=0.5"
      );
      tl.from(
        this.$el.querySelector(".DisclaimerBorder"),
        {
          xPercent: -100,
          duration: 2,
          ease: "quart.inOut",
        },
        "start+=1"
      );
      tl.from(
        this.$el.querySelector(".DisclaimerBorder__Content"),
        {
          xPercent: 100,
          duration: 2,
          ease: "quart.inOut",
        },
        "start+=1"
      );
      tl.add((this.$refs.startBtn as typeof UIBtnPrimary)?.enter(), "-=1.5");
    },

    leaveRequestCamera(el: Element, onComplete: GSAPCallback) {
      const access: Element = this.$el.querySelector(".StepRequest__Access");

      const tl: gsap.core.Timeline = gsap.timeline({ onComplete });
      tl.addLabel("start");
      tl.to(
        access,
        {
          x: -Viewport.windowWidth,
          duration: 2,
          ease: "quart.inOut",
          onStart: () => this.slideWall(),
        },
        "start"
      );
      tl.add((this.$refs.startBtn as typeof UIBtnPrimary)?.leave(), "-=1.5");
    },

    onDoneDrawing() {
      this.send("ENTER");
    },

    slideWall() {
      const bgActivity = GLApp.getInstance().scene.activities.getActivity(
        "background"
      ) as BackgroundActivity;
      bgActivity?.slideWall();
    },

    async onGetPermission() {
      let videoStream = null;
      try {
        videoStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
      } catch (e) {
        console.warn("error media", e);
      }

      setMediaEnabled(videoStream !== null);
      if (videoStream === null) setMediaDisabled();

      if (videoStream && videoStream.getTracks()) {
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
        videoStream = null;
      }

      let audioStream = null;
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
      } catch (e) {
        console.warn("error media", e);
      }

      setMicroEnabled(audioStream !== null);
      if (audioStream === null) setMicroDisabled();

      if (audioStream && audioStream.getTracks()) {
        audioStream.getTracks().forEach(function (track) {
          track.stop();
        });
        audioStream = null;
      }

      (this.$refs.startBtn as typeof UIBtnPrimary)?.leave();

      this.send("ENTER");
    },
  },
});
