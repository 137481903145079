
import { defineComponent } from "vue";
import gsap from "gsap";

export default defineComponent({
  setup() {},

  props: {
    duration: {
      type: Number,
      default: 2.5,
    },
  },

  data() {
    return {
      tl: null,
      hold: false,
      release: false,
    };
  },

  computed: {
    state(): string {
      return this.release ? "release" : "hold";
    },

    label(): string {
      return this.release ? "release" : "hold";
    },
  },

  mounted() {
    this.createTimeline();
  },

  beforeDestroy() {
    this.tl?.kill();
    this.tl?.clear();
    this.tl = null;
  },

  methods: {
    createTimeline() {
      const tl: gsap.core.Timeline = gsap.timeline({
        paused: true,
        onComplete: () => {
          this.holdComplete()
        },
      });

      tl.addLabel("start");

      tl.fromTo(
        this.$refs.background as Element,
        {
          scale: 1,
        },
        {
          scale: 0,
          ease: "quart.out",
        },
        "start"
      );

      tl.to(
        this.$refs.label as Element,
        {
          rotation: `-=${15}_ccw`,
        },
        "start"
      );

      this.tl = tl;
      this.tl.duration(this.duration);
    },

    onMouseDown() {
      this.hold = true;
      this.tl.duration(this.duration).play();
    },

    onMouseUp() {
      this.hold = false;
      this.tl.duration(Number(this.duration) * 0.5).reverse();
    },

    holdComplete() {
      this.release = true;
      this.$emit("release");
    }
  },
});
